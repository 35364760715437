import React, { useState } from 'react';
import ButtonUi from '../ButtonUi';
import { IconUi } from '../IconUi';

export const SortingInputUi = ({fields, onChange}) => {

    const [sortingOrder, setSortingOrder] = useState('ascending');
    const [field, setField] = useState('unsorted');

    const handleDropdownChange = (e) => {
        setField(e.target.value)
        onChange({value: e.target.value, sortingOrder});
    }

    const handleSort = () => {
        if (sortingOrder === 'ascending') {
            setSortingOrder('descending');
            onChange({value: field, sortingOrder: 'descending'});
        } else {
            setSortingOrder('ascending')
            onChange({value: field, sortingOrder: 'ascending'});
        }
    }
    const renderSelectOption = (option) => {
        if (option.children) {
            return (
                <optgroup label={option.label} key={option.value}>
                    {option.children.map(o => renderSelectOption(o))}
                </optgroup>
            )
        } else {
            return (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            )
        }
    }

    return (
        <div style={{display: 'inline-flex'}}>
            <select defaultValue={field} onChange={handleDropdownChange}>
                {[{label:'unsorted', value: null}, ...fields].map(o => renderSelectOption(o))}
            </select>
            <ButtonUi icon onClick={handleSort}>
                <IconUi name={`sort${(field !== 'unsorted') ? ((sortingOrder === 'ascending') ? ' up' : ' down') : ''}`}/>
            </ButtonUi>
        </div>
    )
}
export default SortingInputUi;
