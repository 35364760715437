const prefix = "VOTES::";

export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_LIST = `${prefix}SET_LIST`;
export const APPEND_LIST = `${prefix}APPEND_LIST`;
export const ADD_VOTE = `${prefix}ADD_VOTE`;
export const CLOSE_VOTE = `${prefix}CLOSE_VOTE`;
export const UPDATE_LIST = `${prefix}UPDATE_LIST`;
export const SET_VOTERS = `${prefix}SET_VOTERS`;
export const UPDATE_VOTER = `${prefix}UPDATE_VOTER`;
export const UPDATE_IN_SELECTED = `${prefix}UPDATE_IN_SELECTED`;
export const DELETE_FROM_SELECTED = `${prefix}DELETE_FROM_SELECTED`;