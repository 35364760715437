import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';
import { ErrorUnauthorized } from '../../errors'; 
import { addTagId } from '../persons/actions';

const setList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
})

const setLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
})

const appendList = (data) => ({
    type: acts.APPEND_LIST,
    payload: data,
})

const updateTagInList = (data) => ({
    type: acts.UPDATE,
    payload: data,
})


export const loadList = async (dispatch) => {
    dispatch(setLoading(true));

    const data = await api.getList();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(setList(data));

    dispatch(setLoading(false));
}

export const loadById = async ({dispatch, id}) => {
    dispatch(setLoading(true));

    const data = await api.getById(id);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(appendList([data]));

    dispatch(setLoading(false));
}

export const add = async ({dispatch, tag}) => {
    dispatch(setLoading(true));

    const createdTagId = await api.create(tag);

    if (createdTagId instanceof Error) {
        if (createdTagId.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw createdTagId;
    }

    dispatch(appendList([{...tag, id: createdTagId}]));
    dispatch(setLoading(false));
}

export const createAndAddToPerson = async ({dispatch, tag, personId}) => {
    dispatch(setLoading(true));

    const createdTagId = await api.create(tag);

    if (createdTagId instanceof Error) {
        if (createdTagId.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw createdTagId;
    }

    dispatch(appendList([{...tag, id: createdTagId}]));

    await addTagId({ dispatch, personId, tagId: createdTagId });

    dispatch(setLoading(false));
}

export const update = async ({dispatch, tag}) => {
    dispatch(setLoading(true));

    let updateData = await api.update(tag);

    if (updateData instanceof Error) {
        if (updateData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw updateData;
    }
    dispatch(updateTagInList(tag));
    dispatch(setLoading(false));

}
