import axios from 'axios';
import { cache } from '../../cache';
import { ErrorUnauthorized, ErrorForbidden } from '../../errors';

const rootRef = "/matches/api/v1";
const personsRef = "/persons";
const personsBookRef = "/book";
const personsUnbookRef = "/unbook";
const filesRef = "/files";
const tagsRef = "/tags";
const usersRef = "/users";
const workRef = "/work";
const vacanciesRef = "/vacancies";
const stageTypesRef = "/stage_types";
const category = "persons";

export const getPersons = async ({offset, limit, search='', bookedBy}) => {
    let url = `${rootRef}${personsRef}/?offset=${offset}&limit=${limit}`;

    if (search) {
        url += `&search=${search}`;
    }

    if (bookedBy) {
        url += `&booked_by=${bookedBy}`;
    }

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPersonsByUser = async ({offset, limit, userId}) => {
    let url = `${rootRef}${personsRef}/user/${userId}?offset=${offset}&limit=${limit}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPerson = async (id) => {
    const url = `${rootRef}${personsRef}/person/${id}`

    let response = {data: cache(category, url)};

    if (!response.data) {
        try {
            response = await axios(url);

            cache(category, url, response.data);

            return response.data;
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    return Error(ErrorUnauthorized)
                }

                return new Error(err.response.statusText)
            } 

            return Error(err.message)
        }
    }

    return response.data
}

export const getPersonsByFilter = async ({offset, limit, searchOptions}) => {
    let url = `${rootRef}${personsRef}/search?offset=${offset}&limit=${limit}`;

    try {
        const response = await axios(url,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: searchOptions,
            },);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPersonsByVacancy = async ({offset, limit, vacancyId}) => {
    let url = `${rootRef}${personsRef}/vacancy/${vacancyId}?offset=${offset}&limit=${limit}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}


export const getPersonsWork = async ({offset, limit, search='', bookedBy}) => {
    let url = `${rootRef}${personsRef}${workRef}?offset=${offset}&limit=${limit}`;

    if (search) {
        url += `&search=${search}`;
    }

    if (bookedBy) {
        url += `&booked_by=${bookedBy}`;
    }

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPersonsByUserWork = async ({offset, limit, userId}) => {
    let url = `${rootRef}${personsRef}${workRef}${usersRef}/user/${userId}?offset=${offset}&limit=${limit}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPersonsByFilterWork = async ({offset, limit, searchOptions, bookedBy}) => {
    let url = `${rootRef}${personsRef}${workRef}/search?offset=${offset}&limit=${limit}`;

    if (bookedBy) {
        url += `&booked_by=${bookedBy}`;
    }

    try {
        const response = await axios(url,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: searchOptions,
            },);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPersonsByVacancyWork = async ({offset, limit, vacancyId, bookedBy}) => {
    let url = `${rootRef}${personsRef}${workRef}${vacanciesRef}/vacancy/${vacancyId}?offset=${offset}&limit=${limit}`;

    if (bookedBy) {
        url += `&booked_by=${bookedBy}`;
    }

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPersonsByTagIdWork = async ({offset, limit, tagId, bookedBy}) => {
    let url = `${rootRef}${personsRef}${workRef}${tagsRef}/tag/${tagId}?offset=${offset}&limit=${limit}`;

    if (bookedBy) {
        url += `&booked_by=${bookedBy}`;
    }

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getPersonsByStageTypeIdWork = async ({offset, limit, stageTypeId, bookedBy}) => {
    let url = `${rootRef}${personsRef}${stageTypesRef}/stage_type/${stageTypeId}?offset=${offset}&limit=${limit}`;

    if (bookedBy) {
        url += `&booked_by=${bookedBy}`;
    }

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getDuplicates = async (person) => {
    let url = `${rootRef}${personsRef}/duplicates`;

    try {
        const response = await axios(url,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: person,
            }
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const addPerson = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${personsRef}/`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return 
    }
}

export const updatePerson = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${personsRef}/`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const bookPerson = async ({personId}) => {
    try {
        await axios(
            `${rootRef}${personsRef}/${personId}${personsBookRef}`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            },
        );
        return;

    } catch (err) {
        if (err.response) {
            switch (err.response) {
                case 403:
                    return Error(ErrorForbidden);
            
                case 401:
                    return Error(ErrorUnauthorized);

                default:
                    return new Error(err.response.statusText)
            }
        }

        return err
    }
}

export const unbookPerson = async ({personId}) => {
    try {
        await axios(
            `${rootRef}${personsRef}/${personId}${personsUnbookRef}`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            },
        );
        return;

    } catch (err) {
        if (err.response) {
            switch (err.response) {
                case 403:
                    return Error(ErrorForbidden);
            
                case 401:
                    return Error(ErrorUnauthorized);

                default:
                    return new Error(err.response.statusText)
            }
        }

        return err
    }
}

export const addTagIdToPerson = async ({personId, tagId}) => {
    try {
        await axios(
            `${rootRef}${personsRef}/${personId}${tagsRef}/tag/${tagId}/add`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            },
        );
        return;

    } catch (err) {
        if (err.response) {
            switch (err.response) {
                case 403:
                    return Error(ErrorForbidden);
            
                case 401:
                    return Error(ErrorUnauthorized);

                default:
                    return new Error(err.response.statusText)
            }
        }

        return err
    }
}

export const deleteTagIdFromPerson = async ({personId, tagId}) => {
    try {
        await axios(
            `${rootRef}${personsRef}/${personId}/${tagsRef}/tag/${tagId}/delete`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            },
        );
        return;

    } catch (err) {
        if (err.response) {
            switch (err.response) {
                case 403:
                    return Error(ErrorForbidden);
            
                case 401:
                    return Error(ErrorUnauthorized);

                default:
                    return new Error(err.response.statusText)
            }
        }

        return err
    }
}

export const bindFile = async ({fileId, personId}) => {
    const url = `${rootRef}${filesRef}/${fileId}/bind/${personId}`;

    try {
        const response = await axios(url, {
            method: "PUT",
            headers: {"Content-Type": "application/json"}
        });

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return 
    }
}