import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import mammoth from 'mammoth';
import { Form, Segment } from 'semantic-ui-react';

import FileUi from '../../../components/FileUi';
import Loader from '../../../components/Loader';
import PersonsDuplicateModal from '../../Modals/PersonsDuplicateModal';

import './PersonsNew.scss'

import * as personsActs from '../../../redux/persons/actions';
import * as filesActs from '../../../redux/files/actions';
import { handleError } from '../../../errorHandler';
import { zeroPerson, dataToPersonStruct } from '../../../person';
import { store } from '../../../store';
import { decodeFileData, parseVariablesFromHTMLStringFromHH, deepEqual, validate, getURLSearchParamByName } from '../../../utils';
import PersonCard from '../PersonCard';


export const PersonsNew = () => {

    const { dispatch, state } = useContext(store);

    const { 
        persons: {
            selected,
            duplicateIds,
            loading,
        },
        files: {
            list,
        }
    } = state;


    const [errors, setErrors] = useState({});
    const [isPreparing, setIsPreparing] = useState(false);
    const [preparedFile, setPreparedFile] = useState(undefined);

    const location = useLocation();
    const history = useHistory();

    const fileId = useRef(getURLSearchParamByName({urlSearch: location.search, name: 'file_id'}));

    useEffect(() => {
        personsActs.updateSelected({dispatch, id: 'new', data: {...zeroPerson, service: {id: 'new'}}});
    }, [dispatch])

    useEffect(() => {
        fileId.current && filesActs.load(dispatch, fileId.current)
            .catch(err=>{handleError(err)});
    }, [dispatch]);

    useEffect(() => {
        const parsedValues = fileId.current && list && list[0] && 
            (list[0].ext === 'txt') && 
            getValuesFromFile(list[0].binary_data);
        if (parsedValues) {
            personsActs.updateSelected({dispatch, id: 'new', data: dataToPersonStruct(parsedValues)});
        }
    }, [list, dispatch])

    useEffect(()=>{
        setIsPreparing(true);
        const file = list[0];

        if (!file) {
            setIsPreparing(false);
            return
        }

        let preparedFile;

        (async () => {
            switch (file.ext) {
                case "pdf":
                    preparedFile = ({...file, binary_data: encodeURI(file.binary_data)});
                    break
                case "txt":
                    preparedFile = ({...file, binary_data: decodeFileData(file.binary_data)});
                    break
                case 'docx':
                    const parsedDocx = await mammoth.convertToHtml({arrayBuffer: atob((file.binary_data))});
                    preparedFile = ({...file, binary_data: parsedDocx.value});
                    break
                default:
                    preparedFile = (file);
                    break
            }
            setPreparedFile(preparedFile);
            setIsPreparing(false);
        })();
    }, [list]);

    const handleDiscard = useCallback(() => {
        personsActs.discard(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (duplicateIds?.length === 0) {
            handleDiscard();
            personsActs.setDuplicateIds({dispatch, ids: null});
            history.replace('/persons');
        }
    }, [dispatch, duplicateIds, handleDiscard, history])

    const checkForErrors = useCallback(({value, validateOptions, fieldName}) => {
        const fieldError = validate({value, ...validateOptions});
        setErrors((errors) => {
            fieldError ?
                errors[fieldName] = fieldError
                :
                delete errors[fieldName];
            return errors
        });
    }, [setErrors])

    const getValuesFromFile = (data) => parseVariablesFromHTMLStringFromHH(decodeFileData(data));

    const handleFieldChange = ({ e, data }) => {
        personsActs.updateSelected({
            dispatch, 
            id: 'new',
            data: data ? 
                data  
                : 
                {[e.target.name]: e.target.value}   
            })
    };

    const handleAdd = () => {
        personsActs.checkForDuplicatesCreateAndBindFile({
            dispatch,
            data: {...selected?.new, service: undefined},
            fileId: fileId.current
        })
        .catch(err => handleError(err));
    };

    const handleAddModal = () => {
        personsActs.createAndBindFile({dispatch, data: {...selected.new, service: undefined}, fileId: fileId.current})
            .then(()=>{
                personsActs.setDuplicateIds({ dispatch, ids: null });
                handleDiscard();
                history.replace('/persons');
            })
            .catch(err => handleError(err));
    };

    const onModalDiscard = () => {
        personsActs.updateSelected({dispatch, id: 'new', data: {...zeroPerson, service: {id: 'new'}}});
        personsActs.setDuplicateIds({dispatch, ids: null})
    }

    return (
        <>
            <div className="persons-new">
                <div className="persons-new-split">
                    <div className="persons-new-split-left">
                        {!loading ?   
                            (() => {
                                const person = selected?.new || {...zeroPerson, service: {id: 'new'}};
                                const isPristine = deepEqual(person, zeroPerson);
                                return <Form onSubmit={handleAdd}>
                                    <PersonCard
                                        data={person}
                                        editable={true}
                                        onChangeField={handleFieldChange}
                                        onDiscard={() => {
                                            history.replace('/persons');
                                            handleDiscard();
                                        }}
                                        isPristine={isPristine}
                                        errors={errors}
                                        checkForErrors={checkForErrors}
                                    />
                                </Form>
                            })()
                            :
                            <Loader />
                        }
                    </div>
    
                    <div className="persons-new-split-right">
                    {fileId.current && (
                        !loading && !isPreparing ?
                            preparedFile &&
                                <Segment >
                                    <FileUi content={preparedFile}/>
                                </Segment>
                            :
                            <Loader />
                    )}
                    </div>
                </div>
            </div>
            {duplicateIds &&
                <PersonsDuplicateModal onCreate={handleAddModal} onModalDiscard={onModalDiscard}/>
            }
        </>
    )
}

export default PersonsNew;