import React from "react"

import './styles.scss'

export type OptionType = {
    onClick: (e: any) => void
    onFocus?: () => void
    onBlur?: () => void

    children: any
    selected?: boolean
}

const Option = ({children, onClick, onFocus, onBlur, selected}: OptionType) => {
    const classes = `select-option ${selected? 'selected': ''}`.trim()

    const onFocusHndl = () => {
        onFocus && onFocus()
    }

    const onBlurHndl = () => {
        onBlur && onBlur()
    }

    return (
        <div className={classes}>
            <button
                onFocus={onFocusHndl}
                onBlur={onBlurHndl}
                tabIndex={0}
                onClick={onClick}
            >
                {children}
            </button>
        </div>
    )
}

export default Option