const prefix = "PERSONS::";

export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_CAN_LOAD_MORE = `${prefix}SET_CAN_LOAD_MORE`;
export const SET_TOTAL_COUNT_BY_SEARCH = `${prefix}SET_TOTAL_COUNT_BY_SEARCH`;
export const INCREMENT_TOTAL_COUNT_BY_SEARCH = `${prefix}INCREMENT_TOTAL_COUNT_BY_SEARCH`;
export const SET_DUPLICATE_IDS = `${prefix}SET_DUPLICATE_IDS`;
export const SET_LIST = `${prefix}SET_LIST`;
export const APPEND_LIST = `${prefix}APPEND_LIST`;
export const SET_CHOSEN_ID = `${prefix}SET_CHOSEN_ID`;
export const UPDATE = `${prefix}UPDATE`;
export const ADD = `${prefix}ADD`;
export const ADD_TO_QUICK_ACCESS_LIST = `${prefix}ADD_TO_QUICK_ACCESS_LIST`;

export const SET_FILTERED_AND_SORTED_IDS = `${prefix}SET_FILTERED_AND_SORTED_IDS`;

export const SET_FULLTEXT_SEARCH_VALUE = `${prefix}SET_FULLTEXT_SEARCH_VALUE`;
export const SET_SEARCH_BY_BOOKING_ID = `${prefix}SET_FILTER_OPTIONS_BOOKING_ID`;
export const SET_SEARCH_BY_VACANCY_ID = `${prefix}SET_SEARCH_BY_VACANCY_ID`;
export const SET_SEARCH_BY_STAGE_TYPE_ID = `${prefix}SET_SEARCH_BY_STAGE_TYPE_ID`;

export const SET_SEARCH_OPTIONS = `${prefix}SET_SEARCH_OPTIONS`;
export const DELETE_SEARCH_OPTIONS = `${prefix}DELETE_SEARCH_OPTIONS`;

export const SET_FILTER_OPTIONS_INPUT_VALUE = `${prefix}SET_FILTER_OPTIONS_INPUT_VALUE`;
export const ADD_FILTER_OPTION = `${prefix}ADD_FILTER_OPTION`;
export const DELETE_FILTER_OPTION = `${prefix}DELETE_FILTER_OPTION`;
export const CLEAR_FILTER_OPTIONS = `${prefix}CLEAR_FILTER_OPTIONS`;

export const SET_SORTING_OPTION = `${prefix}SET_SORTING_OPTION`;

export const SET_EDITED_ID =  `${prefix}SET_EDITED_ID`;
export const SET_SELECTED_PERSON_ERRORS =  `${prefix}SET_SELECTED_ERRORS`;
export const SET_SELECTED_FIELD_ERRORS =  `${prefix}UPDATE_SELECTED_FIELD_ERRORS`;
export const UPDATE_SELECTED = `${prefix}UPDATE_SELECTED`;
export const DELETE_SELECTED = `${prefix}DELETE_SELECTED`;

export const SET_BOOKED = `${prefix}SET_BOOKED`;

export const ADD_TAG_ID = `${prefix}ADD_TAG_ID`;
export const DELETE_TAG_ID = `${prefix}DELETE_TAG_ID`;
