import { zeroRelationWithNames } from './relation';
import { formatDate, convertNestedObjectToDropdownStruct } from './utils';

export const dataToPersonStruct = (data) => {
    return {
        service: {
            id: data.id? data.id: "",
            created_at: data.created_at? formatDate(data.created_at): "",
            created_by: data.created_by? data.created_by: "",
            updated_at: data.updated_at? formatDate(data.updated_at): "",
            updated_by: data.updated_by? data.updated_by: "",
        },
        name: data.name? data.name: "",
        midname: data.midname? data.midname: "",
        surname: data.surname? data.surname: "",
        email: data.email? data.email: "",
        cell: data.cell? data.cell: "",
        city: data.city? data.city: "",
        birth: data.birth? formatDate(data.birth): "",
        work_at: data.work_at? data.work_at: "",
        scope: data.scope? data.scope: "",
        money: {
            amount: data.amount? data.amount: "",
            currency: data.currency? data.currency: "",
        },
        booking: data.booking? data.booking: {}
    }
}

export const personFieldScheme = {
    header: [
        [
            {fieldName: "surname", type: "string", required: true},
            {fieldName: "name", type: "string", required: true},
            {fieldName: "midname", type: "any"}
        ]
    ],
    meta: [
        [
            {fieldName: "created_at", type: "date", format: "YYYY-MM-DD"},
            {fieldName: "id", type: "number"}
        ]
    ],
    description: [
        [
            {fieldName: "city", type: "string"},
            {fieldName: "scope", type: "any"},
            {fieldName: "work_at", type: "any"},
            {fieldName: "birth", type: "date", format: "YYYY-MM-DD"},
        ],
        [
            {fieldName: "amount", type: "number"},
            {fieldName: "currency", type: "string"},
            {fieldName: "email", type: "email"},
            {fieldName: "cell", type: "tel"},
        ],
    ]
}

export const personSearchFieldScheme = {
    header: [
        [
            {fieldName: "surname", type: "string"},
            {fieldName: "name", type: "string"}
        ]
    ],
    description: [
        [
            {fieldName: "email", type: "email"},
            {fieldName: "cell", type: "tel"},
        ],
    ]
}
export const zeroPerson = {
    service: {
        id: undefined,
        created_at: "",
        created_by: "",
        updated_at: "",
        updated_by: "",
    },
    name: "",
    midname: "",
    surname: "",
    email: "",
    cell: "",
    city: "",
    birth: "",
    work_at: "",
    scope: "",
    money: {
        amount: "",
        currency: "",
    },
    booking: {
        is_booked: false,
        booked_by: undefined
    }
}

export const deconstructPerson = (person) => {
    return {...person, ...person.service, ...person.money, service: undefined, money: undefined}
}

export const personSortingFieldsTree = convertNestedObjectToDropdownStruct(zeroPerson);
export const personFilterFieldsTree = convertNestedObjectToDropdownStruct({...zeroPerson, relations: zeroRelationWithNames});

export const zeroPersonSearch = {
    name: "",
    surname: "",
    midname: "",
    city: "",
    scope: "",
    money: {
        amount: "",
        currency: "",
    },
    email: "",
    cell: "",
}
