import axios from 'axios';
import { ErrorUnauthorized } from '../../errors';

const rootRef = "/matches/api/v1";
const filesRef = "files"

export const getFiles = async ({id, offset, limit}) => {

    const url = `${rootRef}/${filesRef}/?person_id=${id}&offset=${offset}&limit=${limit}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return 
    }
}

export const getFile = async (fileId) => {
    const url = `${rootRef}/${filesRef}/${fileId}`;


    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return 
    }

}

export const uploadFile = async ({file, personId}) => {
    if (!personId) {
        throw new Error("personId is not provided");
    }
    let url = `${rootRef}/${filesRef}/upload/${personId}/bind`;

    try {
        const response = await axios(url, {
            method: "POST",
            data: file,
            // headers: {"Content-Type": "multipart/form-data"}
        });

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return 
    }
}