import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { Icon, Button, Form } from 'semantic-ui-react';

import { store } from '../../store';
import { deepEqual } from '../../utils';
import { handleError } from '../../errorHandler';
import * as personsActs from '../../redux/persons/actions';
import * as relActs from '../../redux/relations/actions';
import * as usersActs from '../../redux/users/actions';
import * as votesActs from '../../redux/votes/actions';
import * as vacActs from '../../redux/vacancies/actions';
import * as wsActs from '../../redux/websocket/actions';
import * as tagsActs from '../../redux/tags/actions';
import * as profActs from '../../redux/profiles/actions';

import PersonCard from './PersonCard';
import PersonSearchCard from './PersonSearchCard';
import VacanciesModal from './VacanciesModal';
import ConfirmModalUi from '../../components/ConfirmModalUi';
import PersonsDuplicateModal from '../Modals/PersonsDuplicateModal';

// import SortingInputUi from '../../components/SortingInputUi';
import { SearchUi } from "../../components/SearchUi";
import SelectUi from '../../components/SelectUi';
import InputUi from '../../components/StyledInputUi';
import { IconGroupUi, IconUi } from '../../components/IconUi';
import { VotesModalUi } from '../../components/VotesModalUi';
import { FilterInputsUi } from '../../components/FilterInputsUi';
import { ListUi } from '../../components/VirtualizedListUi';
import { CellMeasurerCacheWrapper, CellMeasurerUi } from '../../components/CellMeasurerUi';
import { InfiniteLoaderUi } from '../../components/InfiniteLoaderUi';
import { WindowScrollerUi } from '../../components/WindowScroller';
import { AutoSizerUi } from '../../components/AutoSizerUi';

import './Persons.scss'

import { 
    personFilterFieldsTree,
    // personSortingFieldsTree, 
    zeroPerson, 
    zeroPersonSearch 
} from '../../person';
import { isUserAdmin, isUserRecruiter } from '../../user';


const Persons = ({ onClick }) => {
    const { state, dispatch } = useContext(store);
    
    const [showCandidateForm, setShowCandidateForm] = useState(false);
    const [showSearchForm, setShowSearchForm] = useState(false);

    const [personIdVotesModalIsOpenBy, setPersonIdVotesModalIsOpenBy] = useState(null);
    const [vacanciesModalData, setVacanciesModalData] = useState(null);

    const [relationIdToDelete, setRelationIdToDelete] = useState(null);

    const {
        persons: {
            map,
            allIds,
            limit,
            chosenId,
            editedId,
            selected,
            selectedErrors,
            loading,
            filteredAndSortedIds,
            canLoadMore,
            filterOptions: {
                added: addedFilters,
            },
            fulltextSearchValue,
            searchByBookingId,
            searchOptions,
            searchByVacancyId,
            duplicateIds
        },
        users: {
            list: usersList,
            map: usersMap,
            allIds: usersAllIds,
        }, 
        user: {
            userData: currentUser,
        },
        relations: {
            map: relationsMap,
            byPersonId,
            stageTypesAllIds,
            stageTypesMap,
            stagesMapByRelationId,

            processingCancelReasons,
        },
        vacancies: {
            map: vacanciesMap,
            allIds: vacanciesAllIds,
            searchedIds: vacanciesSearchedIds,
            canLoadMore: vacanciesCanLoadMore,
            fulltextSearchValue: searchByVacancyValue,
            lastCreatedId: vacanciesLastCreatedId
        },
        votes: {
            byPersonId: votesByPersonId,
            selected: votesSelected,
            votersByVoteId
        },
        files: {
            list: filesList
        },
        tags: {
            map: tagsMap,
            allIds: tagsAllIds,
        },
        profiles: {
            list: profilesList,
            map: profilesMap,
        },
        groups: {
            list: groupsList,
            map: groupsMap
        }
    } = state;

    useEffect(() => {
        usersActs.loadList(dispatch)
            .catch(err => handleError(err));

        personsActs.loadList({ dispatch, offset: 0, limit, appendList: false })
            .catch(err => handleError(err));

        votesActs.loadListWithVoters(dispatch)
            .catch(err => handleError(err));

        relActs.listProcessingCancelReasons({dispatch})
            .catch(err => handleError(err));

        wsActs.connect({dispatch, onOpen: () => {wsActs.subscribeToVoters(dispatch)}});

        return () => {
            wsActs.disconnect(dispatch);
            personsActs.clearFilters(dispatch);
            personsActs.clearSearch(dispatch);
            vacActs.setFulltextSearchValue({dispatch, value: ''})
        };
    }, [dispatch, limit]);

    useEffect(() => {
        !chosenId && allIds && allIds[0] && personsActs.setChosenId(dispatch, allIds[0])
    }, [chosenId, allIds, dispatch])

    const loadMore = (offset) => {
        if (!loading && canLoadMore) {
            searchByVacancyId ?
                personsActs.searchByVacancy({ dispatch, offset: allIds.length, limit})
                :
                personsActs.loadThunk({ dispatch, offset, limit });
        }
    };

    const onClickEdit = useCallback((person) => {
        personsActs.select({dispatch, person});
    }, [dispatch])

    const onChangeFilterInput = (filterOption) => {
        personsActs.filter({ dispatch, filterOption })
    };

    const onChangeBookingSelect = useCallback((e, {value}) => {
        personsActs.searchByTextAndBookingId({ dispatch, bookedBy: value})
    }, [dispatch])

    const onDeleteFilter = (index) => {
        personsActs.deleteFilterOption({dispatch, index})
    }

    const onAddFilter = (option) => {
        personsActs.addFilterOption({dispatch, option})
    }

    const relationsById = useMemo(() => {
        const byPersonIdWithNames = {};
        Object.keys(byPersonId).map(personId => {
            byPersonIdWithNames[personId] = byPersonId[personId].map((r => {
                const vacancy = vacanciesMap[r.vacancy_id];
                const stages = stagesMapByRelationId[r.id];
                return {
                    ...r, 
                    vacancy_name: vacancy && vacancy.name,
                    stages: stages? stages.sort((a, b) => a.id - b.id): [],
                } 
            }))
            return undefined
        })
        return byPersonIdWithNames
    }, [byPersonId, vacanciesMap, stagesMapByRelationId]);

    const checkForErrors = useCallback(({value, validateOptions, personId, fieldName}) => {
        personsActs.checkForErrors({ dispatch, value, validateOptions, id: personId, fieldName});
    }, [dispatch])

    const onClickHndlr = useCallback((person) => {
        onClick && onClick(person);
    }, [onClick])

    const addPersonToSelected = useCallback((person) => {
        personsActs.updateSelected({dispatch, id: person.service.id, data: person});
    }, [dispatch]);

    const deletePersonFromSelected = useCallback((id) => {
        personsActs.deleteSelected(dispatch, id);
    }, [dispatch]);

    const handleDiscard = useCallback((id) => {
        personsActs.discard(dispatch);
    }, [dispatch]);

    const handleFieldChange = useCallback(({ e, id, data }) => {
        personsActs.updateSelected({
            dispatch, 
            id, 
            data: data ? 
                data  
                : 
                {[e.target.name]: e.target.value}   
            })
    }, [dispatch]);

    const handleAdd = useCallback(() => {
        personsActs.checkForDuplicatesAndCreate(dispatch, {...selected.new, service: undefined})
            .then(()=>{
                setShowCandidateForm(false);
            })
            .catch(err => handleError(err));
    }, [dispatch, selected]);

    const handleUpdate = useCallback((data) => {
        personsActs.update(dispatch, data)
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeFulltextSearch = (e) => {
        personsActs.searchByTextAndBookingId({ dispatch, value: e.currentTarget.value })

        vacActs.setFulltextSearchValue({dispatch, value: ''});
        setShowSearchForm(false);       
    }

    const handleSearch = useCallback(({person}) => {
        personsActs.startSearch({dispatch, person, limit})
            .catch(err => handleError(err));
    }, [dispatch, limit]);

    const handleSearchDiscard = useCallback((id) => {
        personsActs.endSearch(dispatch)
        setShowSearchForm(false);
    }, [dispatch]);
    
    const onChangeVacanciesFulltextSearch = (searchText) => {
        vacActs.debouncedStartSearch({dispatch, limit, searchText})
        vacActs.setFulltextSearchValue({dispatch, value: searchText});
    }

    const onClickVacanciesFulltextSearch = (vacancyId) => {
        vacActs.setFulltextSearchValue({ dispatch, value: vacanciesMap[vacancyId]?.name});
        setShowSearchForm(false);
        personsActs.startSearchByVacancy({ dispatch, limit, vacancyId })
            .catch(err => handleError(err));
    }
    const onDiscardVacanciesFulltextSearch = () => {
        if (searchByVacancyId) {
            personsActs.loadList({ dispatch, offset: 0, limit, appendList: false})
                .catch(err => handleError(err));
            personsActs.setSearchByVacancyId({dispatch, id: null});
        }
        vacActs.setFulltextSearchValue({dispatch, value: ''});
    }

    const onClickLoadMoreVacanciesFulltextSearch = () => {
        vacanciesCanLoadMore && vacActs.search({ dispatch, limit, offset: vacanciesSearchedIds.length})
    }

    const handleBook = useCallback(({personId, systemUserId}) => {
        personsActs.book({dispatch, personId, systemUserId})
            .catch(err => handleError(err));
    }, [dispatch]);

    const handleUnbook = useCallback(({personId, systemUserId}) =>{
        personsActs.unbook({dispatch, personId, systemUserId})
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeStage = useCallback(({relation_id, stage_type_id}) => {
        relActs.addStage({dispatch, relation_id, stage_type_id})
            .catch(err => handleError(err));
    }, [dispatch])

    const onClickProfileInput = useCallback(({personId, profileId}) => {
        setVacanciesModalData({personId, profileId});
    }, []);

    const onAddVacanciesInModal = async (vacancyIds) => {
        await relActs.addList({dispatch, vacancy_ids: vacancyIds, person_id: vacanciesModalData.personId})
            .catch(err => handleError(err));
    }

    const deleteRelation = async (relationId) => {
        relActs.deleteRelation({ dispatch, relationId })
            .catch(err => handleError(err));
    }

    const onDeleteRelation = useCallback((relationId) => {
        setRelationIdToDelete(relationId);
    }, []);

    const onChangeVote = useCallback(({person_id, name, value}) => {
        votesActs.updateSelected({dispatch, id: person_id, data: {[name]: value}});
    }, [dispatch])

    const onAddVote = useCallback(({id, ...voteData}) => {
        votesActs.add({dispatch, person_id: id, ...voteData})
            .catch(err => handleError(err));
    }, [dispatch]);

    const onCloseVote = useCallback((id) => {
        votesActs.close({dispatch, id})
            .catch(err => handleError(err));
    }, [dispatch])

    const onOpenVotesModal = useCallback((id) => {
        votesActs.loadListById({dispatch, person_id: id})
            .then(() => setPersonIdVotesModalIsOpenBy(id))
            .catch(err => handleError(err));
    }, [dispatch]);

    const onCreateTag = useCallback(async ({personId, label}) => {
        await tagsActs.createAndAddToPerson({ dispatch, personId,  tag: { label }})
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeTag = useCallback(async (tag) => {
        await tagsActs.update({ dispatch, tag })
            .catch(err => handleError(err));
    }, [dispatch]);
    
    const onAddTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.addTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onDeleteTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.deleteTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onCreateProfile = useCallback(async (name) => {
        await profActs.add({dispatch, profile: {name}})
            .catch(err => handleError(err));
    }, [dispatch])

    const onCreateVacancy = async (vacancy) => {
        await vacActs.add(dispatch, {
            ...vacancy,
            group: vacancy.group_id ? 
                groupsMap[vacancy.group_id]
                :
                undefined,
            profile: vacancy.profile_id ?
                profilesMap[vacancy.profile_id]
                :
                undefined,
        })
            .catch(err => handleError(err));
    }

    // processing
    const onCancelProcessing = async ({
        processing_id,
        relation_id,
        initiator,
        cancelled_at,
        reason_id,
        comment,
    }) => {
        await relActs.cancelProcessingWithReason({
            dispatch,
            processing_id,
            relation_id,
            initiator,
            cancelled_at,
            reason_id,
            comment,
        })
    }

    if (!currentUser) {
        return null;
    }

    const filterVacanciesAllIdsForModal = () => {
        if (!vacanciesModalData) {
            return []
        }

        const relationExists = ({personId, vacancyId}) => {
            return byPersonId[personId]
                ?.some(rel => rel.vacancy_id === vacancyId)
        }

        const vacancyHasTheSameProfile = ({vacancyProfileId, chosenProfileId}) => {
            return vacancyProfileId ?
                chosenProfileId === vacancyProfileId
                :
                chosenProfileId === -1
        }
    
        return vacanciesAllIds
            ?.reduce((filteredList, vacId) => {
                const vac = vacanciesMap[vacId];
                if (vac
                    &&
                    !relationExists({personId: vacanciesModalData.personId, vacancyId: vacId})
                    &&
                    vacancyHasTheSameProfile({vacancyProfileId: vac.profile?.id, chosenProfileId: vacanciesModalData.profileId})
                ) {
                    return [...filteredList, vacId];
                }

                return filteredList
            }, []);
    }

    const filterBookedBySearchOptions = () => {
        const options = [
            {key: 'none', value: null, text: '-'},
            {key: 'currentUser', value: currentUser.service.id, text: currentUser.surname + ' ' + currentUser.name + ' (you)'},
        ];

        if (!usersList) {
            return options
        }

        for (let user of usersList) {
            if (
                (isUserAdmin(user) || isUserRecruiter(user))
                &&
                user.service.id !== currentUser.service.id
            ) {
                options.push({
                    key: user.service.id,
                    value: user.service.id,
                    text: user.surname + ' ' + user.name
                });
            }
        }
        return options
    }

    const cache = CellMeasurerCacheWrapper({
        defaultHeight: 300,
        minHeight: 160,
        fixedWidth: true
    });

    const cellRenderer = ({ key, parent, index, style, registerChild }) => {
        const pers = map[filteredAndSortedIds[index]];
        if (!pers) return null;
        const person = selected[pers.service.id] || pers;
        const editable = pers.service.id === editedId
        const isPristine = deepEqual(pers, person);
        return (
            <CellMeasurerUi
                cache={cache}
                style={style} 
                key={key}
                parent={parent}
                rowIndex={index}
                ref={registerChild}
            >
                {({ registerChild }) =>
                <div data-testid="card" 
                    ref={registerChild}
                    style={style} 
                    key={pers.service.id}
                >
                    <PersonCard 
                        relations={relationsById[pers.service.id]}
                        data={person}
                        editable={editable}
                        onEdit={onClickEdit}
                        onChangeField={handleFieldChange}
                        onSubmit={handleUpdate}
                        onDiscard={handleDiscard}
                        onBook={handleBook}
                        onUnbook={handleUnbook}
                        onChangeStage={onChangeStage}
                        onChoose={onClickHndlr}
                        isPristine={isPristine}
                        errors={selectedErrors[pers.service.id]}
                        checkForErrors={checkForErrors}
                        currentUser={currentUser}
                        stageTypesMap={stageTypesMap || {}}
                        stageTypesAllIds={stageTypesAllIds || []}
                        stagesMapByRelationId={stagesMapByRelationId}
                        className={chosenId === pers.service.id ? 'persons-card-chosen' : undefined}
                        vacanciesMap={vacanciesMap}
                        usersMap={usersMap}
                        onOpenVotesModal={onOpenVotesModal}
                        tagsMap={tagsMap}
                        tagsAllIds={tagsAllIds}
                        onChangeTag={onChangeTag}
                        onCreateTag={onCreateTag}
                        onAddTagId={onAddTagId}
                        onDeleteTagId={onDeleteTagId}
                        profilesList={profilesList}
                        onClickProfileInput={onClickProfileInput}
                        onCreateProfile={onCreateProfile}
                        onDeleteRelation={onDeleteRelation}

                        onCancelProcessing={onCancelProcessing}
                        processingCancelReasons={processingCancelReasons}
                    />
                </div>
                }
            </CellMeasurerUi>
        )
    }

    return (
        <>
            <div className="sticky-ui">
                {showSearchForm && (()=> {
                    const person = selected.search || {...zeroPersonSearch, service: 'search'};
                    const editable = true;
                    const isPristine = deepEqual(person, {...zeroPersonSearch, service: {id: 'search'}});
                    return <PersonSearchCard
                        data={person}
                        editable={editable}
                        onChangeField={handleFieldChange}
                        onSubmit={handleSearch}
                        onDiscard={handleSearchDiscard}
                        isPristine={isPristine}
                        errors={selectedErrors.search}
                        checkForErrors={checkForErrors}
                        onMinimize={() => setShowSearchForm(false)}
                    />
                })()}

                {showCandidateForm && (() => {
                    const person = selected.new || {...zeroPerson, service: {id: 'new'}};
                    const editable = true;
                    const isPristine = deepEqual(person, zeroPerson);
                    return <Form name='add person' onSubmit={handleAdd}>
                        <PersonCard
                            data={person}
                            editable={editable}
                            onChangeField={handleFieldChange}
                            onDiscard={(id) => {
                                setShowCandidateForm(false);
                                deletePersonFromSelected(id);
                            }}
                            isPristine={isPristine}
                            errors={selectedErrors.new}
                            checkForErrors={checkForErrors}
                        />
                    </Form>
                })()}

                {!showCandidateForm &&
                    <Button icon 
                        title="add person"
                        onClick={() => {
                            addPersonToSelected({...zeroPerson, service: {id: 'new'}})
                            setShowCandidateForm(true);
                    }}>
                        <Icon name="add"/>
                    </Button>
                }
                {!showSearchForm &&
                    <Button icon 
                        title="search person"
                        className='search-form-button'
                        onClick={() => {
                            !searchOptions && addPersonToSelected({...zeroPersonSearch, service: {id: 'search'}})
                            setShowSearchForm(true);
                    }}>
                        <IconGroupUi>
                            <IconUi name='search' />
                            <IconUi 
                                className='search-form-button-icon-corner'
                                corner='top right'
                                name='add'
                            />
                        </IconGroupUi>
                    </Button>
                }
                <InputUi 
                    placeholder="Text search"
                    value={fulltextSearchValue}
                    onChange={onChangeFulltextSearch}
                />
                
                <SearchUi 
                    placeholder='Search by vacancies'
                    value={searchByVacancyValue}
                    onSearchChange={(e) => 
                        onChangeVacanciesFulltextSearch(e.currentTarget.value)
                    }
                    onResultSelect={({value}) => 
                        onClickVacanciesFulltextSearch(value)
                    }
                    onSearchDiscard={() => onDiscardVacanciesFulltextSearch()}
                    onClickLoadMore={() => onClickLoadMoreVacanciesFulltextSearch()}
                    results={
                        vacanciesSearchedIds
                            .map(vId => ({
                                title: vacanciesMap[vId].name,
                                value: vacanciesMap[vId].service.id,
                            }))
                    }
                />
                {/* <SortingInputUi 
                    fields={personSortingFieldsTree} 
                    onChange={(sortingOption) => {personsActs.sort({ dispatch, sortingOption })}}
                /> */}
                <FilterInputsUi 
                    optionsList={addedFilters} 
                    propsToFilterBy={personFilterFieldsTree} 
                    onChange={onChangeFilterInput}
                    onDelete={onDeleteFilter}
                    onAdd={onAddFilter}
                />
                <SelectUi 
                    placeholder='booked by'
                    value={searchByBookingId}
                    options={filterBookedBySearchOptions()}
                    onChange={onChangeBookingSelect}
                />

            </div>
            <InfiniteLoaderUi
                isRowLoaded={({index}) => !!filteredAndSortedIds[index]}
                loadMoreRows={() => canLoadMore && !loading && loadMore(allIds.length)}
                rowCount={2000}
            >
                {({ onRowsRendered, registerChild: inRegisterChild }) => (
                    <WindowScrollerUi>
                        {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) =>
                            <AutoSizerUi disableHeight>
                                {({ width }) => (
                                    <div ref={registerChild}>
                                        <ListUi
                                            regChild={inRegisterChild}
                                            autoHeight
                                            height={height}
                                            isScrolling={isScrolling}
                                            onScroll={onChildScroll}
                                            scrollTop={scrollTop}
                                            onRowsRendered={onRowsRendered}
                                            rowCount={filteredAndSortedIds.length}
                                            rowHeight={cache.rowHeight}
                                            rowRenderer={cellRenderer}
                                            className='persons-list'
                                            width={width}
                                            scrollToIndex={undefined}
                                            overscanRowCount={5}
                                        />
                                    </div>
                                )}
                            </AutoSizerUi>
                        }
                    </WindowScrollerUi>
                )}
            </InfiniteLoaderUi>

            <VotesModalUi 
                header={'Votes'}
                isOpen={!!personIdVotesModalIsOpenBy}
                onClose={() => setPersonIdVotesModalIsOpenBy(false)}
                data={votesByPersonId[personIdVotesModalIsOpenBy]?.map(p => ({...p, voters: votersByVoteId[p.id]}))}
                usersMap={usersMap}
                usersAllIds={usersAllIds}
                vacanciesMap={vacanciesMap}
                vacanciesAllIds={vacanciesAllIds
                    .filter(v => relationsById[personIdVotesModalIsOpenBy]
                        ?.some(r => r.vacancy_id === v)
                    )
                }
                onChangeField={({...params}) => onChangeVote({person_id: personIdVotesModalIsOpenBy, ...params})}
                onAddVote={({...voteData}) => onAddVote({id: personIdVotesModalIsOpenBy, ...voteData})}
                onCloseVote={onCloseVote}
                formValues={votesSelected[personIdVotesModalIsOpenBy] || {}}
                filesData={filesList}
            />

            {vacanciesModalData &&
                <VacanciesModal
                    header={
                        map[vacanciesModalData.personId]?.surname + ' ' +
                        map[vacanciesModalData.personId]?.name + ' ' +
                        map[vacanciesModalData.personId]?.midname
                    }
                    chosenProfile={profilesMap[vacanciesModalData.profileId] || {name: 'No profile', id: vacanciesModalData.profileId}}
                    vacanciesMap={vacanciesMap}
                    vacanciesAllIds={filterVacanciesAllIdsForModal()}
                    usersMap={usersMap}
                    usersAllIds={usersAllIds}
                    onAddVacancies={onAddVacanciesInModal}
                    onClose={() => setVacanciesModalData(null)}
                    onCreateVacancy={onCreateVacancy}
                    groupsList={groupsList}
                    createdVacancyId={vacanciesLastCreatedId}
                    clearCreatedVacancyId={() => vacActs.clearLastCreatedId(dispatch)}
                />
            }

            {relationIdToDelete && 
                <ConfirmModalUi
                    header={
                        `Do you want to delete 
                        ${vacanciesMap[relationsMap[relationIdToDelete]?.vacancy_id]?.name} from 
                        ${map[relationsMap[relationIdToDelete]?.person_id]?.surname} 
                        ${map[relationsMap[relationIdToDelete]?.person_id]?.name}?`}
                    confirmText='Delete'
                    rejectText='Cancel'
                    open={!!relationIdToDelete}
                    onClose={() => setRelationIdToDelete(null)}
                    onConfirm={() => {
                        deleteRelation(relationIdToDelete)
                            .then(() => {
                                setRelationIdToDelete(null)
                            })
                    }}
                    onReject={() => setRelationIdToDelete(null)}
                />
            }
            {duplicateIds &&
                <PersonsDuplicateModal />
            }
        </>
    )
}

export default React.memo(Persons);
