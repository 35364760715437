import { arrToMap } from '../../utils';
import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    chosenId: undefined,
    limit: 10,
    offset: 0,
    list: [],
    map: {},
    selected: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_LIST:
            return {
                ...state,
                list: action.payload || [],
                map: arrToMap(action.payload),
            }

        case acts.UPDATE:
            const updatedList = state.list.map(item => {
                if (item.id === action.payload.id) {
                    return {...item, ...action.payload}
                }
                return item
            })
            return {
                ...state,
                list: updatedList || [],
                map: {
                    ...state.map,
                    [action.payload.id]: {
                        ...state.map[action.payload.id],
                        ...action.payload,
                    }
                }
            }

        case acts.APPEND_LIST:
            return {
                ...state,
                list: [
                    ...action.payload,
                    ...state.list,
                ],
                map: {
                    ...arrToMap(action.payload),
                    ...state.map,
                }
            }

        case acts.UPDATE_IN_SELECTED:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.payload.id]: {
                        ...(state.selected[action.payload.id] || {}),
                        ...action.payload.data
                    }
                }
            }

        case acts.DELETE_FROM_SELECTED:
            const selectedList = {...state.selected};
            delete selectedList[action.payload];
            return {
                ...state,
                selected: selectedList
            }
    
        default:
            return state;
    }
}

export default reducer;
