import React from 'react';
import { Menu } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import './Navigator.scss'

const Navigator = () => {

    const history = useHistory();

    const navigate = (e, {name}) => {
        history.replace(name)
    }

    return (
        <nav className='navigator'>
            <Menu vertical fluid className='navigator-menu'>
                <Menu.Item onClick={navigate} name='/candidates'>
                    Candidates
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/'>
                    Dashboard
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/persons'>
                    Persons
                </Menu.Item>
                <Menu.Item  onClick={navigate} name='/persons/stages'>
                    Stages
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/persons/new'>
                    New person
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/vacancies'>
                    Vacancies
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/groups'>
                    Groups
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/profiles'>
                    Profiles
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/users'>
                    Users
                </Menu.Item>
                <Menu.Item onClick={navigate} name='/statistics'>
                    Statistics
                </Menu.Item>
            </Menu>
        </nav>
    )
}

export default React.memo(Navigator);