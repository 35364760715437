import React, { useEffect, useRef, useState } from 'react';

import ModalUi from '../../../components/ModalUi';

import { Profile } from '../../../entities/profile';
import { Vacancy } from '../../../entities/vacancy';

import './VacanciesModal.scss'
import { IconUi } from '../../../components/IconUi';
import InputSelectMultiple, { OptionType } from '../../../components/InputSelectMultipleUi';
import VacancyCard from './VacancyCard';
import { Group } from '../../../entities/group';
import VacancyCreationCard from './VacancyCreationCard';
import { User } from '../../../entities/user';
import ButtonUi from '../../../components/StyledButtonUi';
import ConfirmModalUi from '../../../components/ConfirmModalUi';

type VacanciesModalProps = {
    header: string
    chosenProfile: Profile
    vacanciesMap: {
        [vacancyId: number]: Vacancy
    }
    vacanciesAllIds: Array<number>
    groupsList: Array<Group>
    usersMap: {
        [userId: number]: User
    }
    usersAllIds: Array<number>
    createdVacancyId?: number

    onClose: () => void
    onAddVacancies: (vacancyIds: Array<number | string>) => Promise<void>
    onCreateVacancy: (vacancy: CreatedVacancy) => Promise<void>
    clearCreatedVacancyId: () => void
}

type CreatedVacancy = {
    name?: string
    group_id?: number
    profile_id?: number
    hiring_manager_id?: number
    description?: string
}

export const VacanciesModal = ({
    header,
    chosenProfile,
    vacanciesMap,
    vacanciesAllIds,
    groupsList,
    usersMap,
    usersAllIds,
    createdVacancyId,

    onClose,
    onAddVacancies,
    onCreateVacancy,
    clearCreatedVacancyId,
}: VacanciesModalProps) => {

    const [loading, setLoading] = useState(false);

    const [inputValue, setInputValue] = useState('')
    const [selectedOptions, setSelectedOptions] = useState<Array<OptionType>>([])
    const [createdVacancyData, setCreatedVacancyData] = useState<CreatedVacancy>()

    const [isConfirmModalOpen, setIsConfirmButtonOpen] = useState(false);

    const vacanciesMapRef = useRef(vacanciesMap);
    const usersMapRef = useRef(usersMap)

    useEffect(() => {
        vacanciesMapRef.current = vacanciesMap;
    }, [vacanciesMap])

    useEffect(() => {
        usersMapRef.current = usersMap;
    }, [usersMap])

    useEffect(() => {
        const createdVacancy = createdVacancyId && vacanciesMapRef.current[createdVacancyId];
        if (createdVacancy) {
            const hiringManager = usersMapRef.current[createdVacancy.hiring_manager_id];
            const hiringManagerName = hiringManager ?
                `${hiringManager.surname} ${hiringManager.name} ${hiringManager.midname}`.trim()
                :
                undefined;

            setSelectedOptions(optns => [
                ...optns,
                {
                    label: createdVacancy.name,
                    value: createdVacancyId,
                    description: createdVacancy.description || undefined,
                    payload: {
                        ...createdVacancy,
                        hiringManagerName,
                    }
                } 
            ]);
            clearCreatedVacancyId();
        }
    }, [createdVacancyId, clearCreatedVacancyId])

    const filterVacancies = (text: string) => {
        return vacanciesAllIds.reduce((options: Array<{value: number, label: string}>, currentVacId) => {
            const currentVac = vacanciesMap[currentVacId];

            if (currentVac?.name?.toLowerCase()?.includes(text.toLowerCase())) {
                const hiringManager = usersMap[currentVac.hiring_manager_id];
                const hiringManagerName = hiringManager ?
                    `${hiringManager.surname} ${hiringManager.name} ${hiringManager.midname}`.trim()
                    :
                    undefined

                return [
                    ...options,
                    {
                        value: currentVac.service.id,
                        label: currentVac.name,
                        description: (
                            `${currentVac.group?.name || 'No group'} - ${hiringManagerName || 'No hiring manager'}`
                        ),
                        payload: {
                            ...currentVac,
                            hiringManagerName,
                        }
                    }
                ]
            }
            return options
        }, [])
    }
    
    const addOption = (option: OptionType) => {
        setSelectedOptions(value => [...value, option]);
    }

    const removeOption = (option: OptionType) => {
        setSelectedOptions(values => values.filter(v => v !== option));
    }

    const isOptionSelected = (option: OptionType) => {
        return selectedOptions.some(op => op.value === option.value)
    }

    const onClickOption = (option: OptionType) => {
        if (isOptionSelected(option)) {
            removeOption(option);
        } else {
            addOption(option);
        }
    }

    const onChangeInput = (value: string) => {
        setInputValue(value);
    }

    const onDuplicate = (vacancy: Vacancy) => {
        setCreatedVacancyData({
            name: vacancy.name,
            group_id: vacancy.group?.id,
            profile_id: vacancy.profile?.id,
            hiring_manager_id: vacancy.hiring_manager_id,
            description: vacancy.description || undefined
        })
    }

    const onCreate = async (vacancy: CreatedVacancy) => {
        if (loading) {
            return
        }

        setLoading(true);

        await onCreateVacancy({...vacancy, profile_id: chosenProfile.id})
            .then(() => setCreatedVacancyData(undefined))

        setLoading(false);
    }
    const onAddOptions = async () => {
        if (loading) {
            return
        }

        setLoading(true);

        onAddVacancies(selectedOptions.map(so => so.value))
            .then(() => {
                onClose();
            })

        setLoading(false);
    }

    const openConfirmModal = () => {
        setIsConfirmButtonOpen(true);
    }

    const closeConfirmModal = () => {
        setIsConfirmButtonOpen(false);
    }

    return (
        <ModalUi
            open={!!chosenProfile}
            header={header}
            onClose={openConfirmModal}
            className='vacancies-modal'
        >
            <div className='vacancies-modal-content'>
                {chosenProfile?.name}
                <div className='vacancies-modal-body'>
                    <InputSelectMultiple
                        controlled={{
                            inputValue,
                            options: filterVacancies(inputValue),
                            selectedOptions,
                            onChangeInput,
                            onClickOption, 
                        }}

                        withCreate={{
                            handler: async (name) => setCreatedVacancyData({name})
                        }}

                        withOptionButton={{
                            title: 'Duplicate',
                            icon: <IconUi name='copy'/>,
                            handler: (vacancyOption) => onDuplicate(vacancyOption.payload)
                        }}
                        
                        placeholder={"Start typing to find vacancy..."}
                        autoFocus
                    />

                    <div className='vacancies-modal-body-vacancy-cards'>
                        {createdVacancyData && 
                            <VacancyCreationCard 
                                vacancy={createdVacancyData}
                                groupsList={groupsList}
                                usersList={usersAllIds.map(userId => usersMap[userId])}
                                onCreate={onCreate}
                                onDiscard={() => {
                                    setCreatedVacancyData(undefined)
                                }}
                            />
                        }
                        {selectedOptions.map((opt, idx) => (
                            <VacancyCard
                                key={idx}
                                vacancy={opt.payload}
                                onClickDuplicateIcon={onDuplicate}
                                onClickDiscardIcon={() => removeOption(opt)}
                            />
                        ))}
                    </div>
                </div>
                <div className='vacancies-modal-footer'>
                    <ButtonUi
                        disabled={!selectedOptions.length}
                        onClick={onAddOptions}
                    >
                        Submit
                    </ButtonUi>
                </div>
            </div>
            <ConfirmModalUi
                header='Do you want to discard changes?'
                confirmText='Discard changes'
                rejectText='Continue editing'
                open={isConfirmModalOpen}
                onClose={closeConfirmModal}
                onConfirm={onClose}
                onReject={closeConfirmModal}
            />
        </ModalUi>
    )
}

export default VacanciesModal;