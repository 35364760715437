import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';

import { ErrorUnauthorized } from '../../errors';
import { deepEqual, download, validate } from '../../utils';
import { getEditedId, getSelected, getSocialsByUserId } from './reducer';
import { zeroUserWithSocials } from '../../user';

const setLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
});

const setList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
})

const setRolesList = (data) => ({
    type: acts.SET_ROLES,
    payload: data,
})

const setSocialsByUserId = (data) => ({
    type: acts.SET_SOCIALS_BY_ID,
    payload: data,
})

const setUserSocialsByUserId = (data) => ({
    type: acts.SET_USER_SOCIALS_BY_ID,
    payload: data,
});

const appendUsers = (data) => ({
    type: acts.APPEND_LIST,
    payload: data
});

const addUserToList = (data) => ({
    type: acts.ADD,
    payload: data,
})

const updateUserInList = (data) => ({
    type: acts.UPDATE,
    payload: data,
})

const setUsersEditedId = (id) => ({
    type: acts.SET_EDITED_ID,
    payload: id,
})

const updateUserInSelected = (data) => ({
    type: acts.UPDATE_IN_SELECTED,
    payload: data
});

const deleteUserFromSelected = (id) => ({
    type: acts.DELETE_FROM_SELECTED,
    payload: id
});

const setSelectedUserErrors = (data) => ({
    type: acts.SET_SELECTED_USER_ERRORS,
    payload: data,
});

const setSelectedFieldErrors = (data) => ({
    type: acts.SET_SELECTED_FIELD_ERRORS,
    payload: data,
});

export const load = async (dispatch, id) => {
    dispatch(setLoading(true));

    const data = await api.getUserById(id);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(appendUsers([data]));
    dispatch(setLoading(false));
}

export const loadList = async (dispatch) => {
    dispatch(setLoading(true));

    const data = await api.getList();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(setList(data));
    dispatch(setLoading(false));
}

export const loadRolesList = async (dispatch) => {
    dispatch(setLoading(true));

    const data = await api.getRolesList();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(setRolesList(data));
    dispatch(setLoading(false));
}

export const loadSocialsList = async (dispatch) => {
    const mapSocialsByUserId = (list) => {
        const map = {};

        list.forEach(el => {
            map[el.user_id] = [
                ...( map[el.user_id] || [] ),
                el
            ];
        });

        return map;
    }

    dispatch(setLoading(true));

    const list = await api.getSocialsList() || [];

    if (list instanceof Error) {
        if (list.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw list;
    }

    const socialsByUserId = mapSocialsByUserId(list);

    dispatch(setSocialsByUserId(socialsByUserId));
    dispatch(setLoading(false));

}

export const add = async ({dispatch, user}) => {
    dispatch(setLoading(true));

    const data = await api.createUser(user);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    updateUserSocials({ dispatch, user_id: data.service.id, updatedUserSocials: user.socials});

    dispatch(addUserToList({...data, socials: undefined}));
    deleteSelected(dispatch, 'new');
    dispatch(setLoading(false));
}

export const update = async ({dispatch, user}) => {
    dispatch(setLoading(true));
    const data = await api.updateUser(user);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    await updateUserSocials({ dispatch, user_id: user.service.id, updatedUserSocials: user.socials});

    dispatch(updateUserInList({...user, socials: undefined}));
    discard(dispatch);
    dispatch(setLoading(false));

};

export const updateUserSocials = ({dispatch, user_id, updatedUserSocials}) => dispatch(async (dispatch, getState) => {
    const previousSocials = getSocialsByUserId(getState())[user_id] || [];
    const currentSocials = [];

    const addedSocials = [];
    const editedSocials = [];

    updatedUserSocials.forEach(ns => {
        const notBrandNewSocial = previousSocials.find(ps => ps.service.id === ns?.service?.id);

        if (notBrandNewSocial) {
            if (deepEqual(notBrandNewSocial, ns)) {
                currentSocials.push(ns);
            } else {
                editedSocials.push(ns);
            }
        } else {
            addedSocials.push({...ns, user_id});
        }
    });

    const errors = [];

    const addReq = addedSocials.map(as => api.addSocial(as));
    const updateReq = editedSocials.map(us => api.updateSocial(us));

    await Promise.all(addReq)
        .then(responses => {
            responses.map((r) => {
                if (r instanceof Error) {
                    if (r.message === ErrorUnauthorized) {
                        dispatch(signout());
                        return undefined
                    }
                    errors.push(r);
                    return undefined
                }
                currentSocials.push(r);
                return undefined
            })
        });

    await Promise.all(updateReq)
        .then(responses => {
            responses.map((r, idx) => {
                if (r instanceof Error) {
                    if (r.message === ErrorUnauthorized) {
                        dispatch(signout());
                        return undefined
                    }
                    errors.push(r);
                    return undefined
                }
                currentSocials.push(editedSocials[idx]);
                return undefined
            })
        });

    currentSocials.length && dispatch(setUserSocialsByUserId({user_id, data: currentSocials}));

    if (errors.length) {
        throw Error(errors);
    }
})



export const setUsersLoading = (dispatch, load) => {
    dispatch(setLoading(load));
}

export const updateSelected = ({dispatch, id, data}) => {
    dispatch(updateUserInSelected({id, data}));
}

export const updateSelectedSocials = ({dispatch, user, social, data}) => {
    const updatedSocials = [...user.socials];

    const currentSocialIndex = updatedSocials.findIndex(s => s.kind === social);

    if (currentSocialIndex !== -1) {
        updatedSocials[currentSocialIndex] = {...updatedSocials[currentSocialIndex], ...data};
    } else {
        updatedSocials[updatedSocials.length] = {kind: social, ...data}
    }

    dispatch(updateUserInSelected({id: user.service.id, data: {socials: updatedSocials}}));
}

export const deleteSocial = async ({dispatch, socialId, user_id}) => dispatch(async (dispatch, getState) => {
    dispatch(setLoading(true));
    const data = await api.deleteSocial(socialId);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    const userSocialsByUserId = [...getSocialsByUserId(getState())[user_id]];
    const updatedSocials = userSocialsByUserId.filter(s => s.service.id !== socialId);

    dispatch(setUserSocialsByUserId({user_id, data: updatedSocials}));

    const selectedUser = getSelected(getState())[user_id];

    selectedUser && updateSelected({ dispatch, id: user_id, data: {socials: updatedSocials}});

    dispatch(setLoading(false));
});

export const deleteSelected = (dispatch, id) => {
    dispatch(deleteUserFromSelected(id));
    dispatch(setSelectedUserErrors({id, userErrors: null}));
}

export const checkForErrors = ({dispatch, value, validateOptions, id, fieldName}) => {
    const error = validate({value, ...validateOptions});
    dispatch(setSelectedFieldErrors({id, error, fieldName}))
};

export const select = ({dispatch, user}) => dispatch((dispatch, getState) => {
    const previousEditedId = getEditedId(getState());
    previousEditedId && deleteSelected(dispatch, previousEditedId);


    const socials = getSocialsByUserId(getState())[user.service.id] || [];

    updateSelected({ dispatch, id: user.service.id, data: {...zeroUserWithSocials, ...user, socials}});
    dispatch(setUsersEditedId(user.service.id));
});

export const discard = (dispatch) => dispatch((dispatch, getState) => {
    const previousEditedId = getEditedId(getState());
    previousEditedId && deleteSelected(dispatch, previousEditedId);
    dispatch(setUsersEditedId(null));
})

export const downloadRecruitersReport = async ({dispatch, from, to, userIds}) => {
    dispatch(setLoading(true));

    if (userIds.length === 0) {
        setLoading(false);
        return
    }

    if (!from && !to) {
        setLoading(false);
        return
    }

    const file = await api.getRecruitersReport({from, to, userIds});
    download(file);

    dispatch(setLoading(false));
}