import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';
import { ErrorUnauthorized } from '../../errors';
import { arrToMapAndIds } from '../../utils';
import { getStagesMapByRelationId } from './reducer';

const setList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
})

const setLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
})

const appendList = (data) => ({
    type: acts.APPEND_LIST,
    payload: data,
})

// принимает:
//
// [
//     {
//         relation,
//         stage,
//     }
//     ...
// ]
const addRelationsWithStages = (data) => ({
    type: acts.BULK_ADD_RELATION_AND_STAGE,
    payload: data,
})

const addRelationAndStage = ({ relation, stage }) => ({
    type: acts.ADD_RELATION_AND_STAGE,
    payload: {
        relation,
        stage,
    }
})

const deleteFromList = (id) => ({
    type: acts.DELETE,
    payload: id
})

const setStages = (data) => ({
    type: acts.SET_STAGES,
    payload: data,
});

const appendStages = (data) => ({
    type: acts.APPEND_STAGES,
    payload: data,
});

const addStageToList = (data) => ({
    type: acts.ADD_STAGE,
    payload: data,
});

const deleteStageFromList = (data) => ({
    type: acts.DELETE_STAGE,
    payload: data,
});

const setStageTypes = (data) => ({
    type: acts.SET_STAGE_TYPES,
    payload: data,
});

const addStageTypeToList = (data) => ({
    type: acts.ADD_STAGE_TYPE,
    payload: data,
});

const updateStageTypeInList = (data) => ({
    type: acts.UPDATE_STAGE_TYPE,
    payload: data,
});

const deleteStageTypeFromList = (data) => ({
    type: acts.DELETE_STAGE_TYPE,
    payload: data,
});

// async actions

export const loadStagesByRelationId = async ({ dispatch, id }) => {
    dispatch(setLoading(true));

    const data = await api.getStagesByRelationId(id);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }
    data && dispatch(appendStages({ [id]: data }));
    dispatch(setLoading(false));
}

export const loadStagesByRelationIds = ({ dispatch, ids }) => dispatch(async (dispatch, getState) => {
    dispatch(setLoading(true));
    const stagesMapByRelationId = getStagesMapByRelationId(getState());


    let requests = ids.filter(id => !stagesMapByRelationId[id]).map(id => api.getStagesByRelationId(id));

    let stagesMap = {};
    let errors = [];

    await Promise.all(requests)
        .then(responses => {
            responses.map((r) => {
                if (r instanceof Error) {
                    if (r.message === ErrorUnauthorized) {
                        dispatch(signout());
                        return undefined
                    }
                    errors.push(r);
                    return undefined
                }
                if (r && r[0]) {
                    stagesMap = {
                        ...stagesMap,
                        [r[0].relation_id]: r,
                    };
                }
                return undefined
            })
        });

    dispatch(appendStages(stagesMap));
    dispatch(setLoading(false));

    if (errors.length) {
        throw Error(errors);
    }
})

export const loadByStagesPeriod = async ({ dispatch, from, to }) => {
    dispatch(setLoading(true));

    const stages = await api.getStagesListByPeriod({ from, to });

    if (stages instanceof Error) {
        if (stages.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw stages;
    }

    if (stages) {
        const stagesMapByRelationId = stages.reduce((map, stage) => {
            return {
                ...map,
                [stage.relation_id]: [
                    ...(map[stage.relation_id] || []),
                    stage,
                ]
            }
        }, {})
        dispatch(setStages(stagesMapByRelationId));

        const relationIdsToLoad = [];
        const relationIdsToLoadMap = [];

        stages.forEach(stage => {
            if (!relationIdsToLoadMap[stage.relation_id]) {
                relationIdsToLoad.push(stage.relation_id);
                relationIdsToLoadMap[stage.relation_id] = true;
            }
        });

        const loadedRelations = await api.getRelationsListByIds(relationIdsToLoad);

        dispatch(setList(loadedRelations));
    } else {
        dispatch(setStages({}));
    }

    dispatch(setLoading(false));
};

export const loadListByPersonId = async ({ dispatch, id, appendList = false }) => {
    dispatch(setLoading(true));

    const data = await api.getRelationsListByPersonId({ id });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    if (appendList) {
        dispatch(appendList(data));
    } else {
        dispatch(setList(data));
    }

    data && loadStagesByRelationIds({ dispatch, ids: data.map(r => r.id) });

    dispatch(setLoading(false));
}

export const loadListByPersonIds = async ({ dispatch, ids, append = true }) => {
    dispatch(setLoading(true));

    const data = await api.getRelationsListByPersonIds(ids) || [];

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    if (append) {
        dispatch(appendList(data));
    } else {
        dispatch(setList(data));
    }

    data && loadStagesByRelationIds({ dispatch, ids: data.map(r => r.id) });

    dispatch(setLoading(false));
}

export const loadListByVacancyId = async ({ dispatch, id }) => {
    dispatch(setLoading(true));

    const data = await api.getRelationsListByVacancyId({ id });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(setList(data));

    data && loadStagesByRelationIds({ dispatch, ids: data.map(r => r.id) });

    dispatch(setLoading(false));
}

export const add = async ({ dispatch, vacancy_id, person_id }) => {
    dispatch(setLoading(true));

    const data = await api.addRelation({ vacancy_id, person_id });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    // dispatch(appendList([data.relation]));
    dispatch(addRelationAndStage({
        relation: data.relation,
        stage: data.stage,
    }));
    dispatch(setLoading(false));
}


export const addList = async ({ dispatch, vacancy_ids, person_id }) => {
    dispatch(setLoading(true));

    let requests = [];

    vacancy_ids.map(id => {
        requests.push(api.addRelation({ vacancy_id: id, person_id }));
        return undefined
    })

    let relationsAndStages = [];
    let errors = [];

    await Promise.all(requests)
        .then(responses => {
            responses.map((responseData) => {
                if (responseData instanceof Error) {
                    if (responseData.message === ErrorUnauthorized) {
                        dispatch(signout());
                        return undefined
                    }
                    errors.push(responseData);
                    return undefined
                }
                relationsAndStages.push(responseData);
                return undefined
            })
        })


    dispatch(addRelationsWithStages(relationsAndStages))
    // dispatch(appendList(relations));

    loadStagesByRelationIds({ dispatch, ids: relationsAndStages.map(r => r.relation.id) });

    dispatch(setLoading(false));

    if (errors.length) {
        throw Error(errors);
    }
}

export const deleteRelation = async ({ dispatch, relationId }) => {
    dispatch(setLoading(true));

    const data = await api.deleteRelationById({ id: relationId });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(deleteFromList(relationId));
    dispatch(setLoading(false));
}

export const addStage = async ({ dispatch, relation_id, stage_type_id }) => {

    let updateData = await api.createStageByRelationId({ relation_id, stage: { type_id: stage_type_id } });
    if (updateData instanceof Error) {
        if (updateData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw updateData;
    }
    dispatch(addStageToList({ type_id: stage_type_id, relation_id }));
}

export const addStageToMultipleRelations = async ({ dispatch, relation_ids, stage_type_id }) => {

    let requests = relation_ids.map(id => api.createStageByRelationId({ relation_id: id, stage: { type_id: stage_type_id } }));

    let errors = [];

    await Promise.all(requests)
        .then(r => {
            relation_ids.map((id, idx) => {
                if (r[idx] instanceof Error) {
                    if (r[idx].message === ErrorUnauthorized) {
                        dispatch(signout());
                        return undefined
                    }
                    errors.push(r[idx]);
                    return undefined
                }
                dispatch(addStageToList({ type_id: stage_type_id, relation_id: id }));
                return undefined
            })
        })

    if (errors.length) {
        throw Error(errors);
    }
}

export const deleteStage = async ({ dispatch, relationId, stageId }) => {
    dispatch(setLoading(true));

    const data = await api.deleteStage(stageId);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(deleteStageFromList({ relationId, stageId }));

    dispatch(setLoading(false));
}

export const loadStageTypes = async (dispatch) => {
    dispatch(setLoading(true));

    const data = await api.getStageTypes();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }
    const { map, allIds } = arrToMapAndIds(data || []);

    dispatch(setStageTypes({ map, allIds }));

    dispatch(setLoading(false));
}

export const addStageType = async ({ dispatch, stageType }) => {
    dispatch(setLoading(true));

    const data = await api.createStageType(stageType);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(addStageTypeToList(data));

    dispatch(setLoading(false));
}

export const updateStageType = async ({ dispatch, stageType }) => {
    dispatch(setLoading(true));

    const data = await api.updateStageType(stageType);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(updateStageTypeInList(stageType));

    dispatch(setLoading(false));
}

export const deleteStageType = async ({ dispatch, id }) => {
    dispatch(setLoading(true));

    const data = await api.deleteStageType(id);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(deleteStageTypeFromList(id));

    dispatch(setLoading(false));
}

// processing

export const cancelProcessingWithReason = async ({
    dispatch,

    processing_id,
    relation_id,
    initiator,
    cancelled_at,
    reason_id,
    comment,
}) => {
    let err = await api.cancelProcessingWithReason({
        processing_id,
        relation_id,
        initiator,
        cancelled_at,
        reason_id,
        comment,
    })
    if (err) {
        throw err
    }

    // статус away - id 3 в данном случае, обратная совместимость
    const stage_type_id = 3

    dispatch(addStageToList({ type_id: stage_type_id, relation_id }))
}

export const createProcessingCancelReason = async ({
    dispatch,

    reason,
}) => {
    let result = await api.createProcessingCancelReason({
        reason
    })
    if (result instanceof Error) {
        throw result
    }

    if (result.id) {
        return result.id
    }

    dispatch(appendListProcCancelReasons({ id: result.id, reason: reason }))

    return undefined
}

export const listProcessingCancelReasons = async ({ dispatch }) => {
    dispatch(setLoading(true));

    const data = await api.listProcessingCancelReasons();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(setListProcCancelReasons(data));

    dispatch(setLoading(false));
}

const setListProcCancelReasons = (data) => ({
    type: acts.SET_LIST_PROC_CANCEL_REASONS,
    payload: data,
})

const appendListProcCancelReasons = (data) => ({
    type: acts.APPEND_LIST_PROC_CANCEL_REASONS,
    payload: data,
})
