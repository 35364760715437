import React from "react";

import {
    Grid, Segment,
} from 'semantic-ui-react';

const ChainView = () => {
    return (
        <div>
            <Grid columns={2} divided>
                <Element></Element>
                <Element></Element>
                <Element></Element>
            </Grid>
        </div>
    )
}

const Element = () => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Segment>
                    <Grid columns={3} divided>
                        <Grid.Row>
                            <Grid.Column>
                                Vasya Pupkin
                            </Grid.Column>
                            <Grid.Column>
                                mail@mail.ne
                            </Grid.Column>
                            <Grid.Column>
                                +79823123125
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Segment>
            </Grid.Column>
            <Grid.Column>
                <Segment>
                    <div>
                        Java Developer
                    </div>
                    <Steps />
                </Segment>
                <Segment>2</Segment>
            </Grid.Column>
        </Grid.Row>
    )
}

interface Stage {
    id: number
    name: string
}

const Steps = () => {
    const sgoupStyle = {
        padding: "5px",
    }

    const styles = {
        cursor: "pointer",
        border: "1px solid rgba(34,36,38,.15)",
        padding: "5px",
    }

    const stages = [
        {
            id: 3,
            name: "Free",
        },
        {
            id: 2,
            name: "CV review",
        },
        {
            id: 6,
            name: "Interview",
        }
    ]

    const sortById = (arr: Array<Stage>): Array<Stage> => {
        return arr.sort((a, b) => a.id - b.id);
    }

    return (
        <div style={sgoupStyle}>
            {sortById(stages).map((item, idx) => {
                if (stages.length === idx+1) return <span style={{...styles, borderColor: "lightblue"}}>{item.name}</span>

                return <span style={styles}>{item.name}</span>;
            })}
        </div>
    )
}

export default ChainView;