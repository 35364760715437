import React from 'react';
import { Modal } from 'semantic-ui-react';
import { IconUi } from '../IconUi';

import './styles.scss'

export const ModalUi = ({
    header,
    children, 
    open, 
    onOpen=()=>{}, 
    onClose=()=>{},
    triggerElement=undefined, 
    className='', 
    headerClassName='', 
    contentClassName='',
    exitIcon=true,
}) => {

    return <Modal 
        open={open}
        onOpen={onOpen} 
        onClose={onClose}
        trigger={triggerElement}
        className={className}
    >
        <Modal.Header className={headerClassName}>
            {header}
            {exitIcon &&
                <IconUi
                    className='modal-ui-header-exit-icon'
                    name='close'
                    onClick={onClose}
                />
            }
        </Modal.Header>
        <Modal.Content className={contentClassName}>
            {children}
        </Modal.Content>
    </Modal>

}

export default ModalUi