import React, { useEffect, useState } from "react";

import CrossTableUi from "../../../components/StyledTableUi";

import { Group } from "../../../entities/group";
import { StageType } from "../../../entities/relation";

type GroupsChartProps = {
    groupsChartData: {
        [groupId: number]: {
            [stageTypeId: number]: number,
        }
    };
    stageTypesAllIds: number[];
    stageTypesMap: {
        [stageTypeId: number]: StageType,
    };
    groupsList: Group[];
    groupIdsToFilterOut: number[];
}

type SortingOptions = {
    valueToSortBy: number,
    order: "ascending" | "descending",
} | null;

const totalColumn = {id: -1, label: 'Total'}
const totalRow = {id: -1, label: 'Total'}

const GroupsTable = ({
    groupsChartData,
    stageTypesAllIds,
    stageTypesMap,
    groupsList,
    groupIdsToFilterOut
}: GroupsChartProps) => {
    
    const [sortingOptionsByGroup, setSortingOptionsByGroup] = useState<SortingOptions>(null);
    const [sortingOptionsByStage, setSortingOptionsByStage] = useState<SortingOptions>(null);

    const [sortedGroupsList, setSortedGroupsList] = useState<Group[]>([]);
    const [sortedStageTypeIds, setSortedStageTypeIds] = useState<number[]>([]);

    useEffect(() => {
        let currentOptions = sortingOptionsByGroup && {...sortingOptionsByGroup};
        
        const stageTypesToShow = [...stageTypesAllIds];
        
        if (currentOptions) {
            stageTypesToShow.sort((a: number, b: number) => {

                const aValue = (
                    currentOptions && 
                    groupsChartData[currentOptions.valueToSortBy] && 
                    groupsChartData[currentOptions.valueToSortBy][a]
                ) || 0;
                const bValue = (
                    currentOptions && 
                    groupsChartData[currentOptions.valueToSortBy] &&
                    groupsChartData[currentOptions.valueToSortBy][b]
                ) || 0;
                return currentOptions?.order === 'descending' ? bValue - aValue : aValue - bValue;
            })
        }
        setSortedStageTypeIds(stageTypesToShow);

    }, [stageTypesAllIds, sortingOptionsByGroup, groupsChartData])

    

    useEffect(() => {
        let currentOptions = sortingOptionsByStage && {...sortingOptionsByStage};
        
        const groupsToShow = [...groupsList];
        
        if (currentOptions) {
            groupsToShow.sort((a: Group, b: Group) => {

                const aValue = (
                    currentOptions && 
                    groupsChartData[a.id] && 
                    groupsChartData[a.id][currentOptions.valueToSortBy]
                ) || 0;
                const bValue = (
                    currentOptions && 
                    groupsChartData[b.id] &&
                    groupsChartData[b.id][currentOptions.valueToSortBy]
                ) || 0;

                return currentOptions?.order === 'descending' ? bValue - aValue : aValue - bValue;
            })
        }
        setSortedGroupsList(groupsToShow);

    }, [groupsList, sortingOptionsByStage, groupsChartData])
    
    const assembleValuesToCrossTableFormat = () => {
        const totalByGroups: number[] = [];
        const tableData = sortedStageTypeIds.map(stageTypeId => {
            
            const currentStageTypeValues: number[] = [];
            let totalByStageType = 0;

            sortedGroupsList.forEach((group: Group, idx: number) => {
                if (groupIdsToFilterOut[group.id]) return;

                let stagesCountOfCurrentGroupAndType = 0;
                totalByGroups[idx] = (totalByGroups[idx] || 0)

                if (
                    groupsChartData &&
                    groupsChartData[group.id] &&
                    groupsChartData[group.id][stageTypeId]
                ) {
                    stagesCountOfCurrentGroupAndType += groupsChartData[group.id][stageTypeId];
                    totalByStageType += groupsChartData[group.id][stageTypeId];
                    totalByGroups[idx] += groupsChartData[group.id][stageTypeId];
                }

                currentStageTypeValues.push(stagesCountOfCurrentGroupAndType);
            })
            currentStageTypeValues.push(totalByStageType);

            return currentStageTypeValues;
        })
        tableData.push(totalByGroups);
        return tableData;
    }

    const getFilteredGroupAxisData = () => {
        let groupAxisData: {id: number, label: string}[] = [];

        sortedGroupsList.forEach((group: Group) => {
            if (groupIdsToFilterOut[group.id]) return;
            
            groupAxisData.push({id: group.id, label: group.name})
        })

        return [...groupAxisData, totalColumn]
    }

    const getStageTypeAxisData = () => {
        return [...sortedStageTypeIds.map(id => ({id, label: stageTypesMap[id].name})), totalRow]
    }

    const sortByGroup = (value: number) => {
        setSortingOptionsByGroup(so => {
            if (so?.valueToSortBy === value) {
                return {
                    ...so, 
                    order: so.order === 'ascending' ? 'descending' : 'ascending',
                }
            }
            return {valueToSortBy: value, order: 'descending'}
        });
    }

    const sortByStage = (value: number) => {
        setSortingOptionsByStage(so => {
            if (so?.valueToSortBy === value) {
                return {
                    ...so, 
                    order: so.order === 'ascending' ? 'descending' : 'ascending',
                }
            }
            return {valueToSortBy: value, order: 'descending'}
        });
    }

    const sort = ({axis, value}: {axis: "x" | "y", value: number}) => {
        if (value === -1) return;

        if (axis === "x") {
            sortByGroup(value);
        } else {
            sortByStage(value);
        }
    }

    return (
        <CrossTableUi
            data={assembleValuesToCrossTableFormat()}
            yHeader={getStageTypeAxisData()}
            xHeader={getFilteredGroupAxisData()}
            xAxisName="Groups"
            yAxisName="Stage types"
            onClickSort={sort}
            chosenYID={sortingOptionsByStage?.valueToSortBy}
            chosenXID={sortingOptionsByGroup?.valueToSortBy}
        />
    )

}

export default GroupsTable;