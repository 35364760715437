import React from 'react';

import './ServiceData.scss';

interface ServiceDataProps {
    content: string
}

const ServiceData: ({ content }: ServiceDataProps) => React.ReactElement = ({ content }) => {
    
    return <span className='service-data-content'>
        {content}
    </span>
}

export default ServiceData;