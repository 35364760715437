import * as vacancyApi from 'core/api/vacancies'
import * as userApi from 'core/api/users'
import * as groupApi from 'core/api/groups'

import { Vacancy } from 'entities/vacancy'

export type GetExcelReportType = {
    vacancies: Array<Vacancy>
    period: {
        from: string
        to: string
    }
}

export class ReportsAdapter {
    async listVacancies() {
        const resp = await vacancyApi.getVacancies()
        if (resp instanceof Error) {
            return resp
        }

        return resp
    }

    async getVacancyReport({ vacancies, period }: GetExcelReportType): Promise<Blob | Error> {
        return await vacancyApi.getExcelReport({ vacancies, period })
    }

    async getCandidateReport({ vacancies, period }: GetExcelReportType): Promise<Blob | Error> {
        return await vacancyApi.getCandidatesExcelReport({ vacancies, period })
    }

    async listUsers() {
        const resp = await userApi.getUsers()
        if (resp instanceof Error) {
            return resp
        }

        return resp
    }

    async listGroups() {
        const resp = await groupApi.getGroups()
        if (resp instanceof Error) {
            return resp
        }

        return resp
    }
}