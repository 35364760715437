import React, { useCallback, useEffect, useRef, useState } from 'react';
import ButtonUi from '../StyledButtonUi';
import { IconUi } from '../IconUi';
import InputUi from '../StyledInputUi';
import './SearchUi.scss';

export const SearchUi = ({
    value,
    onSearchChange,
    onSearchDiscard,
    onResultSelect,
    results,
    onClickLoadMore,
    ...attributes
}) => {

    const [chosenValue, setChosenValue] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const searchUiContainerRef = useRef();

    const closeOnClickElsewhere = useCallback((e) => {
        if (searchUiContainerRef.current && !searchUiContainerRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    }, [])

    const onInputChange = (e, params) => {
        onSearchChange && onSearchChange(e, params);
        setIsDropdownOpen(true);
    }
    useEffect(() => {
        window.addEventListener('click', closeOnClickElsewhere)

        return () => window.removeEventListener('click', closeOnClickElsewhere);
    }, [closeOnClickElsewhere]);

    useEffect(() => {
        // if the value is discarded outside of the component
        // discard it inside too
        if (value === '') {
            setChosenValue(null);
        }
    }, [value]);

    const onInputClick = () => {
        if (value) {
            setIsDropdownOpen(true);
        }
    }

    const onInputDiscard = () => {
        onSearchDiscard && onSearchDiscard();
        setChosenValue(null);
        setIsDropdownOpen(false);
    }

    const onClickResult = (r) => {
        onResultSelect && onResultSelect(r);
        setChosenValue(r.title);
        setIsDropdownOpen(false);
    }

    return <div className='ui-search-container' ref={searchUiContainerRef}>
        <div className='ui-search'>
            <InputUi 
                value={chosenValue ? ' ' : value}
                disabled={!!chosenValue}
                onClick={onInputClick}
                onChange={onInputChange}
                className='ui-search-input'
                {...attributes} 
            />
            {chosenValue ?
                <div className='ui-search-chosen-value-label'>
                    <span className='ui-search-chosen-value-label-text'>
                        {chosenValue}
                    </span>
                    <div className='ui-search-chosen-value-label-icon'>
                        <IconUi onClick={onInputDiscard} name='close'/>
                    </div>
                </div>
                :
                null
            }
        </div>
        {isDropdownOpen && 
            <div className='ui-search-dropdown'>
                <div className='ui-search-dropdown-list'>
                    {results?.map((r, idx) => 
                        <div 
                            className='ui-search-dropdown-list-item'
                            onClick={() => onClickResult(r)}
                            key={idx}
                        >
                            {r.title}
                        </div>
                    )}
                    {onClickLoadMore && 
                        <div className='ui-search-dropdown-list-load-button-container'>
                            <ButtonUi className='ui-search-dropdown-list-load-button' onClick={()=>onClickLoadMore()}>
                                More...
                            </ButtonUi>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}