import React, { useState } from 'react';
import { Input, Label } from 'semantic-ui-react';


const InputUi = ({ 
    edit, 
    name,
    value, 
    type,
    onChange, 
    errors,
    checkForErrors,
    required,
    action,
    }) => {

    const [touched, setTouched] = useState(false);

    const handleChange = (e, { value }) => {
        setTouched(true);
        checkForErrors && checkForErrors(value);
        onChange && onChange(e);
    }

    if (edit) {
        return <>
            <Input 
                name={name} 
                value={value}
                placeholder={name}
                type={type}
                key={name} 
                onChange={handleChange} 
                required={required}
                fluid 
                action={action}
            />
            {touched && errors && 
                <Label className='error-label' basic color='red' pointing>
                    {errors}
                </Label>
            }
        </>
    }


    return <div>{value}</div>
}

export default InputUi