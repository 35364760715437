import React from 'react';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';

export const CellMeasurerCacheWrapper = ({...props}) => {
    return new CellMeasurerCache(props);
}

export const CellMeasurerUi = ({children, ...props}) => {

    return <CellMeasurer {...props}>
        {({...funcProps}) => children({...funcProps})}
    </CellMeasurer>
}

export default CellMeasurerUi;