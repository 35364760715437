import { arrToMapByServiceId } from '../../utils';
import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    list: [],
    map: {},
    allIds: [],
    socialsByUserId: {},
    selected: {},
    selectedErrors: {},
    editedId: null,
    rolesMap: {},
    rolesAllTypes: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_EDITED_ID:
            return {
                ...state,
                editedId: action.payload,
            }

        case acts.SET_LIST:
            return {
                ...state,
                list: action.payload,
                map: arrToMapByServiceId(action.payload),
                allIds: action.payload? action.payload?.map(u => u.service.id): []
            }

        case acts.APPEND_LIST:
            return {
                ...state,
                list: [
                    ...state.list,
                    ...action.payload
                ],
                map: {
                    ...state.map,
                    ...arrToMapByServiceId(action.payload)
                },
                allIds: [
                    ...state.allIds,
                    ...action.payload.map(u => u.service.id),
                ]
            }
    
        case acts.SET_ROLES:
            const rolesMap = {};
            const rolesAllTypes = [];
            action.payload.forEach(r => {
                rolesAllTypes.push(r.type);
                rolesMap[r.type] = r;
            });
            return {
                ...state,
                rolesMap,
                rolesAllTypes,
            }

        case acts.ADD:
            return {
                ...state,
                list: [
                    action.payload,
                    ...(state.list || []),
                ],
                map: {
                    [action.payload.service.id]: action.payload,
                    ...(state.map || {}),
                },
                allIds: [
                    action.payload.service.id,
                    ...(state.allIds || []),
                ]
            }

        case acts.UPDATE:
            return {
                ...state,
                list: [
                    ...state.list.map(user => {
                        if (user.service.id === action.payload.service.id) {
                            return action.payload
                        }
                        return user
                        }
                    ),
                ],
                map: {
                    ...state.map,
                    [action.payload.service.id]: action.payload
                }
            }

        case acts.SET_SOCIALS_BY_ID:
            return {
                ...state,
                socialsByUserId: action.payload
            }

        case acts.SET_USER_SOCIALS_BY_ID:
            return {
                ...state,
                socialsByUserId: {
                    ...state.socialsByUserId,
                    [action.payload.user_id]: action.payload.data
                }
            }

        case acts.UPDATE_IN_SELECTED:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.payload.id]: {
                        ...(state.selected[action.payload.id] || {}),
                        ...action.payload.data
                    }
                }
            }

        case acts.DELETE_FROM_SELECTED:
            const selectedList = {...state.selected};
            delete selectedList[action.payload];
            return {
                ...state,
                selected: selectedList
            }

        
        case acts.SET_SELECTED_USER_ERRORS:
            return {
                ...state,
                selectedErrors: {
                    ...state.selectedErrors, 
                    [action.payload.id]: action.payload.userErrors
                }
            }

        case acts.SET_SELECTED_FIELD_ERRORS:
            const userErrors = {...state.selectedErrors[action.payload.id]} || {};

            action.payload.error ?
                userErrors[action.payload.fieldName] = action.payload.error
                :
                delete userErrors[action.payload.fieldName];

            return {
                ...state,
                selectedErrors: {
                    ...state.selectedErrors, 
                    [action.payload.id]: userErrors
                }
            }

        default:
            return state;
    }
}

export default reducer;

export const getEditedId = (state) => state.users.editedId;
export const getSocialsByUserId = (state) => state.users.socialsByUserId;
export const getSelected = (state) => state.users.selected;
export const getUsersMap = (state) => state.users.map;
export const getUsersAllIds = (state) => state.users.allIds;