import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';

import { ErrorUnauthorized } from '../../errors';

const setCommentsList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
});

const setCommentsLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
});


const setCommentsIsCommenting = (isCommenting) => ({
    type: acts.SET_IS_COMMENTING,
    payload: isCommenting,
});


const setNewComment = (comment) => ({
    type: acts.ADD,
    payload: comment,
});

const deleteCommentFromList = (commentId) => ({
    type: acts.DELETE,
    payload: commentId,
});

export const loadList = async ({dispatch, id, type, offset, limit}) => {
    dispatch(setCommentsLoading(true));

    const data = await api.getComments({id, type, offset, limit});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(setCommentsList(data || []));
    dispatch(setCommentsLoading(false));
}

export const add = async ({dispatch, id, type, content, replyFor}) => {
    dispatch(setCommentsLoading(true));

    const data = await api.addComment({id, type, content, replyFor});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(setNewComment(data));
    dispatch(setCommentsLoading(false));
    dispatch(setCommentsIsCommenting(false));
}


export const deleteComment = async (dispatch, commentId) => {
    const data = await api.deleteComment(commentId);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(deleteCommentFromList(commentId));
}

export const setIsCommenting = (dispatch, isCommenting) => dispatch(setCommentsIsCommenting(isCommenting));