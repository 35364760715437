import React from "react";
import ModalUi from "../ModalUi";
import ButtonUi from "../StyledButtonUi";

import './styles.scss';

type ConfirmModalUiProps = {
    open: boolean

    header?: string
    confirmText?: string
    rejectText?: string

    onClose: () => void
    onConfirm: () => void
    onReject: () => void
}

const ConfirmModalUi = ({
    open,

    header,
    confirmText="Yes",
    rejectText="No",

    onClose,
    onConfirm,
    onReject,
}: ConfirmModalUiProps) => {

    return (
        <ModalUi
            header={header}
            open={open}
            onClose={onClose}
            className='confirm-modal'
            exitIcon={false}
            headerClassName='confirm-modal-header'
            contentClassName='confirm-modal-content'
        >
            <ButtonUi
                className='confirm-modal-content-button'
                onClick={onReject}
            >
                {rejectText}
            </ButtonUi>
            <ButtonUi
                className='confirm-modal-content-button'
                onClick={onConfirm}
            >
                {confirmText}
            </ButtonUi>
        </ModalUi>
    )
}

export default ConfirmModalUi;