import React from 'react';
import { Input } from 'semantic-ui-react';


export const InputUi = ({...attributes }) => {

    return (
            <Input {...attributes} />
    )
}

export default InputUi