import React, { useState } from 'react';
import ButtonUi from '../ButtonUi';
import { GridUi, GridColumnUi } from '../GridUi';
import HeaderUi from '../HeaderUi';
import InputUi from '../InputUi';
import { ListUi, ListItemUi } from '../ItemsListUi';
import { MenuItemUi } from '../MenuItemUi';
import { MenuUi } from '../MenuUi';
import MessageUi from '../MessageUi';
import ModalUi from '../ModalUi';
import { SegmentUi } from '../SegmentUi';
import SelectUi from '../SelectUi';
import VoterAnswerIcon from '../VoterAnswerIcon';


import './VotesModalUi.scss'

export const VotesModalUi = ({ 
    header, 
    isOpen,
    onOpen, 
    onClose, 
    data,
    usersMap,
    usersAllIds,
    vacanciesMap,
    vacanciesAllIds,
    filesData,
    onChangeField,
    onAddVote,
    onCloseVote,
    formValues,
}) => {

    const [activeIndex, setActiveIndex] = useState((data && data[0] && 0) || -1);

    const onClickMenuItem = (idx) => {
        setActiveIndex(idx);
    }

    const onChangeInput = (e, {name, value}) => {
        onChangeField({name, value});
    }

    const onChangeSelect = (e, {name, value}) => {
        onChangeField({name, value});
    }

    const onClickAddButton = () => {
        onAddVote({...formValues});
    }

    const onCloseModal = () => {
        onClose && onClose();
        setActiveIndex(-1);
    }


    return (
        <ModalUi
            open={isOpen}
            onOpen={onOpen}
            onClose={onCloseModal}
            header={header}
            className='votes-modal'
            headerClassName='votes-modal-header'
            contentClassName='votes-modal-content'
        >
            <GridUi>
                <GridColumnUi width={4}>
                    <MenuUi vertical fluid tabular>
                        <MenuItemUi 
                            active={activeIndex === -1}
                            onClick={(() => onClickMenuItem(-1))}
                            key={-1}
                        >
                            Add Vote
                        </MenuItemUi>
                        {data && data.map((p, idx) => {
                            if (p.closed) {
                                return null
                            }
                            const vacancy = vacanciesMap[p.vacancy_id];
                            return (
                                <MenuItemUi
                                    name={(vacancy ? vacancy.name : `vote #${p.id}`)}
                                    active={activeIndex === idx}
                                    onClick={() => onClickMenuItem(idx)}
                                    key={p.id}
                                />    
                            )
                        })}
                        {data && data.map((p, idx) => {
                            if (!p.closed) {
                                return null
                            }
                            const vacancy = vacanciesMap[p.vacancy_id]
                            return (
                                <MenuItemUi
                                    name={vacancy ? vacancy.name : `vote #${p.id}`}
                                    active={activeIndex === idx}
                                    onClick={() => onClickMenuItem(idx)}
                                    key={p.id}
                                    style={{color: 'grey'}}
                                />    
                            )
                        })}
                    </MenuUi>
                </GridColumnUi>
                <GridColumnUi width={12}>
                    <SegmentUi>
                        {activeIndex === -1 ?
                            <div style={{display: 'flex', flexDirection:'column'}}>
                                <SelectUi 
                                    name='vacancy_id'
                                    value={formValues.vacancy_id || null}
                                    options={[
                                        {key: null, value: null, text: '-'},
                                        ...((vacanciesAllIds && vacanciesAllIds
                                            .map(v => ({key: v, value: v, text: vacanciesMap[v]?.name}))
                                        ) || [])
                                    ]}
                                    placeholder='vacancy'
                                    onChange={onChangeSelect}
                                />
                                <SelectUi 
                                    multiple 
                                    name="user_ids"
                                    value={formValues.user_ids || []}
                                    options={(usersAllIds && usersAllIds.map(u => 
                                            ({key: u, value: u, text: `${usersMap[u]?.surname} ${usersMap[u]?.name}`})
                                        )) || []
                                    }
                                    placeholder='user_ids'
                                    onChange={onChangeSelect}
                                />
                                <SelectUi 
                                    name="file_id"
                                    value={formValues.file_id || null}
                                    options={[
                                        {key: null, value: null, text: '-'},
                                        ...((filesData && filesData.map(f => 
                                            ({key: f.service.id, value: f.service.id, text: f.file_name})
                                        )) || [])
                                    ]}
                                    placeholder='file_id'
                                    onChange={onChangeSelect}
                                />
                                <InputUi 
                                    name='comment'
                                    value={formValues.comment || ''}
                                    placeholder='comment'
                                    onChange={onChangeInput}
                                />
                                <ButtonUi 
                                    onClick={onClickAddButton}
                                    type='submit'
                                    content='Add vote'
                                    disabled={
                                        !formValues || 
                                        !formValues.vacancy_id || 
                                        !formValues.user_ids || 
                                        !formValues.user_ids.length
                                    }
                                />
                            </div>
                            :
                            data && data[activeIndex] && (
                                <>
                                    <ListUi divided className='votes-modal-list'>
                                        {data[activeIndex].user_ids.map((id, idx) => {
                                            const user = usersMap[id] || {name: id, surname: 'User'};
                                            const voter = (data[activeIndex].voters && data[activeIndex].voters.find(f => f.user_id === id)) || {};
                                            return (
                                                <ListItemUi key={idx}>
                                                    <HeaderUi className='votes-modal-list-header'>
                                                        {`${user.surname} ${user.name}`}
                                                    </HeaderUi>
                                                    
                                                    <div className='votes-modal-list-description'>
                                                        <VoterAnswerIcon answer={voter.answer}/>
                                                        {voter.answer}
                                                    </div>
                                                    {voter.comment && voter.comment !== "" &&
                                                        <MessageUi header={'Comment'} content={voter.comment} />
                                                    }
                                                </ListItemUi>
                                            )
                                        })}
                                        <ButtonUi onClick={() => onCloseVote(data[activeIndex].id)}>
                                            Close Vote
                                        </ButtonUi>
                                    </ListUi>
                                    <div>
                                        {data[activeIndex].text}
                                    </div>
                                </>
                            )
                        }
                    </SegmentUi>
                </GridColumnUi>
            </GridUi>
        </ModalUi>
    )
}