import React, { useState } from 'react';
import { Comment } from 'semantic-ui-react';
import Linkify from 'react-linkify';

import CommentFormUi from '../CommentFormUi';

const CommentUi = ({ data, onSubmitComment, onDiscardComment, onDeleteComment, author, replies }) => {

    const [showReplyForm, setShowReplyForm] = useState(false);
    const [showConfirmationForm, setShowConfirmationForm] = useState(false);

    if (!data) {
        return null
    }

    const {
        // id,
        service: {
            created_at,
            created_by,
        },
        // topic_id,
        content,
    } = data;

    return (
        <Comment>
            <Comment.Avatar src='https://react.semantic-ui.com/images/wireframe/square-image.png' />
            <Comment.Content>
                {/* TODO: replace with action or variable */}
                <Comment.Author as='a' target="_blank" href={`/matches/api/v1/users/${created_by}`}>{author ? `${author.name} ${author.surname}` : "Anonymous"}</Comment.Author>
                <Comment.Metadata>
                    <div>{created_at}</div>
                </Comment.Metadata>
                <Comment.Content>
                    {makeHyperLinks(content)}
                </Comment.Content>
                <Comment.Actions>
                    <Comment.Action onClick={() => { setShowReplyForm(!showReplyForm) }}>
                        Reply
                    </Comment.Action>
                    {!showConfirmationForm ?
                        <Comment.Action onClick={() => { setShowConfirmationForm(true) }}>
                            Delete
                        </Comment.Action>
                        :
                        <>
                            <Comment.Action as='b'>Are you sure? </Comment.Action>
                            <Comment.Action onClick={() => {
                                onDeleteComment(data.service.id);
                                setShowConfirmationForm(false);
                            }}>
                                Yes
                            </Comment.Action>
                            <Comment.Action onClick={() => { setShowConfirmationForm(false) }}>
                                <b>No</b>
                            </Comment.Action>
                        </>
                    }
                </Comment.Actions>
                {showReplyForm &&
                    <CommentFormUi
                        onSubmit={(text) => {
                            onSubmitComment(text, data.service.id);
                            setShowReplyForm(false);
                        }}
                        onDiscard={() => {
                            onDiscardComment();
                            setShowReplyForm(false);
                        }}
                    />
                }
            </Comment.Content>
            {replies}
        </Comment>
    )
}

export const makeHyperLinks = (text) => {
    return <Linkify>{text}</Linkify>
}

export default CommentUi;