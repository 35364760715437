import { arrToMapAndIds } from '../../utils';
import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    map: {},
    allIds: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_LIST:
            const {map, allIds} = arrToMapAndIds(action.payload)
            return {
                ...state,
                map: map || {},
                allIds: allIds || [],
            }

        case acts.UPDATE:
            return {
                ...state,
                map: {
                    ...state.map,
                    [action.payload.id]: {
                        ...state.map[action.payload.id], 
                        ...action.payload
                    }
                }
            }

        case acts.APPEND_LIST:
            const {map: mapToAppend, allIds: allIdsToAppend} = arrToMapAndIds(action.payload)
            
            return {
                ...state,
                map: {
                    ...(state.map || {}),
                    ...(mapToAppend || {}),
                },
                allIds: [
                    ...(state.allIds || []),
                    ...(allIdsToAppend || []),
                ],
            }
        default:
            return state;
    }
}

export default reducer;
