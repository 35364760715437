import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';

import { ErrorUnauthorized } from '../../errors';


const setLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
});

const setList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
});

const updateVoterInList = (data) => ({
    type: acts.UPDATE_VOTER,
    payload: data,
});


const setVoters = (data) => ({
    type: acts.SET_VOTERS,
    payload: data,
});

const updateList = (data) => ({
    type: acts.UPDATE_LIST,
    payload: data,
});

const addVote = (data) => ({
    type: acts.ADD_VOTE,
    payload: data,
});
const updateVoteInSelected = (data) => ({
    type: acts.UPDATE_IN_SELECTED,
    payload: data
});

const deleteVoteFromSelected = (id) => ({
    type: acts.DELETE_FROM_SELECTED,
    payload: id
});

const closeVote = (id) => ({
    type: acts.CLOSE_VOTE,
    payload: id
})

export const loadListWithVoters = async (dispatch) => {
    dispatch(setLoading(true));

    const responseData = await api.listVotes() || [];

    if (responseData instanceof Error) {
        if (responseData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw responseData;
    }

    dispatch(setList(responseData));

    const requests = responseData.map(v => api.listVotersByVoteId(v.id))

    let loadedVotersByVoteIds = {};
    let errors = [];

    await Promise.all(requests)
        .then(r => {
            responseData.map((v, idx) => {
                const id = v.id;
                if (r[idx] instanceof Error) {
                    if (r[idx].message === ErrorUnauthorized) {
                        dispatch(signout());
                        return undefined
                    }
                    errors.push(r[idx]);
                    return undefined
                }
                loadedVotersByVoteIds[id] = r[idx];
                return undefined
            })
        })
    dispatch(setVoters(loadedVotersByVoteIds));

    dispatch(setLoading(false));
}

export const loadListById = async ({dispatch, person_id}) => {
    dispatch(setLoading(true));

    const responseData = await api.listVotesByPersonId(person_id);

    if (responseData instanceof Error) {
        if (responseData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw responseData;
    }

    dispatch(updateList(responseData || []));
    dispatch(setLoading(false));
}

export const add = async ({dispatch, person_id, vacancy_id, user_ids, file_id, comment}) => {
    dispatch(setLoading(true));

    const createdVote = await api.createVote({person_id, vacancy_id, user_ids, file_id, comment});

    if (createdVote instanceof Error) {
        if (createdVote.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw createdVote;
    }

    dispatch(addVote(createdVote));
    user_ids.map(u => updateVoter({ dispatch, voter: {user_id: u, vote_id: createdVote.id}}))
    deleteSelected({dispatch, id: person_id});
    dispatch(setLoading(false));
}
export const close = async ({dispatch, id}) => {
    dispatch(setLoading(true));

    const response = api.closeVoteById(id);

    if (response instanceof Error) {
        if (response.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw response;
    }

    dispatch(closeVote(id));
    dispatch(setLoading(false));
}

export const updateVoter = ({dispatch, voter}) => {
    dispatch(updateVoterInList(voter));
}


export const updateSelected = ({dispatch, id, data}) => {
    dispatch(updateVoteInSelected({id, data}));
}

export const deleteSelected = ({dispatch, id}) => {
    dispatch(deleteVoteFromSelected(id));
}