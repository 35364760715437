import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    chosenId: undefined,
    limit: 10,
    offset: 0,
    map: {},
    allIds: [],
    byPersonId: {},
    byVacancyId: {},
    stageTypesMap: {},
    stageTypesAllIds: [],
    stagesMapByRelationId: {},

    // processing
    processingCancelReasons: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_LIST_PROC_CANCEL_REASONS:
            return {
                ...state,
                processingCancelReasons: action.payload,
            }

        case acts.APPEND_LIST_PROC_CANCEL_REASONS:
            return {
                ...state,
                processingCancelReasons: [
                    ...state.processingCancelReasons,
                    action.payload,
                ]
            }

        case acts.SET_LIST:
            const {
                map: mapToSet,
                allIds: allIdsToSet,
                byPersonId: byPersonIdToSet,
                byVacancyId: byVacancyIdToSet,
            } = arrToRelationMapsAndAllIds(action.payload || []);
            return {
                ...state,
                map: mapToSet,
                allIds: allIdsToSet,
                byPersonId: byPersonIdToSet,
                byVacancyId: byVacancyIdToSet,
            }

        case acts.APPEND_LIST:
            const newRelations = action.payload.filter(newRel => !state.map[newRel.id]);

            const { map: newMap, allIds: newAllIds } = arrToRelationMapsAndAllIds(newRelations);
            const byPersonIdAppended = { ...(state.byPersonId || {}) };
            const byVacancyIdAppended = { ...(state.byVacancyId || {}) };

            newRelations.forEach(newRel => {
                byPersonIdAppended[newRel.person_id] = [...(byPersonIdAppended[newRel.person_id] || []), newRel];
                byVacancyIdAppended[newRel.vacancy_id] = [...(byVacancyIdAppended[newRel.vacancy_id] || []), newRel];
            })
            return {
                ...state,
                map: { ...state.map, ...newMap },
                allIds: [...state.allIds, ...newAllIds],
                byPersonId: byPersonIdAppended,
                byVacancyId: byVacancyIdAppended,
            }

        case acts.BULK_ADD_RELATION_AND_STAGE:
            const relationAndStageList = action.payload.filter(listItem => !state.map[listItem.relation.id]);

            const { map: newMap2, allIds: newAllIds2 } = relationAndStageListToMap(relationAndStageList);
            const byPersonIdAppended2 = { ...(state.byPersonId || {}) };
            const byVacancyIdAppended2 = { ...(state.byVacancyId || {}) };

            relationAndStageList.forEach(item => {
                byPersonIdAppended2[item.relation.person_id] = [...(byPersonIdAppended2[item.relation.person_id] || []), item.relation];
                byVacancyIdAppended2[item.relation.vacancy_id] = [...(byVacancyIdAppended2[item.relation.vacancy_id] || []), item.relation];
            })
            return {
                ...state,
                map: { ...state.map, ...newMap2 },
                allIds: [...state.allIds, ...newAllIds2],
                byPersonId: byPersonIdAppended2,
                byVacancyId: byVacancyIdAppended2,
            }

        case acts.ADD_RELATION_AND_STAGE:
            console.log("--- ", action.payload);
            return {
                ...state,
                map: {
                    ...state.map,
                    [action.payload.relation.id]: action.payload.relation,
                },
                allIds: [
                    ...state.map,
                    action.payload.relation.id,
                ],
                byPersonId: {
                    ...state.byPersonId,
                    [action.payload.relation.person_id]: action.payload.relation,
                },
                byVacancyId: {
                    ...state.byPersonId,
                    [action.payload.relation.vacancy_id]: action.payload.relation,
                },
                stagesMapByRelationId: {
                    ...state.stagesMapByRelationId,
                    [action.payload.relation.id]: action.payload.stage,
                }
            }

        case acts.DELETE:
            const relationToDelete = state.map[action.payload];

            if (!relationToDelete) {
                return { ...state }
            }

            const mapWithRelationDeleted = { ...state.map };
            delete mapWithRelationDeleted[action.payload];

            return {
                ...state,
                map: mapWithRelationDeleted,
                allIds: state.allIds.filter(id => id !== action.payload),
                byPersonId: {
                    ...state.byPersonId,
                    [relationToDelete.person_id]:
                        state.byPersonId[relationToDelete.person_id].filter(rel => rel.id !== action.payload),
                },
                byVacancyId: {
                    ...state.byVacancyId,
                    [relationToDelete.vacancy_id]:
                        state.byVacancyId[relationToDelete.vacancy_id].filter(rel => rel.id !== action.payload),
                },
            }

        case acts.SET_STAGES:
            return {
                ...state,
                stagesMapByRelationId: action.payload
            }

        case acts.APPEND_STAGES:
            return {
                ...state,
                stagesMapByRelationId: {
                    ...state.stagesMapByRelationId,
                    ...action.payload
                }
            }

        case acts.ADD_STAGE:
            return {
                ...state,
                stagesMapByRelationId: {
                    ...state.stagesMapByRelationId,
                    [action.payload.relation_id]: [
                        ...(state.stagesMapByRelationId[action.payload.relation_id] || []),
                        action.payload,
                    ],
                }
            }

        case acts.DELETE_STAGE:
            const filteredStages = state.stagesMapByRelationId[action.payload.relationId]
                .filter(s => s.id !== action.payload.stageId);
            return {
                ...state,
                stagesMapByRelationId: {
                    ...state.stagesMapByRelationId,
                    [action.payload.relationId]: filteredStages
                }
            }

        case acts.SET_STAGE_TYPES:
            return {
                ...state,
                stageTypesMap: action.payload.map,
                stageTypesAllIds: action.payload.allIds,
            }

        case acts.ADD_STAGE_TYPE:
            return {
                ...state,
                stageTypesMap: {
                    ...state.stageTypesMap,
                    [action.payload.relation_id]: action.payload
                },
                stageTypesAllIds: [
                    ...state.stageTypesAllIds,
                    action.payload.id,
                ],
            }

        case acts.UPDATE_STAGE_TYPE:
            return {
                ...state,
                stageTypesMap: {
                    ...state.stageTypesMap,
                    [action.payload.id]: action.payload
                }
            }

        case acts.DELETE_STAGE_TYPE:
            const stageTypesMap = { ...state.stageTypes };
            delete stageTypesMap[action.payload];

            const filteredStageTypesAllIds = state.stageTypesAllIds.filter(id => id !== action.payload);
            return {
                ...state,
                stageTypesMap: stageTypesMap,
                stageTypesAllIds: filteredStageTypesAllIds,
            }

        default:
            return state;
    }
}

const arrToRelationMapsAndAllIds = (relArr) => {
    const map = {};
    const allIds = [];
    const byPersonId = {};
    const byVacancyId = {};


    relArr.forEach(rel => {
        map[rel.id] = rel;
        allIds.push(rel.id);
        byPersonId[rel.person_id] = [...(byPersonId[rel.person_id] || []), rel];
        byVacancyId[rel.vacancy_id] = [...(byVacancyId[rel.vacancy_id] || []), rel];
    });

    return { map, allIds, byPersonId, byVacancyId }
}

const relationAndStageListToMap = (relAndStageList) => {
    const map = {};
    const allIds = [];
    const byPersonId = {};
    const byVacancyId = {};


    relAndStageList.forEach(rsItem => {
        map[rsItem.relation.id] = rsItem.relation;
        allIds.push(rsItem.relation.id);
        byPersonId[rsItem.relation.person_id] = [...(byPersonId[rsItem.relation.person_id] || []), rsItem.relation];
        byVacancyId[rsItem.relation.vacancy_id] = [...(byVacancyId[rsItem.relation.vacancy_id] || []), rsItem.relation];
    });

    return { map, allIds, byPersonId, byVacancyId }
}

export default reducer;

export const getRelationsMap = (state) => state.relations.map;
export const getRelationsAllIds = (state) => state.relations.allIds;
export const getRelationsByPersonId = (state) => state.relations.byPersonId;
export const getStagesMapByRelationId = (state) => state.relations.stagesMapByRelationId;
export const getStageTypesAllIds = (state) => state.relations.stageTypesAllIds;
export const getStageTypesMap = (state) => state.relations.stageTypesMap;