import React, { useEffect, useRef, useState } from "react"
import Option from './Option';

import "./styles.scss"

export type OptionType = {
    value: number | string,
    label: string,
    payload?: any,
}

export type PropsType = {
    // handlers
    onDiscard?: Function
    loadOptions: (text: string) => Promise<Array<OptionType>>

    icon?: JSX.Element

    withButton?: {
        title?: string,
        icon?: JSX.Element
        handler: (option: OptionType) => void
    }
    withCreate?: {
        title?: string,
        icon?: JSX.Element
        handler: (createName: string) => Promise<OptionType>
    }

    onSelect?: (option: OptionType) => void

    // basic props
    autoFocus?: boolean
    type?: string
    placeholder?: string
}

const Input = ({
    onDiscard,
    loadOptions,

    type,
    autoFocus,
    placeholder,
    icon,

    withButton,
    withCreate,

    onSelect,
}: PropsType) => {
    if (!type) type = "text"

    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<Array<OptionType>>([])
    const [selected, setSelected] = useState<OptionType|null>(null)
    const [showOptions, setShowOptions] = useState(false)

    const createButtonRef = useRef<HTMLButtonElement>(null)

    const onBlurTimeoutRef = useRef<number | null>(null)
    const onCloseTimeoutRef = useRef<number  | null>(null)

    useEffect(() => () => {
        // cancels timeouts before unmounting
        if (onBlurTimeoutRef.current) {
            window.clearTimeout(onBlurTimeoutRef.current)
        }
        
        if (onCloseTimeoutRef.current) {
            window.clearTimeout(onCloseTimeoutRef.current)
        }
    }, [])

    let blockChanges = false

    let preventBlur = false
    let optionFocused = false

    const onChangeHndl = (e: any) => {
        if (!blockChanges) {
            setSelected(null)
        }

        setValue(e.target.value)
        blockChanges = false
    }

    const onKeyPressHndl = (e: any) => {
        if (e.key === "Escape") {
            onDiscard && onDiscard()
        }
    }

    const onFocusHndl = () => {
        setShowOptions(true)
    }

    const onBlurHndl = () => {
        /**
         * timeout give opportunity to handle
         * onClick before options hide
         */ 
        onBlurTimeoutRef.current = window.setTimeout(() => {
            if (preventBlur) {
                return
            }

            setShowOptions(false)
        }, 100)
    }

    useEffect(() => {
        if (selected) {
            createButtonRef && createButtonRef.current && createButtonRef.current.focus()
        }
    }, [selected])

    const onSelectHndl = (option: OptionType) => () => {
        onSelect && onSelect(option)
        setSelected(option)
        blockChanges = true
        setValue(option.label)
    }

    const onButtonClickHndl = () => {
        withButton && selected && withButton.handler(selected)
    }

    const onCreateClickHndl = (value: string) => async () => {
        if (!withCreate) {
            return
        }

        setLoading(true)
        try {
            let result = await withCreate.handler(value)
            
            setSelected(result)
        } catch (err) {
            // TODO: handle error
            console.log("got error:", err);
        }

        setLoading(false)
    }

    const onFocusOptionHndl = () => {
        optionFocused = true
        preventBlur = true
    }

    const closeOptions = () => {
        onCloseTimeoutRef.current = window.setTimeout(() => {
            if (optionFocused) {
                return
            }
            setShowOptions(false)
        }, 200)
    }

    const onBlurOptionHndl = () => {
        optionFocused = false
        closeOptions()
    }

    useEffect(() => {
        const load = async (text: string) => {
            setLoading(true)

            const opts = await loadOptions(text)
            
            setOptions(opts)
            setLoading(false)
        }

        load(value)
    }, [loadOptions, value, setLoading])

    const classes = `input-select ${withButton? "with-button": ""}`.trim()

    const inputClasses = `${icon? "with-icon": ""} ${selected? "selected": ""}`.trim()

    return (
        <div className={classes}>
            {icon && icon}
            <input
                onKeyUp={onKeyPressHndl}
                className={inputClasses}
                value={value}
                onChange={onChangeHndl}
                autoFocus={autoFocus}
                placeholder={placeholder}

                onFocus={onFocusHndl}
                onBlur={onBlurHndl}
            />

            {withButton && 
                <button
                    ref={createButtonRef}
                    onClick={onButtonClickHndl}
                    disabled={value.length < 2 || !selected}
                    className={"input-select-button"}
                >
                    <React.Fragment>
                        {withButton.icon && withButton.icon}
                        {withButton.title && withButton.title}
                    </React.Fragment>
                </button>
            }

            {!selected && showOptions &&
                <div className={"input-select-options"}>
                    {loading?
                        "loading..."
                        :
                        options.length > 0? options.map((opt, key) => (
                                <Option
                                    onFocus={onFocusOptionHndl}
                                    onBlur={onBlurOptionHndl}
                                    onClick={onSelectHndl(opt)}
                                    key={key}
                                >
                                    {opt.label}
                                    {opt.payload}
                                </Option>        
                            ))
                            :
                            <div className={"input-select-options-empty"}>There is nothing for '{value}'</div>
                        }

                        {withCreate && 
                            value.length > 1 && !loading &&
                            <>
                                <div className={"input-select-options-divider"}></div>

                                <Option
                                    onFocus={onFocusOptionHndl}
                                    onBlur={onBlurOptionHndl}
                                    onClick={onCreateClickHndl(value)}
                                    key={0}
                                >
                                    Create: '{value}'
                                </Option>
                            </>
                            
                        }
                </div>
            }
        </div>
    )
}

export default Input