import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    list: [],
    byPersonId: {},
    selected: {},
    votersByVoteId: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
    
        case acts.SET_LIST:
            return {
                ...state,
                list: action.payload,
                byPersonId: arrToMapByKey(action.payload || [], "person_id"),
            }

        case acts.UPDATE_LIST:
            let updatedList = [...state.list];
            const newVotes = [...action.payload];
            updatedList = updatedList.filter(p => !newVotes.some(np => np.id === p.id));
            updatedList = [...updatedList, ...newVotes];

            return {
                ...state,
                list: updatedList,
                byPersonId: arrToMapByKey(updatedList || [], "person_id"),
            }

        case acts.APPEND_LIST:
            return {
                ...state,
                list: [...state.list, ...action.payload],
                byPersonId: arrToMapByKey([...state.list, ...action.payload] || [], "person_id"),
            }

        case acts.ADD_VOTE:
            return {
                ...state,
                list: [ action.payload, ...state.list],
                byPersonId: arrToMapByKey([ action.payload, ...state.list] || [], "person_id"),
            }

        case acts.CLOSE_VOTE:
            const newList = state.list.map(v => 
                v.id === action.payload ? 
                    {...v, closed: true}
                    :
                    v
                );
            return {
                ...state,
                list: newList,
                byPersonId: arrToMapByKey(newList || [], "person_id"),
            }

        case acts.SET_VOTERS:
            return {
                ...state,
                votersByVoteId: {
                    ...action.payload
                },
            }

        case acts.UPDATE_VOTER:
            let updatedVotersByVoteId = [...(state.votersByVoteId[action.payload.vote_id] || [])];
            const oldVoterIndex = updatedVotersByVoteId.findIndex(v => v.user_id === action.payload.user_id)

            if (oldVoterIndex === -1) {
                updatedVotersByVoteId.push(action.payload);
            } else {
                updatedVotersByVoteId[oldVoterIndex] = action.payload;
            }
                
            return {
                ...state,
                votersByVoteId: {
                    ...state.votersByVoteId, 
                    [action.payload.vote_id]: [
                        ...updatedVotersByVoteId
                    ]
                },
            }

        case acts.UPDATE_IN_SELECTED:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.payload.id]: {
                        ...(state.selected[action.payload.id] || {}),
                        ...action.payload.data
                    }
                }
            }

        case acts.DELETE_FROM_SELECTED:
            const selectedList = {...state.selected};
            delete selectedList[action.payload];
            return {
                ...state,
                selected: selectedList
            }
        default:
            return state;
    }
}

const arrToMapByKey = (arr, key) => {
    const obj = {};

    arr.forEach(el => {
        if (!el[key]) {
            console.error(`the key '${key}' not found at object`, el);
            return
        }

        if (!obj[el[key]]) {
            obj[el[key]] = [el];
            return
        }
        
        obj[el[key]].push(el);
    });

    return obj;
}

export default reducer;