import * as acts from './actionTypes';

export const initialState = {
    ready: false,
    token: '',
    isAuth: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_READY:
            return {
                ...state,
                ready: action.payload
            }
        case acts.SIGN_IN:
            return {
                ...state,
                isAuth: true
            }
        case acts.SIGN_OUT:
            return {
                ...state,
                isAuth: false
            }
        default:
            return state;
    }
}

export default reducer;