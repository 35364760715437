import React, { useEffect, useMemo, useState } from "react"
import { Button, Container, Grid, Input, Segment, Sticky } from "semantic-ui-react"

import { Vacancy } from "entities/vacancy"
import { User } from "entities/user"
import { Group } from "entities/group"

import { ReportsAdapter } from 'compositions/adapter'

import { AccordionUi } from "compositions/Reports/accordion"
import { VacancyList } from "compositions/Reports/vacancy_list"

import 'compositions/Reports/style.scss'

const Reports = () => {
    const reports = useMemo(() => new ReportsAdapter(), [])

    const [gotError, setGotError] = useState<null | string>(null)
    const [selectedVacancies, setSelectedVacancies] = useState({} as { [id: number]: boolean })

    const [vacancyList, setVacancyList] = useState([] as Array<Vacancy>)
    const [userList, setUserList] = useState([] as Array<User>)
    const [groupList, setGroupList] = useState([] as Array<Group>)


    const [fromDate, setFromDate] = useState(() => {
        let d = new Date()
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    })

    const [toDate, setToDate] = useState(() => {
        let d = new Date()
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    })

    useEffect(() => {
        const receiveVacancies = async () => {
            const res = await reports.listVacancies()
            if (res instanceof Error) { setGotError(res.message); return }

            setVacancyList(res)
        }
        const receiveUsers = async () => {
            const res = await reports.listUsers()
            if (res instanceof Error) { setGotError(res.message); return }

            setUserList(res)
        }
        const receiveGroups = async () => {
            const res = await reports.listGroups()
            if (res instanceof Error) { setGotError(res.message); return }

            setGroupList(res)
        }

        receiveVacancies()
        receiveUsers()
        receiveGroups()
    }, [reports])

    const getVacanciesReport = async () => {
        const blob = await reports.getVacancyReport({
            vacancies: vacancyList.filter(vacancy => selectedVacancies[vacancy.service.id]),
            period: { from: fromDate, to: toDate }
        })
        if (blob instanceof Error) { setGotError(blob.message); return }

        var a = document.createElement("a")

        a.href = window.URL.createObjectURL(blob)
        a.download = "report.xlsx"
        a.click()
    }

    const getCandidatesReport = async () => {
        const blob = await reports.getCandidateReport({
            vacancies: vacancyList.filter(vacancy => selectedVacancies[vacancy.service.id]),
            period: { from: fromDate, to: toDate }
        })
        if (blob instanceof Error) { setGotError(blob.message); return }

        var a = document.createElement("a")

        a.href = window.URL.createObjectURL(blob)
        a.download = "report.xlsx"
        a.click()
    }

    const onChangeFromDateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFromDate(event.target.value)
    }

    const onChangeToDateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToDate(event.target.value)
    }

    const onSelectVacancy = (vacancy: Vacancy, forceSelect?: boolean) => {
        setSelectedVacancies((vacancies) => ({
            ...vacancies,
            [vacancy.service.id]: forceSelect !== undefined ? forceSelect : !selectedVacancies[vacancy.service.id],
        }))
    }

    return (
        <Container>
            <Grid columns={"two"} divided>
                <Grid.Row>
                    <Grid.Column>

                        <Segment>
                            <AccordionUi
                                users={userList ? userList.filter(user => user.role === 3) : []}
                                groups={groupList ? groupList.filter(group => !group.is_hidden) : []}
                                vacancies={vacancyList ? vacancyList : []}
                                selectedVacancies={selectedVacancies}

                                onSelectVacancy={onSelectVacancy}
                            />
                        </Segment>

                    </Grid.Column>
                    <Grid.Column>
                        <Sticky>

                            <Segment>
                                <h3>
                                    Выгрузить отчет по датам:
                                </h3>
                                <Grid columns={"two"}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div>
                                                <div>С даты</div>
                                                <Input value={fromDate} type={"date"} onChange={onChangeFromDateHandler} />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div>
                                                <div>По дату</div>
                                                <Input value={toDate} type={"date"} onChange={onChangeToDateHandler} />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={"one"}>
                                        <Grid.Column >
                                            <Button onClick={() => getVacanciesReport()}>
                                                Скачать отчет по вакансиям (.xlsx)
                                            </Button>
                                        </Grid.Column>
                                        <Grid.Column >
                                            <Button onClick={() => getCandidatesReport()}>
                                                Скачать отчет по причинам отказа (.xlsx)
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>

                            <Segment>
                                <h3>
                                    Список выбранных вакансий:
                                </h3>
                                <div>
                                    <VacancyList
                                        data={
                                            vacancyList
                                                .filter(vacancy => selectedVacancies[vacancy.service.id])
                                        }
                                    />
                                </div>
                            </Segment>

                        </Sticky>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div>
                {gotError ? gotError : ""}
            </div>
        </Container>
    )
}

export default Reports
