import React, {
  useContext, useEffect, useState
} from 'react';
import { ToastContainer } from 'react-toastify';

import './App.scss';
import { store } from './store';
import * as auth from './redux/auth/actions';
import * as vacancies from './redux/vacancies/actions';
import * as user from './redux/user/actions';
import * as relations from './redux/relations/actions';
import * as groups from './redux/groups/actions';
import * as profiles from './redux/profiles/actions';
import * as tags from './redux/tags/actions';
import * as users from './redux/users/actions';
import { handleError } from './errorHandler';

import Routes from './routes';
import Navigator from './views/Navigator';
import { Loader } from './components/Loader';
import { Button } from 'semantic-ui-react';

const App = () => {

  const { dispatch, state } = useContext(store);
  const { auth: { isAuth } } = state;

  const [requestsReady, setRequestsReady] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(()=>{
    const authReq = auth.isAuthorized(dispatch);
    const userReq = user.load(dispatch);
    const stageTypesReq = relations.loadStageTypes(dispatch);
    const vacReq = vacancies.loadList(dispatch);
    const grReq = groups.loadList(dispatch);
    const profReq = profiles.loadList(dispatch);
    const tagReq = tags.loadList(dispatch);
    const rolesReq = users.loadRolesList(dispatch);

    setDarkMode(() => {
      const storedDarkMode = JSON.parse(localStorage.getItem('dark-mode')) || false;
      document.documentElement.setAttribute('data-theme', storedDarkMode ? 'dark' : 'light');
      return storedDarkMode
    });
    
    Promise.all([
      authReq,
      userReq,
      stageTypesReq,
      vacReq,
      grReq,
      profReq,
      tagReq,
      rolesReq,
    ]).then(() => {
        setRequestsReady(true);
      })
      .catch(err => handleError(err));
  }, [dispatch]);

  if (!requestsReady) {
    return <Loader />
  }


  return (
    <div className={`page ${darkMode ? 'dark' : "light"}`}>
      <header className="page-header">
        <div className="page-header-title">Matches</div>
      <Button 
        style={{position: 'absolute', right: '1vw', top: '1vh'}} 
        icon={darkMode ? 'sun' : 'moon'} 
        onClick={()=>{
          setDarkMode(prevDarkModeState => {
            localStorage.setItem('dark-mode', !prevDarkModeState);
            document.documentElement.setAttribute('data-theme', prevDarkModeState ? 'light' : 'dark');
            return !prevDarkModeState
          });
        }}
      />
      </header>

      <div className="page-body">
        <Routes isAuth={isAuth} />
      </div>

      <Navigator />
      
      <ToastContainer />

      <footer className="page-footer">

      </footer>
    </div>
  );
}

export default App;
