import React from 'react';

export const FileUi = ({content}) => {

    if (!content)
        return null

    switch (content.ext) {
        case "pdf":
            return <iframe
                className="files-ui-iframe"
                title={content.name}
                frameBorder="0"
                src={"data:application/pdf;base64, " + content.binary_data}
            >
            </iframe>
        case "txt":
            return <p dangerouslySetInnerHTML={{__html: content.binary_data}}/>
        case 'docx':
            return <div dangerouslySetInnerHTML={{__html: content.binary_data}}/>
        default:
            return "file with ext" + content.ext;
    }
}

export default FileUi;