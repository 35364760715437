import React, { useEffect, useRef, useState } from 'react';
import { Tag } from '../../entities/tag';
import { hasParentWithGivenClass } from '../../utils';
import { IconUi } from '../IconUi';
import PopupUi from '../PopupUi';
import TagUi from '../TagUi';

import './styles.scss';

type TagListUiProps = {
    tagsMap: {
        [tagId: number]: Tag
    }
    tagsAllIds: number[] | null
    tagIdsToShow: number[] | null

    onCreateTag: (label: string) => Promise<void>
    onUpdateTag: (tag: Tag) => Promise<void>
    
    onAddTagId: (id: number) => Promise<void>
    onDeleteTagId: (id: number) => Promise<void>
    
    maxTagsToShow?: number
}

export const TagsListUi = ({
    tagsMap,
    tagsAllIds,
    tagIdsToShow,

    onCreateTag,
    onUpdateTag,
    onAddTagId,
    onDeleteTagId,
    
    maxTagsToShow,
}: TagListUiProps) => {

    const [searchInputValue, setSearchValue] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const tagsListRef = useRef<HTMLSpanElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const searchInputValueRef = useRef('');

    useEffect(() => {
        searchInputValueRef.current = searchInputValue;
    }, [searchInputValue]);

    useEffect(() => {
        const createTag = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && searchInputValueRef.current) {
                onCreateTag(searchInputValueRef.current)
                    .then(() => {
                        setSearchValue('');
                    })
            }
        }
        window.addEventListener('keyup', createTag)
        return () => window.removeEventListener('keyup', createTag)
    }, [onCreateTag]);
    
    useEffect(() => {
        const closePopupOnClickElsewhere = (e: MouseEvent) => {
            
            setIsPopupOpen(isOpen => {
                if (!isOpen) return isOpen;

                if (
                    containerRef.current?.contains(e.target as Node) || 
                    // dont close if target is inside this popup or in another nested popup
                    hasParentWithGivenClass({className: 'popup', element: e.target as  Element | null})
                ) {
                    return isOpen;
                } else {
                    return false
                }
            })
        }
        window.addEventListener('click', closePopupOnClickElsewhere);

        return () => window.removeEventListener('click', closePopupOnClickElsewhere);
    }, []);

    return (
        <div className='tags-list-container' ref={containerRef}>
            <span className='tags-list' ref={tagsListRef}>
                {tagIdsToShow?.slice(0, maxTagsToShow).map(tagId => {
                    let tag = tagsMap[tagId];
                    let onUpdate = onUpdateTag;

                    if (!tag) {
                        tag = {id: tagId, label: 'Deleted tag', color: '#ffffff'};
                        onUpdate = (tag) => new Promise((r)=>{r()});
                    }

                    return (
                        <TagUi
                            tag={tag}
                            onDelete={onDeleteTagId}
                            onUpdate={onUpdate}
                            key={tagId}
                        />
                    )
                })}
                {maxTagsToShow && tagIdsToShow && (tagIdsToShow.length - maxTagsToShow) > 0 ?
                    <span className="tags-list-excess-tag-icon" onClick={() => setIsPopupOpen(true)}>
                        +{tagIdsToShow.length - maxTagsToShow}
                    </span>
                    :
                    <span onClick={() => setIsPopupOpen(true)}>
                        <IconUi name='tags' size='small'/>
                    </span>
                }
                <PopupUi
                    on='click'
                    context={tagsListRef}
                    open={isPopupOpen}
                >
                    <div className='tags-list-popup'>
                        <div>
                            {tagIdsToShow?.map(tagId => {
                                let tag = tagsMap[tagId];
                                let onUpdate = onUpdateTag;

                                if (!tag) {
                                    tag = {id: tagId, label: 'Deleted tag', color: '#ffffff'};
                                    onUpdate = (tag) => new Promise((r)=>{r()});
                                }

                                return (
                                    <TagUi
                                        tag={tag}
                                        onDelete={onDeleteTagId}
                                        onUpdate={onUpdate}
                                        key={tagId}
                                    />
                                )
                            })}
                        </div>

                        <input
                            value={searchInputValue}
                            onChange={(e)=>setSearchValue(e.target.value)}
                            className='tags-list-popup-input'
                            placeholder='Tag search'
                        />

                        <div className='tags-list-popup-options'>
                            {
                                tagsAllIds?.map(tagId => {
                                    if (
                                        tagIdsToShow?.includes(tagId) || 
                                        (
                                            searchInputValue &&
                                            tagsMap[tagId].label.indexOf(searchInputValue) === -1
                                        )
                                    ) {
                                        return null
                                    }
                                    return (
                                        <div
                                            key={tagId}
                                            onClick={() => {
                                                onAddTagId(tagId);
                                            }}
                                            className='tags-list-popup-options-option'
                                        >
                                            {tagsMap[tagId].label}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {searchInputValue && 
                            <div>
                                Press Enter to create a new tag
                            </div>
                        }
                    </div>
                </PopupUi>
            </span>
        </div>
    )
}


export default TagsListUi