import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';

import { ErrorUnauthorized } from '../../errors';


const setUserLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
});

const setUserData = (userData) => ({
    type: acts.SET_USER_DATA,
    payload: userData,
});

export const load = async (dispatch) => {
    dispatch(setUserLoading(true));

    const responseData = await api.getUser();

    if (responseData instanceof Error) {
        if (responseData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw responseData;
    }

    dispatch(setUserData(responseData));
    dispatch(setUserLoading(false));
}