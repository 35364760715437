import React from 'react';
import { Button, Form} from 'semantic-ui-react';

import './index.scss';

const textareaTagName = "textarea";

const CommentFormUi = ({
    onSubmit = () => {},
    onDiscard = () => {},
}) => {
    return (
        <Form
            reply
            onSubmit={data => {
                for (let i = 0; data.target.length > i; i++) {
                    if (data.target[i].tagName.toLowerCase() === textareaTagName) {
                        onSubmit(data.target[i].value);

                        break;
                    }
                }
            }}
        >

            <Form.TextArea />

            <Button
                type='submit'
                content='Add comment'
                labelPosition='left'
                icon='edit'
                primary
            />

            <Button
                type='button'
                content="Discard"
                onClick={onDiscard}
            />
        </Form>
    )
}

export default CommentFormUi
