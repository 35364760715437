export const service = {
    created_by: "",
    created_at: "",
    updated_by: "",
    updated_at: "",
}

export const group = {
    // number
    id: -1,
    service: service,
    // string
    name: "",
    // number
    parent_id: null,
    // Array<number>
    child_ids: null,
}