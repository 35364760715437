import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';
import { ErrorUnauthorized } from '../../errors'; 

const setList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
})

const setLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
})

const appendList = (data) => ({
    type: acts.APPEND_LIST,
    payload: data,
})

const updateProfileInList = (data) => ({
    type: acts.UPDATE,
    payload: data,
})

const updateProfileInSelected = (data) => ({
    type: acts.UPDATE_IN_SELECTED,
    payload: data
});

const deleteProfileFromSelected = (id) => ({
    type: acts.DELETE_FROM_SELECTED,
    payload: id
});


// async actions
export const loadList = async (dispatch) => {
    dispatch(setLoading(true));

    const data = await api.getList();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(setList(data));

    dispatch(setLoading(false));
}

export const add = async ({dispatch, profile}) => {
    dispatch(setLoading(true));

    const responseData = await api.create(profile);

    if (responseData instanceof Error) {
        if (responseData.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw responseData;
    }

    dispatch(appendList([responseData]));
    dispatch(setLoading(false));
}

export const update = async ({dispatch, profile}) => {

    let updateData = await api.update(profile);

    if (updateData instanceof Error) {
        if (updateData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw updateData;
    }
    dispatch(updateProfileInList(profile));
}

export const updateSelected = ({dispatch, id, data}) => {
    dispatch(updateProfileInSelected({id, data}));
}

export const deleteSelected = ({dispatch, id}) => {
    dispatch(deleteProfileFromSelected(id));
}