import React, { useContext, useEffect, useRef, useState } from 'react';
import ButtonUi from '../../components/ButtonUi';
import { IconUi } from '../../components/IconUi';
import PopupUi from '../../components/PopupUi';

import GroupsChart from './GroupsChart';
import UsersChart from './UsersChart';
import GroupsTable from './GroupsTable';
import UsersTable from './UsersTable';

import * as relActs from '../../redux/relations/actions';
import * as usersActs from '../../redux/users/actions';

import { store } from '../../store';
import { getLocalDateStringFromObject, isDateValid, setTimeToZeroInDate } from '../../utils';
import { handleError } from '../../errorHandler';

import './Statistics.scss';
import { isUserAdmin, isUserRecruiter } from '../../user';


const Statistics = () => {
    const { dispatch, state } = useContext(store);

    const {
        groups: {
            list: groupsList,
        },
        users: {
            allIds: usersAllIds,
            map: usersMap,
        },
        relations: {
            allIds: relAllIds,
            map: relMap,
            stagesMapByRelationId,
            stageTypesMap,
            stageTypesAllIds,
        },
        vacancies: {
            map: vacanciesMap,
        }
    } = state;

    const [groupsChartData, setGroupsChartData] = useState({});
    const [usersChartData, setUsersChartData] = useState({});

    const [chartsTimeframe, setChartsTimeframe] = useState({from: '', to: ''});

    const [groupIdsToFilterOut, setGroupIdsToFilterOut] = useState({});
    const [userIdsToFilterOut, setUserIdsToFilterOut] = useState({});

    const [stageTypesToFilterOutByChart, setStageTypesToFilterOutByChart] = useState({ groupsChart: {}, usersChart: {} });

    useEffect(() => {
        setUsersChartData(JSON.parse(localStorage.getItem('usersChartData')) || {});
        setGroupsChartData(JSON.parse(localStorage.getItem('groupsChartData')) || {});
        setUserIdsToFilterOut(JSON.parse(localStorage.getItem('userIdsToFilterOut')) || {});
        setGroupIdsToFilterOut(JSON.parse(localStorage.getItem('groupIdsToFilterOut')) || {});
        setStageTypesToFilterOutByChart(JSON.parse(localStorage.getItem('stageTypesToFilterOutByChart')) || { groupsChart: {}, usersChart: {} });
    }, []);

    const setPageDataInLocalStorageRef = useRef();

    useEffect(() => {
        setPageDataInLocalStorageRef.current = () => {
            localStorage.setItem('groupsChartData', JSON.stringify(groupsChartData));
            localStorage.setItem('usersChartData', JSON.stringify(usersChartData));
            localStorage.setItem('chartsTimeframe', JSON.stringify(chartsTimeframe));
            localStorage.setItem('groupIdsToFilterOut', JSON.stringify(groupIdsToFilterOut));
            localStorage.setItem('userIdsToFilterOut', JSON.stringify(userIdsToFilterOut));
            localStorage.setItem('stageTypesToFilterOutByChart', JSON.stringify(stageTypesToFilterOutByChart));
        }
    }, [groupsChartData, usersChartData, chartsTimeframe, groupIdsToFilterOut, userIdsToFilterOut, stageTypesToFilterOutByChart]);

    useEffect(() => {
        const savePageData = () => {
            setPageDataInLocalStorageRef.current && setPageDataInLocalStorageRef.current();
        }
        window.addEventListener('unload', savePageData);
        return () => {
            savePageData();
            window.removeEventListener('unload', savePageData);
        }
    }, []);

    useEffect(() => {
        let to;
        let from;

        const savedTimeframe = JSON.parse(localStorage.getItem('chartsTimeframe'));
        const savedToDate = new Date(savedTimeframe?.to);
        const savedFromDate = new Date(savedTimeframe?.from);
        const isSavedToDateValid = isDateValid(savedToDate);
        const isSavedFromDateValid = isDateValid(savedFromDate);
        if (isSavedToDateValid || isSavedFromDateValid) {
            to = isSavedToDateValid ? savedToDate : undefined;
            from = isSavedFromDateValid ? savedFromDate : undefined;
        } else {
            // setting last week as a timeframe
            to = new Date();
            from = new Date();

            from.setDate(to.getDate() - 7);
            setTimeToZeroInDate(from);
        }

        setChartsTimeframe({
            from: from ? getLocalDateStringFromObject(from) : '',
            to: to ? getLocalDateStringFromObject(to) : '',
        })

        // including full last day
        to?.setDate(to.getDate() + 1);

        relActs.loadByStagesPeriod({dispatch, from: from?.toISOString(), to: to?.toISOString()})
        usersActs.loadList(dispatch)
            .catch(err => handleError(err));
    }, [dispatch])

    useEffect(() => {
        const stageTypesCountByGroup = {};
        relAllIds.forEach(relId => {
            const currentGroupId = vacanciesMap[relMap[relId]?.vacancy_id]?.group?.id || -1;
            stagesMapByRelationId[relId]?.forEach(stage => {
                stageTypesCountByGroup[currentGroupId] = {
                    ...(stageTypesCountByGroup[currentGroupId] || {}),
                    [stage.type_id]: (
                        (stageTypesCountByGroup[currentGroupId] && 
                        stageTypesCountByGroup[currentGroupId][stage.type_id]) || 0
                    ) + 1
                }
            })
        });
        setGroupsChartData(stageTypesCountByGroup);
    }, [
        relMap,
        relAllIds,
        vacanciesMap,
        stagesMapByRelationId,
    ]);

    useEffect(() => {
        const stageTypesCountByUser = {};
        relAllIds.forEach(relId => {
            stagesMapByRelationId[relId]?.forEach(stage => {
                stageTypesCountByUser[stage.created_by] = {
                    ...(stageTypesCountByUser[stage.created_by] || {}),
                    [stage.type_id]: (
                        (stageTypesCountByUser[stage.created_by] && 
                        stageTypesCountByUser[stage.created_by][stage.type_id]) || 0
                    ) + 1
                }
            })
        });
        setUsersChartData(stageTypesCountByUser);
        
    }, [
        stagesMapByRelationId,
        relAllIds
    ]);

    const filterByDate = ({dateType, value}) => {

        let newTimeframe = {...chartsTimeframe};

        // checking if "from" value is before "to" value
        if (dateType === 'to') {
            if (
                chartsTimeframe.from &&
                new Date(chartsTimeframe.from) > new Date(value)
            ) {
                newTimeframe.from = '';
            }
        } else {
            if (
                chartsTimeframe.to &&
                new Date(chartsTimeframe.to) < new Date(value)
            ) {
                newTimeframe.to = '';
            }
        }

        setChartsTimeframe(ct => ({
            ...newTimeframe,
            [dateType]: value
        }));
    };

    const filterColumns = ({chartType, id}) => {
        if (chartType === 'groupsChart') {
            setGroupIdsToFilterOut(groupIds => ({
                ...groupIds,
                [id]: groupIds[id] ? false : true
            }))
        } else {
            setUserIdsToFilterOut(userIds => ({
                ...userIds,
                [id]: userIds[id] ? false : true
            }))
        }
    }
    
    const onClickDateFilter = () => {
        const to = chartsTimeframe.to && new Date(chartsTimeframe.to);
        to && to.setDate(to.getDate() + 1);

        relActs.loadByStagesPeriod({
            dispatch,
            from: chartsTimeframe.from && new Date(chartsTimeframe.from)?.toISOString(),
            to: to && new Date(to)?.toISOString(),
        }).catch(err => handleError(err));
    };

    const onDownloadTable = async () => {
        const to = chartsTimeframe.to && new Date(chartsTimeframe.to);
        to && to.setDate(to.getDate() + 1);

        await usersActs.downloadRecruitersReport({
            dispatch, 
            from: chartsTimeframe.from && new Date(chartsTimeframe.from)?.toISOString(),
            to: to && new Date(to)?.toISOString(),
            userIds: usersAllIds.filter(userId => {
                if (userIdsToFilterOut[userId] || 
                    (
                        !isUserAdmin(usersMap[userId])
                        &&
                        !isUserRecruiter(usersMap[userId])
                    )
                ) {
                    return false
                }
                return true
            })
        }).catch(err => handleError(err))
    }
    
    const assembleUsersChartData = () => {
        const assembledUsersData = []

        usersAllIds.forEach(userId => {
            if (userIdsToFilterOut[userId] ||
                (
                    !isUserAdmin(usersMap[userId])
                    &&
                    !isUserRecruiter(usersMap[userId])
                )
            ) {
                return
            }

            const stagesTypesMapByName = {};

            stageTypesAllIds.forEach(stId => {
                if (usersChartData[userId] && usersChartData[userId][stId])
                    stagesTypesMapByName[stageTypesMap[stId].name] = usersChartData[userId][stId];
            })

            assembledUsersData.push({
                name: usersMap[userId].surname  + ' ' + usersMap[userId].name,
                values: stagesTypesMapByName
            })
        });

        return assembledUsersData;
    };

    const assembleGroupsChartData = () => {
        const assembledGroupsData = []

        groupsList.forEach(group => {
            if (groupIdsToFilterOut[group.id]) return;

            const stagesTypesMapByName = {};

            stageTypesAllIds.forEach(stId => {
                if (groupsChartData[group.id] && groupsChartData[group.id][stId])
                    stagesTypesMapByName[stageTypesMap[stId].name] = groupsChartData[group.id][stId];
            })

            assembledGroupsData.push({
                name: group.name,
                values: stagesTypesMapByName
            })
        });

        return assembledGroupsData;
    };

    const stageTypesForChartFormat = (chartType) => 
        stageTypesAllIds
            .map(stId => ({
                    id: stId,
                    name: stageTypesMap[stId].name,
                    show: !stageTypesToFilterOutByChart[chartType][stId]
                })
            );

    const onFilterStageTypes = ({chartType, stageTypeId}) => {
        setStageTypesToFilterOutByChart(filteredStageTypes => ({
            ...filteredStageTypes,
            [chartType]: {
                ...filteredStageTypes[chartType],
                [stageTypeId]: filteredStageTypes[chartType][stageTypeId] ? false : true
            }
        }))
    }

    return (
        <div className="statistics">
            <div className="statistics-toolbar">
                <div className='statistics-toolbar-filters-date'>
                    <div className='statistics-toolbar-filters-date-from'>
                        From
                        <input
                            value={chartsTimeframe.from}
                            type='date'
                            onChange={(e) => {
                                filterByDate({
                                    dateType: 'from',
                                    value: e.target.value
                                });
                            }}
                        />
                    </div>
                    <div className='statistics-toolbar-filters-date-to'>
                        To
                        <input 
                            value={chartsTimeframe.to}
                            type='date'
                            onChange={(e) => 
                                filterByDate({
                                    dateType: 'to',
                                    value: e.target.value
                                }
                            )}
                        />
                    </div>
                </div>
                <ButtonUi onClick={onClickDateFilter}>
                    Show
                </ButtonUi>
                <PopupUi 
                    trigger={
                        <ButtonUi>
                            Groups
                        </ButtonUi>
                    }
                    on={'click'}
                    wide='very'
                >
                    <div className='statistics-toolbar-filters-columns'>
                        {groupsList.map(gr => (
                            <div 
                                className='statistics-toolbar-filters-columns-column'
                                onClick={() => filterColumns({chartType: 'groupsChart', id: gr.id})}
                                key={gr.id}
                            >
                                {
                                    gr.name
                                }
                                
                                {!groupIdsToFilterOut[gr.id] && <IconUi name='circle' size='tiny'/>}
                            </div>
                        ))}
                    </div>
                </PopupUi>
                    
                <PopupUi 
                    trigger={
                        <ButtonUi>
                            Users
                        </ButtonUi>
                    }
                    on={'click'}
                    wide='very'
                >
                    <div className='statistics-toolbar-filters-columns'>
                        {usersAllIds.map(userId => {
                            if (!usersMap[userId] ||
                                    (
                                        !isUserAdmin(usersMap[userId])
                                        &&
                                        !isUserRecruiter(usersMap[userId])
                                    )
                            ) {
                                return null
                            }

                            return (
                                <div
                                    className='statistics-toolbar-filters-columns-column'
                                    onClick={() => filterColumns({chartType: 'usersChart', id: userId})}
                                    key={userId}
                                >
                                    {
                                        usersMap[userId].surname + ' ' + usersMap[userId].name
                                    }
                                    {!userIdsToFilterOut[userId] && <IconUi name='circle' size='tiny'/>}
                                </div>
                            )
                        })}
                    </div>
                </PopupUi>
            </div>
            <div className="statistics-charts">
                <div className="statistics-charts-chart">
                    <GroupsChart
                        data={assembleGroupsChartData()}
                        stageTypes={stageTypesForChartFormat('groupsChart')}
                        onClickStageTypeLegend={(stageTypeId) => onFilterStageTypes({ chartType: 'groupsChart', stageTypeId })}
                    />
                    <GroupsTable
                        groupsChartData={groupsChartData}
                        stageTypesAllIds={stageTypesAllIds}
                        stageTypesMap={stageTypesMap}
                        groupsList={groupsList}
                        groupIdsToFilterOut={groupIdsToFilterOut}
                    />
                </div>
                <div className="statistics-charts-chart">
                    <UsersChart
                        data={assembleUsersChartData()}
                        stageTypes={stageTypesForChartFormat('usersChart')}
                        onClickStageTypeLegend={(stageTypeId) => onFilterStageTypes({ chartType: 'usersChart', stageTypeId })}
                    />
                    <UsersTable
                        usersChartData={usersChartData}
                        stageTypesAllIds={stageTypesAllIds}
                        stageTypesMap={stageTypesMap}
                        usersAllIds={
                            usersAllIds
                                .filter(userId => 
                                    isUserAdmin(usersMap[userId])
                                    ||
                                    isUserRecruiter(usersMap[userId])
                                )
                        }
                        usersMap={usersMap}
                        userIdsToFilterOut={userIdsToFilterOut}
                        onDownloadTable={onDownloadTable}
                    />
                </div>
            </div>
            <div>
            </div> 
        </div>
    )
}

export default Statistics
