import React, { useEffect, useRef, useContext } from 'react';
import { Segment, Table, Icon } from 'semantic-ui-react';
import c3 from 'c3';


import InputUi from '../../components/NewInputUi';

import { store } from '../../store';

import './Profiles.scss';
import 'c3/c3.css';

import * as profActs from '../../redux/profiles/actions';

import { avoidZeroDate, tryToNormalizeDate, deepEqual } from '../../utils';
import { handleError } from '../../errorHandler';
import { profile as zeroProfile } from '../../profile';

const ProfilesPage = () => {

    const { state, dispatch } = useContext(store);

    const {
        profiles: {
            list,
            selected
        }
    } = state;

    const chartRef = useRef();

    const drawChart = ({ chartData, chartType='bar' }) => {
        c3.generate({
            bindto: '#chart', 
            data: {
                x: 'x',
                columns: [
                    ['x', ...Object.keys(chartData)],
                    ['creation date', ...Object.values(chartData)],
                ],
                type: 'line'
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d'
                    }
                }
            }
        });
    }

    const groupListByCreationTime = (list) => {
        const creationDateMap = {}

        list.map((g) => {
            const date = tryToNormalizeDate(avoidZeroDate(g.service.created_at));
            date && (creationDateMap[date] = (creationDateMap[date] || 0) + 1);
            return null
        })
        return creationDateMap
    }

    useEffect(() => {
        drawChart({chartData: groupListByCreationTime(list)});
    }, [list])

    const addToSelected = (profile) => {
        profActs.updateSelected({dispatch, id: profile.id, data: profile})
    }

    const onChangeSelected = ({profileId, data}) => {
        profActs.updateSelected({dispatch, id: profileId, data})
    }

    const onDiscardProfile = (profileId) => {
        profActs.deleteSelected({dispatch, id: profileId});
    }

    const onClickAddProfileButton = () => {
        addToSelected(zeroProfile)
    }

    const onSubmitProfile = () => {
        profActs.add({dispatch, profile: selected[-1]})
            .then(() => {
                profActs.deleteSelected({dispatch, id: -1})
            })
            .catch(err => handleError(err));
    }
    const onUpdateProfile = (profileId) => {
        profActs.update({dispatch, profile: selected[profileId]})
            .then(profActs.deleteSelected({dispatch, id: profileId}))
            .catch(err => handleError(err));
    }

    return (
        <div className='profiles'>
            <div className='profiles-split'>
                <div className='profiles-split-left'>
                    <Table selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Profile
                                </Table.HeaderCell>
                                <Table.HeaderCell 
                                    content={<Icon name='add' onClick={onClickAddProfileButton}/>}
                                />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {selected[-1] && (() => {
                                const g = zeroProfile;
                                return <Table.Row>
                                    <Table.Cell 
                                        content={
                                            <InputUi 
                                                name='name'
                                                value={selected[g.id] ? selected[g.id].name : g.name} 
                                                edit={true}
                                                onChange={(e) => 
                                                    onChangeSelected({
                                                        profileId: g.id, 
                                                        data: {name: e.target.value}
                                                    })}
                                            />
                                        }
                                    />
                                    <Table.Cell width={1} 
                                        content={
                                            selected[g.id] ?
                                                <>
                                                    <Icon 
                                                        name='save'
                                                        onClick={() => onSubmitProfile()}
                                                    />
                                                    <Icon 
                                                        name='close'
                                                        onClick={() => onDiscardProfile(g.id)}
                                                    />
                                                </>
                                                :
                                                <Icon 
                                                    name='edit'
                                                    onClick={()=>addToSelected(g)}
                                                />
                                        }
                                        />
                                    </Table.Row>
                                })()}
                            {list.map(g =>
                                <Table.Row key={g.id}>
                                    <Table.Cell 
                                        content={
                                            <InputUi 
                                                name='name'
                                                value={selected[g.id] ? selected[g.id].name : g.name} 
                                                edit={!!selected[g.id]}
                                                onChange={(e) => 
                                                    onChangeSelected({
                                                        profileId: g.id, 
                                                        data: {name: e.target.value}
                                                    })
                                                }
                                            />
                                        }
                                    />
                                    <Table.Cell width={1} 
                                        content={
                                            selected[g.id] ?
                                                <>
                                                    <Icon 
                                                        name='save'
                                                        onClick={() => onUpdateProfile(g.id)}
                                                        disabled={deepEqual(selected[g.id], g)}
                                                    />
                                                    <Icon 
                                                        name='close'
                                                        onClick={() => onDiscardProfile(g.id)}
                                                    />
                                                </>
                                                :
                                                <Icon 
                                                    name='edit'
                                                    onClick={()=>addToSelected(g)}
                                                />
                                        }
                                    />
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>
                <div className='profiles-split-right'>
                    <Segment style={{height: '90vh'}}>
                        <div ref={chartRef} id='chart'/>
                    </Segment>
                </div>
            </div>
        </div>
    )
}

export default ProfilesPage;