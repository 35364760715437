import axios from 'axios';
import { ErrorUnauthorized } from '../../errors';

const rootRef = "/matches/api/v1";
const usersRef = "/users";
const socialsRef = "/socials";

export const getUserById = async (id) => {
    try {
        const response = await axios(
            `${rootRef}${usersRef}/${id}`,
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getList = async () => {
    try {
        const response = await axios(
            `${rootRef}${usersRef}/`,
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getRolesList = async () => {
    try {
        const response = await axios(
            `${rootRef}/roles`,
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getRecruitersReport = async ({from, to, userIds}) => {
    try {
        const response = await axios(
            `${rootRef}/reports/users/recruiters/stages?from=${from}&to=${to}&user_ids=${userIds}`,
        )

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const createUser = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${usersRef}/`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const updateUser = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${usersRef}/`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getSocialsList = async () => {
    try {
        const response = await axios(
            `${rootRef}${socialsRef}/`,
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const addSocial = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${socialsRef}/`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const updateSocial = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${socialsRef}/`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const deleteSocial = async (id) => {
    try {
        const response = await axios(
            `${rootRef}${socialsRef}/${id}`,
            {
                method: "DELETE",
                headers: {"Content-Type": "application/json"},
                data: id,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}