import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    isCommenting: false,
    limit: 10,
    offset: 0,
    list: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_IS_COMMENTING:
            return {
                ...state,
                isCommenting: action.payload,
            }

        case acts.SET_LIST:
            return {
                ...state,
                list: action.payload,
            }

        case acts.ADD:
            if (state && state.list) {
                return {
                    ...state,
                    list: [
                        ...state.list,
                        action.payload,
                    ],    
                }
            }

            return {
                ...state,
                list: [
                    action.payload,
                ],
            }
    
        case acts.DELETE:
            return {
                ...state,
                list: [
                    ...state.list.filter((comment)=>comment.service.id!==action.payload)
                ]
            }
        default:
            return state;
    }
}

export default reducer;