import * as acts from './actionTypes';
import * as apiPersons from './api';
import { signout } from '../auth/actions';
import { ErrorUnauthorized, ErrorForbidden } from '../../errors'; 

import { arrToMapAndIdsByServiceId, debounce, excludeEmptyFields, filterObjectsMap, normalizeFilterOption, sortObjectsMapByPropPath, validate } from '../../utils';
import { 
    getSearchOptions, 
    getLimit, 
    getAddedFilterOptions, 
    getAllFilterOptions, 
    getFilteredAndSortedIds, 
    getSortingOption, 
    getEditedId, 
    getFilterOptionsInput,
    getFulltextSearchValue,
    getSearchByVacancyId,
    getAllIds,
    getMap,
    getSearchByBookingId,
    getSearchByStageTypeId
} from './reducer';
import { zeroPerson } from '../../person';
import { getRelationsByPersonId, getStageTypesMap } from '../relations/reducer';
import { getVacanciesMap } from '../vacancies/reducer';
import { getStagesMapByRelationId } from '../relations/reducer';
import { loadListByPersonIds } from '../relations/actions';
import { getUserId } from '../user/reducer';

export const setPersonsLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
});

export const setCanLoadMore = (load) => ({
    type: acts.SET_CAN_LOAD_MORE,
    payload: load,
});

const setPersonsTotalCountBySearch = (data) => ({
    type: acts.SET_TOTAL_COUNT_BY_SEARCH,
    payload: data,
});

const incrementPersonsTotalCountBySearch = (data) => ({
    type: acts.INCREMENT_TOTAL_COUNT_BY_SEARCH,
    payload: data,
});

const setPersonsList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
});

const appendPersonsList = (data) => ({
    type: acts.APPEND_LIST,
    payload: data,
});

const addPersonToList = (data) => ({
    type: acts.ADD,
    payload: data,
})

const addPersonToQuickAccessList = (data) => ({
    type: acts.ADD_TO_QUICK_ACCESS_LIST,
    payload: data,
})

const updatePersonInList = (data) => ({
    type: acts.UPDATE,
    payload: data,
})

const setPersonBooked = ({person_id, is_booked, booked_by}) => ({
    type: acts.SET_BOOKED,
    payload: {
        person_id,
        is_booked,
        booked_by,
    },
})

const setPersonsChosenId = (id) => ({
    type: acts.SET_CHOSEN_ID,
    payload: id,
})

const setPersonsEditedId = (id) => ({
    type: acts.SET_EDITED_ID,
    payload: id,
})

const setFilteredAndSortedIds = (data) => ({
    type: acts.SET_FILTERED_AND_SORTED_IDS,
    payload: data,
});

const setFulltextSearchOptions = (data) => ({
    type: acts.SET_FULLTEXT_SEARCH_VALUE,
    payload: data,
});

const setPersonsSearchByVacancyId = (data) => ({
    type: acts.SET_SEARCH_BY_VACANCY_ID,
    payload: data,
});

const setPersonsSearchByStageTypeId = (data) => ({
    type: acts.SET_SEARCH_BY_STAGE_TYPE_ID,
    payload: data,
});

const setSearchOptions = (data) => ({
    type: acts.SET_SEARCH_OPTIONS,
    payload: data,
});

const setSearchByBooking = (data) => ({
    type: acts.SET_SEARCH_BY_BOOKING_ID,
    payload: data,
});

const setFilterInputValue = (data) => ({
    type: acts.SET_FILTER_OPTIONS_INPUT_VALUE,
    payload: data,
});

const appendFilterOption = (data) => ({
    type: acts.ADD_FILTER_OPTION,
    payload: data,
});

const sliceOutFilterOption = (data) => ({
    type: acts.DELETE_FILTER_OPTION,
    payload: data,
});

const clearFilterOptions = () => ({
    type: acts.CLEAR_FILTER_OPTIONS,
});

const setSortingOption = (data) => ({
    type: acts.SET_SORTING_OPTION,
    payload: data,
});

const setSelectedPersonErrors = (data) => ({
    type: acts.SET_SELECTED_PERSON_ERRORS,
    payload: data,
});

const setSelectedFieldErrors = (data) => ({
    type: acts.SET_SELECTED_FIELD_ERRORS,
    payload: data,
});

const updatePersonInSelected = (data) => ({
    type: acts.UPDATE_SELECTED,
    payload: data
});

const deletePersonFromSelected = (id) => ({
    type: acts.DELETE_SELECTED,
    payload: id
});

const addTagIdToPerson = ({person_id, tag_id}) => ({
    type: acts.ADD_TAG_ID,
    payload: {
        person_id,
        tag_id,
    },
});

const deleteTagIdFromPerson = ({person_id, tag_id}) => ({
    type: acts.DELETE_TAG_ID,
    payload: {
        person_id,
        tag_id,
    },
});

const setPersonsDuplicateIds = (data) => ({
    type: acts.SET_DUPLICATE_IDS,
    payload: data
});

export const setChosenId = (dispatch, id) => dispatch(setPersonsChosenId(id));

export const updateSelected = ({dispatch, id, data}) => {
    dispatch(updatePersonInSelected({id, data}));
}

export const deleteSelected = (dispatch, id) => {
    dispatch(deletePersonFromSelected(id));
    dispatch(setSelectedPersonErrors({id, personErrors: null}));
}

export const checkForErrors = ({dispatch, value, validateOptions, id, fieldName}) => {
    const error = validate({value, ...validateOptions});
    dispatch(setSelectedFieldErrors({id, error, fieldName}))
};

export const select = ({dispatch, person}) => dispatch((dispatch, getState) => {
    const previousEditedId = getEditedId(getState());
    previousEditedId && deleteSelected(dispatch, previousEditedId);

    updateSelected({ dispatch, id: person.service.id, data: {...zeroPerson, ...person}});
    dispatch(setPersonsEditedId(person.service.id));
});

export const discard = (dispatch) => dispatch((dispatch, getState) => {
    const previousEditedId = getEditedId(getState());
    previousEditedId && deleteSelected(dispatch, previousEditedId);
    dispatch(setPersonsEditedId(null));
})

export const setDuplicateIds = ({dispatch, ids}) => {
    dispatch(setPersonsDuplicateIds(ids));
}

export const loadList = async ({dispatch, offset, limit, search, bookedBy, appendList=true, withRelations=true}) => {
    dispatch(setPersonsLoading(true));

    const params = {offset, limit, search, bookedBy};
    const data = await apiPersons.getPersons(params);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    if (data === null) {
        dispatch(setCanLoadMore(false));
        if (!appendList) {
            dispatch(setPersonsList({allIds: [], map: {}}));
            updateFilteredAndSortedIds(dispatch);
        }
    } else {
        const {map, allIds} = arrToMapAndIdsByServiceId(data);

        dispatch(setCanLoadMore(true));
        if (appendList) {
            dispatch(appendPersonsList({map, allIds}));
            filterSortAndAppendIds({dispatch, map, allIds});
        } else {
            dispatch(setPersonsList({map, allIds}));
            updateFilteredAndSortedIds(dispatch);
        }
        withRelations && loadListByPersonIds({ dispatch, ids: data.map(p => p.service.id), append: appendList});
    }

    dispatch(setPersonsLoading(false));
}

export const loadListWork = async ({dispatch, offset, limit, search, bookedBy, appendList=true, withRelations=true}) => {
    dispatch(setPersonsLoading(true));

    const params = {offset, limit, search, bookedBy};
    const data = await apiPersons.getPersonsWork(params);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    if (data && (data.total - (data.offset + data.limit) <= 0)) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }
    dispatch(setPersonsTotalCountBySearch(data?.total || 0));

    if (!data?.list) {
        if (!appendList) {
            dispatch(setPersonsList({allIds: [], map: {}}));
            updateFilteredAndSortedIds(dispatch);
        }
    } else {
        const {map, allIds} = arrToMapAndIdsByServiceId(data.list);

        if (appendList) {
            dispatch(appendPersonsList({map, allIds}));
            filterSortAndAppendIds({dispatch, map, allIds});
        } else {
            dispatch(setPersonsList({map, allIds}));
            updateFilteredAndSortedIds(dispatch);
        }
        withRelations && loadListByPersonIds({ dispatch, ids: data.list.map(p => p.service.id), append: appendList});
    }

    dispatch(setPersonsLoading(false));
}

export const debouncedLoadList = debounce(loadList, 300);

export const debouncedLoadListWork = debounce(loadListWork, 300);

export const loadListByUser = async ({dispatch, offset, limit, appendList=true, withRelations=true}) => dispatch(async (dispatch, getState) => {
    dispatch(setPersonsLoading(true));

    const userId = getUserId(getState());

    const params = {offset, limit, userId};
    const data = await apiPersons.getPersonsByUser(params);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    if (data === null) {
        dispatch(setCanLoadMore(false));
        if (!appendList) {
            dispatch(setPersonsList({allIds: [], map: {}}));
            updateFilteredAndSortedIds(dispatch);
        }
    } else {
        dispatch(setCanLoadMore(true));
        
        const {map, allIds} = arrToMapAndIdsByServiceId(data);

        if (appendList) {
            dispatch(appendPersonsList({map, allIds}));
            filterSortAndAppendIds({dispatch, map, allIds});
        } else {
            dispatch(setPersonsList({map, allIds}));
            updateFilteredAndSortedIds(dispatch);
        }
        withRelations && loadListByPersonIds({ dispatch, ids: allIds, append: appendList })
    }

    dispatch(setPersonsLoading(false));
})

export const searchByTextAndBookingId = ({dispatch, value, bookedBy}) => dispatch((dispatch, getState) => {
    const limit = getLimit(getState());

    if (bookedBy === undefined) {
        bookedBy = getSearchByBookingId(getState());
    } else {
        dispatch(setSearchByBooking(bookedBy));
    }

    if (value === undefined) {
        value = getFulltextSearchValue(getState());
    } else {
        dispatch(setFulltextSearchOptions(value));
    }

    debouncedLoadList({dispatch, offset: 0, limit, search: value, bookedBy, appendList: false});

    dispatch(setSearchOptions(null));
    setSearchByVacancyId({dispatch, id: null});
    dispatch(setPersonsSearchByStageTypeId(null));
    deleteSelected(dispatch, 'search');
})

export const searchByTextAndBookingIdWork = ({dispatch, offset, value, bookedBy, appendList=false}) => dispatch((dispatch, getState) => {
    const limit = getLimit(getState());

    if (bookedBy === undefined) {
        bookedBy = getSearchByBookingId(getState());
    } else {
        dispatch(setSearchByBooking(bookedBy));
    }

    if (value === undefined) {
        value = getFulltextSearchValue(getState());
    } else {
        dispatch(setFulltextSearchOptions(value));
    }

    debouncedLoadListWork({dispatch, offset: offset || 0, limit, search: value, bookedBy, appendList,});

    dispatch(setSearchOptions(null));
    setSearchByVacancyId({dispatch, id: null});
    dispatch(setPersonsSearchByStageTypeId(null));
    deleteSelected(dispatch, 'search');
})

export const search = async ({dispatch, offset, limit, searchOptions, appendList=true, withRelations=true}) => {
    dispatch(setPersonsLoading(true));

    const data = await apiPersons.getPersonsByFilter({offset, limit, searchOptions});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    if (data === null) {
        dispatch(setCanLoadMore(false));

        if (!appendList) {
            dispatch(setPersonsList({allIds: [], map: {}}));
            updateFilteredAndSortedIds(dispatch);
        }

    } else {
        dispatch(setCanLoadMore(true));
        
        const {map, allIds} = arrToMapAndIdsByServiceId(data);

        if (appendList) {
            dispatch(appendPersonsList({map, allIds}))
            filterSortAndAppendIds({dispatch, map, allIds});
        } else {
            dispatch(setPersonsList({map, allIds}));
            updateFilteredAndSortedIds(dispatch);
        }
        
        withRelations && loadListByPersonIds({ dispatch, ids: data.map(p => p.service.id), append: appendList});
    }
    dispatch(setPersonsLoading(false));
}

export const searchWork = async ({dispatch, offset, limit, searchOptions, bookedBy, appendList=true, withRelations=true}) => {
    dispatch(setPersonsLoading(true));

    const data = await apiPersons.getPersonsByFilterWork({offset, limit, searchOptions, bookedBy});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }
    

    if (data && (data.total - (data.offset + data.limit) <= 0)) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }
    dispatch(setPersonsTotalCountBySearch(data?.total || 0));

    if (!data?.list) {
        if (!appendList) {
            dispatch(setPersonsList({allIds: [], map: {}}));
            updateFilteredAndSortedIds(dispatch);
        }

    } else {
        const {map, allIds} = arrToMapAndIdsByServiceId(data.list);

        if (appendList) {
            dispatch(appendPersonsList({map, allIds}))
            filterSortAndAppendIds({dispatch, map, allIds});
        } else {
            dispatch(setPersonsList({map, allIds}));
            updateFilteredAndSortedIds(dispatch);
        }
        
        withRelations && loadListByPersonIds({ dispatch, ids: data.list.map(p => p.service.id), append: appendList});
    }
    dispatch(setPersonsLoading(false));
}

export const startSearch = async ({dispatch, person, limit}) => {
    dispatch(setPersonsLoading(true));

    const mapPersonToSearch = (person, prefix='') => 
        Object.entries(person)
            .reduce((filters, entry) => {
                if (entry[1]) {
                    if (typeof entry[1] !== 'object') {
                        const query = typeof entry[1] === 'string' ? entry[1] : JSON.stringify(entry[1]);
                        filters.push({field: prefix + entry[0], query});
                    } else {
                        filters = [...filters, ...mapPersonToSearch(entry[1], entry[0]+'_')];
                    }
                }
                return filters
            }, []);

    const searchOptions = mapPersonToSearch({...person, service: undefined});

    dispatch(setSearchOptions(searchOptions));

    dispatch(setPersonsLoading(false));

    await search({dispatch, offset: 0, limit, searchOptions, appendList: false});
    setSearchByVacancyId({dispatch, id: null});
    dispatch(setFulltextSearchOptions(''));
    dispatch(setSearchByBooking(null));
    dispatch(setPersonsSearchByStageTypeId(null));
}

export const startSearchWork = async ({dispatch, person, limit, bookedBy}) => {
    dispatch(setPersonsLoading(true));

    const mapPersonToSearch = (person, prefix='') => 
        Object.entries(person)
            .reduce((filters, entry) => {
                if (entry[1]) {
                    if (typeof entry[1] !== 'object') {
                        const query = typeof entry[1] === 'string' ? entry[1] : JSON.stringify(entry[1]);
                        filters.push({field: prefix + entry[0], query});
                    } else {
                        filters = [...filters, ...mapPersonToSearch(entry[1], entry[0]+'_')];
                    }
                }
                return filters
            }, []);

    const searchOptions = mapPersonToSearch({...person, service: undefined});

    dispatch(setSearchOptions(searchOptions));

    dispatch(setPersonsLoading(false));

    await searchWork({dispatch, offset: 0, limit, searchOptions, bookedBy, appendList: false});
    setSearchByVacancyId({dispatch, id: null});
    dispatch(setFulltextSearchOptions(''));
    dispatch(setSearchByBooking(null));
    dispatch(setPersonsSearchByStageTypeId(null));
}

export const deleteSearchOptions = (dispatch) => dispatch((dispatch, getState) => {
    const searchOptions = getSearchOptions(getState());
    if (searchOptions) {
        dispatch(setSearchOptions(null));
        const limit = getLimit(getState());
        const fulltextSearch = getFulltextSearchValue(getState());
        const bookedBy = getSearchByBookingId(getState());
        loadList({ dispatch, offset: 0, limit, search: fulltextSearch, bookedBy, appendList: false});
    }
    deleteSelected(dispatch, 'search');
})

export const deleteSearchOptionsWork = (dispatch) => {
    dispatch(setSearchOptions(null));
    deleteSelected(dispatch, 'search');
}

export const endSearch = async (dispatch) => {
    dispatch(setPersonsLoading(true));
    deleteSearchOptions(dispatch);
    dispatch(setPersonsLoading(false));
}

export const setSearchByVacancyId = ({dispatch, id}) => {
    dispatch(setPersonsSearchByVacancyId(id));
}

export const setSearchByStageTypeId = ({dispatch, id}) => {
    dispatch(setPersonsSearchByStageTypeId(id));
}
export const clearSearch = (dispatch) => {
    dispatch(setPersonsSearchByVacancyId(null));
    dispatch(setFulltextSearchOptions(''));
    dispatch(setSearchByBooking(null));
    dispatch(setSearchOptions(null));
    deleteSelected(dispatch, 'search');
}

export const startSearchByVacancy = async ({dispatch, limit, vacancyId, withRelations=true}) => {
    dispatch(setPersonsLoading(true));

    const data = await apiPersons.getPersonsByVacancy({offset: 0, limit, vacancyId});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    setSearchByVacancyId({dispatch, id: vacancyId});
    dispatch(setFulltextSearchOptions(''));
    dispatch(setSearchByBooking(null));
    dispatch(setPersonsSearchByStageTypeId(null));
    dispatch(setSearchOptions(null));
    deleteSelected(dispatch, 'search');

    if (data === null) {
        dispatch(setCanLoadMore(false));
        dispatch(setPersonsList({allIds: [], map: {}}));
        updateFilteredAndSortedIds(dispatch);
    } else {
        dispatch(setCanLoadMore(true));

        const {map, allIds} = arrToMapAndIdsByServiceId(data);
        
        dispatch(setPersonsList({map, allIds}));
        updateFilteredAndSortedIds(dispatch);
        withRelations && loadListByPersonIds({ dispatch, ids: data.map(p => p.service.id), append: false});
    }
    
    dispatch(setPersonsLoading(false));
}

export const startSearchByVacancyWork = async ({dispatch, limit, vacancyId, bookedBy, withRelations=true}) => {
    dispatch(setPersonsLoading(true));

    const data = await apiPersons.getPersonsByVacancyWork({offset: 0, limit, vacancyId, bookedBy});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    setSearchByVacancyId({dispatch, id: vacancyId});
    dispatch(setFulltextSearchOptions(''));
    dispatch(setSearchByBooking(null));
    dispatch(setPersonsSearchByStageTypeId(null));
    dispatch(setSearchOptions(null));
    deleteSelected(dispatch, 'search');
    
    if (data && (data.total - (data.offset + data.limit) <= 0)) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }

    dispatch(setPersonsTotalCountBySearch(data?.total || 0));


    if (!data?.list) {
        dispatch(setPersonsList({allIds: [], map: {}}));
        updateFilteredAndSortedIds(dispatch);
    } else {
        const {map, allIds} = arrToMapAndIdsByServiceId(data.list);
        
        dispatch(setPersonsList({map, allIds}));
        updateFilteredAndSortedIds(dispatch);
        withRelations && loadListByPersonIds({ dispatch, ids: data.list.map(p => p.service.id), append: false});
    }
    
    dispatch(setPersonsLoading(false));
}

export const searchByVacancy = ({dispatch, offset, limit, withRelations=true}) => dispatch(async (dispatch, getState) => {
    dispatch(setPersonsLoading(true));

    const vacancyId = getSearchByVacancyId(getState());

    const data = await apiPersons.getPersonsByVacancy({
        offset,
        limit,
        vacancyId
    });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    if (data === null) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
        
        const {map, allIds} = arrToMapAndIdsByServiceId(data);

        dispatch(appendPersonsList({map, allIds}));
        filterSortAndAppendIds({dispatch, map, allIds});
        withRelations && loadListByPersonIds({ dispatch, ids: data.map(p => p.service.id)});
    }
    dispatch(setPersonsLoading(false));
})

export const searchByVacancyWork = ({dispatch, offset, limit, bookedBy, withRelations=true}) => dispatch(async (dispatch, getState) => {
    dispatch(setPersonsLoading(true));

    const vacancyId = getSearchByVacancyId(getState());

    const data = await apiPersons.getPersonsByVacancyWork({
        offset,
        limit,
        vacancyId,
        bookedBy
    });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    if (data && (data.total - (data.offset + data.limit) <= 0)) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }

    dispatch(setPersonsTotalCountBySearch(data?.total || 0));

    if (!data?.list) {
        const map = {};
        const allIds = [];
        dispatch(appendPersonsList({map, allIds}));
        filterSortAndAppendIds({dispatch, map, allIds});
    } else {
        const {map, allIds} = arrToMapAndIdsByServiceId(data.list);
        dispatch(appendPersonsList({map, allIds}));
        filterSortAndAppendIds({dispatch, map, allIds});
        withRelations && loadListByPersonIds({ dispatch, ids: data.list.map(p => p.service.id)});
    }
    dispatch(setPersonsLoading(false));
})

export const startSearchByStageType = async ({dispatch, limit, stageTypeId, bookedBy, withRelations=true}) => {
    dispatch(setPersonsLoading(true));

    const data = await apiPersons.getPersonsByStageTypeIdWork({offset: 0, limit, stageTypeId, bookedBy});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    dispatch(setPersonsSearchByStageTypeId(stageTypeId));
    setSearchByVacancyId({dispatch, id: null});
    dispatch(setFulltextSearchOptions(''));
    dispatch(setSearchByBooking(null));
    dispatch(setSearchOptions(null));
    deleteSelected(dispatch, 'search');
    
    if (data && (data.total - (data.offset + data.limit) <= 0)) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }

    dispatch(setPersonsTotalCountBySearch(data?.total || 0));


    if (!data?.list) {
        dispatch(setPersonsList({allIds: [], map: {}}));
        updateFilteredAndSortedIds(dispatch);
    } else {
        const {map, allIds} = arrToMapAndIdsByServiceId(data.list);
        
        dispatch(setPersonsList({map, allIds}));
        updateFilteredAndSortedIds(dispatch);
        withRelations && loadListByPersonIds({ dispatch, ids: data.list.map(p => p.service.id), append: false});
    }
    
    dispatch(setPersonsLoading(false));
}

export const searchByStageType = ({dispatch, offset, limit, bookedBy, withRelations=true}) => dispatch(async (dispatch, getState) => {
    dispatch(setPersonsLoading(true));

    const stageTypeId = getSearchByStageTypeId(getState());

    const data = await apiPersons.getPersonsByStageTypeIdWork({
        offset,
        limit,
        stageTypeId,
        bookedBy
    });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    if (data && (data.total - (data.offset + data.limit) <= 0)) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }

    dispatch(setPersonsTotalCountBySearch(data?.total || 0));

    if (!data?.list) {
        const map = {};
        const allIds = [];
        dispatch(appendPersonsList({map, allIds}));
        filterSortAndAppendIds({dispatch, map, allIds});
    } else {
        const {map, allIds} = arrToMapAndIdsByServiceId(data.list);
        dispatch(appendPersonsList({map, allIds}));
        filterSortAndAppendIds({dispatch, map, allIds});
        withRelations && loadListByPersonIds({ dispatch, ids: data.list.map(p => p.service.id)});
    }
    dispatch(setPersonsLoading(false));
})

export const loadThunk = ({dispatch, offset, limit, appendList=true}) => 
    dispatch((dispatch, getState) => {
        const searchOptions = getSearchOptions(getState());
        const fulltextSearchValue = getFulltextSearchValue(getState());
        const bookedBy = getSearchByBookingId(getState());

        searchOptions ?
            search({dispatch, offset, limit, searchOptions, appendList})
            :
            loadList({dispatch, offset, limit, search: fulltextSearchValue, bookedBy, appendList});
    });

export const load = async (dispatch, id, withRelations=true) => {
    dispatch(setPersonsLoading(true));
    const data = await apiPersons.getPerson(id);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setPersonsLoading(false));
            return
        }
        dispatch(setPersonsLoading(false));
        throw data;
    }

    if (data === null) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(appendPersonsList({map: {[data.service.id]: data}, allIds: [data.service.id]}));
        filterSortAndAppendIds({dispatch, map: {[data.service.id]: data}, allIds: [data.service.id]});
        withRelations && loadListByPersonIds({ dispatch, ids: [data.service.id]});
    }
    dispatch(setPersonsLoading(false));
}

export const checkForDuplicatesAndCreate = async (dispatch, data, {list=true, quickAccess=true}={list: true, quickAccess: true}) => {
    let preparedData = excludeEmptyFields(data);

    const duplicates = await apiPersons.getDuplicates(preparedData);
    
    if (duplicates instanceof Error) {
        if (duplicates.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw duplicates;
    }

    if (duplicates !== null) {
        const {map, allIds} = arrToMapAndIdsByServiceId(duplicates);

        dispatch(setPersonsDuplicateIds(allIds || null));
        dispatch(appendPersonsList({map, allIds}));

        return;
    }

    const retrievedPersonData = await apiPersons.addPerson(preparedData);

    if (retrievedPersonData instanceof Error) {
        if (retrievedPersonData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw retrievedPersonData;
    }

    list && dispatch(addPersonToList(retrievedPersonData));
    quickAccess && dispatch(addPersonToQuickAccessList(retrievedPersonData));
    updateFilteredAndSortedIds(dispatch);
    dispatch(incrementPersonsTotalCountBySearch());
    deletePersonFromSelected('new');
}

export const create = async (dispatch, data, {list=true, quickAccess=true}={list: true, quickAccess: true}) => {
    let preparedData = excludeEmptyFields(data);

    const retrievedPersonData = await apiPersons.addPerson(preparedData);

    if (retrievedPersonData instanceof Error) {
        if (retrievedPersonData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw retrievedPersonData;
    }

    list && dispatch(addPersonToList(retrievedPersonData));
    quickAccess && dispatch(addPersonToQuickAccessList(retrievedPersonData));
    updateFilteredAndSortedIds(dispatch);
    dispatch(incrementPersonsTotalCountBySearch());
    deletePersonFromSelected('new');
}

export const checkForDuplicatesCreateAndBindFile = async ({dispatch, data, fileId}) => {
    let preparedData = excludeEmptyFields(data);

    const duplicates = await apiPersons.getDuplicates(preparedData);
    
    if (duplicates instanceof Error) {
        if (duplicates.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw duplicates;
    }

    if (duplicates !== null) {
        const {map, allIds} = arrToMapAndIdsByServiceId(duplicates);

        dispatch(setPersonsDuplicateIds(allIds || null));
        dispatch(appendPersonsList({map, allIds}));
        return;
    }

    dispatch(setPersonsDuplicateIds([]));

    const retrievedPersonData = await apiPersons.addPerson(preparedData);

    if (retrievedPersonData instanceof Error) {
        if (retrievedPersonData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw retrievedPersonData;
    }

    dispatch(addPersonToList(retrievedPersonData));
    updateFilteredAndSortedIds(dispatch);

    if (fileId) {
        const fileBindResponse = await apiPersons.bindFile({fileId, personId: retrievedPersonData.service.id});

        if (fileBindResponse instanceof Error) {
            if (fileBindResponse.message === ErrorUnauthorized) {
                dispatch(signout());
                return
            }
            throw fileBindResponse;
        }
    }
}

export const createAndBindFile = async ({dispatch, data, fileId}) => {
    let preparedData = excludeEmptyFields(data);

    const retrievedPersonData = await apiPersons.addPerson(preparedData);

    if (retrievedPersonData instanceof Error) {
        if (retrievedPersonData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw retrievedPersonData;
    }

    dispatch(addPersonToList(retrievedPersonData));
    updateFilteredAndSortedIds(dispatch);

    if (fileId) {
        const fileBindResponse = await apiPersons.bindFile({fileId, personId: retrievedPersonData.service.id});

        if (fileBindResponse instanceof Error) {
            if (fileBindResponse.message === ErrorUnauthorized) {
                dispatch(signout());
                return
            }
            throw fileBindResponse;
        }
    }
}

export const update = async (dispatch, data) => {
    let preparedData = excludeEmptyFields(data);

    let updateData = await apiPersons.updatePerson(preparedData);

    if (updateData instanceof Error) {
        if (updateData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw updateData;
    }
    discard(dispatch);
    dispatch(updatePersonInList(data));
    updateFilteredAndSortedIds(dispatch);
}

export const book = async ({dispatch, personId, systemUserId}) => {
    dispatch(setPersonsLoading(true));

    let response = await apiPersons.bookPerson({personId});

    dispatch(setPersonsLoading(false));

    if (response instanceof Error) {
        switch (response.message) {
            case ErrorUnauthorized:
                dispatch(signout());
                return;

            case ErrorForbidden:
                throw ErrorForbidden;
        
            default:
                throw response;
        }
    }

    dispatch(setPersonBooked({person_id: personId, is_booked: true, booked_by: systemUserId}));
    updateFilteredAndSortedIds(dispatch);
}

export const unbook = async ({dispatch, personId}) => {
    let response = await apiPersons.unbookPerson({personId});

    if (response instanceof Error) {
        switch (response.message) {
            case ErrorUnauthorized:
                dispatch(signout());
                return;

            case ErrorForbidden:
                throw ErrorForbidden;
        
            default:
                throw response;
        }
    }

    dispatch(setPersonBooked({person_id: personId, is_booked: false, booked_by: null}));
    updateFilteredAndSortedIds(dispatch);
}


export const addTagId = async ({dispatch, personId, tagId}) => {
    dispatch(setPersonsLoading(true));
    let response = await apiPersons.addTagIdToPerson({personId, tagId});

    if (response instanceof Error) {
        switch (response.message) {
            case ErrorUnauthorized:
                dispatch(signout());
                return;

            case ErrorForbidden:
                throw ErrorForbidden;
        
            default:
                throw response;
        }
    }

    dispatch(addTagIdToPerson({person_id: personId, tag_id: tagId}));
    dispatch(setPersonsLoading(false));
}

export const deleteTagId = async ({dispatch, personId, tagId}) => {
    dispatch(setPersonsLoading(true));
    let response = await apiPersons.deleteTagIdFromPerson({personId, tagId});

    if (response instanceof Error) {
        switch (response.message) {
            case ErrorUnauthorized:
                dispatch(signout());
                return;

            case ErrorForbidden:
                throw ErrorForbidden;
        
            default:
                throw response;
        }
    }

    dispatch(deleteTagIdFromPerson({person_id: personId, tag_id: tagId}));
    dispatch(setPersonsLoading(false));
}

const sortAndSetIds = ({dispatch, map, allIds, sortingOption}) => {
    const sortedIds = sortObjectsMapByPropPath({
        map,
        allIds, 
        sortingOption
    });
    dispatch(setFilteredAndSortedIds(sortedIds));
}

const filterSortAndSetIds = ({ dispatch, allIds=[], map, filterOptions, sortingOption }) => dispatch((dispatch, getState) => {
    const relationsMap = getRelationsByPersonId(getState());
    const vacanciesMap = getVacanciesMap(getState());
    const stagesMap = getStagesMapByRelationId(getState());
    const stageTypesMap = getStageTypesMap(getState());


    const mapWithRelations = {};

    for (let personId of allIds) {
        mapWithRelations[personId] = {
            ...map[personId],
            relations: relationsMap[personId]
                ?.map(r => {
                    const vacancy = vacanciesMap[r.vacancy_id];
                    const relationStagesList = stagesMap[r.id];
                    const currentStage = stageTypesMap && relationStagesList?.length && 
                        stageTypesMap[
                            relationStagesList[
                                relationStagesList.length - 1
                            ]?.type_id
                        ];
                    return {
                        ...r, 
                        vacancy_name: vacancy && vacancy.name,
                        stage_name: currentStage && currentStage.name,
                        stage_color: currentStage && currentStage.color
                    }
                })
            }
    }

    const filteredIds = filterObjectsMap({
        map: mapWithRelations,
        allIds,
        filterOptions,
    })

    sortAndSetIds({dispatch, map, allIds: filteredIds, sortingOption});
});

const filterSortAndSetIdsDebounced = debounce(filterSortAndSetIds, 300);

export const filterSortAndAppendIds = ({dispatch, map, allIds=[], addToEnd=true}) =>
    dispatch((dispatch, getState) => {
        const filterOptions = getAllFilterOptions(getState());
        const sortingOption = getSortingOption(getState());
        const filteredAndSortedIds = getFilteredAndSortedIds(getState());

        const relationsMap = getRelationsByPersonId(getState());
        const vacanciesMap = getVacanciesMap(getState());
        const stagesMap = getStagesMapByRelationId(getState());
        const stageTypesMap = getStageTypesMap(getState());
    
        const mapWithRelations = {};

        for (let personId of allIds) {
            mapWithRelations[personId] = {
                ...map[personId],
                relations: relationsMap[personId]
                    ?.map(r => {
                        const vacancy = vacanciesMap[r.vacancy_id];
                        const relationStagesList = stagesMap[r.id];
                        const currentStage = stageTypesMap && relationStagesList?.length && 
                            stageTypesMap[
                                relationStagesList[
                                    relationStagesList.length - 1
                                ]?.type_id
                            ];
                        return {
                            ...r, 
                            vacancy_name: vacancy && vacancy.name,
                            stage_name: currentStage && currentStage.name,
                            stage_color: currentStage && currentStage.color
                        }
                    })
                }
        }

        const filteredNewPartIds = filterObjectsMap({ 
            map: mapWithRelations,
            allIds,
            filterOptions: filterOptions
        });

        const fullMap = getMap(getState());

        sortAndSetIds({ 
            dispatch, 
            map: {...fullMap, ...map},
            allIds: addToEnd ? 
                [...filteredAndSortedIds, ...filteredNewPartIds]
                :
                [ ...filteredNewPartIds, ...filteredAndSortedIds],
            sortingOption
        })
    });

export const filter = ({ dispatch, filterOption }) => 
    dispatch((dispatch, getState) => {
        dispatch(setPersonsLoading(true));
        const normalizedFilterOptions = normalizeFilterOption(filterOption);
        dispatch(setFilterInputValue(normalizedFilterOptions));
        const map = getMap(getState());
        const allIds = getAllIds(getState());
        const sortingOption = getSortingOption(getState());
        const addedFilterOptions = getAddedFilterOptions(getState());

        filterSortAndSetIdsDebounced({
            dispatch,
            map,
            allIds,
            filterOptions: [...addedFilterOptions, normalizedFilterOptions],
            sortingOption
        })
        dispatch(setPersonsLoading(false));
    });

export const addFilterOption = ({dispatch, option}) => {
    dispatch(setFilterInputValue({value: ''}));
    dispatch(appendFilterOption(option));
}

export const deleteFilterOption = ({dispatch, index}) => 
    dispatch((dispatch, getState) => {
        const map = getMap(getState());
        const allIds = getAllIds(getState());
        const sortingOption = getSortingOption(getState());
        const newAddedFilterOptions = [...getAddedFilterOptions(getState())];
        const addedFilterOptions = getAddedFilterOptions(getState());
        const inputFilterOption = getFilterOptionsInput(getState());
        newAddedFilterOptions.splice(index, 1);
        
        const filterOptions = [...newAddedFilterOptions, addedFilterOptions, inputFilterOption];

        dispatch(sliceOutFilterOption(index));
        filterSortAndSetIds({ 
            dispatch, 
            map,
            allIds,
            filterOptions,
            sortingOption
        })
    });
    
export const clearFilters = (dispatch) => {
    dispatch(clearFilterOptions());
}

export const updateFilteredAndSortedIds = (dispatch) => 
    dispatch((dispatch, getState) => {
        dispatch(setPersonsLoading(true));
        const filterOptions = getAllFilterOptions(getState());
        const sortingOption = getSortingOption(getState());
        const map = getMap(getState());
        const allIds = getAllIds(getState());

        filterSortAndSetIds({ 
            dispatch,
            map,
            allIds, 
            filterOptions: filterOptions,
            sortingOption
        });

        dispatch(setPersonsLoading(false));
    });

export const sort = ({ dispatch, sortingOption }) => dispatch((dispatch, getState) => {
    const filteredAndSortedIds = getFilteredAndSortedIds(getState());
    const map = getMap(getState());
    const propPath = sortingOption.value.split('.');
    const formattedSortingOption = {propPath, sortingOrder: sortingOption.sortingOrder};

    dispatch(setSortingOption(formattedSortingOption));

    sortAndSetIds({ dispatch, map, allIds: filteredAndSortedIds, sortingOption: formattedSortingOption});
})