import React from 'react';

import './styles.scss';

const ButtonUi = ({ className, ...attributes }: any) => {
    const totalClassName = 'styled-button-ui ' + (className || '')

    return <button className={totalClassName} {...attributes}/>
}

export default ButtonUi;