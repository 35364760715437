import React, {
    createContext,
    useCallback,
    useEffect,
    useReducer,
    useRef,
} from 'react';

import reducerPersons,
    { initialState as initStatePersons }
from './redux/persons/reducer';

import reducerFiles,
    { initialState as initStateFiles }
from './redux/files/reducer';

import reducerComments,
    { initialState as initStateComments }
from './redux/comments/reducer';

import reducerUser,
    { initialState as initStateUser }
from './redux/user/reducer';

import reducerAuth,
    { initialState as initStateAuth }
from './redux/auth/reducer';

import reducerVacancies,
    { initialState as initStateVacancies }
from './redux/vacancies/reducer';

import reducerUsers,
    { initialState as initStateUsers }
from './redux/users/reducer';

import reducerRelations,
    { initialState as initStateRelations }
from './redux/relations/reducer';

import reducerProfiles,
    { initialState as initStateProfiles }
from './redux/profiles/reducer';

import reducerGroups,
    { initialState as initStateGroups }
from './redux/groups/reducer';

import reducerVotes,
    { initialState as initStateVotes }
from './redux/votes/reducer';

import reducerTags,
    { initialState as initStateTags }
from './redux/tags/reducer';

// import { wsMiddleware } from './middlewares/wsMiddleware';
import thunkMiddleware from './middlewares/thunkMiddleware';

const combineReducers = (slices) => (state, action) =>
    Object.keys(slices).reduce(
        (acc, prop) => ({
            ...acc,
            [prop]: slices[prop](acc[prop], action),
        }),
        state,
    );

// const initialState = {
//     errors: [],
//     persons: {
//         chosenId: 1,
//         list: [],
//         loading: false,
//     },

//     vacancies: {
//         list: [],
//         loading: false,
//     },

//     comments: {
//         list: [],
//         loading: false,
//     },

//     files: {
//         list: [],
//         loading: false,
//     },
// };
  
const store = createContext({});
  
const { Provider } = store;
  
export const initState = {
    persons: initStatePersons,
    files: initStateFiles,
    comments: initStateComments,
    user: initStateUser,
    auth: initStateAuth,
    vacancies: initStateVacancies,
    users: initStateUsers,
    relations: initStateRelations,
    profiles: initStateProfiles,
    groups: initStateGroups,
    votes: initStateVotes,
    tags: initStateTags,
}

export const rootReducer = combineReducers({
    files: reducerFiles,
    persons: reducerPersons,
    comments: reducerComments,
    user: reducerUser,
    auth: reducerAuth,
    vacancies: reducerVacancies,
    users: reducerUsers,
    relations: reducerRelations,
    profiles: reducerProfiles,
    groups: reducerGroups,
    votes: reducerVotes,
    tags: reducerTags,
})

const pipe = (...functions) => args => functions.reduce((arg, fn) => fn(arg), args);

const middlewares = [
    // wsMiddleware(),
    thunkMiddleware(),
]

export const useReducerMiddleware = ({rootReducer, initState}) => {
    const [state, dispatch] = useReducer(rootReducer, initState);

    const stateRef = useRef(state);
    const getState = useCallback(() => stateRef.current, [stateRef]);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const dispatchMiddleware = useCallback((action) => {
        const middlewaresWithDispatch = middlewares.map((middleware => middleware(dispatchMiddleware, getState)))
        pipe(...middlewaresWithDispatch)((action) => dispatch(action))(action);
    }, [getState]);

    return [state, dispatchMiddleware];
  }


const StateProvider = ({children}) => {
    const [state, dispatch] = useReducerMiddleware({rootReducer, initState})
    return <Provider value={{state, dispatch}}>{children}</Provider>
}

export {store, StateProvider};