import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import { Segment, Table, Dropdown, Icon } from 'semantic-ui-react';
import c3 from 'c3';

import { store } from '../../store';

import './Groups.scss';
import 'c3/c3.css';
import { arrToMap, avoidZeroDate, deepEqual, tryToNormalizeDate } from '../../utils';
import * as groupActs from '../../redux/groups/actions';
import { handleError } from '../../errorHandler';
import InputUi from '../../components/NewInputUi';
import { group as zeroGroup } from '../../group';

const GroupsPage = () => {

    const { state, dispatch } = useContext(store);

    const {
        groups: {
            list,
            selected
        }
    } = state;

    const chartRef = useRef();
    const [awaitNewGroupInList, setAwaitNewGroupInList] = useState(false);

    const drawChart = ({ chartData, chartType='bar' }) => {
        c3.generate({
            bindto: '#chart', 
            data: {
                x: 'x',
                columns: [
                    ['x', ...Object.keys(chartData)],
                    ['creation date', ...Object.values(chartData)],
                ],
                type: 'line'
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d'
                    }
                }
            }
        });
    }

    const groupsMap = useMemo(() => arrToMap(list), [list]);

    const groupListByCreationTime = (list) => {
        const creationDateMap = {}

        list.map((g) => {
            const date = tryToNormalizeDate(avoidZeroDate(g.service.created_at));
            date && (creationDateMap[date] = (creationDateMap[date] || 0) + 1);
            return null
        })
        return creationDateMap
    }

    useEffect(() => {
        drawChart({chartData: groupListByCreationTime(list)});
    }, [list]);


    const handleCascadeUpdates = useCallback(({ uneditedGroup, editedGroup }) => {
        const groupId = editedGroup.id;
        const addedChildren = editedGroup.child_ids ? 
            editedGroup.child_ids.reduce((added, c) => {
                if (!uneditedGroup.child_ids || !uneditedGroup.child_ids.includes(c)) {
                    return [...added, c];
                }
                return added
            }, [])
            :
            [];

        const deletedChildren = uneditedGroup.child_ids ?
            uneditedGroup.child_ids.reduce((deleted, c) => {
                if (!editedGroup.child_ids || !editedGroup.child_ids.includes(c)) {
                    return [...deleted, c];
                }
                return deleted
            }, [])
            :
            [];

        addedChildren && addedChildren.map(c => groupActs.updateInState({ dispatch, group: {id: c, parent_id: groupId}}));
        deletedChildren && deletedChildren.map(c => groupActs.updateInState({ dispatch, group: {id: c, parent_id: null}}));

        const newParentId = editedGroup.parent_id;
        const oldParentId = uneditedGroup.parent_id;

        if (editedGroup.parent_id === uneditedGroup.parent_id)
            return

        if (newParentId) {
            groupActs.updateInState({ 
                dispatch, 
                group: {
                    id: newParentId, 
                    child_ids: [...(groupsMap[newParentId].child_ids || []), groupId]
                }
            })
        }

        if (oldParentId) {
            groupActs.updateInState({ 
                dispatch, 
                group: {
                    id: oldParentId, 
                    child_ids: [...groupsMap[oldParentId].child_ids.filter(id => id !== groupId)]
                }
            })
        }
    }, [dispatch, groupsMap]);

    useEffect(() => {
        if (list && awaitNewGroupInList) {
            handleCascadeUpdates({
                uneditedGroup: zeroGroup,
                editedGroup: list[0]
            });
            setAwaitNewGroupInList(false);
        }
    }, [list, handleCascadeUpdates, awaitNewGroupInList])

    const addToSelected = (group) => {
        groupActs.updateSelected({dispatch, id: group.id, data: group})
    }

    const onChangeSelected = ({groupId, data}) => {
        groupActs.updateSelected({dispatch, id: groupId, data})
    }

    const onDiscardGroup = (groupId) => {
        groupActs.deleteSelected(dispatch, groupId);
    }

    const onClickAddGroupButton = () => {
        addToSelected(zeroGroup)
    }

    const onSubmitGroup = () => {
        groupActs.add({dispatch, group: selected[-1]})
            .then(() => {
                setAwaitNewGroupInList(true);
                groupActs.deleteSelected(dispatch, -1)
            })
            .catch(err => handleError(err));
    }

    const onUpdateGroup = (groupId) => {
        groupActs.update({dispatch, group: selected[groupId]})
            .then(() => {
                handleCascadeUpdates({
                    uneditedGroup: groupsMap[groupId],
                    editedGroup: selected[groupId]
                });
                groupActs.deleteSelected(dispatch, groupId);
            })
            .catch(err => handleError(err));
    }

    const filterUnavailableParentOptions = ({ list, group }) => {
        return list.reduce(
            (options, g) => {
                if ((g.id !== group.id) && 
                    (!group.child_ids || !group.child_ids.includes(g.id))) {
                    options.push({value: g.id, text: g.name, key: g.id})
                }
                return options
            }, []
        )
    }

    const filterUnavaliableChildrenOptions = ({ list, group }) =>
        list.reduce(
            (options, g) => {
                if (g.parent_id === group.id ||
                    (!g.parent_id && 
                    group.parent_id !== g.id &&
                    (g.id !== group.id)
                    )) {
                    options.push({value: g.id, text: g.name, key: g.id})
                }
                return options
            }, []
        )


    return (
        <div className='groups'>
            <div className='groups-split'>
                <div className='groups-split-left'>
                    <Table selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={5}>
                                    Group
                                </Table.HeaderCell>
                                <Table.HeaderCell width={5}>
                                    Parents
                                </Table.HeaderCell>
                                <Table.HeaderCell width={5}>
                                    Children
                                </Table.HeaderCell>
                                <Table.HeaderCell 
                                    content={<Icon name='add' onClick={onClickAddGroupButton}/>} 
                                    width={5}
                                />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {selected[-1] && (() => {
                                const g = zeroGroup;
                                return <Table.Row>
                                    <Table.Cell 
                                        width={5}
                                        content={
                                            <InputUi 
                                                name='name'
                                                value={selected[g.id] ? selected[g.id].name : g.name} 
                                                edit={true}
                                                onChange={(e) => 
                                                    onChangeSelected({
                                                        groupId: g.id, 
                                                        data: {name: e.target.value}
                                                    })}
                                            />
                                        }
                                    />
                                    <Table.Cell width={5} 
                                        content={
                                            <Dropdown 
                                                onChange={(e, { value }) => 
                                                    onChangeSelected({
                                                        groupId: g.id, 
                                                        data: {parent_id: value}
                                                    })}
                                                options={[
                                                    {value: null, text: 'none', key: -1},
                                                    ...filterUnavailableParentOptions({list, group: selected[g.id] })
                                                ]} 
                                                value={selected[g.id] ? selected[g.id].parent_id : g.parent_id}
                                                placeholder='none'
                                                compact
                                            />
                                        }/>
                                    <Table.Cell width={5} 
                                        content={
                                            <Dropdown 
                                                onChange={(e, { value }) => 
                                                    onChangeSelected({
                                                        groupId: g.id, 
                                                        data: {child_ids: value}
                                                    })}

                                                options={filterUnavaliableChildrenOptions({ list, group: selected[g.id] })}
                                                value={selected[g.id].child_ids || g.child_ids || []}
                                                renderLabel={(label) => ({
                                                    basic: true,
                                                    content: label.text,
                                                    size: 'tiny'
                                                })}
                                                placeholder='none'
                                                multiple
                                                selection
                                                fluid
                                            />
                                        }/>

                                        <Table.Cell width={1} 
                                            content={
                                                <>
                                                    <Icon 
                                                        name='save'
                                                        onClick={() => onSubmitGroup()}
                                                    />
                                                    <Icon 
                                                        name='close'
                                                        onClick={() => onDiscardGroup(g.id)}
                                                    />
                                                </>
                                            }
                                        />
                                    </Table.Row>
                                })()}
                            {list.map(g =>
                                <Table.Row key={g.id}>
                                    <Table.Cell 
                                        width={5}
                                        content={
                                            <InputUi 
                                                name='name'
                                                value={selected[g.id] ? selected[g.id].name : g.name} 
                                                edit={!!selected[g.id]}
                                                onChange={(e) => 
                                                    onChangeSelected({
                                                        groupId: g.id, 
                                                        data: {name: e.target.value}
                                                    })
                                                }
                                            />
                                        }
                                    />
                                    <Table.Cell width={5} 
                                        content={
                                            selected[g.id] ?
                                                <Dropdown 
                                                    onChange={(e, { value }) => 
                                                        onChangeSelected({
                                                            groupId: g.id, 
                                                            data: {parent_id: value}
                                                        })}
                                                    options={[
                                                        {value: null, text: 'none', key: -1},
                                                        ...filterUnavailableParentOptions({list, group: selected[g.id] })
                                                    ]} 
                                                    value={selected[g.id]? selected[g.id].parent_id : g.parent_id}
                                                    placeholder='none'
                                                    compact
                                                />
                                                :
                                                groupsMap[g.parent_id] && groupsMap[g.parent_id].name
                                        }/>
                                    <Table.Cell width={5} 
                                        content={
                                            selected[g.id] ?
                                                <Dropdown 
                                                onChange={(e, { value }) => 
                                                    onChangeSelected({
                                                        groupId: g.id, 
                                                        data: {child_ids: value}
                                                    })}
                                                    options={filterUnavaliableChildrenOptions({ list, group: selected[g.id] })} 
                                                    value={selected[g.id].child_ids || g.child_ids || []}
                                                    placeholder='none'
                                                    multiple
                                                    selection
                                                    fluid
                                                />
                                                :
                                                g.child_ids && g.child_ids.map((c, idx) =>
                                                    ((idx !== 0) ? ', ' : '') + (groupsMap[c] && groupsMap[c].name))
                                        }/>
                                        <Table.Cell width={1} 
                                            content={
                                                selected[g.id] ?
                                                    <>
                                                        <Icon 
                                                            name='save'
                                                            onClick={() => onUpdateGroup(g.id)}
                                                            disabled={deepEqual(selected[g.id], g)}
                                                        />
                                                        <Icon 
                                                            name='close'
                                                            onClick={() => onDiscardGroup(g.id)}
                                                        />
                                                    </>
                                                    :
                                                    <Icon 
                                                        name='edit'
                                                        onClick={()=>addToSelected(g)}
                                                    />
                                            }
                                        />
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>
                <div className='groups-split-right'>
                    <Segment style={{height: '90vh'}}>
                        <div ref={chartRef} id='chart'/>
                    </Segment>
                </div>
            </div>
        </div>
    )
}

export default GroupsPage;