import axios from 'axios';
import { Group } from 'entities/group'

const rootRef = "/matches/api/v1";
const groupssRef = "/groups";

const getApiUrl = () => `${rootRef}${groupssRef}/`

export const getGroups = async (): Promise<Array<Group> | Error> => {
    try {
        const response = await axios(getApiUrl());

        return response.data;
    } catch (err: any) {
        if (err.response) {
            if (err.response.status === 401) {
                return new Error("вы не авторизованы")
            }

            return new Error(err.response.statusText)
        }

        return new Error(err.message)
    }
}