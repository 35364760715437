import React, { useMemo, useState } from 'react';
import { Grid, Button, Icon } from 'semantic-ui-react';

import CardUi from '../../../components/NewCardUi';
import InputUi from '../../../components/NewInputUi';

import './PersonCard.scss';

import { 
    tryToNormalizeDate, 
    avoidZeroDate, 
    parseIntegerInput, 
    copyToClipboard, 
    tryToFormatDateToISO,
} from '../../../utils';
import { handleError } from '../../../errorHandler';
import RelationCard from './Relation';
import TagsListUi from '../../../components/TagsListUi';
import { IconUi } from '../../../components/IconUi';
import InputSelect from '../../../components/InputSelectUi';

const PersonCard = ({
    relations=[],
    data,
    editable,
    onEdit=(person)=>{},
    onChangeField,
    onSubmit=(person)=>{},
    onDiscard,
    onBook=({ personId, systemUserId })=>{},
    onUnbook=({ personId, systemUserId })=>{},
    onChoose=()=>{},
    onChangeStage=({ relation_id, stage_type_id })=>{},
    isPristine,
    errors,
    checkForErrors,
    currentUser,
    stagesMapByRelationId,
    stageTypesAllIds,
    stageTypesMap,
    style=undefined,
    className=undefined,
    vacanciesMap,
    usersMap,

    onCancelProcessing,
    processingCancelReasons,

    // onOpenVotesModal,
    tagsMap,
    tagsAllIds,
    onCreateTag,
    onChangeTag,
    onAddTagId,
    onDeleteTagId,
    profilesList,
    onClickProfileInput,
    onCreateProfile,
    onDeleteRelation,
}) => {

    const hasErrors = errors && Object.keys(errors).length !== 0;

    const [showProfileInput, setShowProfileInput] = useState(false);

    const [copyIcons, setCopyIcons] = useState({mail: 'copy', cell: 'copy', person: 'linkify'});

    const onCopyIconClick = ({name, value}) => {
        let oldIconName = '';

        copyToClipboard(value)
            .then(() => setCopyIcons(icons => {
                oldIconName = icons[name];
                return {...icons, [name]: 'check'}
            }))
            .catch(err => handleError(err));
        setTimeout(() => {
            setCopyIcons(icons => ({...icons, [name]: oldIconName}))
        }, 500)
    }

    const relationsByProfilesMap = useMemo(() => {
        const map = {};
        relations && relations.length > 0 &&
            relations.forEach(relation => {
                const vacancy = vacanciesMap[relation.vacancy_id];
                const profileId = vacancy?.profile?.id || -1;
                const profileName = vacancy?.profile?.name || 'No profile';

                map[profileId] = {
                    name: profileName,
                    relations: [
                        ...(map[profileId]?.relations || []),
                        relation,
                    ]
                };
            });
        return map;
    }, [relations, vacanciesMap]);

    return (
        <CardUi 
            onClick={() => onChoose(data)}
            style={style}
            className={'person-card ' + className}
        >
            <div>
                <Grid className="person-card-grid">
                        <Grid.Row
                            className={
                                `person-card-grid-row person-card-grid-row-header` + 
                                `person-card-grid-row person-card-grid-row-header${editable ? '-edited' : ''}`
                            }
                            columns={editable ? 2 : undefined}
                        >
                            <Grid.Column
                                className={`person-card-grid-row-header-column${editable ? '-edited' : ''}`}
                            >
                                <InputUi
                                    name='surname'
                                    value={data.surname}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            e, 
                                            id: data.service.id
                                        })
                                    }
                                    errors={errors && errors.surname}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'string', required: true, size: 255},
                                            fieldName: 'surname',
                                            personId: data.service.id
                                    })}
                                    required
                                />
                            </Grid.Column>
                            <Grid.Column
                                className={`person-card-grid-row-header-column${editable ? '-edited' : ''}`}
                            >
                                <InputUi
                                    name='name'
                                    value={data.name}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            e, 
                                            id: data.service.id
                                        })
                                    }
                                    errors={errors && errors.name}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'string', required: true, size: 255},
                                            fieldName: 'name',
                                            personId: data.service.id
                                    })}
                                    required
                                />
                            </Grid.Column>
                            <Grid.Column
                                className={`person-card-grid-row-header-column${editable ? '-edited' : ''}`}
                            >
                                <InputUi
                                    name='midname'
                                    value={data.midname}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            e, 
                                            id: data.service.id
                                        })
                                    }
                                    errors={errors && errors.midname}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'string', required: false, size: 255},
                                            fieldName: 'midname',
                                            personId: data.service.id
                                    })}
                                />
                            </Grid.Column>
                            {!editable && 
                                <>
                                    <Icon 
                                        onClick={() =>
                                            onCopyIconClick({
                                                name: 'person',
                                                value: window.location.origin + '/persons/' + data.service.id
                                            })
                                        }
                                        size='small'
                                        name={copyIcons.person}
                                        className='person-card-copy-icon'
                                    />
                                    <Grid.Column floated='right' width={4}>
                                        {data.booking.is_booked ?
                                            <Button
                                                type="button"
                                                disabled={data.booking.booked_by !== currentUser.service.id}
                                                size="mini"
                                                color={data.booking.booked_by === currentUser.service.id? "grey": "red"}
                                                title={data.booking.booked_by === currentUser.service.id? "Click to free person": "Already booked"}
                                                onClick={
                                                    data.booking.booked_by === currentUser.service.id?
                                                        () => onUnbook({personId: data.service.id, systemUserId: currentUser.service.id})
                                                        : null
                                                }
                                                className='person-card-booking-button'
                                            >
                                                Booked by {
                                                    data.booking.booked_by === currentUser.service.id ?
                                                        "you"
                                                        :
                                                        `${(() => {
                                                            const userThatBooked = usersMap[data.booking.booked_by];
                                                            const nameLetter = userThatBooked?.name && userThatBooked.name.slice(0, 1) + '.';
                                                            return userThatBooked ?
                                                                (userThatBooked.surname + ' ' + nameLetter)
                                                                :
                                                                data.booking.booked_by;
                                                        })()}`
                                                    }
                                            </Button>
                                            :
                                            <Button
                                                type="button"
                                                size="mini"
                                                color="green"
                                                title="Click to book person"
                                                onClick={() => onBook({personId: data.service.id, systemUserId: currentUser.service.id})}
                                                className='person-card-booking-button'
                                            >
                                                Book
                                            </Button>
                                        }
                                    </Grid.Column>
                                </>
                            }
                        </Grid.Row>
                    {!editable && 
                        <div className='person-card-meta'>
                            <Grid.Row className="person-card-grid-row">
                                <Grid.Column>
                                    <div>
                                        {data.service.id}
                                    </div>
                                    <div>
                                        {tryToNormalizeDate(avoidZeroDate(data.service.created_at))}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </div>
                    }
                    <Grid.Row className="person-card-grid-row" columns={2}>
                        <Grid.Column>
                                <InputUi
                                    name='city'
                                    value={data.city}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            e, 
                                            id: data.service.id
                                        })
                                    }
                                    errors={errors && errors.city}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'any', required: false, size: 255},
                                            fieldName: 'city',
                                            personId: data.service.id
                                    })}
                                />
                                <InputUi
                                    name='scope'
                                    value={data.scope}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            e, 
                                            id: data.service.id
                                        })
                                    }
                                    errors={errors && errors.scope}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'any', required: false, size: 255},
                                            fieldName: 'scope',
                                            personId: data.service.id
                                    })}
                                />
                                <InputUi
                                    name='work_at'
                                    value={data.work_at}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            e, 
                                            id: data.service.id
                                        })
                                    }
                                    errors={errors && errors.work_at}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'any', required: false, size: 255},
                                            fieldName: 'work_at',
                                            personId: data.service.id
                                    })}
                                />
                                <InputUi
                                    name='birth'
                                    value={tryToNormalizeDate(avoidZeroDate(data.birth))}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            e, 
                                            id: data.service.id,
                                            data: {
                                                birth: tryToFormatDateToISO(e.target.value)
                                            }
                                        })
                                    }
                                    errors={errors && errors.birth}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value: tryToNormalizeDate(value), 
                                            validateOptions: {type: 'date', required: false, size: 255},
                                            fieldName: 'birth',
                                            personId: data.service.id
                                    })}
                                    action={<Button type="button" onClick={()=>{console.log('birth action!')}}/>}
                                />
                        </Grid.Column>
                        <Grid.Column>
                                <InputUi
                                    name='amount'
                                    type='number'
                                    value={data.money.amount || ''}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            id: data.service.id,
                                            data: {
                                                money: {
                                                    ...data.money,
                                                    [e.target.name]: parseIntegerInput(e.target.value, 10)
                                                }
                                            }
                                        })
                                    }
                                    errors={errors && errors.amount}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'number', required: false, size: 255},
                                            fieldName: 'number',
                                            personId: data.service.id
                                    })}
                                />
                                <InputUi
                                    name='currency'
                                    value={data.money.currency}
                                    id={data.service.id}
                                    edit={editable}
                                    onChange={
                                        (e)=>onChangeField({
                                            id: data.service.id,
                                            data: {
                                                money: {
                                                    ...data.money,
                                                    [e.target.name]: e.target.value
                                                }
                                            }
                                        })
                                    }
                                    errors={errors && errors.currency}
                                    checkForErrors={(value)=>
                                        checkForErrors({
                                            value, 
                                            validateOptions: {type: 'string', required: false, size: 4},
                                            fieldName: 'currency',
                                            personId: data.service.id
                                    })}
                                />
                                <div>
                                    <div style={!editable ? {display: 'inline-flex'} : undefined}>
                                        <InputUi
                                            name='email'
                                            value={data.email}
                                            id={data.service.id}
                                            edit={editable}
                                            onChange={
                                                (e)=>onChangeField({
                                                    e, 
                                                    id: data.service.id
                                                })
                                            }
                                            errors={errors && errors.email}
                                            checkForErrors={(value)=>
                                                checkForErrors({
                                                    value, 
                                                    validateOptions: {type: 'email', required: false, size: 255},
                                                    fieldName: 'email',
                                                    personId: data.service.id
                                            })}
                                        />
                                        {!editable && data.email && 
                                            <Icon 
                                                name={copyIcons.mail} 
                                                onClick={() => onCopyIconClick({name: 'mail', value: data.email})}
                                                className='person-card-copy-icon'
                                            />
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div style={!editable ? {display: 'inline-flex'} : undefined}>
                                        <InputUi
                                            name='cell'
                                            value={data.cell}
                                            id={data.service.id}
                                            edit={editable}
                                            onChange={
                                                (e)=>onChangeField({
                                                    e, 
                                                    id: data.service.id
                                                })
                                            }
                                            errors={errors && errors.cell}
                                            checkForErrors={(value)=>
                                                checkForErrors({
                                                    value, 
                                                    validateOptions: {type: 'tel', required: false, size: 255},
                                                    fieldName: 'cell',
                                                    personId: data.service.id
                                            })}
                                        />
                                        {!editable && data.cell && 
                                            <Icon 
                                                name={copyIcons.cell} 
                                                onClick={() => onCopyIconClick({name: 'cell', value: data.cell})}
                                                className='person-card-copy-icon'
                                            />
                                        }
                                    </div>
                                </div>
                        </Grid.Column>
                    </Grid.Row>
                    {!editable && relations && relations.length ?
                        <Grid.Row className="person-card-grid-row">
                            <div className='person-card-relations' title={'relations'}>
                                {Object.values(relationsByProfilesMap).map((profile, idx) => (
                                    <div key={idx}>
                                        <div>
                                            {profile.name}
                                        </div>
                                        {profile.relations.map((relation, idx) => {
                                            const vacancy = vacanciesMap[relation.vacancy_id];
                                            const currentRelationStages = stagesMapByRelationId[relation.id];
                                            const lastStage = currentRelationStages && currentRelationStages[currentRelationStages.length - 1];
                                            const lastStageType = stageTypesMap[lastStage?.type_id];
                                            const hiringManagerName = (
                                                (usersMap[vacancy?.hiring_manager_id]?.surname || '') +
                                                ' ' + 
                                                (usersMap[vacancy?.hiring_manager_id]?.name || '')
                                            ).trim();
                                            
                                            return (
                                                <RelationCard
                                                    relation={relation}
                                                    vacancy={
                                                        {
                                                            ...vacancy,
                                                            hiringManagerName
                                                        }
                                                    }
                                                    stageTypesAllIds={stageTypesAllIds}
                                                    stageTypesMap={stageTypesMap}
                                                    lastStageType={lastStageType}
                                                    onChangeStage={onChangeStage}
                                                    key={relation.id}
                                                    onDeleteRelation={onDeleteRelation}

                                                    // процессинг
                                                    onCancelProcessing={onCancelProcessing}
                                                    processingCancelReasons={processingCancelReasons}
                                                />
                                            )
                                        })}
                                    </div>
                                ))}
                            </div>
                        </Grid.Row>
                        :
                        null
                    }
                    
                    {showProfileInput &&
                        <Grid.Row className="person-card-grid-row">
                            <Grid.Column>
                                <InputSelect
                                    loadOptions={(text) => {
                                        const profileOptions =  profilesList.reduce((options, currentPr) => {
                                            if (currentPr?.name?.toLowerCase()?.includes(text.toLowerCase())) {
                                                return [
                                                    ...options,
                                                    {value: currentPr.id, label: currentPr.name}
                                                ]
                                            }
                                            return options
                                        }, []);
                                        return [...profileOptions, {value: -1, label: 'No profile'}]
                                    }}

                                    withButton={{
                                        icon: <Icon name='check'/>,
                                        handler: (option) => {
                                            onClickProfileInput({personId: data.service.id ,profileId: option.value})
                                            setShowProfileInput(false);
                                        },
                                    }}

                                    withCreate={{
                                        handler: onCreateProfile
                                    }}

                                    placeholder={"Start typing to find profile..."}
                                    autoFocus
                                />
                            </Grid.Column>
                        </Grid.Row>
                    }
                    <Grid.Row className="person-card-grid-row">
                        {editable ?
                            <>
                                <Grid.Column width={2}>
                                    <Button 
                                        type='submit'
                                        title='submit person'
                                        icon 
                                        disabled={hasErrors || isPristine}
                                        onClick={() => onSubmit(data)}
                                    >
                                        <Icon name='save'/>
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Button 
                                        type='button'
                                        title='discard person'
                                        icon 
                                        onClick={()=>{
                                            onDiscard(data.service.id);
                                        }}
                                    >
                                        <Icon name='close'/>
                                    </Button>
                                </Grid.Column>
                            </>
                            : 
                            <>
                                <Grid.Column width={8}>
                                    <div data-testid='add-relation-modal'>
                                        <button 
                                            title="show add relation modal"
                                            className='person-card-vacancies-modal-trigger-button'
                                            onClick={() => setShowProfileInput(true)}
                                        >
                                            <IconUi name='add'/>
                                            Add vacancy
                                        </button>
                                    </div>
                                </Grid.Column>
                                <Grid.Column floated='right' width={2}>
                                        <Icon
                                            name='edit'
                                            title='edit person'
                                            onClick={()=>{
                                                onEdit(data);
                                            }}
                                            className='person-card-edit-icon'
                                        />
                                        {/* <ButtonUi
                                            onClick={() => onOpenVotesModal(data.service.id)}
                                            icon='chart bar'
                                            size='tiny'
                                        /> */}
                                </Grid.Column>
                            </>
                        }
                    </Grid.Row>
                    {!editable &&
                        <Grid.Row columns={1} className="person-card-grid-row-tags">
                            <Grid.Column>
                                <TagsListUi 
                                    tagsMap={tagsMap}
                                    tagsAllIds={tagsAllIds}
                                    tagIdsToShow={data.tag_ids}

                                    onCreateTag={(label) => onCreateTag({personId: data.service.id, label})}
                                    onUpdateTag={onChangeTag}
                                    onAddTagId={(tagId) => onAddTagId({personId: data.service.id, tagId})}
                                    onDeleteTagId={(tagId) => onDeleteTagId({personId: data.service.id, tagId})}

                                    maxTagsToShow={3}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </div>
        </CardUi>
    )
}

export default React.memo(PersonCard);