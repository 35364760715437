import { List } from "semantic-ui-react"


export const ListUi = ({children, ...props}) => {
    return <List {...props}>
        {children}
    </List>
}

export const ListItemUi = ({children, ...props}) => {
    return <List.Item {...props}>
        {children}
    </List.Item>
}

export default ListUi;