import React from 'react';
import {WindowScroller} from 'react-virtualized';

export const WindowScrollerUi = ({children, ...props}) => {

    return <WindowScroller {...props}>
        {({...funcProps}) => children({...funcProps})}
    </WindowScroller>
}

export default WindowScrollerUi;