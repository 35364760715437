export const zeroVacancy = {
    service: {
        id: undefined
    },
    name: '',
    scope: '',
    money: {
        amount: 0,
        currency: ''
    },
    description: '',
    hiring_manager_id: -1,
    status: '',
}

export const flattenVacancy = (vacancy) => {
    return {
        name: vacancy.name || '', 
        scope: vacancy.scope || '', 
        id: vacancy.service.id || '', 
        amount: (vacancy.money && vacancy.money.amount) || '', 
        currency: (vacancy.money && vacancy.money.currency) || '',
        group: (vacancy.group && vacancy.group) || '',
        profile: (vacancy.profile && vacancy.profile) || '',
        description: vacancy.description || '',
        hiring_manager_id: vacancy.hiring_manager_id || '',
        status: vacancy.status || '',
    }
}

export const unflattenVacancy = (vacancy) => {
    return {
        name: vacancy.name,
        scope: vacancy.scope, 
        money: {
            amount: vacancy.amount,
            currency: vacancy.currency
        },
        service: {
            id: vacancy.id
        },
        group: vacancy.group,
        profile: vacancy.profile,
        description: vacancy.description,
        hiring_manager_id: vacancy.hiring_manager_id,
        status: vacancy.status,
    }
}