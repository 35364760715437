import axios from 'axios';
import { cache } from '../../cache';
import { ErrorUnauthorized } from '../../errors';

const rootRef = "/matches/api/v1";
const vacanciesRef = "/vacancies";
const groupsRef = "/groups";
const profilesRef = "/profiles";
const statusTypesRef = "/status_types";

const category = "vacancies";

export const getVacancies = async ({limit, offset, searchText}={}) => {
    let url = `${rootRef}${vacanciesRef}/`;

    if (limit !== undefined && offset !== undefined && searchText !== undefined ) {
        url = `${rootRef}${vacanciesRef}/?offset=${offset}&limit=${limit}&search=${searchText}`;
    }
    
    try {
        const response = await axios(url);
        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getVacancy = async (id) => {
    const url = `${rootRef}${vacanciesRef}/vacancy/${id}`

    let response = {data: cache(category, url)};

    if (!response.data) {
        try {
            response = await axios(url);

            cache(category, url, response.data);

            return response.data;
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    return Error(ErrorUnauthorized)
                }

                return new Error(err.response.statusText)
            } 

            return Error(err.message)
        }
    }

    return response.data
}

export const addVacancy = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${vacanciesRef}/`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return 
    }
}

export const updateVacancy = async (data) => {
    try {
        const response = await axios(
            `${rootRef}${vacanciesRef}/`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                data: data,
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const setGroup = async ({vacancy_id, group_id}) => {
    try {
        await axios(
            `${rootRef}${vacanciesRef}/vacancy/${vacancy_id}${groupsRef}/group/${group_id}`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            },
        );

        return null;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const setProfile = async ({vacancy_id, profile_id}) => {
    try {
        await axios(
            `${rootRef}${vacanciesRef}/vacancy/${vacancy_id}${profilesRef}/profile/${profile_id}`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            },
        );

        return null;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getListByGroupId = async ({group_id}) => {
    let url = `${rootRef}${vacanciesRef}${groupsRef}/group/${group_id}`;

    try {
        await axios(url);
        return null;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getListByProfileId = async ({profile_id}) => {
    let url = `${rootRef}${vacanciesRef}${profilesRef}/profile/${profile_id}`;

    try {
        await axios(url);
        return null;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const getStatusTypesList = async () => {
    let url = `${rootRef}${vacanciesRef}${statusTypesRef}`;

    try {
        const response = await axios(url);
        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        } 

        return Error(err.message)
    }
}

export const setStatus = async ({vacancyId, statusType}) => {
    try {
        await axios(
            `${rootRef}${vacanciesRef}/vacancy/${vacancyId}${statusTypesRef}/status_type/${statusType}`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            },
        );

        return null;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}
