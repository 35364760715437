import axios from 'axios';
import { ErrorUnauthorized } from '../../errors'; 

const rootRef = "/matches/api/v1";
const relationsRef = "/relations";
const stagesRef = "/stages";
const stageTypesRef = "/stage_types";
const personsRef = "/persons";
const vacanciesRef = "/vacancies";
const usersRef = "/users";

const apiRef = "/api/v1";


export const getStagesListByPeriod = async ({from, to}) => {
    
    let url = `${rootRef}${relationsRef}/${stagesRef}?`;

    if (from) {
        url += `from=${from}`;
        
        if (to) {
            url += `&`;
        }
    }
    if (to) {
        url += `to=${to}`;
    }

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getRelationsListByUserId = async (id) => {
    
    const url = `${rootRef}${relationsRef}/${usersRef}/user/${id}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getRelationsListByPersonId = async ({id}) => {
    
    const url = `${rootRef}${relationsRef}/${personsRef}/${id}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getRelationsListByPersonIds = async (ids) => {
    
    let url = `${rootRef}${relationsRef}${personsRef}?ids=`;

    ids.forEach((id, idx) => {
        if (idx !== 0) {
            url += ','
        }
        
        url += id;
    })

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getRelationsListByVacancyId = async ({id}) => {
    
    const url = `${rootRef}${relationsRef}/${vacanciesRef}/${id}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getRelationsListByIds = async (ids) => {
    
    let url = `${rootRef}${relationsRef}/relations`;

    try {
        const response = await axios.post(url, {ids});

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const deleteRelationById = async ({id}) => {
    const url = `${rootRef}${relationsRef}/relation/${id}`;

    try {
        const response = await axios.delete(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const addRelation = async ({vacancy_id, person_id}) => {
    const url = `${rootRef}${relationsRef}/`;

    try {
        const response = await axios.post(url, {vacancy_id, person_id});

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}
export const getStagesByRelationId = async (id) => {
    
    const url = `${rootRef}${relationsRef}/relation/${id}${stagesRef}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getStagesByRelationIds = async (ids) => {
    
    let url = `${rootRef}${relationsRef}${stagesRef}?relation_ids=`;

    ids.forEach((id, idx) => {
        if (idx !== 0) {
            url += ','
        }
        
        url += id;
    })


    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const createStageByRelationId = async ({relation_id, stage}) => {
    try {
        const response = await axios(
            `${rootRef}${relationsRef}/relation/${relation_id}${stagesRef}`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: stage
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const deleteStage = async (stage_id) => {
    try {
        const response = await axios(
            `${rootRef}${relationsRef}${stagesRef}/stage/${stage_id}`,
            {
                method: "DELETE",
                headers: {"Content-Type": "application/json"},
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const getStageTypes = async () => {
    
    const url = `${rootRef}${relationsRef}${stageTypesRef}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const createStageType = async (stage_type) => {
    try {
        const response = await axios(
            `${rootRef}${relationsRef}${stageTypesRef}`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: stage_type
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const updateStageType = async (stage_type) => {
    try {
        const response = await axios(
            `${rootRef}${relationsRef}${stageTypesRef}`,
            {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                data: stage_type
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const deleteStageType = async (stage_type_id) => {
    try {
        const response = await axios(
            `${rootRef}${relationsRef}${stageTypesRef}/stage_type/${stage_type_id}`,
            {
                method: "DELETE",
                headers: {"Content-Type": "application/json"},
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

// processing

export const cancelProcessingWithReason = async ({
    processing_id,
    relation_id,
    initiator,
    cancelled_at,
    reason_id,
    comment,
}) => {
    try {
        await axios(
            `${apiRef}/processing/cancel`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: JSON.stringify({
                    processing_id: processing_id,
                    relation_id:   relation_id,
                    initiator:     initiator,
                    cancelled_at:  cancelled_at,
                    reason_id:     reason_id,
                    comment:       comment,        
                })
            },
        )

        return

    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const createProcessingCancelReason = async ({
    reason,
}) => {
    try {
        let response = await axios(
            `${apiRef}/processing/cancel/reason`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: JSON.stringify({
                    reason: reason,
                })
            },
        )

        return response.data

    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const listProcessingCancelReasons = async () => {
    try {
        let response = await axios(
            `${apiRef}/processing/cancel/reason/list`,
            {
                headers: {"Content-Type": "application/json"},
            }
        )

        return response.data.List
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}


/*

Выбираю шаг - cancel (Away, 3)
-> триггер на id 3 причины отказа Away
    Показываю форму причины отказа
    -> триггер нажатие на причину отказа
        Загружаю список причин отказа
    -> триггер ввод текста причины отказа
        Появляется кнопка создания причины отказа
        -> триггер нажатие на создание причины отказа
            Создается причина отказа
    Отправляю форму прекращения процессинга

*/