export const zeroUserWithSocials = {
    service: {
        id: undefined,
        created_at: "",
        created_by: "",
        updated_at: "",
        updated_by: "",
    },
    name: "",
    midname: "",
    surname: "",
    email: "",
    cell: "",
    group_id: null,
    socials: []
}

export const socialsList = ["telegram"]

export const isUserAdmin = (user) => {
    const adminRoleType = 1;

    if (user.role === adminRoleType) {
        return true;
    }

    return false
}

export const isUserRecruiter = (user) => {
    const recruiterRoleType = 2;

    if (user.role === recruiterRoleType) {
        return true;
    }

    return false
}

export const isUserHiringManager = (user) => {
    const hiringManagerRoleType = 3;

    if (user.role === hiringManagerRoleType) {
        return true;
    }

    return false
}
