import React, { useEffect, useRef, useState } from 'react';
import { Tag } from '../../entities/tag';
import { shouldTextColorBeDark } from '../../utils';
import { IconUi } from '../IconUi';
import PopupUi from '../PopupUi';

import './styles.scss';

type TagUiProps = {
    tag: Tag

    onUpdate: (tag: Tag) => Promise<void>
    onDelete: (id: number) => Promise<void>
}

export const TagUi = ({
    tag,
    onUpdate,
    onDelete
}: TagUiProps) => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [colorpickerValue, setColorpickerValue] = useState('');
    const [isEdited, setIsEdited] = useState(false);

    const tagRef = useRef<HTMLSpanElement | null>(null);
    const popupRef = useRef<HTMLDivElement | null>(null);
    
    const inputValueRef = useRef('');
    const inputElementRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const closePopupOnClickElsewhere = (e: MouseEvent) => {
            if (tagRef.current?.contains(e.target as Node) || popupRef.current?.contains(e.target as Node)) {
                return;
            } else {
                setIsPopupOpen(false);
                setIsEdited(false);
                setColorpickerValue('')
            }
        }
        window.addEventListener('click', closePopupOnClickElsewhere);

        return () => window.removeEventListener('click', closePopupOnClickElsewhere);
    }, []);

    useEffect(() => {
        if (isEdited) {
            inputElementRef.current?.focus();
        }
    }, [isEdited]);
    
    useEffect(() => {
        inputValueRef.current = inputValue;
    }, [inputValue]);

    useEffect(() => {
        const updateTag = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && inputValueRef.current) {
                onUpdate({...tag, label: inputValueRef.current})
                    .then(() =>  {
                        setIsEdited(false);
                    })
                }
        }
        window.addEventListener('keyup', updateTag)
        return () => window.removeEventListener('keyup', updateTag)
    }, [onUpdate, tag]);

    return (
        <span
            className='tag'
            style={{
                backgroundColor: colorpickerValue || tag.color || "#ffffff",
                color: shouldTextColorBeDark(colorpickerValue || tag.color || "#ffffff") ? '#000000' : '#FFFFFF'
            }}
            ref={tagRef}
        >
            {isEdited &&
                <input ref={inputElementRef} className='tag-input' value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
            }
            <span className='tag-label'>
                {tag.label}
            </span>
            <span className='tag-label-shadow'>
                {tag.label}
            </span>
            {!isEdited &&
                <span className='tag-menu'>
                    <span className='tag-menu-icon-edit'>
                        <IconUi
                            name='edit'
                            onClick={()=>{
                                setIsPopupOpen(true);
                                setInputValue(tag.label);
                            }}
                        />
                        <PopupUi
                            open={isPopupOpen}
                            context={tagRef}
                        >
                            <div ref={(el) => {
                                if (el) {
                                    popupRef.current = el;
                                }
                            }}>
                                <div
                                    className='tag-menu-popup-option'
                                    onClick={() => {
                                        setIsEdited(true);
                                    }}
                                >
                                    Change name
                                </div>
                                <label className='tag-menu-popup-option' >
                                    Change color
                                    <div 
                                        className='tag-menu-popup-color-input-container' 
                                        style={{
                                            backgroundColor: colorpickerValue || tag.color || '#ffffff',
                                            borderColor: colorpickerValue || tag.color || '#000000',
                                        }}
                                    >
                                        <input
                                            value={colorpickerValue || '#000000'}
                                            type='color'
                                            onChange={(e) => {
                                                setColorpickerValue(e.target.value)
                                            }}
                                        />
                                    </div>
                                </label>
                                {colorpickerValue && 
                                    <div>
                                        <button 
                                            className='tag-menu-popup-save-button'
                                            onClick={() => {
                                                onUpdate({...tag, color: colorpickerValue})
                                                    .then(() => {
                                                        setColorpickerValue('');
                                                    })
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                }
                            </div>
                        </PopupUi>
                    </span>
                    <span className='tag-menu-icon-delete' onClick={() => onDelete(tag.id)}>
                        <IconUi name='close' />
                    </span>
                </span>
            }
        </span>
    )
}


export default TagUi