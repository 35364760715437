import { arrToMapByServiceId } from '../../utils';
import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    canLoadMore: true,
    limit: 10,
    offset: 0,
    list: [],
    map: {},
    allIds: [],
    searchedIds: [],
    fulltextSearchValue: '',
    selected: {},
    quickAccess: [],
    chosenId: undefined,
    lastCreatedId: null,
    statusTypes: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_CHOSEN_ID:
            return {
                ...state,
                chosenId: action.payload,
            }

        case acts.SET_CAN_LOAD_MORE:
            return {
                ...state,
                canLoadMore: action.payload,
            }
    
        case acts.SET_LAST_CREATED_ID:
            return {
                ...state,
                lastCreatedId: action.payload,
            }

        case acts.UPDATE_SELECTED_VACANCY:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.payload.id]: {
                        ...(state.selected[action.payload.id] || {}),
                        ...action.payload.data
                    }
                }
            }

        case acts.DELETE_SELECTED_VACANCY:
            const selectedList = {...state.selected};
            delete selectedList[action.payload];
            return {
                ...state,
                selected: selectedList
            }

        case acts.APPEND_LIST:
            return {
                ...state,
                list: [
                    ...state.list,
                    ...action.payload,
                ],
                map: {
                    ...state.map,
                    ...arrToMapByServiceId(action.payload),
                },
                allIds: {
                    ...state.allIds,
                    ...action.payload.map(v => v.service.id),
                }
            }

        case acts.SET_LIST:
            return {
                ...state,
                list: action.payload,
                map: arrToMapByServiceId(action.payload),
                allIds: action.payload.map(v => v.service.id),
            }

        case acts.ADD_VACANCY_TO_LIST:
            return {
                ...state,
                list: [
                    {...action.payload},
                    ...state.list
                ],
                map: {
                    [action.payload.service.id]: action.payload,
                    ...state.map,
                },
                allIds: [
                    action.payload.service.id,
                    ...state.allIds,
                ]
            }
        case acts.ADD_VACANCY_TO_QUICK_ACCESS_LIST:
            return {
                ...state,
                quickAccess: [
                    ...state.quickAccess,
                    {...action.payload}
                ]
            }

        case acts.UPDATE_VACANCY:
            return {
                ...state,
                list: [
                    ...state.list.map(vacancy => {
                        if (vacancy.service.id === action.payload.service.id) {
                            return action.payload
                        }
                        return vacancy
                        }
                    ),
                ],
                quickAccess: [
                    ...state.quickAccess.map(vacancy => {
                        if (vacancy.service.id === action.payload.service.id) {
                            return action.payload
                        }
                        return vacancy
                        }
                    ),
                ],
                map: {
                    ...state.map,
                    [action.payload.service.id]: action.payload
                }
            }

        case acts.SET_GROUP:
            return {
                ...state,
                list: [
                    ...state.list.map(vacancy => {
                        if (vacancy.service.id === action.payload.vacancy_id) {
                            return {...vacancy, group_id: action.payload.group_id}
                        }
                        return vacancy
                        }
                    ),
                ],
                quickAccess: [
                    ...state.quickAccess.map(vacancy => {
                        if (vacancy.service.id === action.payload.vacancy_id) {
                            return {...vacancy, group_id: action.payload.group_id}
                        }
                        return vacancy
                        }
                    ),
                ],
                map: {
                    ...state.map,
                    [action.payload.vacancy_id]: {
                        ...state.map[action.payload.vacancy_id],
                        group_id: action.payload.group_id
                    }
                }
            }

        case acts.SET_PROFILE:
            return {
                ...state,
                list: [
                    ...state.list.map(vacancy => {
                        if (vacancy.service.id === action.payload.vacancy_id) {
                            return {...vacancy, profile_id: action.payload.profile_id}
                        }
                        return vacancy
                        }
                    ),
                ],
                quickAccess: [
                    ...state.quickAccess.map(vacancy => {
                        if (vacancy.service.id === action.payload.vacancy_id) {
                            return {...vacancy, profile_id: action.payload.profile_id}
                        }
                        return vacancy
                        }
                    ),
                ],
                map: {
                    ...state.map,
                    [action.payload.vacancy_id]: {
                        ...state.map[action.payload.vacancy_id],
                        group_id: action.payload.profile_id
                    }
                }
            }
    
        case acts.SET_STATUS:
            return {
                ...state,
                list: [
                    ...state.list.map(vacancy => {
                        if (vacancy.service.id === action.payload.vacancyId) {
                            return {...vacancy, status: action.payload.statusType}
                        }
                        return vacancy
                        }
                    ),
                ],
                quickAccess: [
                    ...state.quickAccess.map(vacancy => {
                        if (vacancy.service.id === action.payload.vacancyId) {
                            return {...vacancy, status: action.payload.statusType}
                        }
                        return vacancy
                        }
                    ),
                ],
                map: {
                    ...state.map,
                    [action.payload.vacancyId]: {
                        ...state.map[action.payload.vacancyId],
                        status: action.payload.statusType
                    }
                }
            }

        case acts.SET_STATUS_TYPES:
            return {
                ...state,
                statusTypes: action.payload || []
            }
    
        case acts.SET_FULLTEXT_SEARCH_VALUE:
            return {
                ...state,
                fulltextSearchValue: action.payload
            }
    
        case acts.SET_SEARCHED_LIST:
            return {
                ...state,
                map: {...state.map, ...action.payload.map},
                allIds: [...state.allIds, ...action.payload.allIds?.filter(id => !state.allIds.includes(id))],
                searchedIds: action.payload.allIds,
            }

        case acts.APPEND_SEARCHED_LIST:
            return {
                ...state,
                map: {...state.map, ...action.payload.map},
                allIds: [...state.allIds, ...action.payload.allIds?.filter(id => !state.allIds.includes(id))],
                searchedIds: [...state.searchedIds, ...action.payload.allIds],
            }
        default:
            return state;
    }
}

export default reducer;

export const getVacancies = (state) => state.vacancies.list;
export const getVacanciesMap = (state) => state.vacancies.map;
export const getVacanciesAllIds = (state) => state.vacancies.allIds;
export const getFulltextSearchValue = (state) => state.vacancies.fulltextSearchValue;