const prefix = "RELATIONS::";

export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_LIST = `${prefix}SET_LIST`;
export const APPEND_LIST = `${prefix}ADD_LIST`;
export const ADD_RELATION_AND_STAGE = `${prefix}ADD_RELATION_AND_STAGE`;
export const BULK_ADD_RELATION_AND_STAGE = `${prefix}BULK_ADD_RELATION_AND_STAGE`;
export const DELETE = `${prefix}DELETE`;

//stages
export const SET_STAGES = `${prefix}SET_STAGES`;
export const APPEND_STAGES = `${prefix}APPEND_STAGES`;
export const ADD_STAGE = `${prefix}ADD_STAGE`;
export const DELETE_STAGE = `${prefix}DELETE_STAGE`;

//stage types
export const SET_STAGE_TYPES = `${prefix}SET_STAGE_TYPES`;
export const ADD_STAGE_TYPE = `${prefix}ADD_STAGE_TYPE`;
export const UPDATE_STAGE_TYPE = `${prefix}UPDATE_STAGE_TYPE`;
export const DELETE_STAGE_TYPE = `${prefix}DELETE_STAGE_TYPE`;

// processing
export const SET_LIST_PROC_CANCEL_REASONS = `${prefix}SET_LIST_PROC_CANCEL_REASONS`
export const APPEND_LIST_PROC_CANCEL_REASONS = `${prefix}APPEND_LIST_PROC_CANCEL_REASONS`
