import React, { useState } from 'react';
import { IconUi } from '../../../../components/IconUi';
import PopupUi from '../../../../components/PopupUi';
import ButtonUi from '../../../../components/StyledButtonUi';
import { Relation, StageType } from '../../../../entities/relation';
import { User } from '../../../../entities/user';
import { Vacancy } from '../../../../entities/vacancy';

import InputSelect from '../../../../components/InputSelectUi';

import { ProcessingCancelReason } from '../../../../entities/processing';

import * as procApi from '../../../../redux/relations/api';

import './Relation.scss';
import ModalUi from '../../../../components/ModalUi';
import { sortStagesListBySpecificOrder } from 'utils/stages';

const AwayStageType = 3;

type RelationProps = {
    relation: Relation;
    usersMap: {
        [userId: number]: User;
    },
    vacancy: Vacancy & { hiringManagerName?: string };
    stageTypesAllIds: Array<number>;
    stageTypesMap: {
        [stageTypeId: number]: StageType;
    },
    lastStageType: StageType;
    onChangeStage: ({ relation_id, stage_type_id }: { relation_id: number, stage_type_id: number }) => void;

    onCancelProcessing: ({
        processing_id,
        relation_id,
        initiator,
        cancelled_at,
        reason_id,
        comment,
    }: {
        processing_id: number,
        relation_id: number,
        initiator: string,
        cancelled_at: Date,
        reason_id: number | string,
        comment: string,
    }) => void

    processingCancelReasons: Array<ProcessingCancelReason>

    onDeleteRelation: (relationId: number) => void;
}

export const cancelProcessingInitiatorsOptions = [
    { value: "candidate", label: "Candidate" },
    { value: "recruiter", label: "Recruiter" },
    { value: "manager", label: "Manager" },
]

const RelationCard = ({
    relation,
    vacancy,
    stageTypesAllIds,
    stageTypesMap,
    lastStageType,
    onChangeStage,

    onCancelProcessing,
    processingCancelReasons,

    onDeleteRelation
}: RelationProps) => {

    const [chosenStageType, setChosenStageType] = useState<number | null>(null);

    const [openCancelForm, setOpenCancelProcessingForm] = useState<boolean>(false);

    const [cancelFormState, setCancelFormState] = useState<{
        initiator: string,
        cancelled_at: string,
        reason: string,
        reasonId: number | string,
        comment: string,
    }>({
        initiator: "candidate",
        cancelled_at: `${new Date().getFullYear()}-${`${new Date().getMonth() +
            1}`.padStart(2, "0")}-${`${new Date().getDate() + 1}`.padStart(
                2,
                "0"
            )}T${`${new Date().getHours()}`.padStart(
                2,
                "0"
            )}:${`${new Date().getMinutes()}`.padStart(2, "0")}`,
        reason: "",
        reasonId: 0,
        comment: "",
    })

    return (
        <div className={'relation'}>
            <div className={'relation-top'}>
                <div className={'relation-group'}>
                    {
                        vacancy.group?.name
                        ||
                        'No group'
                    }
                </div>
                <div className={'relation-hiring-manager'}>
                    {
                        vacancy.hiringManagerName
                        ||
                        'No hiring manager'
                    }
                </div>
                <div className={'relation-delete-button'}>
                    <ButtonUi onClick={() => onDeleteRelation(relation.id)}>
                        <IconUi name='close' />
                    </ButtonUi>
                </div>
            </div>
            <div className='relation-bottom'>
                <div className={'relation-vacancy'} title={vacancy.description || ""}>
                    {vacancy.name}
                </div>

                <div className={'relation-stage'}>
                    <PopupUi
                        trigger={
                            <div>
                                <button
                                    className={'relation-stage-name'}
                                    style={{ borderColor: (chosenStageType && stageTypesMap[chosenStageType].color) || lastStageType?.color }}
                                    title={lastStageType?.name ? 'Update stage' : 'Add stage'}
                                >
                                    {(chosenStageType && stageTypesMap[chosenStageType].name) || lastStageType?.name || 'Add stage'}
                                </button>
                                {chosenStageType &&
                                    <button
                                        className={'relation-stage-confirm-button'}
                                        onClick={() => {

                                            onChangeStage({ relation_id: relation.id, stage_type_id: chosenStageType })
                                            setChosenStageType(null)

                                        }}
                                        title={'Confirm update'}
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        }
                        on={'click'}
                        wide='very'
                    >
                        <div className={'relation-stage-dropdown'}>
                            {
                                sortStagesListBySpecificOrder(stageTypesAllIds).map((stId, idx) => (
                                    <div
                                        onClick={() => {
                                            if (lastStageType && lastStageType.id === stId) {

                                                setChosenStageType(null)

                                            } else {

                                                if (stId === AwayStageType) {
                                                    // открыть форму завершения процессинга
                                                    setOpenCancelProcessingForm(true)


                                                }

                                                setChosenStageType(stId)

                                            }
                                        }
                                        }
                                        className={
                                            `relation-stage-dropdown-item${idx === 0 ? '-first' : ''}` +
                                            (chosenStageType === stId ? ' relation-stage-dropdown-item-chosen' : '') +
                                            (lastStageType?.id === stId ? ' relation-stage-dropdown-item-current' : '')
                                        }
                                        key={stId}
                                    >
                                        {stageTypesMap[stId].name}
                                    </div>
                                ))
                            }
                        </div>
                    </PopupUi>
                </div>
            </div>

            <ModalUi
                header={"Cancel processing"}
                open={openCancelForm}
            >
                <div className="processing-cancel-reason-form">
                    <div>
                        Cancel processing form
                    </div>
                    <div>
                        Who is initiator?
                        <select name="select-cancel-initiator" id="select-cancel-initiator-id" value={cancelFormState.initiator}
                            onChange={(e: any) => setCancelFormState({ ...cancelFormState, initiator: e.target.value })}>

                            {cancelProcessingInitiatorsOptions.map((option) => (
                                <option selected={cancelFormState.initiator === option.value} key={option.value} value={option.value}>{option.label}</option>
                            ))}

                        </select>
                    </div>
                    <div>
                        Cancel at
                        <input
                            type="datetime-local" name="input-datetime-local" id="input-datetime-local-id"
                            onChange={(e) => setCancelFormState({ ...cancelFormState, cancelled_at: e.target.value })} />
                    </div>
                    <div>
                        Reason
                        <InputSelect
                            onSelect={(opt) => {
                                setCancelFormState({
                                    ...cancelFormState,
                                    reasonId: opt.value,
                                })
                            }}
                            loadOptions={async (text) => {
                                if (!processingCancelReasons) {
                                    return []
                                }

                                return processingCancelReasons
                                    .filter((val) => val.reason.toLowerCase().includes(text.toLowerCase()))
                                    .map((val) => ({ value: val.id, label: val.reason }))
                            }}

                            withCreate={{
                                title: "Create",
                                handler: async (reason) => {

                                    const data = await procApi.createProcessingCancelReason({ reason })

                                    setCancelFormState({
                                        ...cancelFormState,
                                        reasonId: data.id,
                                    })

                                    return { value: data.id, label: reason }
                                }
                            }}

                            placeholder={"Cancel reason..."}
                            autoFocus
                        />
                    </div>

                    <div>
                        <ButtonUi onClick={() => {
                            onCancelProcessing({
                                processing_id: relation.hack_processing_id,
                                relation_id: relation.id,

                                initiator: cancelFormState.initiator,
                                cancelled_at: new Date(cancelFormState.cancelled_at),
                                reason_id: cancelFormState.reasonId,
                                comment: cancelFormState.comment,
                            })

                            setChosenStageType(null)

                            setOpenCancelProcessingForm(false)
                        }}>
                            Cancel processing
                        </ButtonUi>
                    </div>
                </div>
            </ModalUi>

        </div>
    )
}

export default RelationCard;