import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';
import { ErrorUnauthorized } from '../../errors'; 
import { arrToMapAndIdsByServiceId, debounce, excludeEmptyFields } from '../../utils';
import { getFulltextSearchValue } from './reducer';

const setCanLoadMore = (data) => ({
    type: acts.SET_CAN_LOAD_MORE,
    payload: data
})

const setLastCreatedId = (data) => ({
    type: acts.SET_LAST_CREATED_ID,
    payload: data
})

const appendVacanciesList = (data) => ({
    type: acts.APPEND_LIST,
    payload: data,
})

const setChosenVacancyId = (id) => ({
    type: acts.SET_CHOSEN_ID,
    payload: id,
})

const setVacanciesList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
})

const setVacanciesLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
})

const addVacancyToList = (data) => ({
    type: acts.ADD_VACANCY_TO_LIST,
    payload: data,
})

const addVacancyToQuickAccessList = (data) => ({
    type: acts.ADD_VACANCY_TO_QUICK_ACCESS_LIST,
    payload: data,
});

const updateVacancyInList = (data) => ({
    type: acts.UPDATE_VACANCY,
    payload: data,
})

const updateVacancyInSelected = (data) => ({
    type: acts.UPDATE_SELECTED_VACANCY,
    payload: data
})

const deleteVacancyFromSelected = (id) => ({
    type: acts.DELETE_SELECTED_VACANCY,
    payload: id
})

const setGroupInList = (data) => ({
    type: acts.SET_GROUP,
    payload: data
})

const setProfileInList = (data) => ({
    type: acts.SET_PROFILE,
    payload: data
})
const appendVacanciesSearchedList = (data) => ({
    type: acts.APPEND_SEARCHED_LIST,
    payload: data,
});

const setVacanciesSearchedList = (data) => ({
    type: acts.SET_SEARCHED_LIST,
    payload: data,
})

const setVacanciesFulltextSearchValue = (data) => ({
    type: acts.SET_FULLTEXT_SEARCH_VALUE,
    payload: data,
})

const setVacancyStatus = (data) => ({
    type: acts.SET_STATUS,
    payload: data
})

const setStatusTypes = (data) => ({
    type: acts.SET_STATUS_TYPES,
    payload: data
})

export const updateSelected = ({dispatch, id, data}) => dispatch(updateVacancyInSelected({id, data}));

export const deleteSelected = (dispatch, id) => dispatch(deleteVacancyFromSelected(id));

export const setChosenId = ({dispatch, id}) => dispatch(setChosenVacancyId(id));

export const clearLastCreatedId = (dispatch) => dispatch(setLastCreatedId(null));

export const loadList = async (dispatch) => {
    dispatch(setVacanciesLoading(true)); 

    const data = await api.getVacancies();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(setVacanciesList(data || []));

    dispatch(setVacanciesLoading(false));
}

export const load = async (dispatch, id) => {
    dispatch(setVacanciesLoading(true));

    const data = await api.getVacancies(id);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setVacanciesLoading(false));
            return
        }
        dispatch(setVacanciesLoading(false));
        throw data;
    }

    appendVacanciesList(dispatch, data? [data]: [])

    dispatch(setVacanciesLoading(false));
}

export const setGroup = async ({dispatch, vacancy_id, group_id}) => {
    const err = await api.setGroup({vacancy_id, group_id});
    if (err instanceof Error) {
        if (err.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw err;
    }

    dispatch(setGroupInList({ vacancy_id, group_id }))
}


export const setProfile = async ({dispatch, vacancy_id, profile_id}) => {
    const err = await api.setProfile({vacancy_id, profile_id});
    if (err instanceof Error) {
        if (err.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw err;
    }

    dispatch(setProfileInList({ vacancy_id, profile_id }))
}

export const add = async (dispatch, data, {list=true, quickAccess=true}={list: true, quickAccess: true}) => {
    
    let preparedData = excludeEmptyFields(data);

    const retrievedVacancyData = await api.addVacancy(preparedData);

    if (retrievedVacancyData instanceof Error) {
        if (retrievedVacancyData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw retrievedVacancyData;
    }

    list && dispatch(addVacancyToList(retrievedVacancyData));
    quickAccess && dispatch(addVacancyToQuickAccessList(retrievedVacancyData));

    dispatch(setLastCreatedId(retrievedVacancyData.service.id));

    data.group && setGroup({
        dispatch, 
        vacancy_id: retrievedVacancyData.service.id, 
        group_id: preparedData.group.id
    });

    data.profile && setProfile({
        dispatch, 
        vacancy_id: retrievedVacancyData.service.id, 
        profile_id: preparedData.profile.id
    })
}


export const update = async (dispatch, data) => {
    const preparedData = excludeEmptyFields(data);

    let updateData = await api.updateVacancy(preparedData);

    if (updateData instanceof Error) {
        if (updateData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw updateData;
    }

    dispatch(updateVacancyInList(preparedData));
}

// TODO: handle setGroup action
// export const setGroup = async (dispatch, {vacancy_id, group_id}) => {
//     const err = await api.setGroup({vacancy_id, group_id});

//     if (err != null) {
//         if (err instanceof Error) {
//             if (err.message === ErrorUnauthorized) {
//                 dispatch(signout());
//                 return
//             }
//         }

//         throw err;
//     }

//     dispatch(updateVacancyInList(preparedData));
// }

export const setFulltextSearchValue = ({dispatch, value}) => {
    dispatch(setVacanciesFulltextSearchValue(value));
};


export const startSearch = async ({ dispatch, limit, searchText }) => {
    dispatch(setVacanciesLoading(true)); 

    const data = await api.getVacancies({ limit, offset: 0, searchText });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }
    
    if (data === null) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }
    
    const normalizedData = arrToMapAndIdsByServiceId(data || []);

    dispatch(setVacanciesSearchedList(normalizedData));
    

    dispatch(setVacanciesLoading(false));
}

export const debouncedStartSearch = debounce(startSearch, 300);

export const search = ({ dispatch, limit, offset }) => dispatch(async (dispatch, getState) => {
    dispatch(setVacanciesLoading(true)); 

    const searchText = getFulltextSearchValue(getState());

    const data = await api.getVacancies({ limit, offset, searchText });

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }
    
    if (data === null) {
        dispatch(setCanLoadMore(false));
    } else {
        dispatch(setCanLoadMore(true));
    }

    const normalizedData = arrToMapAndIdsByServiceId(data || []);

    dispatch(appendVacanciesSearchedList(normalizedData));

    dispatch(setVacanciesLoading(false));
});

export const loadStatusTypes = async (dispatch) => {
    dispatch(setVacanciesLoading(true)); 

    const data = await api.getStatusTypesList();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(setStatusTypes(data || []));

    dispatch(setVacanciesLoading(false));
}

export const setStatus = async ({dispatch, vacancyId, statusType}) => {
    dispatch(setVacanciesLoading(true)); 

    const data = await api.setStatus({vacancyId, statusType});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw data;
    }

    dispatch(setVacancyStatus({vacancyId, statusType}));

    dispatch(setVacanciesLoading(false));
}