import React from 'react';
import { TextArea, Header, Form } from 'semantic-ui-react';

const Description = ({ content, editable, onChange }) => {

    const onChangeTextArea = (e) => {
        onChange && onChange(e)
    }

    return (
        <>
            <Header>Description</Header>
            {editable ?
                <Form>
                    <TextArea onChange={onChangeTextArea} value={content}/>
                </Form>
                :
                <pre>
                    {content}
                </pre>
            }
        </>
    )
}

export default Description;