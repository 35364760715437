import React, { useState } from 'react';
import ButtonUi from '../ButtonUi';
import { IconUi } from '../IconUi';
import InputUi from '../InputUi';
import { LabelDetailUi, LabelUi } from '../LabelUi';


export const FilterInputsUi = ({optionsList, propsToFilterBy, onChange, onDelete, onAdd }) => {

    const [showInput, setShowInput] = useState(false);
    const [option, setOption] = useState({ value: '', prop: '' });

    const onClickShowIcon = () => {
        setShowInput(true);
    }

    const onClickCloseIcon = () => {
        setShowInput(false);
    }

    const onChangeInput = (e) => {
        onChange && onChange({...option, value: e.target.value});
        setOption(option => ({...option, value: e.target.value}));
    }

    const onChangeSelect = (e) => {
        onChange && onChange({...option, prop: e.target.value});
        setOption(option => ({...option, prop: e.target.value}));
    }

    const onAddFilter = () => {
        onAdd && onAdd(option);
        setOption({ value: '', prop: '' });
    }

    const onDeleteFilter = (index) => {
        onDelete && onDelete(index);
    }

    const renderSelectOption = (prop) => {
        if (prop.children) {
            return (
                <optgroup label={prop.label} key={prop.value}>
                    {prop.children.map(o => renderSelectOption(o))}
                </optgroup>
            )
        } else {
            return (
                <option key={prop.value} value={prop.value}>
                    {prop.label}
                </option>
            )
        }
    }

    return (
        <>
            {optionsList && Object.values(optionsList).map((o, idx) => 
                <LabelUi key={idx}>
                    {o.value}
                    <LabelDetailUi content={o.prop?.child?.propName || o.prop?.propName} />
                    <IconUi onClick={() => onDeleteFilter(idx)} name='close'/>
                </LabelUi>  
            )}
            {showInput ?
                <>
                    <InputUi action onChange={onChangeInput} value={option.value} placeholder='search'>
                        <input />
                        <select value={option.prop} onChange={onChangeSelect}>
                            {[{key: 'all', value: '', label: 'all'}, ...propsToFilterBy].map(o => renderSelectOption(o))}
                        </select>
                        <ButtonUi 
                            icon={(option.value || option.prop) ? 'add' : 'close'} 
                            onClick={(option.value || option.prop) ? onAddFilter : onClickCloseIcon}
                        />
                    </InputUi>
                </>
                :
                <ButtonUi 
                    title='filter search'
                    icon='filter' 
                    onClick={onClickShowIcon}
                />
            }
        </>
    )
}

export default FilterInputsUi