import React from 'react';
import { IconUi } from '../../../../components/IconUi';
import ButtonUi from '../../../../components/StyledButtonUi';
import { Vacancy } from '../../../../entities/vacancy';

import './VacancyCard.scss';

type VacancyCardProps = {
    vacancy: Vacancy & {hiringManagerName?: string}
    onClickDuplicateIcon?: (vacancy: Vacancy & {hiringManagerName?: string}) => void
    onClickDiscardIcon?: () => void
}

const VacancyCard = ({
    vacancy,
    onClickDuplicateIcon,
    onClickDiscardIcon,
}: VacancyCardProps) => {

    return (
        <div className={'vacancy-card'}>
            <div className={'vacancy-card-content'}>
                <div className={'vacancy-card-info'}>
                    <div className={'vacancy-card-name'}>
                        {vacancy.name}
                    </div>
                    <div className={'vacancy-card-group'}>
                        {
                            vacancy.group?.name
                            ||
                            'No group'
                        }
                    </div>
                    <div className={'vacancy-card-hiring-manager'}>
                        {
                            vacancy.hiringManagerName
                            ||
                            'No hiring manager'
                        }
                    </div>
                </div>
                <div className='vacancy-card-description'>
                    <pre>
                        {vacancy.description}
                    </pre>
                </div>
            </div>
            <div className='vacancy-card-toolbar'>
                {onClickDuplicateIcon &&
                    <div className='vacancy-card-toolbar'>
                        <ButtonUi 
                            onClick={() => onClickDuplicateIcon(vacancy)}
                        >
                            <IconUi name='copy'/>
                        </ButtonUi>
                    </div>
                }
                {onClickDiscardIcon &&
                    <div className='vacancy-card-toolbar'>
                        <ButtonUi 
                            onClick={() => onClickDiscardIcon()}
                        >
                            <IconUi name='trash'/>
                        </ButtonUi>
                    </div>
                }
            </div>
        </div>
    )
}

export default VacancyCard;