import React from 'react';
import { Icon } from 'semantic-ui-react';


export const IconUi = ({...attributes}) => {
    return <Icon {...attributes} />
}

export const IconGroupUi = ({children, ...attributes}) => {
    return <Icon.Group {...attributes}>
        {children}
    </Icon.Group>
}