let cacheStorage = {...JSON.parse(localStorage.getItem("cache"))} || {};


export const cache = (category, url, data, toLocalStorage=false) => {

    if (data) {
        cacheStorage[category] = {...cacheStorage[category], [url]: data};
        if (toLocalStorage) {
            localStorage.setItem("cache", JSON.stringify(cacheStorage));
        }
    } else {
        if (cacheStorage[category] && cacheStorage[category][url]) {
            return cacheStorage[category][url]
        } else {
            return null
        }
    }
}