import React from 'react'
import { Comment, Header } from 'semantic-ui-react'

const CommentGroupUi = ({
    children,
}) => {
    return (
        <Comment.Group
            threaded
        >
            <Header textAlign="left" dividing>
                Comments
            </Header>

            {/* list of comments */}
            {children}

        </Comment.Group>
    )
}

export default CommentGroupUi
