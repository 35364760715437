import axios from "axios";
import { ErrorUnauthorized } from '../../errors';

const rootRef = "/matches/api/v1";
const sharedRef = "/shared";

export const shareFile = async (file_id) => {
    
    const url = `${rootRef}${sharedRef}/${file_id}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}