import React from "react";
import BarChartUi from "../../../components/ChartUi/BarChartUi";

type UsersChartProps = {
    data: Array<{name: string, values: {[stageTypeName: string]: number}}>;
    stageTypes: Array<{id: number, name: string, show: boolean}>;
    onClickStageTypeLegend: (id: number | string) => void;
}

const UsersChart = ({
    data,
    stageTypes,
    onClickStageTypeLegend
}: UsersChartProps) => {
    return (
        <BarChartUi 
            data={data}
            title='Users'
            XAxisData={stageTypes}
            onClickLegend={onClickStageTypeLegend}
            stacked={true}
        />
    )
}

export default UsersChart;