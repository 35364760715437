import React from 'react';
import { Card } from 'semantic-ui-react';

const CardUi = ({children, ...attributes}) => {

    return (
        <Card fluid>
            <Card.Content {...attributes} >
                {children}
            </Card.Content>
        </Card>
    )
}

export default CardUi