import React from 'react';
import { Label } from 'semantic-ui-react';

export const LabelDetailUi = ({content}) => {
    return (
        <Label.Detail>
            {content}
        </Label.Detail>
    )
}

export const LabelUi = ({content, children}) => {
    return (
        <Label>
            {content}
            {children}
        </Label>
    )
}
