import React from 'react';

import { Message } from 'semantic-ui-react';

const MessageUi = ({header, content, size='small', color=''}) => {
    return <Message
        header={header}
        content={content}
        size={size}
        color={color}
    />
}

export default MessageUi;