import axios from 'axios';
import { ErrorUnauthorized } from '../../errors';

const rootRef = "/matches/api/v1";
const votesRef = "/votes";

export const listVotes = async () => {
    const url = `${rootRef}${votesRef}`;
    
    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}


export const listVotesByPersonId = async (person_id) => {
    const url = `${rootRef}${votesRef}/person/${person_id}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}


export const listVotersByVoteId = async (vote_id) => {
    const url = `${rootRef}${votesRef}/vote/${vote_id}/voters`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}


export const createVote = async ({user_ids, vacancy_id, person_id, file_id, comment}) => {
    try {
        const response = await axios(
            `${rootRef}${votesRef}`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                data: {
                    person_id: person_id,
                    vacancy_id: vacancy_id,
                    user_ids: user_ids,
                    file_id: file_id,
                    comment: comment,
                },
            },
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}


export const closeVoteById = async (vote_id) => {
    const url = `${rootRef}${votesRef}/${vote_id}`;

    try {
        const response = await axios.put(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

