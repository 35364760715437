import axios from 'axios';
import { ErrorUnauthorized } from '../../errors';

const rootRef = "/matches/api/v1";
const metadataRef = "/metadata";

const usersRef = "/users";


export const getUser = async () => {

    const url = `${rootRef}${metadataRef}${usersRef}`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}