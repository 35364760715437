import React, { useEffect, useContext, useCallback, useMemo, useState } from 'react';

import { store } from '../../store';

import './Users.scss';

import * as usersActions from '../../redux/users/actions';
import * as grActions from '../../redux/groups/actions';

import { socialsList, zeroUserWithSocials } from '../../user';
import { arrToMap, deepEqual } from '../../utils';
import { handleError } from '../../errorHandler';

import FormInputUi from '../../components/NewInputUi';
import InputUi from '../../components/InputUi';
import { IconUi } from '../../components/IconUi';
import SelectUi from '../../components/SelectUi';
import { TableUi, TableUiRow, TableUiHeader, TableUiHeaderCell, TableUiBody, TableUiCell } from '../../components/TableUi';
import ButtonUi from '../../components/ButtonUi';


const UsersPage = () => {

    const { state, dispatch } = useContext(store);

    const {
        users: {
            list,
            socialsByUserId,
            selected,
            selectedErrors,
            rolesMap,
            rolesAllTypes
        },
        groups: {
            list: groupsList
        }
    } = state;

    const [currentSocialNetworkKind, setCurrentSocialNetworkKind] = useState('')

    useEffect(() => {
        usersActions.loadList(dispatch)
            .catch(err => handleError(err));

        usersActions.loadSocialsList(dispatch)
            .catch(err => handleError(err));

        grActions.loadList(dispatch)
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeField = useCallback(({ e, id, data }) => {
        usersActions.updateSelected({
            dispatch, 
            id, 
            data: data ? 
                data  
                : 
                {[e.target.name]: e.target.value}   
        })
    }, [dispatch]);

    const onChangeSocialsField = useCallback(({ user, social, data}) => {
        usersActions.updateSelectedSocials({
            dispatch, 
            user,
            social,
            data 
        })
    }, [dispatch]);

    const groupsById = useMemo(() => {
        return arrToMap(groupsList);
    }, [groupsList]);

    const checkForErrors = useCallback(({value, validateOptions, userId, fieldName}) => 
        usersActions.checkForErrors({ dispatch, value, validateOptions, id: userId, fieldName}), [dispatch])
    const onSubmit = useCallback((data) => {
        data.service.id === 'new' ?
            usersActions.add({dispatch, user: {...data, service: undefined}})
                .then(() => setCurrentSocialNetworkKind(null))
                .catch(err => handleError(err))
            :
            usersActions.update({dispatch, user: data})
                .then(() => setCurrentSocialNetworkKind(null))
                .catch(err => handleError(err));
    }, [dispatch]);
    const onDiscard = useCallback(() => {
        usersActions.discard(dispatch);
        setCurrentSocialNetworkKind(null);
    },[dispatch]);
    const onEdit = useCallback((data) => usersActions.select({ dispatch, user: data }), [dispatch]);
    const onOpenAddForm = useCallback(() => usersActions.select({ dispatch, user: {...zeroUserWithSocials, service: {id: 'new'}}}), [dispatch]);
    const onDeleteSocial = useCallback(({user_id, socialId}) => usersActions.deleteSocial({dispatch, user_id, socialId}), [dispatch]);

    return (
        <div className='users'>
                <div className='users-table'>

                <TableUi selectable={true} fixed>
                    <TableUiHeader>   
                        <TableUiRow>
                            <TableUiHeaderCell>
                                Surname
                            </TableUiHeaderCell>
                            <TableUiHeaderCell>
                                Name
                            </TableUiHeaderCell>
                            <TableUiHeaderCell>
                                Midname
                            </TableUiHeaderCell>
                            <TableUiHeaderCell>
                                Cell
                            </TableUiHeaderCell>
                            <TableUiHeaderCell>
                                Email
                            </TableUiHeaderCell>
                            <TableUiHeaderCell>
                                Group
                            </TableUiHeaderCell>
                            <TableUiHeaderCell>
                                Socials
                            </TableUiHeaderCell>
                            <TableUiHeaderCell>
                                Roles
                            </TableUiHeaderCell>
                            <TableUiHeaderCell width={1}>
                                <IconUi 
                                    name="add"
                                    onClick={onOpenAddForm}
                                />
                            </TableUiHeaderCell>
                        </TableUiRow>
                    </TableUiHeader>
                    <TableUiBody>
                        {
                            (selected.new ?
                                [
                                    {...zeroUserWithSocials, service: {id: 'new'}},
                                    ...(list || [])
                                ]
                                :
                                (list || [])).map(u => {
                                const data = selected[u.service.id] || u;
                                const editable = selected[u.service.id];
                                const errors = selectedErrors[u.service.id];
                                const hasErrors = errors && Object.keys(errors).length !== 0;
                                const isPristine = deepEqual(u, data);
                                const disableSubmit = hasErrors || isPristine || !data.surname || !data.name;
                                const currentSocial = data.socials?.find(s => s.kind === currentSocialNetworkKind);
                                return (
                                    <TableUiRow key={u.service.id}>
                                        <TableUiCell verticalAlign='top'>
                                            <FormInputUi
                                                name='surname'
                                                value={data.surname}
                                                edit={editable}
                                                onChange={
                                                    (e) => onChangeField({
                                                        e,
                                                        id: data.service.id
                                                    })
                                                }
                                                errors={errors && errors.surname}
                                                checkForErrors={(value) =>
                                                    checkForErrors({
                                                        value,
                                                        validateOptions: { type: 'string', required: true, size: 255 },
                                                        fieldName: 'surname',
                                                        userId: data.service.id
                                                    })}
                                                required
                                                transparent
                                            />
                                        </TableUiCell>
                                        <TableUiCell verticalAlign='top'>
                                            <FormInputUi
                                                name='name'
                                                value={data.name}
                                                id={data.service.id}
                                                edit={editable}
                                                onChange={
                                                    (e) => onChangeField({
                                                        e,
                                                        id: data.service.id
                                                    })
                                                }
                                                errors={errors && errors.name}
                                                checkForErrors={(value) =>
                                                    checkForErrors({
                                                        value,
                                                        validateOptions: { type: 'string', required: true, size: 255 },
                                                        fieldName: 'name',
                                                        userId: data.service.id
                                                    })}
                                                required
                                                transparent
                                            />
                                        </TableUiCell>
                                        <TableUiCell verticalAlign='top'>
                                            <FormInputUi
                                                name='midname'
                                                value={data.midname}
                                                id={data.service.id}
                                                edit={editable}
                                                onChange={
                                                    (e) => onChangeField({
                                                        e,
                                                        id: data.service.id
                                                    })
                                                }
                                                errors={errors && errors.midname}
                                                checkForErrors={(value) =>
                                                    checkForErrors({
                                                        value,
                                                        validateOptions: { type: 'string', required: false, size: 255 },
                                                        fieldName: 'midname',
                                                        userId: data.service.id
                                                    })}
                                                    transparent
                                            />
                                        </TableUiCell>
                                        <TableUiCell verticalAlign='top'>
                                            <FormInputUi
                                                name='cell'
                                                value={data.cell}
                                                edit={editable}
                                                onChange={
                                                    (e) => onChangeField({
                                                        e,
                                                        id: data.service.id
                                                    })
                                                }
                                                errors={errors && errors.cell}
                                                checkForErrors={(value) =>
                                                    checkForErrors({
                                                        value,
                                                        validateOptions: { type: 'tel', required: true, size: 255 },
                                                        fieldName: 'cell',
                                                        userId: data.service.id
                                                    })}
                                                    transparent
                                            />
                                        </TableUiCell>
                                        <TableUiCell verticalAlign='top'>
                                            <FormInputUi
                                                name='email'
                                                value={data.email}
                                                id={data.service.id}
                                                edit={editable}
                                                onChange={
                                                    (e) => onChangeField({
                                                        e,
                                                        id: data.service.id
                                                    })
                                                }
                                                errors={errors && errors.email}
                                                checkForErrors={(value) =>
                                                    checkForErrors({
                                                        value,
                                                        validateOptions: { type: 'email', required: true, size: 255 },
                                                        fieldName: 'email',
                                                        userId: data.service.id
                                                    })}
                                                    transparent
                                            />
                                        </TableUiCell>
                                        <TableUiCell style={{overflow: 'visible'}} verticalAlign='top'>
                                            {editable ?
                                                <SelectUi
                                                    name='group_id'
                                                    value={data.group_id || ''}
                                                    id={data.service.id}
                                                    onChange={
                                                        (e, {value}) => onChangeField({
                                                            e,
                                                            id: data.service.id,
                                                            data: {group_id: value}
                                                        })
                                                    }
                                                    options={groupsList.map(g => ({value: g.id, text: g.name}))}
                                                    fluid
                                                />
                                                :
                                                <div>
                                                    {groupsById[data.group_id]?.name}
                                                </div>
                                            }
                                        </TableUiCell>
                                        <TableUiCell verticalAlign='top' style={{overflow: 'visible'}}>
                                            {
                                                editable ?
                                                    <>
                                                        <SelectUi 
                                                            fluid 
                                                            options={socialsList.map(s => ({text: s, value: s}))}
                                                            placeholder="social network"
                                                            onChange={(e, { value }) => setCurrentSocialNetworkKind(value)}
                                                        />
                                                        <InputUi 
                                                            fluid 
                                                            name='ref'
                                                            placeholder="social link"
                                                            disabled={!currentSocialNetworkKind}
                                                            value={currentSocial?.ref || ''}
                                                            onChange={(e) => onChangeSocialsField({
                                                                e,
                                                                user: data,
                                                                social: currentSocialNetworkKind,
                                                                data: {ref: e.target.value}
                                                            })}
                                                        />
                                                        <InputUi 
                                                            fluid 
                                                            name='uniq_id'
                                                            placeholder="social id"
                                                            disabled={!currentSocialNetworkKind}
                                                            value={currentSocial?.uniq_id || ''}
                                                            onChange={(e) => onChangeSocialsField({
                                                                e,
                                                                user: data,
                                                                social: currentSocialNetworkKind,
                                                                data: {uniq_id: e.target.value}
                                                            })}
                                                        />
                                                        {currentSocial?.service?.id && 
                                                            <ButtonUi 
                                                                onClick={() => 
                                                                    onDeleteSocial({
                                                                        user_id: data.service?.id,
                                                                        socialId: currentSocial.service?.id
                                                                    })
                                                                }
                                                                fluid
                                                            >
                                                                Delete
                                                            </ButtonUi>
                                                        }
                                                    </>
                                                    :
                                                    socialsByUserId[data.service.id]?.map(s =>
                                                        <IconUi size='big' key={s.service.id} name={s.kind}/>
                                                    ) || ''
                                            }
                                        </TableUiCell>
                                        <TableUiCell style={{overflow: 'visible'}} verticalAlign='top'>
                                            {editable ?
                                                <SelectUi
                                                    name='role'
                                                    value={data.role || ''}
                                                    id={data.service.id}
                                                    onChange={
                                                        (e, {value}) => onChangeField({
                                                            e,
                                                            id: data.service.id,
                                                            data: {role: value}
                                                        })
                                                    }
                                                    options={rolesAllTypes.map(r => ({value: r, text: rolesMap[r]?.name}))}
                                                    fluid
                                                />
                                                :
                                                <div>
                                                    {rolesMap[data.role]?.name}
                                                </div>
                                            }
                                        </TableUiCell>
                                        <TableUiCell verticalAlign='top'>
                                            {editable && 
                                                <IconUi 
                                                    name='add' 
                                                    disabled={disableSubmit}
                                                    onClick={() => !disableSubmit && onSubmit(data)}
                                                />
                                            }
                                            <IconUi 
                                                name={editable ? 'close' : 'edit'} 
                                                onClick={() => editable ? onDiscard() : onEdit(data)}
                                            />
                                        </TableUiCell>
                                    </TableUiRow>
                                )})
                        }
                        
                    </TableUiBody>
                </TableUi>
            </div>
        </div>
    )
}

export default UsersPage;