import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


let errorStorage = [];

export const handleError = (err) => {
    toast(err.message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    errorStorage.push(err)
}