import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    userData: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            }
            
        default:
            return state;
    }
}

export default reducer;

export const getUserId = (state) => state.user.userData?.service?.id;
