import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { Icon, Button, Form } from 'semantic-ui-react';

import { store } from '../../../store';
import { deepEqual } from '../../../utils';
import { handleError } from '../../../errorHandler';
import * as personsActs from '../../../redux/persons/actions';
import * as relActs from '../../../redux/relations/actions';
import * as usersActs from '../../../redux/users/actions';
import * as vacActs from '../../../redux/vacancies/actions';
import * as wsActs from '../../../redux/websocket/actions';
import * as tagsActs from '../../../redux/tags/actions';
import * as profActs from '../../../redux/profiles/actions';

import PersonCard from '../../Persons/PersonCard';
import PersonSearchCard from '../../Persons/PersonSearchCard';
import VacanciesModal from '../../Persons/VacanciesModal';
import ConfirmModalUi from '../../../components/ConfirmModalUi';
import PersonsDuplicateModal from '../../Modals/PersonsDuplicateModal';

import InputUi from '../../../components/StyledInputUi';
import { FilterInputsUi } from '../../../components/FilterInputsUi';
import { SearchUi } from "../../../components/SearchUi";
import { ListUi } from '../../../components/VirtualizedListUi';
import { CellMeasurerCacheWrapper, CellMeasurerUi } from '../../../components/CellMeasurerUi';
import { InfiniteLoaderUi } from '../../../components/InfiniteLoaderUi';
import { WindowScrollerUi } from '../../../components/WindowScroller';
import { AutoSizerUi } from '../../../components/AutoSizerUi';
import { IconGroupUi, IconUi } from '../../../components/IconUi';

import './PersonsList.scss'

import {
    personFilterFieldsTree,
    zeroPerson,
    zeroPersonSearch
} from '../../../person';
import { sortStagesListBySpecificOrder } from 'utils/stages';


const Workspace = ({ onClick }) => {
    const { state, dispatch } = useContext(store);

    const [showCandidateForm, setShowCandidateForm] = useState(false);
    const [showSearchForm, setShowSearchForm] = useState(false);
    const [vacanciesModalData, setVacanciesModalData] = useState(null);

    const [relationIdToDelete, setRelationIdToDelete] = useState(null);

    const {
        persons: {
            map,
            allIds,
            limit,
            chosenId,
            editedId,
            selected,
            selectedErrors,
            loading,
            filteredAndSortedIds,
            canLoadMore,
            filterOptions: {
                added: addedFilters,
            },
            fulltextSearchValue,
            searchOptions,
            searchByVacancyId,
            searchByStageTypeId,
            totalCountBySearch,
            duplicateIds
        },
        users: {
            map: usersMap,
            allIds: usersAllIds,
        },
        user: {
            userData: currentUser,
        },
        relations: {
            map: relationsMap,
            byPersonId,
            stageTypesAllIds,
            stageTypesMap,
            stagesMapByRelationId,

            processingCancelReasons,
        },
        vacancies: {
            map: vacanciesMap,
            allIds: vacanciesAllIds,
            searchedIds: vacanciesSearchedIds,
            canLoadMore: vacanciesCanLoadMore,
            fulltextSearchValue: searchByVacancyValue,
            lastCreatedId: vacanciesLastCreatedId
        },
        tags: {
            map: tagsMap,
            allIds: tagsAllIds,
        },
        profiles: {
            list: profilesList,
            map: profilesMap,
        },
        groups: {
            list: groupsList,
            map: groupsMap
        }
    } = state;

    useEffect(() => {
        usersActs.loadList(dispatch)
            .catch(err => handleError(err));

        relActs.listProcessingCancelReasons({ dispatch })
            .catch(err => handleError(err));

        wsActs.connect({ dispatch, onOpen: () => { wsActs.subscribeToVoters(dispatch) } });

        return () => {
            wsActs.disconnect(dispatch);
            vacActs.setFulltextSearchValue({ dispatch, value: '' })
        };
    }, [dispatch, limit]);

    useEffect(() => {
        personsActs.searchByTextAndBookingIdWork({ dispatch, bookedBy: currentUser.service.id })

        return () => {
            personsActs.clearFilters(dispatch);
            personsActs.clearSearch(dispatch);
        };
    }, [dispatch, limit, currentUser]);

    useEffect(() => {
        !chosenId && allIds && allIds[0] && personsActs.setChosenId(dispatch, allIds[0])
    }, [chosenId, allIds, dispatch])

    const load = (loadOffset) => {
        let offset = loadOffset || allIds.length || 0;

        if (searchByVacancyId) {
            personsActs.searchByVacancyWork({ dispatch, offset, limit, bookedBy: currentUser?.service?.id })
            return
        }

        if (searchByStageTypeId) {
            personsActs.searchByStageType({ dispatch, offset, limit, bookedBy: currentUser?.service?.id })
            return
        }

        if (searchOptions) {
            personsActs.searchWork({
                dispatch,
                offset,
                limit,
                searchOptions,
                bookedBy: currentUser?.service?.id
            })
            return
        }

        personsActs.searchByTextAndBookingIdWork({ dispatch, offset, bookedBy: currentUser.service.id, appendList: true })
    };

    const onClickEdit = useCallback((person) => {
        personsActs.select({ dispatch, person });
    }, [dispatch])

    const onChangeFilterInput = (filterOption) => {
        personsActs.filter({ dispatch, filterOption })
    };

    const onDeleteFilter = (index) => {
        personsActs.deleteFilterOption({ dispatch, index })
    }

    const onAddFilter = (option) => {
        personsActs.addFilterOption({ dispatch, option })
    }

    const relationsById = useMemo(() => {
        const byPersonIdWithNames = {};
        Object.keys(byPersonId).map(personId => {
            byPersonIdWithNames[personId] = byPersonId[personId].map((r => {
                const vacancy = vacanciesMap[r.vacancy_id];
                const stages = stagesMapByRelationId[r.id];
                return {
                    ...r,
                    vacancy_name: vacancy && vacancy.name,
                    stages: stages ? stages.sort((a, b) => a.id - b.id) : [],
                }
            }))
            return undefined
        })
        return byPersonIdWithNames
    }, [byPersonId, vacanciesMap, stagesMapByRelationId]);

    const checkForErrors = useCallback(({ value, validateOptions, personId, fieldName }) => {
        personsActs.checkForErrors({ dispatch, value, validateOptions, id: personId, fieldName });
    }, [dispatch])

    const onClickHndlr = useCallback((person) => {
        onClick && onClick(person);
    }, [onClick])

    const addPersonToSelected = useCallback((person) => {
        personsActs.updateSelected({ dispatch, id: person.service.id, data: person });
    }, [dispatch]);

    const deletePersonFromSelected = useCallback((id) => {
        personsActs.deleteSelected(dispatch, id);
    }, [dispatch]);

    const handleDiscard = useCallback((id) => {
        personsActs.discard(dispatch);
    }, [dispatch]);

    const handleFieldChange = useCallback(({ e, id, data }) => {
        personsActs.updateSelected({
            dispatch,
            id,
            data: data ?
                data
                :
                { [e.target.name]: e.target.value }
        })
    }, [dispatch]);

    const handleAdd = useCallback(() => {
        personsActs.checkForDuplicatesAndCreate(dispatch, { ...selected.new, service: undefined })
            .then(() => {
                setShowCandidateForm(false);
            })
            .catch(err => handleError(err));
    }, [dispatch, selected]);

    const handleUpdate = useCallback((data) => {
        personsActs.update(dispatch, data)
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeFulltextSearch = (e) => {
        personsActs.searchByTextAndBookingIdWork({ dispatch, value: e.target.value, bookedBy: currentUser?.service?.id })

        vacActs.setFulltextSearchValue({ dispatch, value: '' });
        setShowSearchForm(false);
    }

    const handleSearch = useCallback(({ person }) => {
        personsActs.startSearchWork({ dispatch, person, limit, bookedBy: currentUser?.service?.id })
            .catch(err => handleError(err));
        vacActs.setFulltextSearchValue({ dispatch, value: '' });
    }, [dispatch, limit, currentUser]);

    const handleSearchDiscard = () => {
        if (searchOptions) {
            personsActs.loadListWork({ dispatch, offset: 0, limit, appendList: false, bookedBy: currentUser?.service?.id })
                .catch(err => handleError(err));
        }
        personsActs.deleteSearchOptionsWork(dispatch);
        setShowSearchForm(false);
    }

    const onChangeVacanciesFulltextSearch = (searchText) => {
        vacActs.debouncedStartSearch({ dispatch, limit, searchText })
        vacActs.setFulltextSearchValue({ dispatch, value: searchText });
    }

    const onClickVacanciesFulltextSearch = (vacancyId) => {
        vacActs.setFulltextSearchValue({ dispatch, value: vacanciesMap[vacancyId]?.name });
        setShowSearchForm(false);
        personsActs.startSearchByVacancyWork({ dispatch, limit, vacancyId, bookedBy: currentUser?.service?.id })
            .catch(err => handleError(err));
    }

    const onDiscardVacanciesFulltextSearch = () => {
        if (searchByVacancyId) {
            personsActs.loadListWork({ dispatch, offset: 0, limit, appendList: false, bookedBy: currentUser?.service?.id })
                .catch(err => handleError(err));
            personsActs.setSearchByVacancyId({ dispatch, id: null });
        }
        vacActs.setFulltextSearchValue({ dispatch, value: '' });
    }

    const onClickLoadMoreVacanciesFulltextSearch = () => {
        vacanciesCanLoadMore && vacActs.search({ dispatch, limit, offset: vacanciesSearchedIds.length })
    }

    const onClickSearchByStageTypes = (stageTypeId) => {
        personsActs.startSearchByStageType({ dispatch, limit, stageTypeId, bookedBy: currentUser?.service?.id })
            .catch(err => handleError(err));
        setShowSearchForm(false);
        vacActs.setFulltextSearchValue({ dispatch, value: '' });
    }

    const onDiscardSearchByStageTypes = () => {
        if (searchByStageTypeId) {
            personsActs.loadListWork({ dispatch, offset: 0, limit, appendList: false, bookedBy: currentUser?.service?.id })
                .catch(err => handleError(err));
            personsActs.setSearchByStageTypeId({ dispatch, id: null });
        }
    }

    const handleBook = useCallback(({ personId, systemUserId }) => {
        personsActs.book({ dispatch, personId, systemUserId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const handleUnbook = useCallback(({ personId, systemUserId }) => {
        personsActs.unbook({ dispatch, personId, systemUserId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeStage = useCallback(({ relation_id, stage_type_id }) => {
        relActs.addStage({ dispatch, relation_id, stage_type_id })
            .catch(err => handleError(err));
    }, [dispatch])

    // const onChangeProcessing = useCallback(({
    //     processing_id,
    //     relation_id,
    //     initiator,
    //     cancelled_at,
    //     reason_id,
    //     comment,
    // }) => {


    //     relActs.cancelProcessingWithReason({
    //         dispatch,
    //         processing_id,
    //         relation_id,
    //         initiator,
    //         cancelled_at,
    //         reason_id,
    //         comment,
    //     }).catch(err => handleError(err));
    // }, [dispatch])

    const onClickProfileInput = useCallback(({ personId, profileId }) => {
        setVacanciesModalData({ personId, profileId });
    }, []);

    const onAddVacanciesInModal = async (vacancyIds) => {
        await relActs.addList({ dispatch, vacancy_ids: vacancyIds, person_id: vacanciesModalData.personId })
            .catch(err => handleError(err));
    }

    const deleteRelation = async (relationId) => {
        relActs.deleteRelation({ dispatch, relationId })
            .catch(err => handleError(err));
    }

    const onDeleteRelation = useCallback((relationId) => {
        setRelationIdToDelete(relationId);
    }, [])

    const onCreateTag = useCallback(async ({ personId, label }) => {
        await tagsActs.createAndAddToPerson({ dispatch, personId, tag: { label } })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeTag = useCallback(async (tag) => {
        await tagsActs.update({ dispatch, tag })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onAddTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.addTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onDeleteTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.deleteTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onCreateProfile = useCallback(async (name) => {
        await profActs.add({ dispatch, profile: { name } })
            .catch(err => handleError(err));
    }, [dispatch])

    const onCreateVacancy = async (vacancy) => {
        await vacActs.add(dispatch, {
            ...vacancy,
            group: vacancy.group_id ?
                groupsMap[vacancy.group_id]
                :
                undefined,
            profile: vacancy.profile_id ?
                profilesMap[vacancy.profile_id]
                :
                undefined,
        })
            .catch(err => handleError(err));
    }

    // processing
    const onCancelProcessing = async ({
        processing_id,
        relation_id,
        initiator,
        cancelled_at,
        reason_id,
        comment,
    }) => {
        await relActs.cancelProcessingWithReason({
            dispatch,
            processing_id,
            relation_id,
            initiator,
            cancelled_at,
            reason_id,
            comment,
        })
    }

    if (!currentUser) {
        return null;
    }

    const filterVacanciesAllIdsForModal = () => {
        if (!vacanciesModalData) {
            return []
        }

        const relationExists = ({ personId, vacancyId }) => {
            return byPersonId[personId]
                ?.some(rel => rel.vacancy_id === vacancyId)
        }

        const vacancyHasTheSameProfile = ({ vacancyProfileId, chosenProfileId }) => {
            return vacancyProfileId ?
                chosenProfileId === vacancyProfileId
                :
                chosenProfileId === -1
        }

        return vacanciesAllIds
            ?.reduce((filteredList, vacId) => {
                const vac = vacanciesMap[vacId];
                if (vac
                    &&
                    !relationExists({ personId: vacanciesModalData.personId, vacancyId: vacId })
                    &&
                    vacancyHasTheSameProfile({ vacancyProfileId: vac.profile?.id, chosenProfileId: vacanciesModalData.profileId })
                ) {
                    return [...filteredList, vacId];
                }

                return filteredList
            }, []);
    }

    const cache = CellMeasurerCacheWrapper({
        defaultHeight: 300,
        minHeight: 160,
        fixedWidth: true
    });

    const cellRenderer = ({ key, parent, index, style, registerChild }) => {
        const pers = map[filteredAndSortedIds[index]];
        if (!pers) return null;
        const person = selected[pers.service.id] || pers;
        const editable = pers.service.id === editedId
        const isPristine = deepEqual(pers, person);
        return (
            <CellMeasurerUi
                cache={cache}
                style={style}
                key={key}
                parent={parent}
                rowIndex={index}
                ref={registerChild}
            >
                {({ registerChild }) =>
                    <div data-testid="card"
                        ref={registerChild}
                        style={style}
                        key={pers.service.id}
                    >
                        <PersonCard
                            relations={relationsById[pers.service.id]}
                            data={person}
                            editable={editable}
                            onEdit={onClickEdit}
                            onChangeField={handleFieldChange}
                            onSubmit={handleUpdate}
                            onDiscard={handleDiscard}
                            onBook={handleBook}
                            onUnbook={handleUnbook}
                            onChangeStage={onChangeStage}
                            onChoose={onClickHndlr}
                            isPristine={isPristine}
                            errors={selectedErrors[pers.service.id]}
                            checkForErrors={checkForErrors}
                            currentUser={currentUser}
                            stageTypesMap={stageTypesMap || {}}
                            stageTypesAllIds={stageTypesAllIds || []}
                            stagesMapByRelationId={stagesMapByRelationId}
                            className={chosenId === pers.service.id ? 'persons-list-card-chosen' : undefined}
                            vacanciesMap={vacanciesMap}
                            usersMap={usersMap}
                            tagsMap={tagsMap}
                            tagsAllIds={tagsAllIds}
                            onChangeTag={onChangeTag}
                            onCreateTag={onCreateTag}
                            onAddTagId={onAddTagId}
                            onDeleteTagId={onDeleteTagId}
                            profilesList={profilesList}
                            onClickProfileInput={onClickProfileInput}
                            onCreateProfile={onCreateProfile}
                            onDeleteRelation={onDeleteRelation}

                            onCancelProcessing={onCancelProcessing}
                            processingCancelReasons={processingCancelReasons}
                        />
                    </div>
                }
            </CellMeasurerUi>
        )
    }

    return (
        <>
            <div className="sticky-ui">
                {showSearchForm && (() => {
                    const person = selected.search || { ...zeroPersonSearch, service: 'search' };
                    const editable = true;
                    const isPristine = deepEqual(person, { ...zeroPersonSearch, service: { id: 'search' } });
                    return <PersonSearchCard
                        data={person}
                        editable={editable}
                        onChangeField={handleFieldChange}
                        onSubmit={handleSearch}
                        onDiscard={handleSearchDiscard}
                        isPristine={isPristine}
                        errors={selectedErrors.search}
                        checkForErrors={checkForErrors}
                        onMinimize={() => setShowSearchForm(false)}
                    />
                })()}

                {showCandidateForm && (() => {
                    const person = selected.new || { ...zeroPerson, service: { id: 'new' } };
                    const editable = true;
                    const isPristine = deepEqual(person, zeroPerson);
                    return <Form name='add person' onSubmit={handleAdd}>
                        <PersonCard
                            data={person}
                            editable={editable}
                            onChangeField={handleFieldChange}
                            onDiscard={(id) => {
                                setShowCandidateForm(false);
                                deletePersonFromSelected(id);
                            }}
                            isPristine={isPristine}
                            errors={selectedErrors.new}
                            checkForErrors={checkForErrors}
                        />
                    </Form>
                })()}

                {!showCandidateForm &&
                    <Button icon
                        title="add person"
                        onClick={() => {
                            addPersonToSelected({ ...zeroPerson, service: { id: 'new' } })
                            setShowCandidateForm(true);
                        }}>
                        <Icon name="add" />
                    </Button>
                }
                {!showSearchForm &&
                    <Button icon
                        title="search person"
                        className='search-form-button'
                        onClick={() => {
                            !searchOptions && addPersonToSelected({ ...zeroPersonSearch, service: { id: 'search' } })
                            setShowSearchForm(true);
                        }}>
                        <IconGroupUi>
                            <IconUi name='search' />
                            <IconUi
                                className='search-form-button-icon-corner'
                                corner='top right'
                                name='add'
                            />
                        </IconGroupUi>
                    </Button>
                }
                <InputUi
                    placeholder="Text search"
                    value={fulltextSearchValue}
                    onChange={onChangeFulltextSearch}
                />
                <SearchUi
                    placeholder='Search by vacancies'
                    value={searchByVacancyValue}
                    onSearchChange={(e) =>
                        onChangeVacanciesFulltextSearch(e.target.value)
                    }
                    onResultSelect={({ value }) =>
                        onClickVacanciesFulltextSearch(value)
                    }
                    onSearchDiscard={() => onDiscardVacanciesFulltextSearch()}
                    onClickLoadMore={() => onClickLoadMoreVacanciesFulltextSearch()}
                    results={
                        vacanciesSearchedIds
                            .map(vId => ({
                                title: vacanciesMap[vId].name,
                                value: vacanciesMap[vId].service.id,
                            }))
                    }
                />
                <FilterInputsUi
                    optionsList={addedFilters}
                    propsToFilterBy={personFilterFieldsTree}
                    onChange={onChangeFilterInput}
                    onDelete={onDeleteFilter}
                    onAdd={onAddFilter}
                />
                <select
                    value={searchByStageTypeId || -1}
                    onChange={(e) => {
                        if (e.target.value === '-1') {
                            onDiscardSearchByStageTypes();
                            return
                        }
                        onClickSearchByStageTypes(e.target.value)
                    }}
                >
                    <option value={-1} key='none'>
                        -
                    </option>
                    {
                        sortStagesListBySpecificOrder(stageTypesAllIds).map(stId => {
                            if (!stageTypesMap[stId]) return null;

                            return (
                                <option value={stId} key={stId}>
                                    {stageTypesMap[stId].name}
                                </option>
                            )
                        })
                    }
                </select>
                <div style={{ width: "100%" }}>
                    {totalCountBySearch === null ?
                        null
                        :
                        `Found: ${totalCountBySearch} candidates.`}
                </div>
            </div>
            <InfiniteLoaderUi
                isRowLoaded={({ index }) => !!filteredAndSortedIds[index]}
                loadMoreRows={() => !loading && canLoadMore && load()}
                rowCount={2000}
                threshold={5}
            >
                {({ onRowsRendered, registerChild: inRegisterChild }) => (
                    <WindowScrollerUi>
                        {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) =>
                            <AutoSizerUi disableHeight>
                                {({ width }) => (
                                    <div ref={registerChild}>
                                        <ListUi
                                            regChild={inRegisterChild}
                                            autoHeight
                                            height={height}
                                            isScrolling={isScrolling}
                                            onScroll={onChildScroll}
                                            scrollTop={scrollTop}
                                            onRowsRendered={onRowsRendered}
                                            rowCount={filteredAndSortedIds.length}
                                            rowHeight={cache.rowHeight}
                                            rowRenderer={cellRenderer}
                                            className='persons-list'
                                            width={width}
                                            scrollToIndex={undefined}
                                            overscanRowCount={5}
                                        />
                                    </div>
                                )}
                            </AutoSizerUi>
                        }
                    </WindowScrollerUi>
                )}
            </InfiniteLoaderUi>
            {vacanciesModalData &&
                <VacanciesModal
                    header={
                        map[vacanciesModalData.personId]?.surname + ' ' +
                        map[vacanciesModalData.personId]?.name + ' ' +
                        map[vacanciesModalData.personId]?.midname
                    }
                    chosenProfile={profilesMap[vacanciesModalData.profileId] || { name: 'No profile', id: vacanciesModalData.profileId }}
                    vacanciesMap={vacanciesMap}
                    vacanciesAllIds={filterVacanciesAllIdsForModal()}
                    usersMap={usersMap}
                    usersAllIds={usersAllIds}
                    onAddVacancies={onAddVacanciesInModal}
                    onClose={() => setVacanciesModalData(null)}
                    onCreateVacancy={onCreateVacancy}
                    groupsList={groupsList}
                    createdVacancyId={vacanciesLastCreatedId}
                    clearCreatedVacancyId={() => vacActs.clearLastCreatedId(dispatch)}
                />
            }

            {relationIdToDelete &&
                <ConfirmModalUi
                    header={
                        `Do you want to delete 
                        ${vacanciesMap[relationsMap[relationIdToDelete]?.vacancy_id]?.name} from 
                        ${map[relationsMap[relationIdToDelete]?.person_id]?.surname} 
                        ${map[relationsMap[relationIdToDelete]?.person_id]?.name}?`}
                    confirmText='Delete'
                    rejectText='Cancel'
                    open={!!relationIdToDelete}
                    onClose={() => setRelationIdToDelete(null)}
                    onConfirm={() => {
                        deleteRelation(relationIdToDelete)
                            .then(() => {
                                setRelationIdToDelete(null);
                            })
                    }}
                    onReject={() => setRelationIdToDelete(null)}
                />
            }
            {duplicateIds &&
                <PersonsDuplicateModal />
            }
        </>
    )
}

export default React.memo(Workspace);
