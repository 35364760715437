import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { Grid, Input, Button, Header, Icon, Form, Dropdown, Label } from 'semantic-ui-react';

import CardUi from '../../components/NewCardUi';
import SortingInputUi from '../../components/SortingInputUi';
import InputUi from '../../components/NewInputUi';
import ButtonUi from '../../components/StyledButtonUi';
import Description from './Description';

import * as vacActs from '../../redux/vacancies/actions';
import * as usersActs from '../../redux/users/actions';

import { store } from '../../store';
import { validate, deepEqual, parseIntegerInput, filterObjectsByListOfValues, sortFlatObjects, arrToMap } from '../../utils';
import { handleError } from '../../errorHandler';

import { zeroVacancy, flattenVacancy, unflattenVacancy } from '../../vacancy';

import './Vacancies.scss';
import Comments from '../Comments';
import { isUserHiringManager } from '../../user';

const Vacancies = () => {

    const { dispatch, state } = useContext(store);
    
    const {
        vacancies: {
            list,
            allIds,
            map,
            selected,
            chosenId,
            statusTypes,
        },
        groups: {
            list: groupsList
        },
        profiles: {
            list: profilesList
        },
        users: {
            map: usersMap,
            list: usersList
        }
    } = state

    useEffect(() => {
        usersActs.loadList(dispatch)
            .catch(err => handleError(err));

        vacActs.loadStatusTypes(dispatch)
            .catch(err => handleError(err));
    }, [dispatch]);

    useEffect(() => {
        !chosenId && allIds && allIds[0] && vacActs.setChosenId({dispatch, id: allIds[0]})
    }, [chosenId, allIds, dispatch])

    // arr to map
    let groupsMap = useMemo(() => arrToMap(groupsList), [groupsList]);
    let profilesMap = useMemo(() => arrToMap(profilesList), [profilesList]);

    const [showVacancyForm, setShowVacancyForm] = useState(false);
    const [editId, setEditId] = useState(null);
    const [errors, setErrors] = useState({});

    const [filteredAndSortedList, setFilteredAndSortedList] = useState([]);
    const [filterOptions, setFiterOptions] = useState({value: ''});
    const [sortingOptions, setSortingOptions] = useState({field: 'unsorted', sortingOrder: 'ascending'});

    const [editedStatusMapByVacancyId, setEditedStatusMapByVacancyId] = useState({});

    const filteredObjects = useMemo(()=>
        filterObjectsByListOfValues(list, {values: [filterOptions.value]}), [list, filterOptions]);

    useEffect(() => {
        setFilteredAndSortedList(
            sortFlatObjects(
                filteredObjects
                    .map(vacancy=>flattenVacancy(vacancy)),
                    
                sortingOptions
            ).map(vacancy=>unflattenVacancy(vacancy)))
    }, [filteredObjects, sortingOptions]);

    const checkForErrors = useCallback(({value, validateOptions, vacancyId, fieldName}) => {
        const fieldError = validate({value, ...validateOptions});
        setErrors((errors) => {
            const vacancyErrors = {...errors[vacancyId]};
            fieldError ?
                vacancyErrors[fieldName] = fieldError
                :
                delete vacancyErrors[fieldName];
            return {...errors, [vacancyId]: vacancyErrors}
        });
    }, [setErrors])

    const handleFilterInput = (e) => {
        setFiterOptions({value: e.target.value});
    }

    const isEdit = (id) => {
        return editId === id;
    }

    const addVacancyToSelected = (vacancy) => {
        vacActs.updateSelected({dispatch, id: vacancy.service.id, data: vacancy});
        setErrors({...errors, [vacancy.service.id]: {}});
    };

    const deleteVacancyFromSelected = (id) => {
        vacActs.deleteSelected(dispatch, id);
        setErrors({...errors, [id]: {}});
    };

    const setEdit = (vacancy) => {
        if (editId) {
            deleteVacancyFromSelected(editId);
        }

        setEditId((vacancy && vacancy.service && vacancy.service.id) || null);
        if (vacancy) {
            addVacancyToSelected(vacancy)
        }
    };

    const onClickVacancy = (id) => {
        vacActs.setChosenId({dispatch, id});
    }

    const handleFieldChange = ({ e, id, data }) => {
        vacActs.updateSelected({
            dispatch, 
            id, 
            data: data ? 
                data  
                : 
                {[e.target.name]: e.target.value}   
            })
    }

    const handleUpdate = ({ id, uneditedVac}) => {
        selected[id] &&
            (vacActs.update(dispatch, {...selected[id], service: {id}})
                .then(()=>{
                    selected[id].group && !deepEqual(uneditedVac.group, selected[id].group) &&
                        (vacActs.setGroup({
                            dispatch, 
                            vacancy_id: id, 
                            group_id: selected[id].group.id
                        }).catch(err => handleError(err)))

                    selected[id].profile && !deepEqual(uneditedVac.profile, selected[id].profile) &&
                        (vacActs.setProfile({
                            dispatch, 
                            vacancy_id: id, 
                            profile_id: selected[id].profile.id
                        }).catch(err => handleError(err)));

                    setEdit(null)
                })
                .catch(err => {handleError(err)}));
    }

    const handleAdd = () => {
        selected.new &&
            (vacActs.add(dispatch, {...selected.new, service: undefined})
                .then(()=>{
                    setShowVacancyForm(false);
                    deleteVacancyFromSelected('new')
                })
                .catch(err => handleError(err)));
    }

    const handleDiscard = (id) => {
        vacActs.updateSelected({
            dispatch, 
            id, 
            data: { ...zeroVacancy, ...map[id]}
        })
        setErrors({...errors, [id]: {}});
    }

    const selectVacancy = (vacancy) => {
        vacActs.updateSelected({dispatch, id: vacancy.service.id, data: {...zeroVacancy, ...vacancy}})
        setErrors({...errors, [vacancy.service.id]: {}})
    }

    const onChangeStatusType = useCallback(({vacancyId, statusType}) => {
        setEditedStatusMapByVacancyId(sm => ({
            ...sm,
            [vacancyId]: statusType
        }))
    }, []);

    const onUpdateStatusType = useCallback(({vacancyId, statusType}) => {
        vacActs.setStatus({dispatch, vacancyId, statusType})
            .then(() => {
                setEditedStatusMapByVacancyId(sm => ({
                    ...sm,
                    [vacancyId]: undefined
                }))
            })
            .catch(err => handleError(err));
        
    }, [dispatch]);

    return (
        <>
        <div className="vacancies">
            <div className="vacancies-split">
                <div className="vacancies-split-left">
                    <div className="sticky-ui">
                        {showVacancyForm ? (()=> {
                            const vacancy = selected.new || zeroVacancy;
                            const editable = true;
                            const isPristine = deepEqual(vacancy, zeroVacancy);
                            const hasErrors = errors.new && Object.keys(errors.new).length !== 0;
                            return <CardUi>
                                <Form name='add vacancy' onSubmit={()=>handleAdd()}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Header>
                                                    <InputUi
                                                        name='name'
                                                        value={vacancy.name}
                                                        edit={editable}
                                                        onChange={
                                                            (e)=>handleFieldChange({
                                                                e, 
                                                                id: vacancy.service.id
                                                            })
                                                        }
                                                        errors={errors[vacancy.service.id] && errors[vacancy.service.id].name}
                                                        checkForErrors={(value)=>
                                                            checkForErrors({
                                                                value, 
                                                                validateOptions: {type: 'any', required: true, size: 255},
                                                                fieldName: 'name',
                                                                vacancyId: vacancy.service.id
                                                        })}
                                                        required
                                                    />
                                                </Header>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <InputUi
                                                    name='scope'
                                                    value={vacancy.scope}
                                                    edit={editable}
                                                    onChange={
                                                        (e)=>handleFieldChange({
                                                            e, 
                                                            id: vacancy.service.id
                                                        })
                                                    }
                                                    errors={errors[vacancy.service.id] && errors[vacancy.service.id].scope}
                                                    checkForErrors={(value)=>
                                                        checkForErrors({
                                                            value, 
                                                            validateOptions: {type: 'any', required: false, size: 255},
                                                            fieldName: 'scope',
                                                            vacancyId: vacancy.service.id
                                                    })}
                                                />
                                                <Dropdown 
                                                    onChange={
                                                        (e, { value })=>handleFieldChange({
                                                            e, 
                                                            id: vacancy.service.id,
                                                            data: {
                                                                group: groupsMap[value]
                                                            }
                                                        })
                                                    }
                                                    options={[
                                                        {value: null, text: 'none', key: -1},
                                                        ...(groupsList || []).map(g=>({value: g.id, text: g.name, key: g.id}))
                                                    ]} 
                                                    value={(vacancy.group && vacancy.group.id) || null}
                                                    placeholder='group'
                                                    selection
                                                    fluid
                                                />
                                                <Dropdown 
                                                    onChange={
                                                        (e, { value })=>handleFieldChange({
                                                            e, 
                                                            id: vacancy.service.id,
                                                            data: {
                                                                hiring_manager_id: value
                                                            }
                                                        })
                                                    }
                                                    options={[
                                                        {value: null, text: 'none', key: -1},
                                                        ...(usersList || []).map(usr=>({value: usr.service.id, text: `${usr.surname} ${usr.name}`, key: usr.service.id}))
                                                    ]} 
                                                    value={vacancy.hiring_manager_id !== -1? vacancy.hiring_manager_id: null}
                                                    placeholder='hiring manager'
                                                    selection
                                                    fluid
                                                />
                                                <Dropdown 
                                                    onChange={
                                                        (e, { value })=>handleFieldChange({
                                                            e, 
                                                            id: vacancy.service.id,
                                                            data: {
                                                                profile: profilesMap[value]
                                                            }
                                                        })
                                                    }
                                                    options={[
                                                        {value: null, text: 'none', key: -1},
                                                        ...(profilesList || []).map(g=>({value: g.id, text: g.name, key: g.id}))
                                                    ]} 
                                                    value={(vacancy.profile && vacancy.profile.id) || null}
                                                    placeholder='profile'
                                                    selection
                                                    fluid
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <InputUi
                                                    name='amount'
                                                    value={(vacancy.money && 
                                                        vacancy.money.amount) || ''
                                                    }
                                                    type='number'
                                                    edit={editable}
                                                    onChange={
                                                        (e)=>handleFieldChange({
                                                            id: vacancy.service.id,
                                                            data: {
                                                                money: {
                                                                    ...vacancy.money,
                                                                    [e.target.name]: parseIntegerInput(e.target.value, 10)
                                                                }
                                                            }
                                                        })
                                                    }
                                                    errors={errors[vacancy.service.id] && errors[vacancy.service.id].amount}
                                                    checkForErrors={(value)=>
                                                        checkForErrors({
                                                            value, 
                                                            validateOptions: {type: 'number', required: false, size: 255},
                                                            fieldName: 'amount',
                                                            vacancyId: vacancy.service.id
                                                    })}
                                                />
                                                <InputUi
                                                    name='currency'
                                                    value={(vacancy.money && 
                                                        vacancy.money.currency)
                                                    }
                                                    edit={editable}
                                                    onChange={
                                                        (e)=>handleFieldChange({
                                                            id: vacancy.service.id,
                                                            data: {
                                                                money: {
                                                                    ...vacancy.money,
                                                                    [e.target.name]: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }
                                                    errors={errors[vacancy.service.id] && errors[vacancy.service.id].currency}
                                                    checkForErrors={(value)=>
                                                        checkForErrors({
                                                            value, 
                                                            validateOptions: {type: 'string', required: false, size: 4},
                                                            fieldName: 'currency',
                                                            vacancyId: vacancy.service.id
                                                    })}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column width={2}>
                                                <Button 
                                                    icon 
                                                    disabled={hasErrors || isPristine}
                                                    type='submit'
                                                    title='submit vacancy'
                                                >
                                                    <Icon name='save'/>
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Button 
                                                    icon 
                                                    type='button'
                                                    title='discard vacancy'
                                                    onClick={()=>{
                                                        deleteVacancyFromSelected('new')
                                                        setShowVacancyForm(false);
                                                        }
                                                    }
                                                >
                                                    <Icon name='close'/>
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            </CardUi>})()
                            :
                            <Button 
                                icon 
                                title="add vacancy"
                                onClick={()=>{
                                    addVacancyToSelected({...zeroVacancy, service: {id: 'new'}})
                                    setShowVacancyForm(true);
                                }
                            }>
                                <Icon name='add'/>
                            </Button>
                        }

                        <Input onChange={handleFilterInput} placeholder='filter'/>  
                        <SortingInputUi 
                            fields={
                                Object.keys(flattenVacancy(zeroVacancy))
                                    .map(prop=>{return {value: prop, label: prop}})
                            } 
                            onChange={({value, sortingOrder})=>{setSortingOptions({field: value, sortingOrder})}}
                        />
                    </div>
                        {filteredAndSortedList.map((vac, idx) => {
                            const vacancy = selected[vac.service.id] || vac;
                            const editable = isEdit(vac.service.id);
                            const isPristine = deepEqual(vacancy, vac);
                            const hasErrors = errors[vac.service.id] && Object.keys(errors[vac.service.id]).length !== 0;
                            const newStatus = editedStatusMapByVacancyId[vac.service.id] === vacancy.status ?
                                undefined
                                :
                                editedStatusMapByVacancyId[vac.service.id];
                            return (
                                <div data-testid="card" key={idx}>
                                    <CardUi >
                                        <Grid
                                            onClick={() => onClickVacancy(vacancy.service.id)}
                                            className={chosenId === vacancy.service.id ? 'vacancies-split-left-card-chosen' : undefined}
                                        >
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Header>
                                                        <InputUi
                                                            name='name'
                                                            value={vacancy.name}
                                                            id={vacancy.service.id}
                                                            edit={editable}
                                                            required
                                                            onChange={
                                                                (e)=>handleFieldChange({
                                                                    e, 
                                                                    id: vacancy.service.id
                                                                })
                                                            }
                                                            errors={errors[vacancy.service.id] && errors[vacancy.service.id].name}
                                                            checkForErrors={(value)=>
                                                                checkForErrors({
                                                                    value, 
                                                                    validateOptions: {type: 'any', required: true, size: 255},
                                                                    fieldName: 'name',
                                                                    vacancyId: vacancy.service.id
                                                            })}
                                                        />
                                                    </Header>
                                                </Grid.Column>
                                                {!editable && (vacancy.group.id || vacancy.profile.id) &&
                                                    <Grid.Column>
                                                        <Label ribbon="right">
                                                            {vacancy.group.name} 
                                                            {vacancy.group.name && vacancy.profile.name && <br/>}
                                                            {vacancy.hiring_manager_id !== -1 && usersMap[vacancy.hiring_manager_id] &&
                                                                `${usersMap[vacancy.hiring_manager_id].surname} ${usersMap[vacancy.hiring_manager_id].name}`} <br/>
                                                            {vacancy.profile.name}</Label>
                                                    </Grid.Column>
                                                }
                                            </Grid.Row>

                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <InputUi
                                                        name='scope'
                                                        value={vacancy.scope}
                                                        edit={editable}
                                                        onChange={
                                                            (e)=>handleFieldChange({
                                                                e, 
                                                                id: vacancy.service.id
                                                            })
                                                        }
                                                        errors={errors[vacancy.service.id] && errors[vacancy.service.id].scope}
                                                        checkForErrors={(value)=>
                                                            checkForErrors({
                                                                value, 
                                                                validateOptions: {type: 'any', required: false, size: 255},
                                                                fieldName: 'scope',
                                                                vacancyId: vacancy.service.id
                                                        })}
                                                    />
                                                    {editable && 
                                                        <>
                                                            <Dropdown 
                                                                title='groups dropdown'
                                                                onChange={
                                                                    (e, { value })=>handleFieldChange({
                                                                        e, 
                                                                        id: vacancy.service.id,
                                                                        data: {
                                                                            group: groupsMap[value]
                                                                        }
                                                                    })
                                                                }
                                                                options={[
                                                                    {value: null, text: 'no group', key: -1},
                                                                    ...(groupsList || []).map(g=>({value: g.id, text: g.name, key: g.id}))
                                                                ]} 
                                                                value={(vacancy.group && vacancy.group.id) || null}
                                                                placeholder='group'
                                                                selection
                                                                fluid
                                                            />
                                                            <Dropdown 
                                                                onChange={
                                                                    (e, { value }) => handleFieldChange({
                                                                        e, 
                                                                        id: vacancy.service.id,
                                                                        data: {
                                                                            hiring_manager_id: value
                                                                        }
                                                                    })
                                                                }
                                                                options={[
                                                                    {value: null, text: 'no hiring manager', key: -1},
                                                                    ...(usersList || [])
                                                                        .filter(usr => isUserHiringManager(usr))
                                                                        .map(usr=>({
                                                                            value: usr.service.id,
                                                                            text: `${usr.surname} ${usr.name}`,
                                                                            key: usr.service.id
                                                                        }))
                                                                ]} 
                                                                value={vacancy.hiring_manager_id !== -1? vacancy.hiring_manager_id: null}
                                                                placeholder='hiring manager'
                                                                selection
                                                                fluid
                                                            />
                                                            <Dropdown 
                                                                onChange={
                                                                    (e, { value })=>handleFieldChange({
                                                                        e, 
                                                                        id: vacancy.service.id,
                                                                        data: {
                                                                            profile: profilesMap[value]
                                                                        }
                                                                    })
                                                                }
                                                                options={[
                                                                    {value: null, text: 'no profile', key: -1},
                                                                    ...(profilesList || []).map(g=>({value: g.id, text: g.name, key: g.id}))
                                                                ]} 
                                                                value={(vacancy.profile && vacancy.profile.id) || null}
                                                                placeholder='profile'
                                                                selection
                                                                fluid
                                                            />
                                                        </>
                                                    }
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <InputUi
                                                        name='amount'
                                                        value={(vacancy.money && 
                                                            vacancy.money.amount) || ''
                                                        }
                                                        type='number'
                                                        edit={editable}
                                                        onChange={
                                                            (e)=>handleFieldChange({
                                                                id: vacancy.service.id,
                                                                data: {
                                                                    money: {
                                                                        ...vacancy.money,
                                                                        [e.target.name]: parseIntegerInput(e.target.value)
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        errors={errors[vacancy.service.id] && errors[vacancy.service.id].amount}
                                                        checkForErrors={(value)=>
                                                            checkForErrors({
                                                                value, 
                                                                validateOptions: {type: 'number', required: false, size: 255},
                                                                fieldName: 'amount',
                                                                vacancyId: vacancy.service.id
                                                        })}
                                                    />
                                                    <InputUi
                                                        name='currency'
                                                        value={(vacancy.money && 
                                                            vacancy.money.currency)
                                                        }
                                                        id={vacancy.service.id}
                                                        edit={editable}
                                                        onChange={
                                                            (e)=>handleFieldChange({
                                                                id: vacancy.service.id,
                                                                data: {
                                                                    money: {
                                                                        ...vacancy.money,
                                                                        [e.target.name]: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        errors={errors[vacancy.service.id] && errors[vacancy.service.id].currency}
                                                        checkForErrors={(value)=>
                                                            checkForErrors({
                                                                value, 
                                                                validateOptions: {type: 'string', required: false, size: 4},
                                                                fieldName: 'currency',
                                                                vacancyId: vacancy.service.id
                                                        })}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row>
                                                {editable ?
                                                    <>
                                                        <Grid.Column width={2}>
                                                            <Button 
                                                                icon 
                                                                title="submit vacancy"
                                                                disabled={hasErrors || isPristine}
                                                                onClick={()=>handleUpdate({id: vacancy.service.id, uneditedVac: vac})}
                                                            >
                                                                <Icon name='save'/>
                                                            </Button>
                                                        </Grid.Column>
                                                        <Grid.Column width={2}>
                                                            <Button 
                                                                icon 
                                                                title="discard vacancy"
                                                                onClick={()=>{
                                                                    handleDiscard(vacancy.service.id); 
                                                                    setEdit(null)
                                                                }}
                                                            >
                                                                <Icon name='close'/>
                                                            </Button>
                                                        </Grid.Column>
                                                    </>
                                                    :
                                                    <>
                                                        <Grid.Column width={2}>
                                                            <Button 
                                                                icon 
                                                                title="edit vacancy"
                                                                onClick={()=>{
                                                                    selectVacancy(vacancy);
                                                                    setEdit(vacancy);
                                                                }}
                                                            >
                                                                <Icon name='edit'/>
                                                            </Button>
                                                        </Grid.Column>
                                                        <Grid.Column floated='right' width={9}>
                                                            <div className='vacancies-split-left-card-status'>
                                                                <select
                                                                    value={newStatus || vacancy.status}
                                                                    onChange={(e) => onChangeStatusType({
                                                                        vacancyId: vacancy.service.id,
                                                                        statusType: e.currentTarget.value
                                                                    })}
                                                                >
                                                                    <option value={''} hidden>-</option>
                                                                    {statusTypes.map((st, idx) => (
                                                                        <option value={st} key={idx}>
                                                                            {st}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {newStatus && 
                                                                    <ButtonUi
                                                                        onClick={() => onUpdateStatusType({
                                                                            vacancyId: vacancy.service.id,
                                                                            statusType: newStatus
                                                                        })}
                                                                    >
                                                                        Update
                                                                    </ButtonUi>
                                                                }
                                                            </div>
                                                        </Grid.Column>
                                                    </>
                                                }
                                            </Grid.Row>
                                        </Grid>
                                    </CardUi>
                                </div>
                        )})}
                </div>

                <div className="vacancies-split-right vacancies-sticky">
                        <div className="vacancies-top ui segment">
                            {(() => {
                                const vacIdToShow = (selected.new && selected.new.service.id) || editId;
                                const content = vacIdToShow ?
                                    (selected[vacIdToShow] && selected[vacIdToShow].description)
                                    :
                                    chosenId ?
                                        (() => {
                                            const chosenVac = map[chosenId];
                                            return (chosenVac && chosenVac.description)
                                        })()
                                        :
                                        ''
                                return (
                                    <Description 
                                        editable={vacIdToShow}
                                        content={content}
                                        onChange={(e) => 
                                            handleFieldChange({id: vacIdToShow || chosenId, data: {description: e.target.value}})}
                                    />
                                )
                            })()}
                        </div>
                        <div className="vacancies-bottom ui segment">
                            {chosenId &&
                                <Comments threadId={chosenId} topicType='vacancies'/>
                            }
                        </div>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default Vacancies