import axios from 'axios';
import { ErrorUnauthorized } from '../../errors';
import { excludeEmptyFields } from '../../utils';

const rootRef = "/matches/api/v1";
const groupsRef = "/groups";

export const getList = async () => {
    
    const url = `${rootRef}${groupsRef}/`;

    try {
        const response = await axios(url);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const update = async (group) => {
    const url = `${rootRef}${groupsRef}/`;

    const gr = excludeEmptyFields(group)
    
    try {
        const response = await axios.put(url, gr);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const create = async (group) => {
    const url = `${rootRef}${groupsRef}/`;

    const gr = excludeEmptyFields(group)
    
    try {
        const response = await axios.post(url, gr);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}