export const sortStagesListBySpecificOrder = (stageTypesAllIds: number[]) => {
    // - Free 0 1
    // - In work 1 2
    // - CV Review 2 4
    // - CV Review Accepted 3 10
    // - CV Review Rejected 4 11
    // - Technical interview 5 31
    // - Technical interview Accepted 6 32
    // - Technical interview Rejected 7 33
    // - PM interview 8 34
    // - PM interview Accepted 9 35
    // - PM interview Rejected 10 36
    // - Interview  11 5
    // - Interview Accepted 12 12
    // - Interview Rejected 13 13
    // - Pre-offer 14 28
    // - Offer 15 6
    // - Offer Accepted 16 7
    // - Offer Rejected 17 9
    // - Away 18 3
    let stages = new Array<number>(stageTypesAllIds.length).fill(0)

    stageTypesAllIds.forEach((value) => {
        switch (value) {
            case 1:
                stages[0] = value
                break

            case 2:
                stages[1] = value
                break

            case 4:
                stages[2] = value
                break

            case 10:
                stages[3] = value
                break

            case 11:
                stages[4] = value
                break

            case 31:
                stages[5] = value
                break

            case 32:
                stages[6] = value
                break

            case 33:
                stages[7] = value
                break

            case 34:
                stages[8] = value
                break

            case 35:
                stages[9] = value
                break

            case 36:
                stages[10] = value
                break

            case 5:
                stages[11] = value
                break

            case 12:
                stages[12] = value
                break

            case 13:
                stages[13] = value
                break

            case 28:
                stages[14] = value
                break

            case 6:
                stages[15] = value
                break

            case 7:
                stages[16] = value
                break

            case 9:
                stages[17] = value
                break

            case 3:
                stages[18] = value
                break

        }
    })

    return stages
}