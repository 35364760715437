import React from 'react';

import './styles.scss';

const InputUi = ({ className, ...attributes }: any) => {
    const totalClassName = 'styled-input-ui ' + (className || '')

    return <input className={totalClassName} {...attributes}/>
}

export default InputUi;