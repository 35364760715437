import React, { useState } from "react";
import { copyToClipboard } from "../../utils";
import { IconUi } from "../IconUi";

import './StyledTable.scss'

type TableUiProps = {
    data: (number | string)[][];
    xHeader: {id: number, label: (number | string)}[];
    yHeader: {id: number, label: (number | string)}[];
    xAxisName?: string;
    yAxisName?: string;
    onClickSort?: ({axis, value}: {axis: "x" | "y", value: number}) => void;
    chosenXID?: number;
    chosenYID?: number;
    onDownloadTable?: () => Promise<void>;
}

const CrossTableUi = ({
    data,
    xHeader,
    yHeader,
    onClickSort,
    chosenXID,
    chosenYID,
    yAxisName,
    xAxisName,
    onDownloadTable,
}: TableUiProps) => {

    const [copyIconName, setCopyIconName] = useState<"copy" | "check">("copy");
    const [downloadIconName, setDownloadIconName] = useState<"download" | "check">("download");
    
    const onCopyTable = () => {
        let tableDataInText = "Matches\t";
        xHeader.forEach((x, idx) => {
            if (idx !== 0) {
                tableDataInText += "\t";
            }
            tableDataInText += x.label;
        });

        data.forEach((row, idx) => {
            tableDataInText += "\n";
            tableDataInText += yHeader[idx]?.label;

            row.forEach(cell => {
                tableDataInText += "\t";
                tableDataInText += cell;
            })
        });
        copyToClipboard(tableDataInText)
            .then(() => setCopyIconName("check"));
        setTimeout(() => {
            setCopyIconName("copy")
        }, 500)
    }
    const onClickDownloadIcon = () => {
        if (onDownloadTable) {
            onDownloadTable()
                .then(() => {
                    setDownloadIconName("check")

                    setTimeout(() => {
                        setDownloadIconName("download")
                    }, 500)
                })

        }
    }

    return <div className="ui-table-container">
        <div className="ui-table-download-icon-container">
            <IconUi name={copyIconName} onClick={onCopyTable}/>
            {onDownloadTable && <IconUi name={downloadIconName} onClick={onClickDownloadIcon}/>}
        </div>
        <div className="ui-table-content">
            <table className="ui-table">
                <thead className="ui-table-header-x">
                    <tr>
                        <th className="ui-table-cross-cell">
                                <span className="ui-table-cross-cell-label-y">
                                    {yAxisName}
                                </span>
                                
                                <span className="ui-table-cross-cell-label-x">
                                    {xAxisName}
                                </span>
                        </th>
                        {xHeader.map((col, idx) => (
                            <th 
                                className={`ui-table-header-x-cell${chosenXID === col.id ? ' cell-chosen' : ''}`}
                                onClick={() => onClickSort && onClickSort({axis: "x", value: col.id})}
                                key={idx}
                            >
                                {col?.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                
                <tbody className="ui-table-body">
                    {data.map((row, idx) => (
                        <tr key={idx}>
                            <th 
                                className={`ui-table-body-header-y-cell${chosenYID === yHeader[idx]?.id ? ' cell-chosen' : ''}`}
                                onClick={() => onClickSort && onClickSort({axis: "y", value: yHeader[idx].id})}
                            >
                                {yHeader[idx]?.label}
                            </th>
                            {row.map((value, idx) => (
                                <td 
                                    key={idx}
                                    className="ui-table-body-cell"
                                > 
                                    {value}
                                </td>
                            ))}
                        </tr> 
                    ))}
                </tbody>
            </table>
        </div>
    </div>
}

export default CrossTableUi;