import React from 'react';
import { Table } from 'semantic-ui-react';

export const TableUi = ({...props}) => <Table {...props} />
export const TableUiRow = ({...props}) => <Table.Row {...props} />
export const TableUiHeader = ({...props}) => <Table.Header {...props} />
export const TableUiHeaderCell = ({...props}) => <Table.HeaderCell {...props} />
export const TableUiBody = ({...props}) => <Table.Body {...props} />
export const TableUiCell = ({...props}) => <Table.Cell {...props} />

export default TableUi