import React from "react"
import { Vacancy } from "entities/vacancy"
import { Button } from "semantic-ui-react"

type VacancyUiProps = {
    vacancy: Vacancy

    onClick?: (vacancy: Vacancy) => void
    onDelete?: (vacancy: Vacancy) => void
}

const VacancyUi = ({ onClick, vacancy, onDelete }: VacancyUiProps) => {
    return (
        <div
            key={vacancy.service.id}
            className="report-vacancy-ui"
            onClick={() => onClick && (vacancy)}
        >
            {`${vacancy.service.id}: ${vacancy.name}`}

            {onDelete && <Button onClick={() => onDelete(vacancy)} icon="delete" />}
        </div>
    )
}

export type VacancyListProps = {
    data: Vacancy[]
}


export const VacancyList = ({ data }: VacancyListProps) => {
    return (
        <>
            {
                data && data.length > 0 ? data.map(vacancy => (
                    <VacancyUi
                        key={vacancy.service.id}
                        vacancy={vacancy}
                    />

                )) : <div>Список пуст</div>
            }
        </>
    )
}