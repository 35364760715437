import axios from 'axios';
import { Vacancy } from 'entities/vacancy'

const rootRef = "/matches/api/v1";
const vacanciesRef = "/vacancies";

const getApiUrl = () => `${rootRef}${vacanciesRef}/`

export const getVacancies = async (): Promise<Array<Vacancy> | Error> => {
    try {
        const response = await axios(getApiUrl());

        return response.data;
    } catch (err: any) {
        if (err.response) {
            if (err.response.status === 401) {
                return new Error("вы не авторизованы")
            }

            return new Error(err.response.statusText)
        }

        return new Error(err.message)
    }
}

export type GetExcelReportType = {
    vacancies: Array<Vacancy>
    period: {
        from: string
        to: string
    }
}

export const getCandidatesExcelReport = async ({ vacancies, period }: GetExcelReportType): Promise<Blob | Error> => {
    try {
        const resp = await fetch("/api/v1/reports/candidates/excel", {
            method: "POST",
            body: JSON.stringify({
                vacancy_ids: getVacancyIds(vacancies),
                from_date: period.from,
                to_date: period.to,
            }),
        })

        const blob = await resp.blob()

        return blob
    } catch (err: any) {
        if (err.response) {
            if (err.response.status === 401) {
                return new Error("вы не авторизованы")
            }

            return new Error(err.response.statusText)
        }

        return new Error(err.message)
    }
}

export const getExcelReport = async ({ vacancies, period }: GetExcelReportType): Promise<Blob | Error> => {
    try {
        const resp = await fetch("/api/v1/reports/vacancies/excel", {
            method: "POST",
            body: JSON.stringify({
                vacancy_ids: getVacancyIds(vacancies),
                from_date: period.from,
                to_date: period.to,
            }),
        })

        const blob = await resp.blob()

        return blob
    } catch (err: any) {
        if (err.response) {
            if (err.response.status === 401) {
                return new Error("вы не авторизованы")
            }

            return new Error(err.response.statusText)
        }

        return new Error(err.message)
    }
}

const getVacancyIds = (vacancies: Array<Vacancy>): Array<number> => {
    let vacancyIds = [] as Array<number>

    vacancies.forEach(el => {
        vacancyIds.push(el.service.id)
    })

    return vacancyIds
}


// export type getVacanciesReqeustType = {
//     limit: number
//     offset: number
//     searchText: string
// }

// `${rootRef}${vacanciesRef}/?offset=${offset}&limit=${limit}&search=${searchText}`