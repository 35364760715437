const prefix = "USERS::";

export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_USER = `${prefix}SET_USER`;
export const SET_LIST = `${prefix}SET_LIST`;
export const APPEND_LIST = `${prefix}APPEND_LIST`;
export const ADD = `${prefix}ADD`;
export const UPDATE = `${prefix}UPDATE`;
export const SET_ROLES = `${prefix}SET_ROLES`;

export const SET_SOCIALS_BY_ID = `${prefix}SET_SOCIALS_BY_ID`;
export const SET_USER_SOCIALS_BY_ID = `${prefix}SET_USER_SOCIALS_BY_ID`;

export const SET_EDITED_ID =  `${prefix}SET_EDITED_ID`;
export const SET_SELECTED_USER_ERRORS =  `${prefix}SET_SELECTED_USER_ERRORS`;
export const SET_SELECTED_FIELD_ERRORS =  `${prefix}UPDATE_SELECTED_FIELD_ERRORS`;
export const UPDATE_IN_SELECTED = `${prefix}UPDATE_IN_SELECTED`;
export const DELETE_FROM_SELECTED = `${prefix}DELETE_FROM_SELECTED`;