import React, { useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import { Segment } from 'semantic-ui-react';

import PersonCard from '../PersonCard';
import Files from '../Files';
import Loader from '../../../components/Loader';
import Comments from '../../Comments';
import VacanciesModal from '../VacanciesModal';
import ConfirmModalUi from '../../../components/ConfirmModalUi';

import './PersonsId.scss'

import { store } from '../../../store';

import { deepEqual, getURLSearchParamByName, validate, validateId } from '../../../utils';
import { handleError } from '../../../errorHandler';
import * as personsActs from '../../../redux/persons/actions';
import * as relActs from '../../../redux/relations/actions';
import * as votesActs from '../../../redux/votes/actions';
import * as usersActs from '../../../redux/users/actions';
import * as wsActs from '../../../redux/websocket/actions';
import * as tagsActs from '../../../redux/tags/actions';
import * as vacActs from '../../../redux/vacancies/actions';
import * as profActs from '../../../redux/profiles/actions';


export const Person = () => {

    const { dispatch, state } = useContext(store);

    const { 
            persons: {
                map,
                loading, 
                selected,
            },
            vacancies: {
                map: vacanciesMap,
                allIds: vacanciesAllIds,
                lastCreatedId: vacanciesLastCreatedId
            },
            relations: {
                map: relationsMap,
                byPersonId,
                stageTypesMap,
                stageTypesAllIds,
                stagesMapByRelationId,
            },
            users: {
                map: usersMap,
                allIds: usersAllIds
            },
            user: {
                userData: currentUser
            },
            tags: {
                map: tagsMap,
                allIds: tagsAllIds,
            },
            profiles: {
                list: profilesList,
                map: profilesMap,
            },
            groups: {
                list: groupsList,
                map: groupsMap
            }
        } = state;

    const [errors, setErrors] = useState({});
    const [editable, setEditable] = useState(false);
    const [vacanciesModalData, setVacanciesModalData] = useState(null);

    const [relationIdToDelete, setRelationIdToDelete] = useState(null);

    const location = useLocation();

    const personId = useRef(validateId(location.pathname.replace('/persons/', '')));

    const isModeHandledOnMount = useRef(false);

    useEffect(() => {
        if (personId.current) {
            personsActs.load(dispatch, personId.current)
                .catch(err=>{handleError(err)});
        } else {
            handleError(new Error('Incorrect id'))
        }
        
        votesActs.loadListWithVoters(dispatch)
            .catch(err => handleError(err));
            
        usersActs.loadList(dispatch)
            .catch(err => handleError(err));

        wsActs.connect({dispatch, onOpen: () => {wsActs.subscribeToVoters(dispatch)}});

        return () => wsActs.disconnect(dispatch);
    }, [dispatch]);

    const setEdit = useCallback((person) => {
        setEditable(true);
        personsActs.select({dispatch, person});
    }, [dispatch]);

    useEffect(() => {
        if (!isModeHandledOnMount.current) {
            const mode = getURLSearchParamByName({urlSearch: location.search, name: 'mode'});

            if (mode === 'edit') {
                if (map[personId.current]) {
                    setEdit(map[personId.current])
                    isModeHandledOnMount.current = true;
                }
            } else {
                isModeHandledOnMount.current = true;
            }
        }
    }, [map, location, setEdit])

    const relationsById = useMemo(() => {
        let byPersonIdWithNames = (byPersonId[personId.current] || []).map((r => {
            const vacancy = vacanciesMap[r.vacancy_id];
            const stages = stagesMapByRelationId[r.id];
            return {
                ...r, 
                vacancy_name: vacancy && vacancy.name,
                stages
            } 
        }))
        return byPersonIdWithNames
    }, [byPersonId, vacanciesMap, stagesMapByRelationId]);


    const checkForErrors = useCallback(({value, validateOptions, fieldName}) => {
        const fieldError = validate({value, ...validateOptions});
        setErrors((errors) => {
            const personErrors = {...errors};
            fieldError ?
                personErrors[fieldName] = fieldError
                :
                delete personErrors[fieldName];
            return personErrors
        });
    }, [setErrors]);

    const onCreateTag = useCallback(async (label) => {
        await tagsActs.createAndAddToPerson({ dispatch, tag: { label }})
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeTag = useCallback(async (tag) => {
        await tagsActs.update({ dispatch, tag })
            .catch(err => handleError(err));
    }, [dispatch]);
    
    const onAddTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.addTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onDeleteTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.deleteTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onCreateProfile = useCallback(async (name) => {
        await profActs.add({dispatch, profile: {name}})
            .catch(err => handleError(err));
    }, [dispatch])

    const onCreateVacancy = useCallback(async (vacancy) => {
        await vacActs.add(dispatch, {
            ...vacancy,
            group: vacancy.group_id ? 
                groupsMap[vacancy.group_id]
                :
                undefined,
            profile: vacancy.profile_id ?
                profilesMap[vacancy.profile_id]
                :
                undefined,
        })
            .catch(err => handleError(err));
    }, [dispatch, groupsMap, profilesMap])

    const onClickProfileInput = useCallback(({personId, profileId}) => {
        setVacanciesModalData({personId, profileId});
    }, []);

    const onAddVacanciesInModal = async (vacancyIds) => {
        await relActs.addList({dispatch, vacancy_ids: vacancyIds, person_id: vacanciesModalData.personId})
            .catch(err => handleError(err));
    }
    
    const deleteRelation = async (relationId) => {
        relActs.deleteRelation({ dispatch, relationId })
            .catch(err => handleError(err));
    }

    const onDeleteRelation = useCallback((relationId) => {
        setRelationIdToDelete(relationId);
    }, []);

    if (!personId.current) {
        return null;
    }

    const handleDiscard = (id) => {
        personsActs.discard(dispatch);
        setErrors({});
        setEditable(false);
    };

    const handleFieldChange = ({ e, data }) => {
        personsActs.updateSelected({
            dispatch,
            id: personId.current,
            data: data ? 
                data  
                : 
                {[e.target.name]: e.target.value}   
            })
    };

    const handleUpdate = () => {
        selected &&
            (personsActs.update(dispatch, {...selected[personId.current]})
                .then(()=>{
                    setEditable(false)
                })
                .catch(err => handleError(err)));
    };

    const handleBook = ({personId, systemUserId}) =>{
        personsActs.book({dispatch, personId, systemUserId})
            .catch(err=>handleError(err));
    };

    const handleUnbook = ({personId, systemUserId}) =>{
        personsActs.unbook({dispatch, personId, systemUserId})
            .catch(err=>handleError(err));
    };

    const onChangeStage = ({relation_id, stage_type_id}) => {
        relActs.addStage({dispatch, relation_id, stage_type_id: stage_type_id})
            .catch(err => handleError(err));
    }

    const onOpenVotesModal = (id) => {
        votesActs.loadListById({dispatch, person_id: id})
            .catch(err => handleError(err));
    }
    
    const filterVacanciesAllIdsForModal = () => {
        if (!vacanciesModalData) {
            return []
        }

        const relationExists = ({personId, vacancyId}) => {
            return byPersonId[personId]
                ?.some(rel => rel.vacancy_id === vacancyId)
        }

        const vacancyHasTheSameProfile = ({vacancyProfileId, chosenProfileId}) => {
            return vacancyProfileId ?
                chosenProfileId === vacancyProfileId
                :
                chosenProfileId === -1
        }
    
        return vacanciesAllIds
            ?.reduce((filteredList, vacId) => {
                const vac = vacanciesMap[vacId];
                if (vac
                    &&
                    !relationExists({personId: vacanciesModalData.personId, vacancyId: vacId})
                    &&
                    vacancyHasTheSameProfile({vacancyProfileId: vac.profile?.id, chosenProfileId: vacanciesModalData.profileId})
                ) {
                    return [...filteredList, vacId];
                }

                return filteredList
            }, []);
    }

    const personData = selected[personId.current] || map[personId.current];
    const isPristine = deepEqual(personData, map[personId.current]);

    return (
            <div className="persons-id">
                <div className="persons-id-split">
                    <div className="persons-id-split-left">
                        {map[personId.current] ?
                            <div data-testid="card">
                                <PersonCard 
                                    relations={relationsById? relationsById: []}
                                    usersMap={usersMap}
                                    vacanciesMap={vacanciesMap}
                                    data={personData}
                                    editable={editable}
                                    onEdit={setEdit}
                                    onChangeField={handleFieldChange}
                                    onSubmit={()=>handleUpdate()}
                                    onDiscard={handleDiscard}
                                    onBook={handleBook}
                                    onUnbook={handleUnbook}
                                    onChangeStage={onChangeStage}
                                    isPristine={isPristine}
                                    errors={errors}
                                    checkForErrors={checkForErrors}
                                    currentUser={currentUser}
                                    stageTypesMap={stageTypesMap || {}}
                                    stageTypesAllIds={stageTypesAllIds || []}
                                    stagesMapByRelationId={stagesMapByRelationId}                               
                                    onOpenVotesModal={onOpenVotesModal}
                                    tagsMap={tagsMap}
                                    tagsAllIds={tagsAllIds}
                                    onChangeTag={onChangeTag}
                                    onCreateTag={onCreateTag}
                                    onAddTagId={onAddTagId}
                                    onDeleteTagId={onDeleteTagId}
                                    profilesList={profilesList}
                                    onClickProfileInput={onClickProfileInput}
                                    onCreateProfile={onCreateProfile}
                                    onDeleteRelation={onDeleteRelation}
                                />
                            </div>
                                :
                                loading ?
                                    <Loader />
                                    :
                                    'user not found'
                        }
                        <div className="persons-id-bottom ui segment">
                            <Comments threadId={personId.current} topicType="persons"/>
                        </div>
                    </div>
    
                    <div className="persons-id-split-right">
                    {map[personId.current] ?
                            <Segment>
                                <Files personId={personId.current}/>
                            </Segment>
                        :
                        <Loader />
                        }
                    </div>
                </div>
                
                {vacanciesModalData &&
                    <VacanciesModal
                        header={
                            map[vacanciesModalData.personId]?.surname + ' ' +
                            map[vacanciesModalData.personId]?.name + ' ' +
                            map[vacanciesModalData.personId]?.midname
                        }
                        chosenProfile={profilesMap[vacanciesModalData.profileId] || {name: 'No profile', id: vacanciesModalData.profileId}}
                        vacanciesMap={vacanciesMap}
                        vacanciesAllIds={filterVacanciesAllIdsForModal()}
                        usersMap={usersMap}
                        usersAllIds={usersAllIds}    
                        onAddVacancies={onAddVacanciesInModal}
                        onClose={() => setVacanciesModalData(null)}
                        onCreateVacancy={onCreateVacancy}
                        groupsList={groupsList}
                        createdVacancyId={vacanciesLastCreatedId}
                        clearCreatedVacancyId={() => vacActs.clearLastCreatedId(dispatch)}
                    />
                }
                
                {relationIdToDelete && 
                    <ConfirmModalUi
                        header={
                            `Do you want to delete 
                            ${vacanciesMap[relationsMap[relationIdToDelete]?.vacancy_id]?.name} from 
                            ${map[relationsMap[relationIdToDelete]?.person_id]?.surname} 
                            ${map[relationsMap[relationIdToDelete]?.person_id]?.name}?`}
                        confirmText='Delete'
                        rejectText='Cancel'
                        open={!!relationIdToDelete}
                        onClose={() => setRelationIdToDelete(null)}
                        onConfirm={() => {
                            deleteRelation(relationIdToDelete)
                                .then(() => {
                                    setRelationIdToDelete(null)
                                })
                        }}
                        onReject={() => setRelationIdToDelete(null)}
                    />
                }
            </div>
    )
}

export default Person;
