import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    chosenId: null,
    limit: 10,
    offset: 0,
    map: {},
    allIds: [],
    editedId: null,
    selected: {},
    filteredAndSortedIds: [],
    fulltextSearchValue: '',
    searchOptions: null,
    searchByVacancyId: null,
    searchByBookingId: null,
    searchByStageTypeId: null,
    filterOptions: {
        inputValue: { value: '' }, 
        added: [],
    },
    sortingOption: {},
    canLoadMore: true,
    selectedErrors: {},
    totalCountBySearch: null,
    duplicateIds: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_CAN_LOAD_MORE:
            return {
                ...state,
                canLoadMore: action.payload,
            }

        case acts.SET_TOTAL_COUNT_BY_SEARCH:
            return {
                ...state,
                totalCountBySearch: action.payload
            }

        case acts.INCREMENT_TOTAL_COUNT_BY_SEARCH:
            return {
                ...state,
                totalCountBySearch: (state.totalCountBySearch || 0) + 1,
            }

        case acts.SET_CHOSEN_ID:
            return {
                ...state,
                chosenId: action.payload,
            }

        case acts.SET_EDITED_ID:
            return {
                ...state,
                editedId: action.payload,
            }

        case acts.SET_DUPLICATE_IDS:
            return {
                ...state,
                duplicateIds: action.payload,
            }

        case acts.SET_LIST:
            return {
                ...state,
                map: action.payload.map,
                allIds: action.payload.allIds,
            }
    
        case acts.APPEND_LIST:
            const newIds = (action.payload?.allIds || []).filter(id => !state.allIds.includes(id));
            return {
                ...state,
                map: {...state.map, ...action.payload.map},
                allIds: [...state.allIds, ...newIds],
            }

        case acts.SET_FILTERED_AND_SORTED_IDS:
            return {
                ...state,
                filteredAndSortedIds: action.payload,
            }

        case acts.ADD:
            return {
                ...state,
                map: {
                    [action.payload.service.id]: action.payload,
                    ...state.map
                },
                allIds: [
                    action.payload.service.id,
                    ...state.allIds
                ],
            }
    
        case acts.UPDATE:
            return {
                ...state,
                map: {
                    ...state.map,
                    [action.payload.service.id]: action.payload
                },
            }
    
        case acts.SET_FULLTEXT_SEARCH_VALUE:
            return {
                ...state,
                fulltextSearchValue: action.payload
            }

        case acts.SET_SEARCH_BY_VACANCY_ID:
            return {
                ...state,
                searchByVacancyId: action.payload
            }
            
        case acts.SET_SEARCH_BY_BOOKING_ID:
            return {
                ...state,
                searchByBookingId: action.payload,
            }
            
        case acts.SET_SEARCH_BY_STAGE_TYPE_ID:
            return {
                ...state,
                searchByStageTypeId: action.payload,
            }
            
        case acts.SET_SEARCH_OPTIONS:
            return {
                ...state,
                searchOptions: action.payload
            }

        case acts.DELETE_SEARCH_OPTIONS:
            return {
                ...state,
                searchOptions: null
            }
        
        case acts.SET_FILTER_OPTIONS_INPUT_VALUE:
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    inputValue: action.payload
                }
            }
    
        case acts.ADD_FILTER_OPTION:
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    added: [...state.filterOptions.added, action.payload],
                }
            }
      
        case acts.DELETE_FILTER_OPTION:
            let updatedAddedFilterOptions = [...state.filterOptions.added];
            updatedAddedFilterOptions.splice(action.payload, 1);
            return {
                ...state,
                filterOptions:{
                    ...state.filterOptions,
                    added: updatedAddedFilterOptions,
                } 
            }

        case acts.CLEAR_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: {
                    ...initialState.filterOptions,
                },
                filteredAndSortedIds: [
                    ...state.allIds,
                ]
            }
    
        case acts.SET_SORTING_OPTION:
            return {
                ...state,
                sortingOption: action.payload
            }

        case acts.SET_BOOKED:
            return {
                ...state,
                map: {
                    ...state.map,
                    [action.payload.person_id]: {
                        ...state.map[action.payload.person_id], 
                        booking: {
                            ...state.map[action.payload.person_id].booking,
                            is_booked: action.payload.is_booked,
                            booked_by: action.payload.booked_by,
                        }
                    }
                },
            }

            case acts.ADD_TAG_ID:
                return {
                    ...state,
                    map: {
                        ...state.map,
                        [action.payload.person_id]: {
                            ...state.map[action.payload.person_id], 
                            tag_ids: [
                                ...(state.map[action.payload.person_id].tag_ids || []),
                                action.payload.tag_id,
                            ]
                        }
                    },
                }

                case acts.DELETE_TAG_ID:
                    const updatedTagIds = (state.map[action.payload.person_id].tag_ids || [])
                        .filter(tagId => tagId !== action.payload.tag_id);
                    return {
                        ...state,
                        map: {
                            ...state.map,
                            [action.payload.person_id]: {
                                ...state.map[action.payload.person_id], 
                                tag_ids: updatedTagIds,
                            }
                        },
                    }
    
        case acts.UPDATE_SELECTED:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.payload.id]: {
                        ...(state.selected[action.payload.id] || {}),
                        ...action.payload.data
                    }
                }
            }

        case acts.DELETE_SELECTED:
            const selectedList = {...state.selected};
            delete selectedList[action.payload];
            return {
                ...state,
                selected: selectedList
            }
        
        case acts.SET_SELECTED_PERSON_ERRORS:
            return {
                ...state,
                selectedErrors: {
                    ...state.selectedErrors, 
                    [action.payload.id]: action.payload.personErrors
                }
            }

        case acts.SET_SELECTED_FIELD_ERRORS:
            const personErrors = {...state.selectedErrors[action.payload.id]} || {};

            action.payload.error ?
                personErrors[action.payload.fieldName] = action.payload.error
                :
                delete personErrors[action.payload.fieldName];

            return {
                ...state,
                selectedErrors: {
                    ...state.selectedErrors, 
                    [action.payload.id]: personErrors
                }
            }
        default:
            return state;
    }
}

export default reducer;

export const getMap = (state) => state.persons.map;
export const getAllIds = (state) => state.persons.allIds;
export const getFilteredAndSortedIds = (state) => state.persons.filteredAndSortedIds;
export const getFulltextSearchValue = (state) => state.persons.fulltextSearchValue;
export const getSearchByVacancyId = (state) => state.persons.searchByVacancyId;
export const getSearchByBookingId = (state) => state.persons.searchByBookingId;
export const getSearchByStageTypeId = (state) => state.persons.searchByStageTypeId;
export const getSearchOptions = (state) => state.persons.searchOptions;
export const getAllFilterOptions = (state) => 
    [
        ...state.persons.filterOptions.added, 
        state.persons.filterOptions.inputValue,
    ];
export const getAddedFilterOptions = (state) => state.persons.filterOptions.added;
export const getFilterOptionsInput = (state) => state.persons.filterOptions.inputValue;
export const getSortingOption = (state) => state.persons.sortingOption;
export const getLimit = (state) => state.persons.limit;
export const getEditedId = (state) => state.persons.editedId;