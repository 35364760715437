import React, { useEffect, useRef, useState } from "react"
import Option from './Option';

import "./styles.scss"

export type OptionType = {
    value: number | string,
    label: string,
    description?: string,
    payload?: any,
}

export type PropsType = {
    // handlers
    onDiscard?: Function
    loadOptions?: (text: string) => Promise<Array<OptionType>>

    icon?: JSX.Element

    withButton?: {
        title?: string,
        icon?: JSX.Element
        handler: (options: Array<string | number>) => void
    }

    withCreate?: {
        title?: string,
        icon?: JSX.Element
        handler: (createName: string) => Promise<OptionType> | Promise<void>
    }

    withOptionButton?: {
        title?: string,
        icon?: JSX.Element
        handler: (option: OptionType) => void
    }

    // controlled state of the component
    controlled?: {
        inputValue: string
        options: Array<OptionType>
        selectedOptions: Array<OptionType>

        onChangeInput: (value: string) => void
        onClickOption: (option: OptionType) => void
    }

    // basic props
    autoFocus?: boolean
    type?: string
    placeholder?: string
}

const InputSelectMultiple = ({
    controlled,

    onDiscard,
    loadOptions,

    autoFocus,
    placeholder,

    withButton,
    withCreate,
    withOptionButton,
}: PropsType) => {

    const [loading, setLoading] = useState(false)
    const [showOptions, setShowOptions] = useState(false)

    const [internalInputValue, setInternalInputValue] = useState('')
    const [internalOptions, setInternalOptions] = useState<Array<OptionType>>([])
    const [internalSelectedOptions, setInternalSelectedOptions] = useState<Array<OptionType>>([])
    
    // check if component is controlled by parent state or internal state
    const inputValue = controlled?.inputValue || internalInputValue
    const options = controlled?.options || internalOptions
    const selectedOptions = controlled?.selectedOptions || internalSelectedOptions

    const createButtonRef = useRef<HTMLButtonElement>(null)
    const inputSelectRef = useRef<HTMLDivElement>(null)
    const optionsDropdownRef = useRef<HTMLDivElement>(null)
    
    const preventDropdownClose = useRef(true)

    const onBlurTimeoutRef = useRef<number | null>(null)
    const onCloseTimeoutRef = useRef<number  | null>(null)

    useEffect(() => () => {
        // cancels timeouts before unmounting
        if (onBlurTimeoutRef.current) {
            window.clearTimeout(onBlurTimeoutRef.current)
        }
        
        if (onCloseTimeoutRef.current) {
            window.clearTimeout(onCloseTimeoutRef.current)
        }
    }, [])

    useEffect(() => {
        const closeOptionsDropdownOnClickElsewhere = (e: MouseEvent) => {
            if (preventDropdownClose.current) {
                preventDropdownClose.current = false;
                return
            }

            if (inputSelectRef.current?.contains(e.target as Node)
                ||
                optionsDropdownRef.current?.contains(e.target as Node)
            ) {
                return
            }

            setShowOptions(false);
        }
        window.addEventListener('click', closeOptionsDropdownOnClickElsewhere)

        return () => window.removeEventListener('click', closeOptionsDropdownOnClickElsewhere)
    }, [])

    let preventBlur = false
    let optionFocused = false

    const addOptionInternal = (option: OptionType) => {
        setInternalSelectedOptions(value => [...value, option]);
    }

    const removeOptionInternal = (option: OptionType) => {
        setInternalSelectedOptions(values => values.filter(v => v !== option));
    }

    const isOptionSelected = (option: OptionType) => {
        return selectedOptions.some(op => op.value === option.value)
    }

    const onClickOption = (option: OptionType) => {
        if (controlled) {
            controlled.onClickOption(option)
        } else {
            if (isOptionSelected(option)) {
                removeOptionInternal(option);
            } else {
                addOptionInternal(option);
            }
        }

        preventDropdownClose.current = true;
    }

    const onChangeHndl = (e: any) => {
        if (controlled) {
            controlled.onChangeInput(e.target.value)
        } else {
            setInternalInputValue(e.target.value)
        }
    }

    const onKeyPressHndl = (e: any) => {
        if (e.key === "Escape") {
            onDiscard && onDiscard()
        }
    }

    const onFocusHndl = () => {
        setShowOptions(true)
    }

    const onBlurHndl = () => {
        /**
         * timeout give opportunity to handle
         * onClick before options hide
         */ 
        onBlurTimeoutRef.current = window.setTimeout(() => {
            if (preventBlur) {
                return
            }

            setShowOptions(false)
        }, 100)
    }

    const onButtonClickHndl = () => {
        withButton?.handler(selectedOptions.map(op => op.value))
    }

    const onCreateClickHndl = (value: string) => async () => {
        if (!withCreate) {
            return
        }

        setLoading(true)
        try {
            let result = await withCreate.handler(value)

            result && !controlled && addOptionInternal(result)
        } catch (err) {
            // TODO: handle error
            console.log("got error:", err);
        }

        setLoading(false)
    }

    const onFocusOptionHndl = () => {
        optionFocused = true
        preventBlur = true
    }

    const closeOptions = () => {
        onCloseTimeoutRef.current = window.setTimeout(() => {
            if (optionFocused) {
                return
            }
            setShowOptions(false)
        }, 200)
    }

    const onBlurOptionHndl = () => {
        optionFocused = false
        closeOptions()
    }

    useEffect(() => {
        if (!controlled && loadOptions) {
            const load = async (text: string) => {
                setLoading(true)

                const opts = await loadOptions(text)
                
                setInternalOptions(opts)
                setLoading(false)
            }

            load(inputValue)
        }
    }, [loadOptions, inputValue, setLoading, controlled])

    const classes = `input-select-multiple-container ${withButton? "with-button": ""}`.trim()

    return (
        <div className={classes}>
            <div className={"input-select-multiple"} ref={inputSelectRef}>
                <div className="input-select-multiple-chosen-options">
                    {selectedOptions.map((opt, key) => (
                        <Option
                            onFocus={onFocusOptionHndl}
                            onBlur={onBlurOptionHndl}
                            onClick={() => onClickOption(opt)}
                            key={key}
                            className={"input-select-multiple-chosen-options-option"}
                        >
                            {opt.label}
                        </Option> 
                    ))}
                </div>
                <input
                    onKeyUp={onKeyPressHndl}
                    className={"input-select-multiple-input"}
                    onChange={onChangeHndl}
                    autoFocus={autoFocus}
                    placeholder={placeholder}

                    onFocus={onFocusHndl}
                    onBlur={onBlurHndl}
                />
                {showOptions &&
                    <div className={"input-select-multiple-options"} ref={optionsDropdownRef}>
                        {loading?
                            "loading..."
                            :
                            options.length > 0? options.map((opt, key) => {
                                if (isOptionSelected(opt)) {
                                    return null
                                }

                                return (
                                    <Option
                                        onFocus={onFocusOptionHndl}
                                        onBlur={onBlurOptionHndl}
                                        onClick={() => onClickOption(opt)}
                                        key={key}
                                    >
                                        <div className={"input-select-multiple-options-option"}>
                                            {opt.label}
                                            {withOptionButton &&
                                                <div
                                                    title={withOptionButton.title}
                                                    className={"input-select-multiple-options-option-button"}
                                                    onClick={(e) => {
                                                        withOptionButton.handler(opt);
                                                        setShowOptions(false);
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    {withOptionButton.icon}
                                                </div>
                                            }
                                        </div>
                                        <br/>
                                        <span className={"input-select-multiple-options-option-description"}>{opt.description}</span>
                                    </Option>        
                                )})
                                :
                                <div className={"input-select-multiple-options-empty"}>There is nothing for '{inputValue}'</div>
                            }

                            {withCreate && 
                                inputValue.length > 1 && !loading &&
                                <>
                                    <div className={"input-select-multiple-options-divider"}></div>

                                    <Option
                                        onFocus={onFocusOptionHndl}
                                        onBlur={onBlurOptionHndl}
                                        onClick={onCreateClickHndl(inputValue)}
                                        key={0}
                                    >
                                        Create: '{inputValue}'
                                    </Option>
                                </>
                                
                            }
                    </div>
                }
            </div>
            {withButton && 
                <button
                    ref={createButtonRef}
                    onClick={onButtonClickHndl}
                    disabled={inputValue.length < 2 && !selectedOptions.length}
                    className={"input-select-multiple-button"}
                >
                    <React.Fragment>
                        {withButton.icon && withButton.icon}
                        {withButton.title && withButton.title}
                    </React.Fragment>
                </button>
            }
        </div>
    )
}

export default InputSelectMultiple