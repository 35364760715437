import * as acts from './actionTypes';


const connectWebsocket = (onOpen) => ({
    type: acts.CONNECT,
    payload: onOpen,
});

const disconnectWebsocket = () => ({
    type: acts.DISCONNECT
});

const sendMessage = (message) => ({
    type: acts.SEND_MESSAGE,
    payload: message
});

export const connect = ({dispatch, onOpen}) => {
    dispatch(connectWebsocket(onOpen));
}

export const disconnect = (dispatch) => {
    dispatch(disconnectWebsocket());
}
export const subscribeToVoters = (dispatch) => {
    dispatch(sendMessage({
        action: 'subscribe',
        topic: 'voters'
    }));
}

export const send = ({dispatch, message}) => {
    dispatch(sendMessage(message));
}
