import React from 'react';
import { Grid, Header, Button, Icon } from 'semantic-ui-react';

import CardUi from '../../../components/NewCardUi';
import InputUi from '../../../components/NewInputUi';
import { parseIntegerInput } from '../../../utils';


const PersonSearchCard = ({
    data,
    editable,
    isPristine,
    onChangeField,
    onSubmit,
    onDiscard,
    errors,
    checkForErrors,
    onMinimize,
}) =>{
    const hasErrors = errors && Object.keys(errors).length !== 0;

    return (
        <CardUi >
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header>
                            <InputUi
                                name='surname'
                                value={data.surname}
                                id={data.service.id}
                                edit={editable}
                                onChange={
                                    (e)=>onChangeField({
                                        e, 
                                        id: data.service.id
                                    })
                                }
                                errors={errors && errors.surname}
                                checkForErrors={(value)=>
                                    checkForErrors({
                                        value, 
                                        validateOptions: {type: 'string', required: false, size: 255},
                                        fieldName: 'surname',
                                        personId: data.service.id
                                })}
                            />
                            <InputUi
                                name='name'
                                value={data.name}
                                id={data.service.id}
                                edit={editable}
                                onChange={
                                    (e)=>onChangeField({
                                        e, 
                                        id: data.service.id
                                    })
                                }
                                errors={errors && errors.name}
                                checkForErrors={(value)=>
                                    checkForErrors({
                                        value, 
                                        validateOptions: {type: 'string', required: false, size: 255},
                                        fieldName: 'name',
                                        personId: data.service.id
                                })}
                            />
                            <InputUi
                                name='midname'
                                value={data.midname}
                                id={data.service.id}
                                edit={editable}
                                onChange={
                                    (e)=>onChangeField({
                                        e, 
                                        id: data.service.id
                                    })
                                }
                                errors={errors && errors.name}
                                checkForErrors={(value)=>
                                    checkForErrors({
                                        value, 
                                        validateOptions: {type: 'string', required: false, size: 255},
                                        fieldName: 'midname',
                                        personId: data.service.id
                                })}
                            />
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <InputUi
                            name='city'
                            value={data.city}
                            id={data.service.id}
                            edit={editable}
                            onChange={
                                (e)=>onChangeField({
                                    e, 
                                    id: data.service.id
                                })
                            }
                            errors={errors && errors.city}
                            checkForErrors={(value)=>
                                checkForErrors({
                                    value, 
                                    validateOptions: {type: 'any', required: false, size: 255},
                                    fieldName: 'city',
                                    personId: data.service.id
                            })}
                        />
                        <InputUi
                            name='scope'
                            value={data.scope}
                            id={data.service.id}
                            edit={editable}
                            onChange={
                                (e)=>onChangeField({
                                    e, 
                                    id: data.service.id
                                })
                            }
                            errors={errors && errors.scope}
                            checkForErrors={(value)=>
                                checkForErrors({
                                    value, 
                                    validateOptions: {type: 'any', required: false, size: 255},
                                    fieldName: 'scope',
                                    personId: data.service.id
                            })}
                        />
                        <InputUi
                            name='email'
                            value={data.email}
                            id={data.service.id}
                            edit={editable}
                            onChange={
                                (e)=>onChangeField({
                                    e, 
                                    id: data.service.id
                                })
                            }
                            errors={errors && errors.email}
                            checkForErrors={(value)=>
                                checkForErrors({
                                    value, 
                                    validateOptions: {type: 'email', required: false, size: 255},
                                    fieldName: 'name',
                                    personId: data.service.id
                            })}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <InputUi
                            name='amount'
                            type='number'
                            value={data.money.amount || ''}
                            id={data.service.id}
                            edit={editable}
                            onChange={
                                (e)=>onChangeField({
                                    id: data.service.id,
                                    data: {
                                        money: {
                                            ...data.money,
                                            [e.target.name]: parseIntegerInput(e.target.value, 10)
                                        }
                                    }
                                })
                            }
                            errors={errors && errors.amount}
                            checkForErrors={(value)=>
                                checkForErrors({
                                    value, 
                                    validateOptions: {type: 'number', required: false, size: 255},
                                    fieldName: 'number',
                                    personId: data.service.id
                            })}
                        />
                        <InputUi
                            name='currency'
                            value={data.money.currency}
                            id={data.service.id}
                            edit={editable}
                            onChange={
                                (e)=>onChangeField({
                                    id: data.service.id,
                                    data: {
                                        money: {
                                            ...data.money,
                                            [e.target.name]: e.target.value
                                        }
                                    }
                                })
                            }
                            errors={errors && errors.currency}
                            checkForErrors={(value)=>
                                checkForErrors({
                                    value, 
                                    validateOptions: {type: 'string', required: false, size: 4},
                                    fieldName: 'currency',
                                    personId: data.service.id
                            })}
                        />
                        <InputUi
                            name='cell'
                            value={data.cell}
                            id={data.service.id}
                            edit={editable}
                            onChange={
                                (e)=>onChangeField({
                                    e, 
                                    id: data.service.id
                                })
                            }
                            errors={errors && errors.cell}
                            checkForErrors={(value)=>
                                checkForErrors({
                                    value, 
                                    validateOptions: {type: 'tel', required: false, size: 255},
                                    fieldName: 'cell',
                                    personId: data.service.id
                            })}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={2}>
                        <Button 
                            type='submit'
                            icon 
                            disabled={hasErrors || isPristine}
                            onClick={() => onSubmit({person: data})}
                        >
                            <Icon name='search'/>
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Button 
                            type='button'
                            icon 
                            disabled={isPristine}
                            onClick={()=>{
                                onDiscard(data.service.id); 
                            }}
                        >
                            <Icon name='close'/>
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Button 
                            type='button'
                            icon 
                            onClick={()=>{
                                onMinimize(); 
                            }}
                        >
                            <Icon name='angle up'/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </CardUi>
    )
}

export default PersonSearchCard;