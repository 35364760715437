import React, { useContext, useEffect, useMemo, useRef } from 'react';
import CommentGroupUi from '../../components/CommentGroupUi';
import Comment from './Comment';
import CommentFormUi from '../../components/CommentFormUi';

import { store } from '../../store';
import * as commentsActs from '../../redux/comments/actions';
import { Button } from 'semantic-ui-react';

import { handleError } from '../../errorHandler';
import { commentsToThread } from '../../utils';
import * as usersActs from '../../redux/users/actions';

const Comments = ({threadId, topicType}) => {
    const { state, dispatch } = useContext(store);

    const {
        comments: {
            loading,
            list,
            limit,
            offset,
            isCommenting,
        },
        users: {
            map
        }
    } = state;

    const requestedUserIds = useRef([]);

    const nestedComments = useMemo(()=>commentsToThread([...list]).nestedComments, [list]);

    useEffect(() => {
        commentsActs.loadList({
            dispatch: dispatch,
            id: threadId,
            type: topicType,
            offset: offset,
            limit: limit,
        })
        .catch(err => {handleError(err)});
    }, [threadId, topicType, dispatch, limit, offset]);

    useEffect(() => {
        const userIdsToLoad = [...new Set(list.map(comment => comment.service.created_by))]
            .filter(userIdToLoad => !map[userIdToLoad] && !requestedUserIds.current.includes(userIdToLoad));

        userIdsToLoad.map(userId => {
            return usersActs.load(dispatch, userId)
                .catch(err => handleError(err));
        });

        requestedUserIds.current = [...requestedUserIds.current, ...userIdsToLoad];
    }, [list, map, dispatch]);

    const onSubmitComment = (text, replyFor) => {
        commentsActs.add({
            dispatch: dispatch,
            id: threadId,
            type: topicType,
            content: text,
            replyFor: replyFor || null
        }).then(() => {

        }).catch(err => {
            // TODO: handle error
            console.log("[ERROR]", err);
            handleError(err);
        });
    }

    const onDiscardComment = () => {
        commentsActs.setIsCommenting(dispatch, false);
    }

    const onDeleteComment = (commentId) => {
        commentsActs.deleteComment(dispatch, commentId)
            .catch(err=>{handleError(err)})
    }

    const onAddingComment = () => {
        commentsActs.setIsCommenting(dispatch, true);
    }

    return (
        <div className="comments">
            {loading && <div>Loading...</div>}
            <CommentGroupUi>
                {isCommenting?
                    <CommentFormUi
                        onSubmit={onSubmitComment}
                        onDiscard={onDiscardComment}
                    />
                :
                    <Button
                        content="Comment"
                        onClick={onAddingComment}
                        icon="edit"
                        primary
                    />
                }

                {list && (list.length !== 0) && 
                    nestedComments
                    .sort((a, b) => b.service.id - a.service.id)
                    .map((comment, key) => {
                        return (
                            <Comment 
                                key={key}
                                data={comment} 
                                onSubmitComment={(text, replyFor)=>(onSubmitComment(text, replyFor))} 
                                onDiscardComment={onDiscardComment}
                                onDeleteComment={onDeleteComment}
                            />
                        )
                    })
                
                }

            </CommentGroupUi>
        </div>
    )
}

export default React.memo(Comments);