import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';
import { ErrorUnauthorized } from '../../errors'; 

const setList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
})

const setLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
})

const appendList = (data) => ({
    type: acts.APPEND_LIST,
    payload: data,
})

const updateGroupInList = (data) => ({
    type: acts.UPDATE,
    payload: data,
})

const updateGroupInSelected = (data) => ({
    type: acts.UPDATE_IN_SELECTED,
    payload: data
});

const deleteGroupFromSelected = (id) => ({
    type: acts.DELETE_FROM_SELECTED,
    payload: id
});

// async actions
export const loadList = async (dispatch) => {
    dispatch(setLoading(true));

    const data = await api.getList();

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw data;
    }

    dispatch(setList(data));

    dispatch(setLoading(false));
}

export const add = async ({dispatch, group}) => {
    dispatch(setLoading(true));

    const responseData = await api.create(group);

    if (responseData instanceof Error) {
        if (responseData.message === ErrorUnauthorized) {
            signout(dispatch);
            return
        }
        throw responseData;
    }

    dispatch(appendList([responseData]));
    dispatch(setLoading(false));
}

export const update = async ({dispatch, group}) => {

    let updateData = await api.update(group);

    if (updateData instanceof Error) {
        if (updateData.message === ErrorUnauthorized) {
            dispatch(signout());
            return
        }
        throw updateData;
    }
    dispatch(updateGroupInList(group));
}

export const updateInState = ({dispatch, group}) => {
    dispatch(updateGroupInList(group));
}

export const updateSelected = ({dispatch, id, data}) => {
    dispatch(updateGroupInSelected({id, data}));
}

export const deleteSelected = (dispatch, id) => {
    dispatch(deleteGroupFromSelected(id));
}
