import React from 'react';
import { Button } from 'semantic-ui-react';


export const ButtonUi = ({children, ...props}) => {
    return <Button {...props}>
        {children}
    </Button>
}

export default ButtonUi