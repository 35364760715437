import { Grid } from "semantic-ui-react"


export const GridUi = ({children, ...props}) => {
    return <Grid {...props}>
        {children}
    </Grid>
}

export const GridColumnUi = ({children, ...props}) => {
    return <Grid.Column {...props}>
        {children}
    </Grid.Column>
}

export const GridRowUi = ({children, ...props}) => {
    return <Grid.Row {...props}>
        {children}
    </Grid.Row>
}

export default GridUi;