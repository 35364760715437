import React, { useEffect, useState } from "react";

import CrossTableUi from "../../../components/StyledTableUi";

import { StageType } from "../../../entities/relation";
import { User } from "../../../entities/user";

type UsersChartProps = {
    stageTypesAllIds: number[];
    stageTypesMap: {
        [stageTypeId: number]: StageType,
    };
    usersChartData: {
        [userId: number]: {
            [stageTypeId: number]: number,
        }
    };
    usersAllIds: number[];
    usersMap: {
        [userId: number]: User,
    }
    userIdsToFilterOut: number[];
    onDownloadTable?: () => Promise<void>;
}

type SortingOptions = {
    valueToSortBy: number,
    order: "ascending" | "descending",
} | null;

const totalColumn = {id: -1, label: 'Total'}
const totalRow = {id: -1, label: 'Total'}

const UsersTable = ({
    usersChartData,
    stageTypesAllIds,
    stageTypesMap,
    usersAllIds,
    userIdsToFilterOut,
    usersMap,
    onDownloadTable,
}: UsersChartProps) => {

    const [sortingOptionsByUser, setSortingOptionsByUser] = useState<SortingOptions>(null);
    const [sortingOptionsByStage, setSortingOptionsByStage] = useState<SortingOptions>(null);

    const [sortedUserIds, setSortedUserIds] = useState<number[]>([]);
    const [sortedStageTypeIds, setSortedStageTypeIds] = useState<number[]>([]);

    useEffect(() => {
        let currentOptions = sortingOptionsByUser && {...sortingOptionsByUser};
        
        const stageTypesToShow = [...stageTypesAllIds];
        
        if (currentOptions) {
            stageTypesToShow.sort((a: number, b: number) => {

                const aValue = (
                    currentOptions && 
                    usersChartData[currentOptions.valueToSortBy] && 
                    usersChartData[currentOptions.valueToSortBy][a]
                ) || 0;

                const bValue = (
                    currentOptions && 
                    usersChartData[currentOptions.valueToSortBy] &&
                    usersChartData[currentOptions.valueToSortBy][b]
                ) || 0;
                return currentOptions?.order === 'descending' ? bValue - aValue : aValue - bValue;
            })
        }
        setSortedStageTypeIds(stageTypesToShow);

    }, [stageTypesAllIds, sortingOptionsByUser, usersChartData])

    

    useEffect(() => {
        let currentOptions = sortingOptionsByStage && {...sortingOptionsByStage};
        
        const userIdsToShow = [...usersAllIds];
        
        if (currentOptions) {
            userIdsToShow.sort((a: number, b: number) => {

                const aValue = (
                    currentOptions && 
                    usersChartData[a] && 
                    usersChartData[a][currentOptions.valueToSortBy]
                ) || 0;

                const bValue = (
                    currentOptions && 
                    usersChartData[b] &&
                    usersChartData[b][currentOptions.valueToSortBy]
                ) || 0;
                return currentOptions?.order === 'descending' ? bValue - aValue : aValue - bValue;
            })
        }
        setSortedUserIds(userIdsToShow);

    }, [usersAllIds, sortingOptionsByStage, usersChartData])
    
    const assembleValuesToCrossTableFormat = () => {
        const totalByUsers: number[] = [];

        const tableData = sortedStageTypeIds.map(stageTypeId => {
            
            const currentStageTypeValues: number[] = [];
            let totalByStageType = 0;

            sortedUserIds.forEach((userId: number, idx: number) => {
                if (userIdsToFilterOut[userId]) return;

                let stagesCountOfCurrentUserAndType = 0;
                totalByUsers[idx] = (totalByUsers[idx] || 0)

                if (
                    usersChartData &&
                    usersChartData[userId] &&
                    usersChartData[userId][stageTypeId]
                ) {
                    stagesCountOfCurrentUserAndType += usersChartData[userId][stageTypeId];
                    totalByStageType += usersChartData[userId][stageTypeId];
                    totalByUsers[idx] += usersChartData[userId][stageTypeId];
                }

                currentStageTypeValues.push(stagesCountOfCurrentUserAndType);
            })
            currentStageTypeValues.push(totalByStageType);

            return currentStageTypeValues;
        })
        tableData.push(totalByUsers);
        return tableData;
    }

    const getFilteredUsersAxisData = () => {
        let userAxisData: {id: number, label: string}[] = [];

        sortedUserIds.forEach((userId: number) => {
            if (userIdsToFilterOut[userId]) return;
            userAxisData.push({id: userId, label: usersMap[userId].surname + ' ' + usersMap[userId].name})
        })
        return [...userAxisData, totalColumn]
    }

    const getStageTypeAxisData = () => {
        return [...sortedStageTypeIds.map(id => ({id, label: stageTypesMap[id].name})), totalRow]
    }

    const sortByUser = (value: number) => {
        setSortingOptionsByUser(so => {
            if (so?.valueToSortBy === value) {
                return {
                    ...so, 
                    order: so.order === 'ascending' ? 'descending' : 'ascending',
                }
            }
            return {valueToSortBy: value, order: 'descending'}
        });
    }

    const sortByStage = (value: number) => {
        setSortingOptionsByStage(so => {
            if (so?.valueToSortBy === value) {
                return {
                    ...so, 
                    order: so.order === 'ascending' ? 'descending' : 'ascending',
                }
            }
            return {valueToSortBy: value, order: 'descending'}
        });
    }

    const sort = ({axis, value}: {axis: "x" | "y", value: number}) => {
        if (value === -1) return;

        if (axis === "x") {
            sortByUser(value);
        } else {
            sortByStage(value);
        }
    }

    return (
        <CrossTableUi
            data={assembleValuesToCrossTableFormat()}
            yHeader={getStageTypeAxisData()}
            xHeader={getFilteredUsersAxisData()}
            xAxisName="Users"
            yAxisName="Stage types"
            chosenYID={sortingOptionsByStage?.valueToSortBy}
            chosenXID={sortingOptionsByUser?.valueToSortBy}
            onClickSort={sort}
            onDownloadTable={onDownloadTable}
        />
    )

}

export default UsersTable;