export const service = {
    created_by: "",
    created_at: "",
    updated_by: "",
    updated_at: "",
}

export const profile = {
    // number
    id: -1,
    service: service,
    // string
    name: "",
}