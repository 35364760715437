import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import GroupsPage from './views/Groups';

import Workspace from './views/Workspace';
import Recruiter from './views/PageRecruiter';
import PersonsNew from './views/Persons/New';
import Person from './views/Persons/Person';
import Stages from './views/Stages';
import ProfilesPage from './views/Profiles';
import Vacancies from './views/Vacancies';
import UsersPage from './views/Users';
import ChainView from './views/Chain';
import CandidatesList from './views/Candidates';
import Statistics from './views/Statistics';
import Reports from 'compositions/Reports';


export const Routes = ({ isAuth }) => {

    if (!isAuth) {
        window.location.replace('https://matches.work/auth/login');
        return null
    }

    return (
        <Switch>
            <Route path='/' exact component={Workspace} />
            <Route path='/persons' exact component={Recruiter} />
            <Route path='/persons/new' component={PersonsNew} />
            <Route path='/persons/stages' component={Stages} />
            <Route path='/persons/:id' component={Person} />
            <Route path='/vacancies' component={Vacancies} />
            <Route path='/groups' component={GroupsPage} />
            <Route path='/profiles' component={ProfilesPage} />
            <Route path='/users' component={UsersPage} />
            <Route path='/chain' component={ChainView} />
            <Route path='/candidates' component={CandidatesList} />
            <Route path='/statistics' component={Statistics} />
            {/*  */}
            <Route path='/reports' component={Reports} />
            {/*  */}
            <Redirect to='/persons' />
        </Switch>
    )
}

export default React.memo(Routes);
