import React, { useContext, useEffect, useState, useRef } from 'react';
import mammoth from 'mammoth';
import { Accordion, Button, Form, Icon, TextArea } from 'semantic-ui-react';

import Loader from '../../../components/Loader';

import "./Files.scss";

import * as filesActs from '../../../redux/files/actions';
import * as sharedApi from '../../../redux/shared/api';
import { store } from '../../../store';
import { copyToClipboard, decodeFileData } from '../../../utils';
import { handleError } from '../../../errorHandler';
import ButtonUi from '../../../components/ButtonUi';

const Files = ({personId: chosenId}) => {
    const { state, dispatch } = useContext(store);

    const fileInputRef = useRef(null);

    const [chosenFiles, setChosenFiles] = useState([]);
    const [activeFileIndex, setActiveFileIndex] = useState(0);
    const [preparedFiles, setPreparedFiles] = useState([]);
    const [isPreparing, setIsPreparing] = useState(false);
    const [showTextForm, setShowTextForm] = useState(false);

    const {
        files: {
            list,
            loading
        },
    } = state;

    useEffect(() => {
        let isMounted = true;
        filesActs.loadList({dispatch, id: chosenId, offset: 0, limit: 10})
            .then(() => {isMounted && setActiveFileIndex(0)})
            .catch(err=>{handleError(err)})
        return () => {isMounted = false}
    }, [chosenId, dispatch])

    useEffect(()=>{
        setIsPreparing(true);

        if (!list) {
            setIsPreparing(false);
            return
        }

        let preparedList = [];

        (async () => {
            for (const file of list) {
                switch (file.ext) {
                    case "pdf":
                        preparedList.push({...file, binary_data: encodeURI(file.binary_data)});
                        break
                    case "txt":
                        preparedList.push({...file, binary_data: decodeFileData(file.binary_data)});
                        break
                    case 'docx':
                        const parsedDocx = await mammoth.convertToHtml({arrayBuffer: atob((file.binary_data))});
                        preparedList.push({...file, binary_data: parsedDocx.value});
                        break
                    default:
                        preparedList.push(file);
                        break
                }
            }
            setPreparedFiles(preparedList);
            setIsPreparing(false);
        })();
    }, [list]);

    const handleAccordionClick = (id) => {
        const newIndex = (activeFileIndex === id) ? -1 : id;
        setActiveFileIndex(newIndex);
    }

    const prepareFileToSubmit = (files) => {
        const firstFile = files[0];

        let fd = new FormData();
        fd.append("file", firstFile);
        return fd
    }

    const prepareTextToSubmitAsFile = (text) => {
        const fileName = new Date().toISOString() + '.txt';
        const file = new File([text], fileName);
        return prepareFileToSubmit([file]);
    }

    const handleChangeFiles = async (e) => {
        const files = e.target.files;
        if (!files || files.length === 0) {
            // TODO: set error
            return null;
        }

        setChosenFiles(files);
    }

    const handleShare = async (file_id) => {
        const hash = await sharedApi.shareFile(file_id);

        const sharedUrl = `https://matches.work/shared/${hash}`;

        await copyToClipboard(sharedUrl);
    }

    return (
        <div className="files-ui">
            {!isPreparing ? 
                (preparedFiles.length !== 0) && <Accordion styled fluid>
                    {preparedFiles.map((file, idx)=>
                            <div key={idx}>
                                <Accordion.Title
                                    index={idx}
                                    active={activeFileIndex===idx}
                                    onClick={()=>handleAccordionClick(idx)}
                                >
                                    <Icon name='dropdown'/>
                                    {file.file_name}
                                </Accordion.Title>
                                {activeFileIndex===idx && 
                                    <Accordion.Content
                                        active={activeFileIndex===idx}
                                    >
                                        <div>
                                            {(() => {
                                                var blob = new Blob([file.binary_data], {
                                                    type: 'octet/stream'
                                                });

                                                const href = window.URL.createObjectURL(blob)
                                                return (
                                                    <>
                                                        <a download={`${file.file_name}.${file.ext}`} href={href}>
                                                            <ButtonUi size="mini">
                                                                Download
                                                            </ButtonUi>
                                                        </a>
                                                        
                                                        <ButtonUi size="mini" disabled={file.ext !== "txt"} onClick={() => handleShare(file.service.id)}>
                                                            Share
                                                        </ButtonUi>
                                                        
                                                    </>
                                                )
                                            })()}
                                        </div>
                                        {(()=>{
                                            switch (file.ext) {
                                                case "pdf":
                                                    return <iframe
                                                        className="files-ui-iframe"
                                                        title={idx}
                                                        frameBorder="0"
                                                        src={"data:application/pdf;base64, " + file.binary_data}
                                                    >
                                                    </iframe>
                                                case "txt":
                                                    return <p dangerouslySetInnerHTML={{__html: file.binary_data}}/>
                                                case 'docx':
                                                    return <div dangerouslySetInnerHTML={{__html: file.binary_data}}/>
                                                default:
                                                    return "file with ext" + file.ext;
                                            }
                                        })()}
                                    </Accordion.Content>
                                }
                            </div>
                        )
                    }
                </Accordion>
                :
                <Loader />
            }

            {showTextForm && 
                <Form onSubmit={(e) => {
                    filesActs.upload({
                        dispatch, 
                        file: prepareTextToSubmitAsFile(e.target.children.text.value), 
                        personId: chosenId
                    }).then(()=>{setShowTextForm(false)})
                    .catch(err=>handleError(err))
                }}>
                    <TextArea required name="text"/>
                    <Button 
                        type="submit" 
                        icon="save"
                        disabled={loading}
                    />
                    <Button
                        type="button"
                        icon="close"
                        onClick={()=>setShowTextForm(false)}
                    />
                </Form>
            }

            <div className='files-ui-buttons'>
                {!showTextForm && 
                    <Button 
                        type="button"
                        content="Add notes" 
                        labelPosition="left"
                        icon="edit"
                        onClick={()=>setShowTextForm(true)}
                    />
                }

                <Button
                    content="Choose File"
                    disabled={isPreparing}
                    labelPosition="left"
                    icon="file"
                    onClick={() => fileInputRef.current.click()}
                />
                <input
                    ref={fileInputRef}
                    type="file"
                    hidden
                    onChange={handleChangeFiles}
                />

                {chosenFiles && chosenFiles.length > 0 &&
                    <Button
                        disabled={loading}
                        onClick={() => {
                            filesActs.upload({
                                dispatch, 
                                file: prepareFileToSubmit(chosenFiles), 
                                personId: chosenId
                            }).then(()=>{setChosenFiles([])})
                            .catch(err=>handleError(err))
                        }}
                    >
                        Upload file
                    </Button>
                }
            </div>
        </div>
    )
}

export default React.memo(Files);