import React, { useEffect, useContext } from "react";
import { store } from "../../store";

import * as personsActs from '../../redux/persons/actions';
import * as usersActs from '../../redux/users/actions';
import * as vacActs from '../../redux/vacancies/actions';
import * as relActs from '../../redux/relations/actions';


import CandidatesList from "./CandidatesList";
import { handleError } from "../../errorHandler";


const CandidatesWrapper = () => {
    const { state, dispatch } = useContext(store);

    const {
        persons: {
            map,
            allIds,
            filteredAndSortedIds,
            filterOptions: {
                added
            },
            limit,
            canLoadMore: perCanLoadMore,
            searchByVacancyId,
            fulltextSearchValue
        },
        vacancies: {
            map: vacMap,
            allIds: vacIds,
            searchedIds: vacSearchedIds,
            canLoadMore: vacCanLoadMore,
            fulltextSearchValue: vacFullTextSearchValue,
        },
        users: {
            map: usersMap,
            allIds: usersIds
        },
        relations: {
            byPersonId,
            stageTypesMap: stMap,
            stagesMapByRelationId,
        }
    } = state;

    useEffect(() => {
        personsActs.loadList({ dispatch, offset: 0, limit, appendList: false})
            .catch(err => handleError(err));
            
        usersActs.loadList(dispatch)
            .catch(err => handleError(err));

        return () => {
            personsActs.clearFilters(dispatch);
            personsActs.clearSearch(dispatch);
            vacActs.setFulltextSearchValue({dispatch, value: ''});
        };
    }, [dispatch, limit]);

    const data = {
        candidatesIds: filteredAndSortedIds,
        candidatesMap: map,
        filterOptions: {
            addedFilters: added
        },
        fulltextSearchValue,
        searchByVacancyValue: vacFullTextSearchValue,
        vacanciesIds: vacIds,
        vacanciesMap: vacMap,
        vacanciesSearchedIds: vacSearchedIds,
        usersIds: usersIds,
        usersMap: usersMap,
        relationsByPersonId: byPersonId,
        stageTypesMap: stMap,
		stagesMapByRelationId,
    }

    const onChangeFulltextSearch = (value) => {
        personsActs.searchByTextAndBookingId({ dispatch, value })

        vacActs.setFulltextSearchValue({dispatch, value: ''});
    }

    const onChangeVacanciesFulltextSearch = (searchText) => {
        vacActs.debouncedStartSearch({dispatch, limit, searchText});
        vacActs.setFulltextSearchValue({dispatch, value: searchText});
    }

    const onSearchByVacancy = (vacancyId) => {
        vacActs.setFulltextSearchValue({ dispatch, value: vacMap[vacancyId]?.name})
        personsActs.startSearchByVacancy({ dispatch, limit, vacancyId })
            .catch(err => handleError(err));
    }
    const onDiscardVacanciesFulltextSearch = () => {
        personsActs.loadList({ dispatch, offset: 0, limit, appendList: false})
            .catch(err => handleError(err));
        personsActs.setSearchByVacancyId({dispatch, id: null});
        vacActs.setFulltextSearchValue({dispatch, value: ''});
    }

    const onClickLoadMoreVacanciesFulltextSearch = () => {
        vacCanLoadMore && vacActs.search({ dispatch, limit, offset: vacSearchedIds.length})
    }

    const onChangeFilter = (filterOption) => {
        personsActs.filter({ dispatch, filterOption })
    };

    const onDeleteFilter = (index) => {
        personsActs.deleteFilterOption({dispatch, index})
    }

    const onAddFilter = (option) => {
        personsActs.addFilterOption({dispatch, option})
    }

    const loadHandler = () => {
        if (perCanLoadMore) {
            searchByVacancyId ?
                personsActs.searchByVacancy({ dispatch, offset: allIds.length, limit})
                :
                personsActs.loadList({ dispatch, offset: allIds.length, search: fulltextSearchValue, limit})
                    .catch(err => handleError(err));
        }
    }

    const onDeleteStage = ({ relationId, stageId }) => {
        relActs.deleteStage({ dispatch, relationId, stageId })
            .catch(err => handleError(err))
    }

    const handlers = {
        onChangeFilter,
        onAddFilter,
        onDeleteFilter,
        loadHandler,
        onChangeFulltextSearch,
        onChangeVacanciesFulltextSearch,
        onClickVacanciesFulltextSearch: onSearchByVacancy,
        onDiscardVacanciesFulltextSearch,
        onClickLoadMoreVacanciesFulltextSearch,
        onDeleteStage
    }

    return (
        <CandidatesList 
            data={data}
            handlers={handlers}
        />
    )
}

export default CandidatesWrapper;