import React, { useEffect, useRef, useState } from "react";
import ButtonUi from "../../../components/ButtonUi";
import FilterInputsUi from "../../../components/FilterInputsUi";
import { IconUi } from "../../../components/IconUi";
import InputUi from "../../../components/StyledInputUi";
import ModalUi from "../../../components/ModalUi";
import { SearchUi } from "../../../components/SearchUi";
import ServiceData from "../../../components/ServiceData";
import { FilterOption, Person } from "../../../entities/person";
import { Relation, Stage, StageType } from "../../../entities/relation";
import { User } from "../../../entities/user";
import { Vacancy } from "../../../entities/vacancy";
import { personFilterFieldsTree } from "../../../person";
import { avoidZeroDate, tryToNormalizeDate } from "../../../utils";

import "./Candidates.scss";

type CandidatesListData = {
    candidatesIds: number[],
    candidatesMap: {
      [id: string]: Person
    },
    vacanciesIds: number[],
    vacanciesMap: {
      [id: string]: Vacancy
    },
    vacanciesSearchedIds: number[],
    fulltextSearchValue: string,
    searchByVacancyValue: string,
    usersIds: number[],
    usersMap: {
      [id: string]: User
    },
    relationsByPersonId: {
      [id: number]: Relation[]
    },
    stageTypesMap: {
      [id: string]: StageType
    },
    stagesMapByRelationId: {
      [id: string]: Stage[]
    },
    filterOptions:{
        addedFilters: FilterOption[];
        inputValue: string
    }
}

type CandidatesListHandlers = {
    onChangeFulltextSearch: (value: string) => void,
    onChangeVacanciesFulltextSearch: (value: string) => void,
    onClickVacanciesFulltextSearch: (value: string) => void,
    onDiscardVacanciesFulltextSearch: () => void,
    onClickLoadMoreVacanciesFulltextSearch: () => void,
    onChangeFilter: ({value, prop}: {value: string, prop: string}) => void,
    onDeleteFilter:  (index: number) => void,
    onAddFilter: ({value, prop}: {value: string, prop: string}) => void,
    loadHandler: (listLength: number) => void,
    onDeleteStage: ({relationId, stageId}: {relationId: number, stageId: number}) => void,
}

const CandidatesList = (
	{data, handlers}: {
		data: CandidatesListData, 
		handlers: CandidatesListHandlers
	}
) => {

	const {
		candidatesIds,
		candidatesMap,
        fulltextSearchValue,
        searchByVacancyValue,
		vacanciesMap,
        vacanciesSearchedIds,
		relationsByPersonId,
		stageTypesMap,
		stagesMapByRelationId,
        filterOptions: {
            addedFilters,
        },
	} = data;

    const {
        onChangeFulltextSearch,
        onChangeVacanciesFulltextSearch,
        onClickVacanciesFulltextSearch,
        onDiscardVacanciesFulltextSearch,
        onClickLoadMoreVacanciesFulltextSearch,
        onChangeFilter,
        onAddFilter,
        onDeleteFilter,
        loadHandler,
        onDeleteStage
    } = handlers

    const [activePopUpIdx, setActivePopUpIdx] = useState<number | null>(null);
    const [stageToDelete, setStageToDelete] = useState<{stageId: number, relationId: number, typeId: number} | null>(null);
    const [showModalMenu, setShowModalMenu] = useState<boolean>(false);
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<{x: number, y: number} | null>(null);

    const contextMenuRef = useRef<HTMLDivElement>();

    const onContextMenuStage = ({e, stageId, relationId, typeId}: {e: React.MouseEvent<HTMLDivElement, MouseEvent>, stageId: number, relationId: number, typeId: number}) => {
        e.preventDefault();
        setContextMenuPosition({x: e.pageX, y: e.pageY});
        setStageToDelete({stageId, relationId, typeId});
        setShowContextMenu(true);
    }

    useEffect(() => {
        const closeContextMenu = (e: MouseEvent) => {
            if (contextMenuRef.current && e.target && contextMenuRef.current.contains(e.target as Node)) {
                return
            }
            setStageToDelete(null);
            setShowContextMenu(false);
            setContextMenuPosition(null);
            setShowModalMenu(false);
        }
        window.addEventListener('click', closeContextMenu);

        return () => window.removeEventListener('click', closeContextMenu)
    }, []);

 
    return (
        <div className='candidates-container'>
            <div className='candidates-toolbar'>
                <InputUi 
                    value={fulltextSearchValue}
                    placeholder='Search'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                        onChangeFulltextSearch(e.currentTarget.value)
                    }
                />
                <SearchUi 
                    value={searchByVacancyValue}
                    placeholder='Search by vacancies'
                    onSearchChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                        onChangeVacanciesFulltextSearch(e.currentTarget.value)
                    }
                    onResultSelect={({value} : {value: string}) => 
                        onClickVacanciesFulltextSearch(value)
                    }
                    onSearchDiscard={() => onDiscardVacanciesFulltextSearch()}
                    onClickLoadMore={() => onClickLoadMoreVacanciesFulltextSearch()}
                    results={
                        vacanciesSearchedIds
                            .map(vId => ({
                                title: vacanciesMap[vId].name,
                                value: vacanciesMap[vId].service.id,
                            }))
                    }
                />
                <FilterInputsUi
                    optionsList={addedFilters} 
                    propsToFilterBy={personFilterFieldsTree} 
                    onChange={onChangeFilter}
                    onDelete={onDeleteFilter}
                    onAdd={onAddFilter}
                />
            </div>
            <div className='candidates'>
                {candidatesIds.map(cId =>
                    candidatesMap[cId] && <div className='candidates-person' key={cId}>
                        <div className='candidates-person-info'>
                            <div className='candidates-person-info-name'>
                                <a href={'/persons/' + cId} target='_blank' rel="noreferrer">
                                    {`${candidatesMap[cId].surname} ${candidatesMap[cId].name} ${candidatesMap[cId].midname}`}
                                </a>
                                <IconUi name='copy'/>
                                <ServiceData 
                                    content={
                                        tryToNormalizeDate(
                                            avoidZeroDate(candidatesMap[cId].service.created_at)
                                            )
                                        }
                                />
                            </div>
                            <div className='candidates-person-info-city'>
                                {candidatesMap[cId].city}                        
                            </div>
                            <div className='candidates-person-info-contacts'>
                                <div>
                                    {candidatesMap[cId].cell}
                                    {candidatesMap[cId].cell && <IconUi name='copy'/>}
                                </div>
                                <div>
                                    {candidatesMap[cId].email}
                                    {candidatesMap[cId].email && <IconUi name='copy'/>}
                                </div>
                            </div>
                        </div>
                        <div className='candidates-person-relations'>
                            {relationsByPersonId[cId]?.map((rel) =>
                                <div className='candidates-person-relations-relation' key={rel.id}>
                                    <div className='candidates-person-relations-relation-vacancy'>
                                        <div className='candidates-person-relations-relation-vacancy'>
                                            {vacanciesMap[rel.vacancy_id].name}
                                        </div>
                                        
                                        <div className='candidates-person-relations-relation-vacancy'>
                                            {vacanciesMap[rel.vacancy_id].profile?.name}
                                        </div>

                                        <div className='candidates-person-relations-relation-vacancy'>
                                            {vacanciesMap[rel.vacancy_id].money.amount ?
                                                vacanciesMap[rel.vacancy_id].money.amount + ' ' + vacanciesMap[rel.vacancy_id].money.currency
                                                :
                                                ''
                                            }
                                        </div>
                                        
                                        <div className='candidates-person-relations-relation-vacancy'>
                                            {vacanciesMap[rel.vacancy_id].group?.name}
                                        </div>
                                    </div>
                                    <div className='candidates-person-relations-relation-chain'>
                                        {stagesMapByRelationId[rel.id]?.sort((a, b) => a.id - b.id).map((s) =>
                                            <div 
                                                className='candidates-person-relations-relation-chain-link'
                                                style={{borderColor: stageTypesMap[s.type_id]?.color}}
                                                onClick={() => setActivePopUpIdx(id => id === s.id ? null : s.id)}
                                                key={s.id}
                                                onContextMenu={(e) => onContextMenuStage({e, relationId: rel.id, stageId: s.id, typeId: s.type_id})}
                                            >
                                                <div>
                                                    {stageTypesMap[s.type_id]?.name}
                                                </div>
                                                <div>
                                                    {tryToNormalizeDate(avoidZeroDate(s.created_at))}
                                                </div>
                                                {activePopUpIdx === s.id &&
                                                    <div className='candidates-person-relations-relation-chain-link-pop-up'>
                                                        <div>
                                                            <div>
                                                                {stageTypesMap[s.type_id]?.name}
                                                            </div>
                                                            <div>
                                                                {tryToNormalizeDate(avoidZeroDate(s.created_at))}
                                                            </div>
                                                            <div>
                                                                {s.comment}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className='load-button'>
                    <ButtonUi onClick={() => loadHandler(candidatesIds.length)}>
                        Load More
                    </ButtonUi>
                </div>
            </div>
            {showModalMenu && stageToDelete && <ModalUi 
                header='Are you sure you want to delete this stage permanently?'
                open={true}
                onClose={()=>setStageToDelete(null)}
                className='delete-modal'
            >
                <ButtonUi onClick={()=>setStageToDelete(null)}>
                    Cancel
                </ButtonUi>
                <ButtonUi
                    onClick={() => {
                        onDeleteStage({relationId: stageToDelete.relationId, stageId: stageToDelete.stageId});
                        setStageToDelete(null);
                        setShowModalMenu(false);
                    }}
                    className='delete-modal-confirm-button'
                >
                    Delete stage {stageTypesMap[stageToDelete.typeId].name}
                </ButtonUi>
            </ModalUi>}
            {showContextMenu && stageToDelete && <div 
                className='context-menu' 
                style={{top:contextMenuPosition?.y, left: contextMenuPosition?.x}}
                ref={(el) => {
                    if (el) {
                        contextMenuRef.current = el
                    }
                }}
            >
                <div 
                    className='context-menu-option'
                    onClick={() => {
                        setShowContextMenu(false);
                        setShowModalMenu(true);
                    }}
                >
                    Delete
                </div>
            </div>
            }
        </div>
    )
}


export default CandidatesList;