import axios from 'axios';
import { ErrorUnauthorized } from '../../errors';

const rootRef = "/matches/api/v1";
const commentsRef = "/comments"

export const getComments = async ({id, type, offset, limit}) => {
    try {
        const response = await axios(
            `${rootRef}${commentsRef}/?topic_type=${type}&id=${id}&offset=${offset}&limit=${limit}`,
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const addComment = async ({id, type, content, replyFor}) => {
    try {
        const response = await axios.post(
            `${rootRef}${commentsRef}`,
            {
                topic_id: id,
                topic_type: type,
                content: content,
                is_reply: !!replyFor,
                reply_for: replyFor
            }
        );

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}

export const deleteComment = async (commentId) => {
    try {
        const response = await axios.delete(`${rootRef}${commentsRef}/${commentId}`);

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }

            return new Error(err.response.statusText)
        }

        return err
    }
}