import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import './BarChart.scss';

type Column = {
    name: string,
    values : {
        [stageName: string]: number | string;
    }
};

type XAxisData = Array<{
    id: number;
    name: string;
    show: boolean;
}>;

type BarChartProps = {
    data: Column[];
    title: string;
    XAxisData: XAxisData;
    onClickLegend?: (id: number | string) => void,
    stacked?: boolean;
    height?: number;
}

const chartColors = [
    "#006699",
    "#FF6600",
    "#009933",
    "#D62728",
    "#BD9467",
    "#8C564B",
    "#E377C2",
    "#7F7F7F",
    "#BCBD22",
    "#17BECF",
];
  
const BarChartUi = ({
    data,
    onClickLegend,
    XAxisData,
    stacked,
    height=400,
}: BarChartProps) => {
    if (!data || !data.length) return null;


    return (
        <div>
            <div>
                <ResponsiveContainer width='100%' height={height}>
                    <BarChart
                        data={data.map(cd => ({name: cd.name, ...cd.values}))}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        {XAxisData.map((columnData, idx) => {
                            return <Bar
                                id={columnData.id.toString()}
                                key={columnData.name}
                                hide={!columnData.show}
                                dataKey={columnData.name}
                                stackId={stacked ? "all" : undefined}
                                fill={chartColors[idx % 10]}
                            />;
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className="legend">
                {XAxisData.map((columnData, idx) => (
                    <div 
                        className={`legend-item ${columnData.show ? '' : 'legend-item-hide'}`}
                        onClick={() => onClickLegend && onClickLegend(columnData.id)}
                        key={columnData.id}
                    >
                        <div className="legend-item-color-box" style={{backgroundColor: chartColors[idx % 10]}} />
                        {columnData.name}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BarChartUi;