import * as acts from './actionTypes';

export const initialState = {
    loading: false,
    list: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case acts.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case acts.SET_LIST:
            return {
                ...state,
                list: action.payload,
            }
        case acts.APPEND_LIST:
            return {
                ...state,
                list: [
                    ...state.list, 
                    ...action.payload
                ]
            }
    
        default:
            return state;
    }
}

export default reducer;