const prefix = "VACANCIES::";

export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_CHOSEN_ID = `${prefix}SET_CHOSEN_ID`;
export const SET_CAN_LOAD_MORE = `${prefix}SET_CAN_LOAD_MORE`;
export const SET_LAST_CREATED_ID = `${prefix}SET_LAST_CREATED_ID`;
export const SET_LIST = `${prefix}SET_LIST`;
export const UPDATE_SELECTED_VACANCY = `${prefix}UPDATE_SELECTED_VACANCY`;
export const DELETE_SELECTED_VACANCY = `${prefix}DELETE_SELECTED_VACANCY`;
export const APPEND_LIST = `${prefix}APPEND_LIST`;
export const UPDATE_VACANCY= `${prefix}UPDATE_VACANCY`;
export const ADD_VACANCY_TO_LIST= `${prefix}ADD_VACANCY_TO_LIST`;
export const ADD_VACANCY_TO_QUICK_ACCESS_LIST= `${prefix}ADD_VACANCY_TO_QUICK_ACCESS_LIST`;
export const SET_GROUP = `${prefix}SET_GROUP`;
export const SET_PROFILE = `${prefix}SET_PROFILE`;
export const SET_STATUS_TYPES = `${prefix}SET_STATUS_TYPES`;
export const SET_STATUS = `${prefix}SET_STATUS`;

export const SET_SEARCHED_LIST = `${prefix}SET_SEARCHED_IDS`;
export const APPEND_SEARCHED_LIST = `${prefix}APPEND_SEARCHED_IDS`;

export const SET_FULLTEXT_SEARCH_VALUE = `${prefix}SET_FULLTEXT_SEARCH_VALUE`;