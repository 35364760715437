import * as acts from './actionTypes';
import * as api from './api';
import { signout } from '../auth/actions';

import { ErrorUnauthorized } from '../../errors';

const setFilesList = (data) => ({
    type: acts.SET_LIST,
    payload: data,
});

const appendFilesToList = (data) => ({
    type: acts.APPEND_LIST,
    payload: data
});

const setFilesLoading = (load) => ({
    type: acts.SET_LOADING,
    payload: load,
});

export const loadList = async ({dispatch, id, offset, limit}) => {
    dispatch(setFilesLoading(true));

    const data = await api.getFiles({id, offset, limit});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setFilesLoading(false));
            return
        }
        dispatch(setFilesLoading(false));
        throw data
    }

    dispatch(setFilesList(data || []));
    dispatch(setFilesLoading(false));
}

export const load = async (dispatch, fileId) => {
    setFilesLoading(dispatch, true);

    const data = await api.getFile(fileId);

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setFilesLoading(false));
            return
        }
        dispatch(setFilesLoading(false));
        throw data
    }

    dispatch(appendFilesToList([data]));

    dispatch(setFilesLoading(false));
}

export const upload = async ({dispatch, file, personId}) => {
    setFilesLoading(dispatch, true);

    const data = await api.uploadFile({file, personId});

    if (data instanceof Error) {
        if (data.message === ErrorUnauthorized) {
            dispatch(signout());
            dispatch(setFilesLoading(false))
            return
        }
        dispatch(setFilesLoading(false))
        throw data
    }

    await load(dispatch, data.id);

    dispatch(setFilesLoading(false));
}

