import React, {
    useCallback,
    useContext, 
    useEffect,
    useRef,
    useState
} from 'react';

import Files from '../Persons/Files';
import PersonsList from './PersonsList';
import Comments from '../Comments';

import { store } from '../../store';
import { setChosenId } from '../../redux/persons/actions';
import { throttle } from '../../utils';

import "./Workspace.scss";

export const Workspace = () => {
    const { dispatch, state } = useContext(store);

    const {persons: {
        chosenId
    }} = state

    const [rightSplitHeght, setRightSplitHeight] = useState(undefined);
    const rightSplitRef = useRef();

    useEffect(() => {
        const updateRightSplitHeight = () => {
            if (rightSplitRef) {
                const rect = rightSplitRef.current.getBoundingClientRect();
                if (rect.bottom !== window.innerHeight) {
                    const updatedHeight = window.innerHeight - rect.top
                    setRightSplitHeight(rph => updatedHeight > window.innerHeight ? rph : updatedHeight);
                }
            }
        }

        const throttledUpdateRightSplitHeight = throttle(updateRightSplitHeight, 200)
        
        window.addEventListener('scroll', throttledUpdateRightSplitHeight);
        updateRightSplitHeight();

        return () => {
            window.removeEventListener('scroll', throttledUpdateRightSplitHeight)
        }
    }, [])

    const onClickPersons = useCallback(p => setChosenId(dispatch, p.service.id), [dispatch]);

    return (
        <div className="workspace">
            <div className="workspace-split">
                <div className="workspace-split-left">
                    <PersonsList
                        onClick={onClickPersons}
                    />
                </div>

                <div className="workspace-split-right workspace-sticky"
                    style={{
                        height: rightSplitHeght + 'px', 
                        transition: 'height .2s ease-in-out'
                    }}
                    ref={(el) => {
                        if (el) {
                            rightSplitRef.current = el
                        }
                    }}
                >

                {chosenId && 
                    <>
                        <div className="workspace-top ui segment">
                            <Files personId={chosenId}/>
                        </div>
                        <div className="workspace-bottom ui segment">
                            <Comments threadId={chosenId} topicType="persons"/>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Workspace;
