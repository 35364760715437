import React from "react";

import BarChartUi from "../../../components/ChartUi/BarChartUi";

type GroupsChartProps = {
    data: Array<{name: string, values: {[stageTypeName: string]: number}}>;
    stageTypes: Array<{id: number, name: string, show: boolean}>;
    onClickStageTypeLegend: (id: number | string) => void;
}

const GroupsChart = ({
    data,
    stageTypes,
    onClickStageTypeLegend
}: GroupsChartProps) => {
    return (
        <BarChartUi 
            data={data}
            title='Groups'
            XAxisData={stageTypes}
            onClickLegend={onClickStageTypeLegend}
            stacked={true}
        />
    )
}

export default GroupsChart;