import axios from 'axios';

import { ErrorUnauthorized } from '../../errors';

const authRef = "/auth";


export const isAuthorized = async () => {

    const url = `${authRef}/authorized`;

    try {
        const response = await axios(url); 

        return response.data;
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                return Error(ErrorUnauthorized)
            }
            return new Error(err.response.statusText)
        }

        return err
    }
}