import * as acts from './actionTypes';
import * as api from './api';

import { ErrorUnauthorized } from '../../errors';


const setReady = (isReady) => ({
    type: acts.SET_READY,
    payload: isReady
});

const signin = () => ({type: acts.SIGN_IN});

export const signout = () => ({type: acts.SIGN_OUT});

export const isAuthorized = async (dispatch) => {
    dispatch(setReady(false));

    const isAuthorized = await api.isAuthorized();

    if (isAuthorized instanceof Error) {
        if (isAuthorized.message === ErrorUnauthorized) {
            dispatch(signout(dispatch));
            dispatch(setReady(true));
            return
        }
        dispatch(setReady(true));
        throw isAuthorized;
    }

    dispatch(signin());
    dispatch(setReady(true));
}