import React, { useEffect, useState } from 'react';
import { IconUi } from '../../../../components/IconUi';
import SelectUi from '../../../../components/SelectUi';
import ButtonUi from '../../../../components/StyledButtonUi';
import { Group } from '../../../../entities/group';
import { User } from '../../../../entities/user';

import './VacancyCreationCard.scss';

type CreatedVacancy = {
    name?: string
    group_id?: number
    profile_id?: number
    hiring_manager_id?: number
    description?: string
}

type VacancyCardProps = {
    vacancy?: CreatedVacancy
    groupsList: Array<Group>
    usersList: Array<User>
    onCreate: (vacancy: CreatedVacancy) => void
    onDiscard: () => void
}
type VacancyFieldsToBeEdited = 'name' | 'group_id' | 'hiring_manager_id' | 'description'
const VacancyCreationCard = ({
    vacancy,
    groupsList,
    usersList,
    onCreate,
    onDiscard
}: VacancyCardProps) => {

    const [editedVacancyData, setEditedVacancyData] = useState<CreatedVacancy | null>(vacancy || null);

    useEffect(() => {
        vacancy && setEditedVacancyData(vacancy);
    }, [vacancy])

    const onChangeField = ({fieldName, value}: {fieldName: VacancyFieldsToBeEdited, value: any}) => {
        setEditedVacancyData(data => {
            if (data) {
                return {...data, [fieldName]: value}
            }
            return {[fieldName]: value}
        })
    }

    const onClickDiscardButton = () => {
        onDiscard && onDiscard();
        setEditedVacancyData(null);
    }

    const vacancyToShow = editedVacancyData;

    return (
        <div className={'vacancy-creation-card'}>
            <div className={'vacancy-creation-card-content'}>
                <div className={'vacancy-creation-card-info'}>
                    <input
                        className={'vacancy-creation-card-info-input'}
                        value={vacancyToShow?.name || ''}
                        onChange={(e) => onChangeField({ fieldName: 'name', value: e.target.value})}
                    />
                    <SelectUi 
                        placeholder='Group'
                        value={vacancyToShow?.group_id || null}
                        options={[
                            {key: 'none', value: null, text: '-'},
                            ...(groupsList || []).map((g: Group) => ({key: g.id, value: g.id, text: g.name}))
                        ]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: {value: number}) => onChangeField({ fieldName: 'group_id', value: value})}
                    />
                    <SelectUi 
                        placeholder='Hiring manager'
                        value={vacancyToShow?.hiring_manager_id || null}
                        options={[
                            {key: 'none', value: null, text: '-'},
                            ...(usersList || []).map((u: User) => ({key: u.service.id, value: u.service.id, text: u.surname + ' ' + u.name}))
                        ]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: {value: number}) => onChangeField({ fieldName: 'hiring_manager_id', value: value})}
                    />
                </div>
                <div className='vacancy-creation-card-description'>
                    <textarea
                        className={'vacancy-creation-card-description-textarea'}
                        value={vacancyToShow?.description || ''}
                        onChange={(e) => onChangeField({ fieldName: 'description', value: e.target.value})}
                    />
                </div>
            </div>
            <div className='vacancy-creation-card-toolbar'>
                <ButtonUi
                    disabled={!editedVacancyData || !editedVacancyData.name}
                    onClick={() => editedVacancyData && onCreate(editedVacancyData)}
                >
                    <IconUi name='check'/>
                </ButtonUi>
                <ButtonUi
                    onClick={onClickDiscardButton}
                >
                    <IconUi name='trash'/>
                </ButtonUi>
            </div>
        </div>
    )
}

export default VacancyCreationCard;