import React, { useContext, useState, useMemo, useCallback }from "react";
import ModalUi from "../../../components/ModalUi";
import { store } from "../../../store";
import PersonCard from "../../Persons/PersonCard";
import { deepEqual } from '../../../utils';
import { handleError } from '../../../errorHandler';
import * as personsActs from '../../../redux/persons/actions';
import * as relActs from '../../../redux/relations/actions';
import * as vacActs from '../../../redux/vacancies/actions';
import * as tagsActs from '../../../redux/tags/actions';
import * as profActs from '../../../redux/profiles/actions';
import VacanciesModal from "../../Persons/VacanciesModal";
import ConfirmModalUi from "../../../components/ConfirmModalUi";
import { zeroPerson } from "../../../person";
import { Form } from "semantic-ui-react";

import { Relation, Stage } from "../../../entities/relation";
import { Person } from "../../../entities/person";
import { Tag } from "../../../entities/tag";

import './styles.scss';

type CreatedVacancy = {
    name?: string
    group_id?: number
    profile_id?: number
    hiring_manager_id?: number
    description?: string
}

type PersonsDuplicateModalProps = {
    duplicateIds?: Array<number>
    onClose?: () => void
    onModalDiscard?: () => void
    onCreate?: () => void
    onEdit?: (person: Person) => void
    onChangeField?: ({ e, id, data}: {
            e: React.FormEvent<HTMLInputElement>,
            id: number,
            data?: {[field: string]: any}
        }) => void
    onSubmit?: (person: Person) => void
    onDiscard?: (id: number) => void
    onBook?: (
        { personId, systemUserId }:
        { personId: number, systemUserId: number }
    ) => void
    onUnbook?: (
        { personId }:
        { personId: number }
    ) => void
    onChangeStage?: (
        { relation_id, stage_type_id }: 
        { relation_id: number, stage_type_id: number }
    ) => void
    checkForErrors?: (
        { value, validateOptions, personId, fieldName }: {
            value: string,
            validateOptions: {
                type: string,
                required: boolean,
                size: number,
            },
            personId: number,
            fieldName: string,
        }) => void
    onOpenVotesModal?: (id: number) => void
    onChangeTag?: (tag: Tag) => Promise<void>
    onCreateTag?: (
        { personId, label }:
        { personId: number, label: string}
    ) => Promise<void>
    onAddTagId?: (
        { personId, tagId }:
        { personId: number, tagId: number}
    ) => Promise<void>
    onDeleteTagId?: (
        { personId, tagId }:
        { personId: number, tagId: number}
    ) => Promise<void>
    onClickProfileInput?: (
        { personId, profileId }:
        { personId: number, profileId: number}
    ) => void
    onCreateProfile?: (name: string) => void
    onDeleteRelation?: (relationId: any) => void
}


const PersonsDuplicateModal = (handlersToOverride: PersonsDuplicateModalProps) => {
    const { state, dispatch } = useContext<any>(store);

    const {
        persons: {
            map,
            editedId,
            selected,
            selectedErrors,
            duplicateIds,
        },
        users: {
            map: usersMap,
            allIds: usersAllIds,
        }, 
        user: {
            userData: currentUser,
        },
        relations: {
            map: relationsMap,
            byPersonId,
            stageTypesAllIds,
            stageTypesMap,
            stagesMapByRelationId,

            processingCancelReasons,
        },
        vacancies: {
            map: vacanciesMap,
            allIds: vacanciesAllIds,
            lastCreatedId: vacanciesLastCreatedId
        },
        tags: {
            map: tagsMap,
            allIds: tagsAllIds,
        },
        profiles: {
            list: profilesList,
            map: profilesMap,
        },
        groups: {
            list: groupsList,
            map: groupsMap
        }
    } = state;

    const [vacanciesModalData, setVacanciesModalData] = useState<{personId: number, profileId: number} | null>(null);
    
    const [relationIdToDelete, setRelationIdToDelete] = useState<number | null>(null);
    const [isUserTryingToCreate, setIsUserTryingToCreate] = useState<boolean>(false);
    const [isUserTryingToDiscard, setIsUserTryingToDiscard] = useState<boolean>(false);

    const onClickEdit = useCallback((person) => {
        personsActs.select({dispatch, person});
    }, [dispatch])

    const relationsById = useMemo(() => {
        const byPersonIdWithNames: {
            [personId: string]: Array<Relation & {vacancy_name: string, stages: Array<Stage>}>
        } = {};
        Object.keys(byPersonId).map(personId => {
            byPersonIdWithNames[personId] = byPersonId[personId].map(((r: Relation) => {
                const vacancy = vacanciesMap[r.vacancy_id];
                const stages = stagesMapByRelationId[r.id];
                return {
                    ...r, 
                    vacancy_name: vacancy && vacancy.name,
                    stages: stages? stages.sort((a: Relation, b: Relation) => a.id - b.id): [],
                } 
            }))
            return undefined
        })
        return byPersonIdWithNames
    }, [byPersonId, vacanciesMap, stagesMapByRelationId]);

    const checkForErrors = useCallback(({value, validateOptions, personId, fieldName}) => {
        personsActs.checkForErrors({ dispatch, value, validateOptions, id: personId, fieldName});
    }, [dispatch])

    const deletePersonFromSelected = useCallback((id) => {
        personsActs.deleteSelected(dispatch, id);
    }, [dispatch]);

    const handleDiscard = useCallback((id) => {
        personsActs.discard(dispatch);
    }, [dispatch]);

    const handleFieldChange = useCallback(({ e, id, data }) => {
        personsActs.updateSelected({
            dispatch, 
            id, 
            data: data ? 
                data  
                : 
                {[e.target.name]: e.target.value}   
            })
    }, [dispatch]);

    const onCreate = useCallback(() => {
        personsActs.create(dispatch, {...selected.new, service: undefined})
            .then(()=>{
                setIsUserTryingToCreate(false);
                personsActs.setDuplicateIds({dispatch, ids: null});
                deletePersonFromSelected('new');
            })
            .catch(err => handleError(err));
    }, [deletePersonFromSelected, dispatch, selected]);

    const handleUpdate = useCallback((data) => {
        personsActs.update(dispatch, data)
            .catch(err => handleError(err));
    }, [dispatch]);

    const handleBook = useCallback(({personId, systemUserId}) => {
        personsActs.book({dispatch, personId, systemUserId})
            .catch(err => handleError(err));
    }, [dispatch]);

    const handleUnbook = useCallback(({personId}) =>{
        personsActs.unbook({dispatch, personId})
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeStage = useCallback(({relation_id, stage_type_id}) => {
        relActs.addStage({dispatch, relation_id, stage_type_id})
            .catch(err => handleError(err));
    }, [dispatch])

    const onClickProfileInput = useCallback(({personId, profileId}) => {
        setVacanciesModalData({personId, profileId});
    }, []);

    const onAddVacanciesInModal = async (vacancyIds: Array<(string | number)>) => {
        await relActs.addList({dispatch, vacancy_ids: vacancyIds, person_id: vacanciesModalData?.personId})
            .catch(err => handleError(err));
    }

    const deleteRelation = async (relationId: number) => {
        relActs.deleteRelation({ dispatch, relationId })
            .catch(err => handleError(err));
    }

    const onDeleteRelation = useCallback((relationId) => {
        setRelationIdToDelete(relationId);
    }, [])

    const onCreateTag = useCallback(async ({personId, label}) => {
        await tagsActs.createAndAddToPerson({ dispatch, personId,  tag: { label }})
            .catch(err => handleError(err));
    }, [dispatch]);

    const onChangeTag = useCallback(async (tag) => {
        await tagsActs.update({ dispatch, tag })
            .catch(err => handleError(err));
    }, [dispatch]);
    
    const onAddTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.addTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onDeleteTagId = useCallback(async ({ personId, tagId }) => {
        await personsActs.deleteTagId({ dispatch, personId, tagId })
            .catch(err => handleError(err));
    }, [dispatch]);

    const onCreateProfile = useCallback(async (name) => {
        await profActs.add({dispatch, profile: {name}})
            .catch(err => handleError(err));
    }, [dispatch])

    const onCreateVacancy = async (
        vacancy: CreatedVacancy
    ) => {
        await vacActs.add(dispatch, {
            ...vacancy,
            group: vacancy.group_id ? 
                groupsMap[vacancy.group_id]
                :
                undefined,
            profile: vacancy.profile_id ?
                profilesMap[vacancy.profile_id]
                :
                undefined,
        })
            .catch(err => handleError(err));
    }

    const onModalDiscard = () => {
        deletePersonFromSelected('new');
        personsActs.setDuplicateIds({dispatch, ids: null})
    }

    // processing
    const onCancelProcessing = async ({
        processing_id,
        relation_id,
        initiator,
        cancelled_at,
        reason_id,
        comment,
    }: any) => {
        await relActs.cancelProcessingWithReason({
            dispatch,
            processing_id,
            relation_id,
            initiator,
            cancelled_at,
            reason_id,
            comment,
        })
    }

    if (!currentUser) {
        return null;
    }

    const filterVacanciesAllIdsForModal = () => {
        if (!vacanciesModalData) {
            return []
        }

        const relationExists = (
            {personId, vacancyId}:
            {personId: number, vacancyId: number}
        ) => {
            return byPersonId[personId]
                ?.some((rel: Relation) => rel.vacancy_id === vacancyId)
        }

        const vacancyHasTheSameProfile = (
            {vacancyProfileId, chosenProfileId}:
            {vacancyProfileId: number, chosenProfileId: number}
        ) => {
            return vacancyProfileId ?
                chosenProfileId === vacancyProfileId
                :
                chosenProfileId === -1
        }
    
        return vacanciesAllIds
            ?.reduce((filteredList: Array<number>, vacId: number) => {
                const vac = vacanciesMap[vacId];
                if (vac
                    &&
                    !relationExists({personId: vacanciesModalData.personId, vacancyId: vacId})
                    &&
                    vacancyHasTheSameProfile({vacancyProfileId: vac.profile?.id, chosenProfileId: vacanciesModalData.profileId})
                ) {
                    return [...filteredList, vacId];
                }

                return filteredList
            }, []);
    }

    const personCardHandlers = {
        onEdit: handlersToOverride.onEdit || onClickEdit,
        checkForErrors: handlersToOverride.checkForErrors || checkForErrors,
        onDeleteRelation: handlersToOverride.onDeleteRelation || onDeleteRelation,
        onClickProfileInput: handlersToOverride.onClickProfileInput || onClickProfileInput,
        onCreateProfile: handlersToOverride.onCreateProfile || onCreateProfile,
        onChangeField: handlersToOverride.onChangeField || handleFieldChange,
        onSubmit: handlersToOverride.onSubmit || handleUpdate,
        onDiscard: handlersToOverride.onDiscard || handleDiscard,
        onBook: handlersToOverride.onBook || handleBook,
        onUnbook: handlersToOverride.onUnbook || handleUnbook,
        onChangeStage: handlersToOverride.onChangeStage || onChangeStage,
        onChangeTag: handlersToOverride.onChangeTag || onChangeTag,
        onCreateTag: handlersToOverride.onCreateTag || onCreateTag,
        onAddTagId: handlersToOverride.onAddTagId || onAddTagId,
        onDeleteTagId: handlersToOverride.onDeleteTagId || onDeleteTagId,
    }

    return (
        <div>
            {duplicateIds?.length ?
                <ModalUi
                    open={true}  
                    onClose={()=>setIsUserTryingToDiscard(true)}
                    header={'There are candidates with similar contacts'}
                    className={'persons-duplicate-modal'}
                    headerClassName={'persons-duplicate-modal-header'}
                    contentClassName={'persons-duplicate-modal-content-container'}
                >
                    <div className={'persons-duplicate-modal-content'}>
                        {(() => {
                            const person = {...(selected.new || zeroPerson), service: {id: 'new'}};
                            const editable = true;
                            const isPristine = deepEqual(person, zeroPerson);
                            return <Form name='add person' onSubmit={() => setIsUserTryingToCreate(true)}>
                                <PersonCard
                                    data={person}
                                    editable={editable}
                                    onChangeField={handleFieldChange}
                                    onDiscard={() => setIsUserTryingToDiscard(true)}
                                    isPristine={isPristine}
                                    errors={selectedErrors.new}
                                    checkForErrors={checkForErrors}
                                    currentUser={undefined}
                                    stagesMapByRelationId={undefined}
                                    stageTypesAllIds={undefined}
                                    stageTypesMap={undefined}
                                    vacanciesMap={undefined}
                                    usersMap={undefined}
                                    tagsMap={undefined}
                                    tagsAllIds={undefined}
                                    onCreateTag={undefined}
                                    onChangeTag={undefined}
                                    onAddTagId={undefined}
                                    onDeleteTagId={undefined}
                                    profilesList={undefined}
                                    onClickProfileInput={undefined}
                                    onCreateProfile={undefined}
                                    onDeleteRelation={undefined}

                                    onCancelProcessing={onCancelProcessing}
                                    processingCancelReasons={processingCancelReasons}
                                />
                            </Form>
                        })()}
                        <div>
                            {duplicateIds.map((id: number) => {
                                const pers = map[id];
                                if (!pers) return null;
                                const person = selected[pers.service.id] || pers;
                                const editable = pers.service.id === editedId
                                const isPristine = deepEqual(pers, person);
                                return (
                                    <PersonCard
                                        key={id}
                                        relations={relationsById[pers.service.id]}
                                        data={person}
                                        editable={editable}
                                        isPristine={isPristine}
                                        errors={selectedErrors[pers.service.id]}
                                        currentUser={currentUser}
                                        stageTypesMap={stageTypesMap || {}}
                                        stageTypesAllIds={stageTypesAllIds || []}
                                        stagesMapByRelationId={stagesMapByRelationId}
                                        vacanciesMap={vacanciesMap}
                                        usersMap={usersMap}
                                        tagsMap={tagsMap}
                                        tagsAllIds={tagsAllIds}
                                        profilesList={profilesList}

                                        onCancelProcessing={onCancelProcessing}
                                        processingCancelReasons={processingCancelReasons}
                                        
                                        {...personCardHandlers}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </ModalUi>
                :
                null
            }
            
            {vacanciesModalData &&
                <VacanciesModal
                    header={
                        map[vacanciesModalData.personId]?.surname + ' ' +
                        map[vacanciesModalData.personId]?.name + ' ' +
                        map[vacanciesModalData.personId]?.midname
                    }
                    chosenProfile={profilesMap[vacanciesModalData.profileId] || {name: 'No profile', id: vacanciesModalData.profileId}}
                    vacanciesMap={vacanciesMap}
                    vacanciesAllIds={filterVacanciesAllIdsForModal()}
                    usersMap={usersMap}
                    usersAllIds={usersAllIds}
                    onAddVacancies={onAddVacanciesInModal}
                    onClose={() => setVacanciesModalData(null)}
                    onCreateVacancy={onCreateVacancy}
                    groupsList={groupsList}
                    createdVacancyId={vacanciesLastCreatedId}
                    clearCreatedVacancyId={() => vacActs.clearLastCreatedId(dispatch)}
                />
            }
            
            {relationIdToDelete && 
                <ConfirmModalUi
                    header={
                        `Do you want to delete 
                        ${vacanciesMap[relationsMap[relationIdToDelete]?.vacancy_id]?.name} from 
                        ${map[relationsMap[relationIdToDelete]?.person_id]?.surname} 
                        ${map[relationsMap[relationIdToDelete]?.person_id]?.name}?`}
                    confirmText='Delete'
                    rejectText='Cancel'
                    open={!!relationIdToDelete}
                    onClose={() => setRelationIdToDelete(null)}
                    onConfirm={() => {
                        deleteRelation(relationIdToDelete)
                            .then(() => {
                                setRelationIdToDelete(null);
                            })
                    }}
                    onReject={() => setRelationIdToDelete(null)}
                />
            }
            
            {isUserTryingToCreate && 
                <ConfirmModalUi
                    header='Do you want to create anyway?'
                    confirmText='Create'
                    rejectText='Continue editing'
                    open={true}
                    onClose={() => setIsUserTryingToCreate(false)}
                    onConfirm={handlersToOverride.onCreate || onCreate}
                    onReject={() => setIsUserTryingToCreate(false)}
                />
            }
            
            {isUserTryingToDiscard && 
                <ConfirmModalUi
                    header='Do you want to discard changes?'
                    confirmText='Discard changes'
                    rejectText='Continue editing'
                    open={true}
                    onClose={() => setIsUserTryingToDiscard(false)}
                    onConfirm={() => {

                        if (handlersToOverride.onModalDiscard) {
                            handlersToOverride.onModalDiscard();
                        } else {
                            onModalDiscard()
                        }
                        
                        setIsUserTryingToDiscard(false);
                    }}
                    onReject={() => setIsUserTryingToDiscard(false)}
                />
            }
        </div>
    )
}

export default PersonsDuplicateModal;