import React, { useContext } from 'react';
import { Comment as SemanticComment } from 'semantic-ui-react';

import CommentUi from '../../../components/CommentUi';

import { store } from '../../../store';


const Comment = ({data, onSubmitComment, onDiscardComment, onDeleteComment}) => {

    const { state } = useContext(store);

    const { 
        users: { 
            map 
        } 
    } = state

    const replies = data.replies && data.replies.map((replyComment)=>
        <SemanticComment.Group key={replyComment.service.id}>
            <Comment 
                data={replyComment}
                onSubmitComment={onSubmitComment} 
                onDiscardComment={onDiscardComment}
                onDeleteComment={onDeleteComment}
            />
        </SemanticComment.Group>
    )
    
    return (
        <CommentUi 
            data={data}
            author={map[data.service.created_by]}
            onSubmitComment={onSubmitComment} 
            onDiscardComment={onDiscardComment}
            onDeleteComment={onDeleteComment}
            replies={replies}
        />
    )
}

export default Comment