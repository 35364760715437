import React, { useEffect, useContext, useState } from 'react';
import { Label, List, Button, Icon, Input, Segment } from 'semantic-ui-react';
import { Field, Formik, Form } from 'formik';

import './Stages.scss'

import { store } from '../../store';
import { handleError } from '../../errorHandler';

import * as relActs from '../../redux/relations/actions';


const Stages = () => {
    const { dispatch, state } = useContext(store);

    const {
        relations: {
            stageTypesMap,
            stageTypesAllIds,
        }
    } = state

    const [selectedStage, setSelectedStage] = useState({name: '', color: '#000000'});
    const [showForm, setShowForm] = useState(false);


    useEffect(() => {
        relActs.loadStageTypes(dispatch)
          .catch(err => {handleError(err)})
      }, [dispatch]);

    const handleSelect = (stage) => {
        setSelectedStage(stage);
        setShowForm(true);
    }

    const selectNone = () => {
        setSelectedStage({name: '', color: '#000000'});
        setShowForm(true);
    }

    const handleSubmit = (values, { setSubmitting }) => {
        selectedStage.name ? 
            relActs.updateStageType({dispatch, stageType: values})
                .then(()=>{setSelectedStage(values)})
                .catch(err => {handleError(err)})
                .finally(()=>{setSubmitting(false)})
            :
            relActs.addStageType({dispatch, stageType: values})
                .catch(err => {handleError(err)})
                .finally(()=>{setSubmitting(false)})
    }

    return (
        <div className="stages">
            <div className="stages-split">
                <div  className="stages-split-left">
                    <div className="sticky-ui">  
                        <Button icon onClick={selectNone}>
                            <Icon name='add' />
                        </Button>
                    </div>
                    <List divided selection>
                        {stageTypesAllIds?.map((stId, idx) => 
                            <List.Item onClick={()=>handleSelect(stageTypesMap[stId])} key={idx} className={(selectedStage.id === stId) ? 'stages-split-left-selected' : ''}>
                                <List.Header style={{display: 'inline-block'}}>
                                    {stageTypesMap[stId]?.name}
                                </List.Header>
                                <List.Content floated='right' >
                                <Label circular style={{background: stageTypesMap[stId]?.color, boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5'}} />
                                </List.Content>
                            </List.Item>
                            )
                        }
                    </List>
                </div>
                <div className="stages-split-right">
                    {showForm && (
                        <Formik
                            enableReinitialize
                            initialValues={selectedStage}
                            onSubmit={handleSubmit}
                        >
                            {({dirty, values}) => (
                                <Form>
                                    <Segment basic size='small'>
                                    <Field name="name">
                                        {({field}) => (
                                            <Input 
                                                fluid
                                                placeholder="Stage name" 
                                                {...field} 
                                                labelPosition='right'
                                                label={
                                                    <Field name="color">
                                                        {({field})=> (
                                                            <Label basic id="stages-color-input-label" style={{background: field.value}}>
                                                                <Input className='stages-color-input' id="name" {...field} type="color"/>
                                                            </Label>
                                                        )}
                                                    </Field>
                                                } 
                                            />
                                        )}
                                    </Field>
                                    <Button 
                                        fluid 
                                        type='submit' 
                                        disabled={!dirty || !values.name}
                                        style={{display: 'block'}}
                                    >
                                            {selectedStage.name ? 'Update' : 'Add'}
                                    </Button>
                                    </Segment>
                                </Form>
                            
                            )}

                        </Formik>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Stages
