import React, { useState } from "react"

import { Group } from "entities/group"
import { User } from "entities/user"

import { Accordion, Checkbox, Icon } from 'semantic-ui-react'
import { Vacancy } from "entities/vacancy"

export type AccordionUiProps = {
    users: User[]
    groups: Group[]
    vacancies: Vacancy[]
    selectedVacancies: { [id: number]: boolean }

    onSelectVacancy: (vacancy: Vacancy, forceSelect?: boolean) => void
}

export const AccordionUi = ({
    users,
    groups,
    vacancies,
    selectedVacancies,
    onSelectVacancy,
}: AccordionUiProps) => {
    const [active, setActive] = useState(-1)
    const [activeHiringManager, setActiveHiringManager] = useState("closed")

    const selectActive = (_: any, titleProps: any) => {
        const { index } = titleProps
        const newIndex = active === index ? -1 : index

        setActive(newIndex)
    }

    const selectActiveHiringManager = (_: any, titleProps: any) => {
        const { index } = titleProps
        const newIndex = activeHiringManager === index ? "closed" : index

        setActiveHiringManager(newIndex)
    }

    return (
        <Accordion styled>
            {groups.map((group, groupKey) => (
                <div key={groupKey}>
                    <Accordion.Title
                        active={active === groupKey}
                        index={groupKey}
                        onClick={selectActive}
                    >
                        <Icon name="dropdown" />
                        {group.name}
                    </Accordion.Title>
                    <Accordion.Content active={active === groupKey}>

                        {/* Нанимающие менеджеры этой группы */}

                        <Accordion styled>
                            {users
                                .filter(user => user.group_id === group.id)
                                .map((user, userKey) => (
                                    <div key={userKey}>
                                        <Accordion.Title
                                            active={activeHiringManager === `${groupKey}-${userKey}`}
                                            index={`${groupKey}-${userKey}`}
                                            onClick={selectActiveHiringManager}
                                        >
                                            <Icon name="dropdown" />
                                            {`${user.surname} ${user.name}`}
                                        </Accordion.Title>
                                        <Accordion.Content active={activeHiringManager === `${groupKey}-${userKey}`}>

                                            {/* Вакансии этого нанимающего менеджера */}

                                            <div>
                                                {vacancies
                                                    .filter(vacancy => vacancy.hiring_manager_id === user.service.id)
                                                    .map((vacancy, vacancyKey) => (
                                                        <div key={vacancyKey}>
                                                            <Checkbox
                                                                checked={selectedVacancies[vacancy.id]}
                                                                onClick={() => {
                                                                    onSelectVacancy(vacancy)
                                                                }}
                                                                label={vacancy.name}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        </Accordion.Content>
                                    </div>
                                ))}
                        </Accordion>
                    </Accordion.Content>
                </div>
            ))}
        </Accordion>
    )
}