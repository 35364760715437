import { handleError } from "../errorHandler";

export const thunkMiddleware = () => {
    return (dispatch, getState) => next => async (thunkOrAction) => {
        if (typeof thunkOrAction === 'function') {
            try {
                await thunkOrAction(dispatch, getState);
            } catch(err) {
                handleError(err);
            }
        } else {
            next(thunkOrAction);
        }
    };
}

export default thunkMiddleware