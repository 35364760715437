import React from 'react';
import { IconUi, IconGroupUi } from '../IconUi';
import './VoterAnswerIcon.scss'

export const VoterAnswerIcon = ({answer, backgroundSize='large', iconSize='small', ...props}) => {
    const buttonProps = {};
    switch (answer) {
        case ('relevant'):
            buttonProps.icon = 'checkmark';
            buttonProps.squareColor = 'green';
            break;
        case ('doubt'):
            buttonProps.icon = 'question';
            buttonProps.squareColor = 'yellow';
            break;
        case ('nonrelevant'):
            buttonProps.icon = 'close';
            buttonProps.squareColor = 'red';
            break;
        default:
            buttonProps.icon = 'question';
            buttonProps.squareColor = 'grey';
            break;
    }

    return (
        <IconGroupUi className='voter-answer-icon-container' {...props}>
            <IconUi 
                className='voter-answer-icon-background' 
                size={backgroundSize} 
                name='square' 
                color={buttonProps.squareColor}
            />
            <IconUi size={iconSize} name={buttonProps.icon} color='black' />
        </IconGroupUi>
    )
}

export default VoterAnswerIcon