import { fromJS } from 'immutable';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const calcOffset = (page, limit) => {
    if (page < 0) {
        return 0;
    }
    return (page-1) * limit;
}

export const sleep = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms)
    })
}

export const httpResponseStatusText = (response) => {
    return `${response.status} ${response.statusText}`
}

export const decodeFileData = (data) => {
    return decodeURIComponent(escape(atob(data)));
}

export const deepEqual = (left, right) => {
    const l = fromJS({...left});
    const r = fromJS({...right});

    return l.equals(r);
}

export const formatDate = (date) => {
    return new Date(date).toISOString();
}


export const parseVariablesFromHTMLStringFromHH = (file, variables) => {

    const parsableValues = {
        surname: {selector: 'p.resume__title', regex: /^[A-ZА-Яё]+\s?/gi, toSliceOut: '', type: 'string'},
        name: {selector: 'p.resume__title', regex: /\s[A-ZА-Яё]+\s?/gi, toSliceOut: '', type: 'string'},
        midname: {selector: 'p.resume__title', regex: /\s[A-ZА-Яё]+$/gi, toSliceOut: '', type: 'string'},
        cell: {selector: 'p', regex: /([+]?[0-9]{1,4}[\s]?(&nbsp;)?[(]?[0-9]{2,4}[)]?[\s]?(&nbsp;)?[0-9]{7})/gi, toSliceOut: /(&nbsp;)/gi, type: 'string'},
        email: {selector: 'p', regex: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gi, toSliceOut: '', type: 'string'},
        amount: {selector: 'span.resume__salary', regex: /[\d(&nbsp;)]+/gi, toSliceOut: /(&nbsp;)/gi, type: 'number'},
        scope: {selector: 'p.resume__position', regex: /[\s\S]+/gi, toSliceOut: '', type: 'string'},
        city: {selector: 'p', regex: /((Reside in)|(Проживает)): [a-zа-я- ё]+/gi, toSliceOut: /((Reside in)|(Проживает)): /gi, type: 'string'},
        currency: {selector: 'p', regex: /<span class="resume__salary">.+<\/span>[\n\s]*[a-zа-яё]+/gi, toSliceOut: /<span class="resume__salary">.+<\/span>[\n\s]*/gi, type: 'string'},
    }

    let parsedValues = {};

    try {
        let valuesToParse = variables ?
            variables.filter(field => parsableValues[field.fieldName])
            :
            Object.keys(parsableValues).map(v => {return {fieldName: v}})

        const parsedDoc = new DOMParser().parseFromString(file, "text/html");

        const correctValueType = (parsedValue, type) => {
            if (type === 'number')
                return parseInt(parsedValue, 10);

            return parsedValue
        }

        valuesToParse.forEach(field => {
            let selectorElementsList = parsedDoc;

            if (parsableValues[field.fieldName].selector) {
                selectorElementsList = parsedDoc.querySelectorAll(parsableValues[field.fieldName].selector);
            }

            let regexMatches = [];

            if (parsableValues[field.fieldName].regex) {
                [...selectorElementsList].forEach(el => {
                    if (parsableValues[field.fieldName].regex.test(el.innerHTML)) {
                        regexMatches.push(
                            correctValueType(
                                (el.innerHTML
                                    .match(parsableValues[field.fieldName].regex)[0]
                                    .replace(parsableValues[field.fieldName].toSliceOut, '')
                                    .trim()), parsableValues[field.fieldName].type)
                            )
                    }
                })
            }

            parsedValues[field.fieldName] = regexMatches[0] || ''

        })
    } catch (e) {
        return parsedValues
    }
    return parsedValues
}


export const validateId = (id) => {
    if (/^\d+$/.test(id) && !isNaN(parseInt(id)))
        return parseInt(id)

    return undefined
}

export const copyToClipboard = async (data) => {
    await navigator.clipboard.writeText(data);
}

export const deconstructFormikFieldsScheme = (fieldsScheme) => {
    return [...fieldsScheme.header, ...fieldsScheme.meta, ...fieldsScheme.description].flat()
}

export const addCommentDeeply = (comment, nestedComments, deepPath) => {
    if (deepPath && deepPath.length === 1) {
        if(nestedComments.length === 0) {
            nestedComments.push(comment);
            deepPath.push(0);

            return {nestedComments, deepPath}
        }

        nestedComments[deepPath[0]].replies = nestedComments[deepPath[0]].replies ?
            [...nestedComments[deepPath[0]].replies, comment]
            :
            [comment];
        deepPath.push(nestedComments[deepPath[0]].replies.length-1);

        return {nestedComments, deepPath}
    }

    let newDeepPath = [];

    let {
        nestedComments: retrievedNestedComments,
        deepPath: retrievedDeepPath
    } = addCommentDeeply(comment, nestedComments[deepPath[0]].replies, deepPath.slice(1));

    nestedComments[deepPath[0]].replies = retrievedNestedComments;
    newDeepPath = retrievedDeepPath;

    deepPath.push(newDeepPath[newDeepPath.length-1]);

    return {nestedComments, deepPath}
}


export const commentsToThread = (commentsArray) => {
    let idsMap = {};

    let nestedComments = [];

    [...commentsArray].forEach((comment, idx) => {
        if (!comment.is_reply) {
            nestedComments.push({...comment});


            idsMap[comment.service.id] = idsMap[comment.service.id] ?
                idsMap[comment.service.id].push([idx], nestedComments.length-1)
                :
                [nestedComments.length-1]
            return
        }

        if (!idsMap[comment.reply_for]) {
            console.warn("comment with no reply", comment);
            
            return
        }

        const pathToCommentItReplies = [...idsMap[comment.reply_for]];

        const {
            nestedComments: retrievedNestedComments,
            deepPath: address
        } = addCommentDeeply({...comment}, nestedComments, pathToCommentItReplies);

        nestedComments = retrievedNestedComments;

        idsMap[comment.service.id] = [...address];

    })

    return { nestedComments, idsMap}
}

export const flattenObjectOfObjects = (obj, flatObj={}, prefix='') => {
    Object.keys(obj).forEach(field => {
        if (typeof(obj[field]) === 'object' && obj[field] !== null) {
            flatObj = {...flatObj, ...flattenObjectOfObjects(obj[field], flatObj, prefix+field)};
            return
        }
        flatObj[prefix+field] = obj[field];
    })
    return flatObj
}

export const debug = (label="-", ...args) => {
    console.log(`%c[debug][${label}]:`, "color: green;", ...args);
}

export const validate = ({value, type, required=false, size=undefined}) => {
    let error;

        if (value) {

            if (size && (value.toString().length > size)) {
                error = 'Too long' 
            } 

            switch (type) {
                case "string": {
                    //Latin and Cyrillic alphabets only and '-'
                    if (!/^[a-zа-яё -]+$/i.test(value)) {
                        error = "Only Latin and Cyrillic characters allowed";
                    }
                    break
                }
                case "number": {
                    //only numbers
                    if (!/^\d+$/i.test(value)) {
                        error = "Only numbers allowed";
                    }
                    break
                }
                case "email": {
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                        error = "Invalid email address";
                    }
                    break
                }
                case "date": {
                    //yyyy-MM-dd
                    if (!/^\d{4}-[01]\d-[0-3]\d$/.test(value)) {
                        error = "Please use the yyyy-MM-dd format";
                    }
                    break
                }
                case "tel": {
                    // optional '+' in the beginning and only numbers after
                    if (!/^([+]?[0-9]{1,4}[\s]?[(]?[0-9]{2,4}[)]?[\s]?[0-9]{7})$/.test(value)) {
                        error = "Invalid phone number format";
                    }
                    break
                }
                case "any": {
                    break
                }
                default: {
                    break
                }
            }
        } else if (required) {
            error = "The field is empty"
        }

        return error
}

export const excludeEmptyFields = (obj) => {
    const copy = {...obj};

    Object.keys(copy).forEach(key => {
        if ((copy[key] !== null) && (!Array.isArray(copy[key])) && (typeof copy[key] === "object")) {
            copy[key] = excludeEmptyFields(copy[key]);
        } else if (copy[key] === "") {
            delete copy[key];   
        }
    })
    return copy;
}

export const parseIntegerInput = (num, base=10) => {
    const parsedValue = parseInt(num, base)
    return (!isNaN(parsedValue) && parsedValue) || ''
}

export const isObject = (x) => {
    return typeof x === 'object' && x !== null && !Array.isArray(x)
}

export const tryToNormalizeText = (text) => {
    if (typeof text !== 'string') {
        return text
    }
    return text.toLowerCase().trim()
}

export const tryToString = (value) => {
    if (!value) {
        return value
    }
}

export const filterObjectsList = ({list, filterOptions}) => {
    const compareText = ({text, option}) => {

        const stringOption = {...option, value: option.value.toString()};
        const stringText = text.toString();

        if (tryToNormalizeText(stringText).includes(tryToNormalizeText(stringOption.value))) {
            return true
        } else if (stringOption.transliterate) {
            if (tryToNormalizeText(stringText).includes(transliterate({text: tryToNormalizeText(stringOption.value)}))) {
                return true
            }
        }
    }
    const checkEl = ({el, option}) => {

        if (el === null || el === undefined) {
            return false;
        }
        if (option.value === null || option.value === undefined) {
            return true;
        }
        if (Array.isArray(el)) {
            for (let e of el) {
                if (checkEl({el: e, option})) {
                    return true
                }
            }
            return false
        }

        if (isObject(el)) {
            const entries = Object.entries(el);
            for (let e of entries) {
                if (option.prop && option.prop !== e[0]) {
                    continue;
                }

                if (checkEl({el: e[1], option: {...option, prop: undefined}})) {
                    return true
                }
            }
            return false
        }

        if (compareText({text: el, option: option})) {
            return true
        }
            
        return false;
    }

    return list.filter(el => {
        for (let option of filterOptions) {
            if (!checkEl({el, option})) {
                return false
            }  
        }
        return true
    })
}

const filterElement = ({el, option}) => {    
    const compareText = ({text, option}) => {
        const stringOption = {...option, value: option.value.toString()};
        const stringText = text.toString();

        if (tryToNormalizeText(stringText).includes(tryToNormalizeText(stringOption.value))) {
            return true
        } else if (stringOption.transliterate !== false) {
            if (tryToNormalizeText(stringText).includes(transliterate({text: tryToNormalizeText(stringOption.value)}))) {
                return true
            }
        }
    }

    if (el === null || el === undefined) {
        return false;
    }
    if (option.value === null || option.value === undefined) {
        return true;
    }
    if (Array.isArray(el)) {
        for (let e of el) {
            if (filterElement({el: e, option})) {
                return true
            }
        }
        return false
    }

    if (isObject(el)) {
        const entries = Object.entries(el);
        for (let e of entries) {

            if (option.prop && option.prop.propName !== e[0]) {
                continue;
            }
            
            if (filterElement({el: e[1], option: {...option, prop: option.prop?.child}})) {
                return true
            }
        }
        return false
    }

    if (compareText({text: el, option: option})) {
        return true
    }
        
    return false;
}


export const filterObjectsListMutable = ({list, filterOptions}) => {
    const returnList = [];
    list.map(el => {
        for (let option of filterOptions) {
            if (!filterElement({el, option})) {
                return undefined;
            }  
        }
        returnList.push(el);
        return undefined
    });

    return returnList;
}

export const filterObjectsMap = ({map, allIds, filterOptions}) => {
    const filteredIds = [];
    allIds.map(id => {
        for (let option of filterOptions) {
            if (!filterElement({el: map[id], option})) {
                return undefined;
            }  
        }
        filteredIds.push(id);
        return undefined
    });

    return filteredIds;
}

export const normalizeFilterOption = (op) => {
    if (!op.prop) return op;
    const pathArr = op.prop.split('.');
    const parsePathArr = (p) => ({propName: p[0], child: p.length && parsePathArr(p.slice(1))});
    return {...op, prop: parsePathArr(pathArr)};
};

export const filterPersonsWithRelations = ({list, filterOptions, relationsMap={}}) => {
    const returnList = [];
    list.map(el => {
        for (let option of filterOptions) {
            if (!filterElement({el: {...el, relations: relationsMap[el.service.id]}, option})) {
                return undefined;
            }  
        }
        returnList.push(el);
        return undefined
    });

    return returnList;
}

export const filterObjectsByListOfValues = (list, filterOptions) => {

    return list.filter((obj) => {
        const flatObjectJSON = JSON.stringify(
            Object.values(
                flattenObjectOfObjects(obj)
            ).filter(value=>value)
        ).toLowerCase();

        for (let value of filterOptions.values) {
            if (flatObjectJSON.includes(value.toLowerCase())) {
                return true;
            }
        }
        return false
    })
}

export const createPropsTreeWithPath = (obj, parentPath=[]) => {
    return Object.entries(obj).map(entry => {
        let path = [...parentPath, entry[0]];
        if (entry[1] && typeof entry[1] === 'object' && !Array.isArray(entry[1])) {
            return {
                prop: entry[0], 
                path,
                children: createPropsTreeWithPath(entry[1], path)
            };
        } else {
            return {prop: entry[0], path}
        }
    })
}

export const getPropertyValueByPath = ({object, propPath}) => {
    if (!object || !propPath || !propPath.length)
        return 

    return propPath.reduce((value, p) => {
        return value[p];
    }, {...object})
}

export const sortFlatObjects = (list, sortingOptions) => {
    if (!list) return list;
    if (sortingOptions.field === 'unsorted')
        return list

    const a = list.sort((a, b) => {
        const refValue = a[sortingOptions.field]
        const compareValue =  b[sortingOptions.field]

        if (refValue === undefined && compareValue === undefined) {
            return 0
        }

        const comparison = refValue && compareValue ?
            (refValue.toString().localeCompare(compareValue.toString(), undefined, {numeric: true, sensitivity: 'base'}) 
            * ((sortingOptions.sortingOrder === 'descending') ? (-1) : 1))
            :
            refValue ?
                -1
                :
                1
        return comparison
    })

    return a
}

export const sortObjectsByPropPath = ({list, sortingOption}) => {
    if (!list) return list;
    if (sortingOption.field === 'unsorted')
        return list
    return list.sort((a, b) => {
        const refValue = getPropertyValueByPath({object: a, propPath: sortingOption.propPath});
        const compareValue =  getPropertyValueByPath({object: b, propPath: sortingOption.propPath});

        if (refValue === undefined && compareValue === undefined) {
            return 0
        }

        const comparison = refValue && compareValue ?
            (refValue.toString().localeCompare(compareValue.toString(), undefined, {numeric: true, sensitivity: 'base'}) 
            * ((sortingOption.sortingOrder === 'descending') ? (-1) : 1))
            :
            refValue ?
                -1
                :
                1
        return comparison
    })
}

export const sortObjectsMapByPropPath = ({map, allIds, sortingOption}) => {
    if (!allIds || !map) return allIds;
    if (sortingOption.field === 'unsorted')
        return allIds
    return allIds.sort((a, b) => {
        const refValue = getPropertyValueByPath({object: map[a], propPath: sortingOption.propPath});
        const compareValue =  getPropertyValueByPath({object: map[b], propPath: sortingOption.propPath});

        if (refValue === undefined && compareValue === undefined) {
            return 0
        }

        const comparison = refValue && compareValue ?
            (refValue.toString().localeCompare(compareValue.toString(), undefined, {numeric: true, sensitivity: 'base'}) 
            * ((sortingOption.sortingOrder === 'descending') ? (-1) : 1))
            :
            refValue ?
                -1
                :
                1
        return comparison
    })
}
export const tryToNormalizeDate = (date) => {
    const normalDate = dayjs(date, ['YYYY-MM-DD', 'DD-MM-YYYY', 'YYYY.MM.DD', 'DD.MM.YYYY', 'YYYY/MM/DD', 'DD/MM/YYYY']).format('YYYY-MM-DD');
    return (normalDate !== 'Invalid Date') ? normalDate : date
}

export const getLocalDateStringFromObject = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
}

export const setTimeToZeroInDate = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
};

export const avoidZeroDate = (date) => {
    return (date && date.includes('0001-01-01')) ? '' : date
}

export const tryToFormatDateToISO = (date) => {
    const normalizedDate = tryToNormalizeDate(date);
    return dayjs(normalizedDate, 'YYYY-MM-DD').isValid()? new Date(normalizedDate).toISOString() : date
}

export function throttle(func, ms) {

    let isThrottled = false,
        savedArgs,
        savedThis;

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(() => {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            timeout = null;
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const transliterate = ({text}) => {
    const ruToEn = {
        "ё": "yo",
        "й": "j",
        "ц": "ts",
        "у": "u",
        "к": "k",
        "е": "ye",
        "н": "n",
        "г": "g",
        "ш": "sh",
        "щ": "shch",
        "з": "z",
        "х": "kh",
        "ъ": '"',
        "ф": "f",
        "ы": "y",
        "в": "v",
        "а": "a",
        "п": "p",
        "р": "r",
        "о": "o",
        "л": "l",
        "д": "d",
        "ж": "zh",
        "э": "e",
        "я": "ya",
        "ч": "ch",
        "с": "s",
        "м": "m",
        "и": "i",
        "т": "t",
        "ь": "'",
        "б": "b",
        "ю": "yu"
    };    const enToRu = {
        a: "а",
        b: "б",
        ch: "ч",
        d: "д",
        e: "э",
        f: "ф",
        g: "г",
        kh: "х",
        i: "и",
        j: "й",
        k: "к",
        l: "л",
        m: "м",
        n: "н",
        o: "о",
        p: "п",
        r: "р",
        s: "с",
        shch: "щ",
        sh: "ш",
        t: "т",
        ts: "ц",
        u: "у",
        v: "в",
        ya: "я",
        yo: "ё",
        ye: "е",
        yu: "ю",
        z: "з",
        zh: "ж",
        y: 'ы',
        '"': "ъ",
        "'": "ь"
    } 

    const transliterateToEn = (word) => {
        return word.toLowerCase().split('').map((char) => { 
            return ruToEn[char] || char; 
        }).join("");
    }

    const transliterateToRu = (word) => {
        const wordArray = word.toLowerCase().split('');
        let toOmit = [];
        return wordArray.map((char, idx) => {
            if (toOmit.includes(idx))
                return undefined

            if (char + wordArray[idx+1]+wordArray[idx+2]+wordArray[idx+3] in enToRu) {
                toOmit.push(idx+1);
                toOmit.push(idx+2);
                toOmit.push(idx+3);
                return enToRu[char + wordArray[idx+1]+wordArray[idx+2]+wordArray[idx+3]]
            }

            if (char + wordArray[idx+1]in enToRu) {
                toOmit.push(idx+1);
                return enToRu[char + wordArray[idx+1]]
            }

            if (char in enToRu) {
                return enToRu[char]
            }

            return char
        }).join("");
    }

    if (/[а-яё]/i.test(text)) {
        return transliterateToEn(text);
    } else {
        return transliterateToRu(text);
    }
}

export const shouldTextColorBeDark = (backgroundColor) => {

	if (backgroundColor.slice(0, 1) === '#') {
		backgroundColor = backgroundColor.slice(1);
	}

	const r = parseInt(backgroundColor.substr(0,2),16);
	const g = parseInt(backgroundColor.substr(2,2),16);
	const b = parseInt(backgroundColor.substr(4,2),16);

	const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	return (yiq >= 128) ? true : false;
};

export const arrToMap = (arr) => {
    if (!arr) {
        return {};
    }
    let map = {}
    arr.forEach(el => {
        map[el.id] = el;
    });

    return map;
}

export const arrToMapAndIds = (arr) => {
    if (!arr) {
        return {};
    }
    let map = {};
    let allIds = [];
    arr.forEach(el => {
        map[el.id] = el;
        allIds.push(el.id);
    });

    return {map, allIds};
}

export const arrToMapByServiceId = (arr) => {
    if (!arr) {
        return {};
    }
    let map = {}
    arr.forEach(el => {
        map[el.service.id] = el;
    });

    return map;
}

export const arrToMapAndIdsByServiceId = (arr) => {
    if (!arr) {
        return {};
    }
    let map = {};
    let allIds = [];
    arr.forEach(el => {
        map[el.service?.id] = el;
        allIds.push(el.service.id);
    });

    return {map, allIds};
}

export const filterTree = ({tree, conditionToFilterOut}) => {
    const a = tree && tree.reduce((filteredTree, el) => {
        
        if (el.children) {
            let filteredSubtree = filterTree({tree: el.children, conditionToFilterOut});

            if (filteredSubtree.length === 0) {
                return filteredTree
            }

            return [...filteredTree, {...el, children: filteredSubtree}]
        } else {
            if (conditionToFilterOut && conditionToFilterOut(el)) {
                return filteredTree
            }
        }
        return [...filteredTree, el]
    }, [])
    return a || tree
}

export const buildVacancyTree = (vacanciesList) => {
    const tree = [];

    const addLayer = ({el, tree, propName, parentKey = ''}) => {
        const propId = (el[propName] && el[propName].id) || 0;
        const propKey = parentKey + propName + ((el[propName] && el[propName].id) || 0);

        let propGroup = tree.find(p => p.id === propId);

        !propGroup && (() => {
            tree.push({
                id: propId,
                key: propKey,
                name: (el[propName] && el[propName].name) || 'no ' + propName,
                children: [],
            });
            propGroup = tree[tree.length - 1];
        })();
        return propGroup
    }

    vacanciesList.map((v) => {
        let profileLayer = addLayer({el: v, tree, propName: 'profile'});
        let groupLayer = addLayer({el: v, tree: profileLayer.children, propName: 'group', parentKey: profileLayer.key});

        groupLayer.children.push(v);

        return undefined
    })
    return tree
}

export const convertTreeToDropdownStruct = (tree) => {
    if (!tree) return;
    return tree.map((el) => {
        return {
            value: el.path.join('.'), 
            label: el.prop, 
            children: convertTreeToDropdownStruct(el.children)
        }
    })
}

export const convertNestedObjectToDropdownStruct = (obj) => {
    const tree = createPropsTreeWithPath(obj);
    return convertTreeToDropdownStruct(tree)
}

export const parseDate = (date) => new Date(date);

export const isDateValid = (date) => date instanceof Date && !isNaN(date);

export const hasParentWithGivenClass = ({className, element}) => {
    while (element) {
        if (element.classList.contains(className)) {
            return true;
        }
        element = element.parentElement 
    }
    return false;
}

export const getURLSearchParamsMap = (urlSearch) => {
    const paramsMap = {};
    const urlParams = new URLSearchParams(urlSearch)

    for (let entry of urlParams.entries()) {
        paramsMap[entry[0]] = entry[1];
    }

    return paramsMap
}

export const getURLSearchParamByName = ({urlSearch, name}) => {
    const paramsMap = getURLSearchParamsMap(urlSearch);

    return paramsMap[name];
}
export const download = (file) => {
    const  blob = new Blob([(Buffer.from(file.binary_data, 'base64'))]);
            
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.setAttribute('href', href);
    link.setAttribute('download', `${file.file_name}.${file.ext}`);
    document.body.appendChild(link);
    link.click();

    link.remove()
}